import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouterLinkButton } from "./ui/Button";
import { useAuth } from "../lib/auth";
import { useLocation } from "react-router-dom";

export function AccountMenu() {
    const auth = useAuth();
    const location = useLocation();

    if (auth.state === "authenticated") {
        const user = auth.user;
        return <div className="flex flex-wrap items-center">
            <div className="muted mr-4 basis-full mb-2 sm:mb-0 sm:basis-auto"><FontAwesomeIcon className="mr-1" icon={faUser} /> {user.name}</div>
            <RouterLinkButton size="md" to="/account" state={{from: location.pathname}} kind="secondary" className="mr-2">Settings</RouterLinkButton>
            <RouterLinkButton size="md" to="/logout" kind="destructive" className="mr-1">Log out</RouterLinkButton>
        </div>;
    } else if (auth.state === "unauthenticated") {
        return <RouterLinkButton size="md" to="/login" kind="primary" state={{from: location}}>Log in</RouterLinkButton>;
    } else {
        return <div className="muted">Loading...</div>;
    }
}
