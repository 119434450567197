import * as XLSX from 'xlsx';
import { questionTypes } from '../components/surveys/questionTypes.jsx';
import { getFileURL } from './file.js';
import { ScheduleModel } from './ScheduleModel.js';

/**
 * @typedef ExportDataType
 * @property {string} id
 * @property {string} name
 * @property {string} description
 * @property {(cache: SharedExportCache, eventID: string) => Promise<import('xlsx').Sheet>} export
 */

export class SharedExportCache {
    /**
     * @param {import('./API.js').APIContext} api 
     */
    constructor(api) {
        this.cache = new Map();
        this.api = api;
    }

    /**
     * @param {string} path 
     * @param {string} query 
     * @returns {Promise<any>}
     */
    async getURL(path, query) {
        const fullPath = path + (query ? '?' + new URLSearchParams(query).toString() : '');
        if (this.cache.has(fullPath)) {
            return await this.cache.get(fullPath);
        }
        const promise = this.api.get(fullPath);
        this.cache.set(fullPath, promise);
        return await promise;
    }
}

function getSurveyHeader(questions) {
    const ordered = [...questions].sort((a, b) => a.order - b.order);
    return {order: ordered.map(q => q.id), names: ordered.map(q => q.name)};
}

async function getSurveyRow(questions, values, api) {
    const row = {};
    await Promise.all(questions.map(async question => {
        if (!values[question.id]) return;
        const type = questionTypes.get(question.question_type);
        const response = await type.exportResponse(question, values[question.id]);
        if (response?._type === "file") {
            row[question.id] = getFileURL(api, response.id);
        } else if (response?._type === "categories") {
            // TODO: Categories
            row[question.id] = response.categories.join(", ");
        } else if (response?._type === "timeslots") {
            // TODO: Timeslots
            row[question.id] = response.timeslots.join(", ");
        } else {
            row[question.id] = response?.toString();
        }
    }));
    return row;
}

/**
 * @type {ExportDataType[]}
 */
export const dataTypes = [
    {
        id: "projects",
        name: "Projects",
        description: "Details, categories, names of owners",
        export: async (cache, eventID) => {
            // Get a list of projects.
            const projects = await cache.getURL(`/events/${eventID}/projects`);

            // Get the project details survey.
            const questions = await cache.getURL(`/events/${eventID}/surveys/project/questions`, {list_all: true});
            const { order, names } = getSurveyHeader(questions);
            
            // For each project, fetch its details.
            const rows = [];
            for (const project of projects) {
                let projectDetails = [];
                try {
                    projectDetails = await cache.getURL(`/project/${project.id}/details`);
                } catch (err) {
                    if (err.status !== 404) throw err;
                }

                const users = await cache.getURL(`/projects/${project.id}/users/member`);

                rows.push({
                    ...(await getSurveyRow(questions, projectDetails, cache.api)),
                    id: project.id,
                    name: project.name,
                    category: project.category?.name,
                    users: users.map(u => u.email).join(", "),
                });
            }

            const sheet = XLSX.utils.json_to_sheet(rows, {header: ["id", "name", "category", "users", ...order]});
            XLSX.utils.sheet_add_aoa(sheet, [["ID", "Name", "Category", "Author(s)", ...names]], {origin: "A1"});
            return sheet;
        },
    },
    {
        id: "categories",
        name: "Categories",
        description: "IDs, names of categories",
        export: async (cache, eventID) => {
            const categories = await cache.getURL(`/events/${eventID}/categories`);
            const sheet = XLSX.utils.json_to_sheet(categories, {header: ["id", "name"]});
            XLSX.utils.sheet_add_aoa(sheet, [["ID", "Name"]], {origin: "A1"});
            return sheet;
        },
    },
    {
        id: "users",
        name: "Users",
        description: "Names, emails, roles",
        export: async (cache, eventID) => {
            const users = await cache.getURL(`/events/${eventID}/users`);
            const questions = await cache.getURL(`/events/${eventID}/surveys/registration/questions`, {list_all: true});
            const { order, names } = getSurveyHeader(questions);
            const rows = [];
            for (const user of users) {
                let userDetails = [];
                try {
                    userDetails = await cache.getURL(`/events/${eventID}/registrations/${user.id}`);
                } catch (err) {
                    if (err.status !== 404) throw err;
                }

                rows.push({
                    ...(await getSurveyRow(questions, userDetails, cache.api)),
                    id: user.id,
                    name: user.name,
                    email: user.email,
                    roles: user.roles.map(r => r.name).join(", "),
                });
            }
            const sheet = XLSX.utils.json_to_sheet(rows, { header: ["id", "email", "name", "roles", ...order] });
            XLSX.utils.sheet_add_aoa(sheet, [["ID", "Email", "Name", "Role(s)", ...names]], { origin: "A1" });
            return sheet;
        },
    },
    {
        id: "schedule",
        name: "Schedule",
        description: "Table of judges and timeslots",
        export: async (cache, eventID) => {
            // 100% legit code
            const fakeAPI = {get: (a, b) => cache.getURL(a, b)};
            const scheduleModel = await ScheduleModel.fetch(fakeAPI, eventID);
            const timeslots = scheduleModel.getTimeslots();
            const rows = [];
            for (const user of scheduleModel.getUsers()) {
                const row = {user: user.email};
                const projects = scheduleModel.getProjectsForUser(user.id);
                timeslots.forEach(({id}) => {
                    const projectsForTimeslot = projects[id];
                    if (!projectsForTimeslot) return;

                    if (projectsForTimeslot.length === 1) {
                        const project = projectsForTimeslot[0];
                        row[id] = `${project.name ?? "Untitled"} (${project.id})`;
                    } else if (projectsForTimeslot.length > 1) {
                        row[id] = `${projectsForTimeslot.length} projects`;
                    }
                });
                rows.push(row);
            }
            const dates = timeslots.map(({start, ...timeslot}) => ({...timeslot, start: new Date(start)}));
            dates.sort((a, b) => a.start - b.start);

            const sheet = XLSX.utils.json_to_sheet(rows, { header: ["user", ...dates.map(t => t.id)] });
            XLSX.utils.sheet_add_aoa(sheet, [["User", ...dates.map(t => t.start)]], { origin: "A1", dateNF: "h:mm AM/PM" });
            return sheet;
        },
    },
    {
        id: "reviews",
        name: "Reviews",
        description: "Reviews left by judges",
        export: async (cache, eventID) => {
            const projects = await cache.getURL(`/events/${eventID}/projects`);
            const questions = await cache.getURL(`/events/${eventID}/surveys/review/questions`, { list_all: true });
            const { order, names } = getSurveyHeader(questions);
            const rows = [];
            for (const project of projects) {
                const reviews = await cache.getURL(`/project/${project.id}/reviews/all`);
                for (const review of reviews) {
                    rows.push({
                        ...(await getSurveyRow(questions, review.survey_answers, cache.api)),
                        user: review.user.email,
                        projectID: project.id,
                        projectName: project.name,
                    });
                }
            }
            const sheet = XLSX.utils.json_to_sheet(rows, { header: ["projectID", "projectName", "user", ...order] });
            XLSX.utils.sheet_add_aoa(sheet, [["Project ID", "Project Name", "Author", ...names]], { origin: "A1" });
            return sheet;
        },
    },
];

/**
 * @typedef ExportFormat
 * @property {string} id
 * @property {string} name
 * @property {string} description
 * @property {boolean} supportsMulti
 * @property {(sheets: {name: string, sheet: import('xlsx').Sheet}[], baseFilename: string) => void} export
 */

/**
 * @type {ExportFormat[]}
 */
export const formats = [
    {
        id: "xlsx",
        name: "Excel",
        supportsMulti: true,
        description: ".xlsx",
        export: async (sheets, baseFilename) => {
            const workbook = XLSX.utils.book_new();
            sheets.forEach(({ sheet, name }) => {
                XLSX.utils.book_append_sheet(workbook, sheet, name);
            });
            XLSX.writeFile(workbook, baseFilename + ".xlsx");
        },
    },
    {
        id: "csv",
        name: "CSV",
        supportsMulti: false,
        description: ".csv",
        export: async (sheets, baseFilename) => {
            const workbook = XLSX.utils.book_new();
            sheets.forEach(({sheet, name}) => {
                XLSX.utils.book_append_sheet(workbook, sheet, name);
            });
            XLSX.writeFile(workbook, baseFilename + ".csv");
        },
    },
    {
        id: "ods",
        name: "OpenDocument Spreadsheet",
        supportsMulti: true,
        description: ".ods",
        export: async (sheets, baseFilename) => {
            const workbook = XLSX.utils.book_new();
            sheets.forEach(({ sheet, name }) => {
                XLSX.utils.book_append_sheet(workbook, sheet, name);
            });
            XLSX.writeFile(workbook, baseFilename + ".ods");
        },
    },
];

/**
 * @param {string[]} dataTypeIDs
 * @param {string} formatID
 * @param {import('./API.js').APIContext} api
 * @param {string} eventID
 */
export async function performExport(dataTypeIDs, formatIDs, api, eventID) {
    const types = dataTypes.filter(type => dataTypeIDs.includes(type.id));
    const format = formats.find(f => formatIDs.includes(f.id));
    const sheets = [];
    const cache = new SharedExportCache(api);
    for (const type of types) {
        const sheet = await type.export(cache, eventID);
        sheets.push({name: type.name, sheet});
    }
    format.export(sheets, `${eventID}-${types.map(type => type.id).join("-")}`);
}
