import { useContext } from "react";
import { useEvent, usePermissionChecker } from "../../../../components/EventLayout";
import { ProjectContext } from "../view";
import permissions from '../../../../permissions.json';
import { SurveyForm } from "../../../../components/surveys/SurveyForm";
import { submitSurvey } from "../../../../lib/surveys";
import { useState } from "react";
import { SurveysService } from "../../../../client";
import { useAPI, useRequest } from "../../../../lib/API";
import { useEffect } from "react";
import { useMemo } from "react";
import { questionTypes } from "../../../../components/surveys/questionTypes";
import { LoadingText } from "../../../../components/ui/Loading";
import { ErrorText } from "../../../../components/ui/Input";
import { useTable } from "react-table";

function ManageResponses({ data, loading, error, reload }) {
    const tableOptions = useMemo(() => ({
        columns: [
            {
                Header: "Responder",
                accessor: "user.name",
            },
            ...((data && [...data[0]].sort((a, b) => (a.order ?? 0) - (b.order ?? 0)).map(question => {
                const type = questionTypes.get(question.question_type);
                return {
                    id: question.id,
                    Header: question.name,
                    accessor: response => {
                        const value = response.survey_answers[question.id];
                        if (value === "" || value === null || value === undefined) return null;
                        return <type.Viewer style="table" question={question} value={value} />;
                    },
                };
            })) || []),
        ],
        data: (data && data[1]) || [],
    }), [data]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(tableOptions);

    return <div>
        <h2 className="heading font-bold mb-2">Feedback!</h2>
        <p className="mb-4">See the feedback left for your project.</p>
        {loading && <LoadingText messages={[
            "Loading responses...",
            "Simulating birthday party...",
        ]} />}
        {error && <ErrorText>Couldn't load responses. Try reloading the page.</ErrorText>}
        {data && <div className="w-full overflow-x-auto">
            <table className="min-w-full" {...getTableProps()}>
                <thead className="sticky top-0 bg-gray-200 dark:bg-gray-700">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th className="first-of-type:pl-4 last-of-type:pr-4 px-3 py-2 text-center" {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return <tr className="even:bg-gray-100 dark:even:bg-gray-800" {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td className="px-3 py-1 text-center" {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                </td>
                            ))}
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>}
        {data && data.length === 0 && <div className="w-full flex justify-center items-center px-4 py-8">
            <div>You don't have any feedback yet. Check back later!</div>
        </div>}
    </div>;
}

export function ReviewsTab() {
    const { project } = useContext(ProjectContext);
    const { id: eventID } = useEvent();
    const [isSurveySubmitted, setSurveySubmitted] = useState(false);

    const check = usePermissionChecker();
    const api = useAPI();
    const reviewsRequest = useRequest(() => {
        return Promise.all([
            SurveysService.getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(eventID, "review", check()),
            SurveysService.getAllReviewsProjectProjectIdReviewsAllGet(project.id),
        ]);
    }, [eventID, project.id]);

    useEffect(() => {
        setSurveySubmitted(false);
    }, [project.id]);

    return <div>
        {(check(permissions.createAllFeedback) || (project?.user_type === "judge" && check(permissions.createAssignedFeedback))) && <>
            <h2 className="heading font-bold mb-2">Leave feedback</h2>
            <SurveyForm editable={check(permissions.editAllFeedback) || check(permissions.editOwnFeedback)} surveyType="review" existingResponseURL={`/project/${project.id}/reviews`} onSubmit={async (survey, _, questions) => {
                // TODO refactor submitSurvey thing
                await submitSurvey(api, "post", `/project/${project.id}/reviews`, questions, survey);
                setSurveySubmitted(true);
            }} onDelete={async () => {
                await SurveysService.deleteReviewProjectProjectIdReviewsDelete(project.id);
                setSurveySubmitted(false);
            }} disabled={existing => {
                if (existing) return true;
                return isSurveySubmitted;
            }} hint={existing => {
                if (isSurveySubmitted) return "Your feedback has been submitted!";
                if (existing) return "You've already submitted a review for this project.";
            }} emptyText="There aren't any review criteria to submit." />
        </>}

        {(check(permissions.viewAllFeedback) || (project?.user_type === "member" && check(permissions.viewProjectFeedback)) || (project?.user_type === "judge" && check(permissions.viewAssignedFeedback))) && <div>
            <hr className="my-6 dark:border-gray-700" />
            <ManageResponses {...reviewsRequest} />
        </div>}
    </div>;
}

const reviewsTabDefinition = {
    path: "reviews",
    name: "Feedback",
    showTab: ({
        project,
        checkPermission,
    }) => {
        if (checkPermission(permissions.createAllFeedback)) return true;
        if (project.user_type === "judge" && checkPermission(permissions.createAssignedFeedback)) return true;

        if (checkPermission(permissions.editAllFeedback)) return true;
        if (project.user_type === "judge" && checkPermission(permissions.editOwnFeedback)) return true;

        if (checkPermission(permissions.viewAllFeedback)) return true;
        if (project.user_type === "member" && checkPermission(permissions.viewProjectFeedback)) return true;
        if (project.user_type === "judge" && checkPermission(permissions.viewAssignedFeedback)) return true;
    },
    component: ReviewsTab,
};

export default reviewsTabDefinition;
