/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddonStatusRead } from '../models/AddonStatusRead';
import type { AddonStatusUpdate } from '../models/AddonStatusUpdate';
import type { JudgeAddUpdate } from '../models/JudgeAddUpdate';
import type { JudgeAssignmentRead } from '../models/JudgeAssignmentRead';
import type { TimeslotCreate } from '../models/TimeslotCreate';
import type { TimeslotRead } from '../models/TimeslotRead';
import type { TimeslotUpdate } from '../models/TimeslotUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScheduleService {

    /**
     * Start Scheduler For Event
     * @param eventId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static startSchedulerForEventEventsEventIdScheduleStartGet(
        eventId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/schedule/start',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Schedule
     * @param eventId
     * @param all
     * @returns JudgeAssignmentRead Successful Response
     * @throws ApiError
     */
    public static getEventScheduleEventsEventIdScheduleGet(
        eventId: string,
        all: boolean = false,
    ): CancelablePromise<Array<JudgeAssignmentRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/schedule',
            path: {
                'event_id': eventId,
            },
            query: {
                'all': all,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Event Schedule
     * @param eventId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetEventScheduleEventsEventIdScheduleDelete(
        eventId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/events/{event_id}/schedule',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Schedule
     * @param projectId
     * @returns JudgeAssignmentRead Successful Response
     * @throws ApiError
     */
    public static getProjectScheduleProjectsProjectIdScheduleGet(
        projectId: string,
    ): CancelablePromise<Array<JudgeAssignmentRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{project_id}/schedule',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Or Update Project Judges
     * @param projectId
     * @param requestBody
     * @returns JudgeAssignmentRead Successful Response
     * @throws ApiError
     */
    public static addOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPut(
        projectId: string,
        requestBody: Array<JudgeAddUpdate>,
    ): CancelablePromise<Array<JudgeAssignmentRead>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/projects/{project_id}/users/judges',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Judges From Project
     * @param projectId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDelete(
        projectId: string,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{project_id}/users/judges/delete',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Schedule Status
     * @param eventId
     * @returns AddonStatusRead Successful Response
     * @throws ApiError
     */
    public static getEventScheduleStatusEventsEventIdScheduleStatusGet(
        eventId: string,
    ): CancelablePromise<AddonStatusRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/schedule/status',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event Schedule Status
     * @param eventId
     * @param requestBody
     * @returns AddonStatusRead Successful Response
     * @throws ApiError
     */
    public static updateEventScheduleStatusEventsEventIdScheduleStatusPut(
        eventId: string,
        requestBody: AddonStatusUpdate,
    ): CancelablePromise<AddonStatusRead> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/events/{event_id}/schedule/status',
            path: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Event Timeslots
     * @param eventId
     * @returns TimeslotRead Successful Response
     * @throws ApiError
     */
    public static listEventTimeslotsEventsEventIdScheduleTimeslotsGet(
        eventId: string,
    ): CancelablePromise<Array<TimeslotRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/schedule/timeslots',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event Timeslots
     * @param eventId
     * @param requestBody
     * @returns TimeslotRead Successful Response
     * @throws ApiError
     */
    public static updateEventTimeslotsEventsEventIdScheduleTimeslotsPut(
        eventId: string,
        requestBody: Record<string, TimeslotUpdate>,
    ): CancelablePromise<Array<TimeslotRead>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/events/{event_id}/schedule/timeslots',
            path: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event Timeslots
     * @param eventId
     * @param requestBody
     * @returns TimeslotRead Successful Response
     * @throws ApiError
     */
    public static createEventTimeslotsEventsEventIdScheduleTimeslotsPost(
        eventId: string,
        requestBody: Array<TimeslotCreate>,
    ): CancelablePromise<Array<TimeslotRead>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/events/{event_id}/schedule/timeslots',
            path: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Event Timeslots
     * @param eventId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete(
        eventId: string,
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/events/{event_id}/schedule/timeslots',
            path: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
