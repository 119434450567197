import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from './ui/Button';

/**
 * @param {import('ui/Button.jsx').ButtonProps & {text: string}} props 
 */
export function CopyButton({text, ...props}) {
    const [copied, setCopied] = useState(false);

    return <Button size="sm" kind="secondary" onClick={() => {
        navigator.clipboard.writeText(text);
        setCopied(true);
    }} {...props}>
        {copied ? "Copied!" : <><FontAwesomeIcon icon={faCopy} className="mr-2" /> Copy</>}
    </Button>;
}
