import { useContext } from "react";
import { SurveysService } from "../../../../client";
import { useEvent, usePermissionChecker } from "../../../../components/EventLayout";
import { useRequest } from "../../../../lib/API";
import { useUser } from "../../../../lib/auth";
import { ProjectContext } from "../view";
import permissions from '../../../../permissions.json';
import { SurveyViewer } from "../../../../components/surveys/SurveyViewer";
import { LoadingText } from "../../../../components/ui/Loading";
import { ErrorText } from "../../../../components/ui/Input";

export function DetailsTab() {
    const { project, members } = useContext(ProjectContext);
    const { id: eventID } = useEvent();
    const currentUser = useUser();

    const check = usePermissionChecker();

    const { data: details, error: detailsError } = useRequest(async () => {
        const [questions, values] = await Promise.all([
            SurveysService.getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(eventID, "project", check()),
            SurveysService.getProjectDetailsProjectProjectIdDetailsGet(project.id),
        ]);
        return { questions, values };
    }, [eventID, project.id]);

    const myProject = members.data?.find(member => member.id === currentUser.id) !== undefined;
    const canEdit = check(permissions.editAllProject) || (check(permissions.editOwnProject) && myProject);

    return <div>
        {details && <SurveyViewer {...details} />}
        {(!details && !detailsError) && <LoadingText messages={["Loading more details..."]} />}
        {/* TODO: Improve this message! 
            Former solution: <ErrorText>Couldn't load details. Try reloading the page.</ErrorText>*/}
        {detailsError && <p>No project details found. {canEdit && <ErrorText>Please complete the required questions!</ErrorText>}</p>}
    </div>;
}

const detailsTabDefinition = {
    path: ".",
    name: "Details",
    showTab: () => true,
    component: DetailsTab,
};

export default detailsTabDefinition;
