/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SurveyQuestionCreate } from './SurveyQuestionCreate';

export type Create = {
    type: Create.type;
    question: SurveyQuestionCreate;
};

export namespace Create {

    export enum type {
        CREATE = 'create',
    }


}

