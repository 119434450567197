import { FormikProvider, useFormik } from "formik";
import { OneColumnLayout } from "../../components/OneColumnLayout";
import { Survey, useEmptyResponse } from "../../components/surveys/Survey";
import { Main } from "../../components/ui/Main";

export function FileUploaderTest() {
    const questions = [
        {
            id: "1",
            question_type: "file",
            name: "Upload a file",
            description: "This is a file upload question",
            required: true,
        },
    ];
    
    const emptyResponse = useEmptyResponse(questions);
    const formik = useFormik({
        initialValues: {
            survey: emptyResponse,
        },
    });

    return <OneColumnLayout title="File Uploader Test">
        <Main>
            <FormikProvider value={formik}>
                <Survey name="survey" questions={questions} />
            </FormikProvider>
        </Main>
    </OneColumnLayout>;
}
