/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Approval } from '../models/Approval';
import type { ApprovalRead } from '../models/ApprovalRead';
import type { ApprovalRoleCount } from '../models/ApprovalRoleCount';
import type { Create } from '../models/Create';
import type { Delete } from '../models/Delete';
import type { SurveyQuestionRead } from '../models/SurveyQuestionRead';
import type { SurveyResponseRead } from '../models/SurveyResponseRead';
import type { SurveyType } from '../models/SurveyType';
import type { Update } from '../models/Update';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SurveysService {

    /**
     * Get Approval
     * @param projectId
     * @returns Approval Successful Response
     * @throws ApiError
     */
    public static getApprovalProjectProjectIdApprovalsGet(
        projectId: string,
    ): CancelablePromise<Approval> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project/{project_id}/approvals',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Approval
     * @param projectId
     * @param requestBody
     * @returns Approval Successful Response
     * @throws ApiError
     */
    public static createApprovalProjectProjectIdApprovalsPost(
        projectId: string,
        requestBody: Approval,
    ): CancelablePromise<Approval> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/project/{project_id}/approvals',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Approval
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteApprovalProjectProjectIdApprovalsDelete(
        projectId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/project/{project_id}/approvals',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Approvals
     * @param projectId
     * @returns ApprovalRead Successful Response
     * @throws ApiError
     */
    public static getAllApprovalsProjectProjectIdApprovalsAllGet(
        projectId: string,
    ): CancelablePromise<Array<ApprovalRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project/{project_id}/approvals/all',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Approval Counts
     * @param projectId
     * @returns ApprovalRoleCount Successful Response
     * @throws ApiError
     */
    public static getApprovalCountsProjectProjectIdApprovalsCountGet(
        projectId: string,
    ): CancelablePromise<Record<string, ApprovalRoleCount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project/{project_id}/approvals/count',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Questions
     * @param eventId
     * @param surveyType
     * @param listAll
     * @returns SurveyQuestionRead Successful Response
     * @throws ApiError
     */
    public static getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(
        eventId: string,
        surveyType: SurveyType,
        listAll: boolean = false,
    ): CancelablePromise<Array<SurveyQuestionRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/surveys/{survey_type}/questions',
            path: {
                'event_id': eventId,
                'survey_type': surveyType,
            },
            query: {
                'list_all': listAll,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Manage Questions
     * @param eventId
     * @param surveyType
     * @param requestBody
     * @returns SurveyQuestionRead Successful Response
     * @throws ApiError
     */
    public static manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPut(
        eventId: string,
        surveyType: SurveyType,
        requestBody: Array<(Create | Update | Delete)>,
    ): CancelablePromise<Array<SurveyQuestionRead>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/events/{event_id}/surveys/{survey_type}/questions',
            path: {
                'event_id': eventId,
                'survey_type': surveyType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Registration
     * @param eventId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getRegistrationEventsEventIdRegistrationsGet(
        eventId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/registrations',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Registration
     * @param eventId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createRegistrationEventsEventIdRegistrationsPost(
        eventId: string,
        requestBody: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/events/{event_id}/registrations',
            path: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Registration
     * @param eventId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteRegistrationEventsEventIdRegistrationsDelete(
        eventId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/events/{event_id}/registrations',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Registrations
     * @param eventId
     * @returns SurveyResponseRead Successful Response
     * @throws ApiError
     */
    public static getAllRegistrationsEventsEventIdRegistrationsAllGet(
        eventId: string,
    ): CancelablePromise<Array<SurveyResponseRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/registrations/all',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Registration By User
     * @param eventId
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getRegistrationByUserEventsEventIdRegistrationsUserIdGet(
        eventId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/registrations/{user_id}',
            path: {
                'event_id': eventId,
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Review
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getReviewProjectProjectIdReviewsGet(
        projectId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project/{project_id}/reviews',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Review
     * @param projectId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createReviewProjectProjectIdReviewsPost(
        projectId: string,
        requestBody: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/project/{project_id}/reviews',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Review
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteReviewProjectProjectIdReviewsDelete(
        projectId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/project/{project_id}/reviews',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Reviews
     * @param projectId
     * @returns SurveyResponseRead Successful Response
     * @throws ApiError
     */
    public static getAllReviewsProjectProjectIdReviewsAllGet(
        projectId: string,
    ): CancelablePromise<Array<SurveyResponseRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project/{project_id}/reviews/all',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Details
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getProjectDetailsProjectProjectIdDetailsGet(
        projectId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/project/{project_id}/details',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Project Details
     * @param projectId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createProjectDetailsProjectProjectIdDetailsPost(
        projectId: string,
        requestBody: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/project/{project_id}/details',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Project Details
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteProjectDetailsProjectProjectIdDetailsDelete(
        projectId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/project/{project_id}/details',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
