import { FormikProvider, useFormik } from "formik";
import { useState } from 'react';
import Helmet from 'react-helmet';
import { AuthService } from "../client";
import { ColorBackgroundLayout } from "../components/ColorBackgroundLayout.tsx";
import { Button } from '../components/ui/Button';
import { Input, ErrorText } from '../components/ui/Input';

export function Create() {
    const [message, setMessage] = useState(null);
    const [isError, setError] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            confirm: "",
        },
        validate: ({ name, email, password, confirm }) => {
            const errors = {};
            if (!name) {
                errors.name = "Required";
            }
            if (!email) {
                errors.email = "Required";
            }
            if (!password) {
                errors.password = "Required";
            } else {
                const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
                if (password.length < 8) {
                    errors.password = "Password must be at least 8 characters";
                } else if (password.length < 16 && !specialCharRegex.test(password)) {
                    errors.password = "Password must contain at least one special character or be at least 16 characters";
                }
            }
            if (password !== confirm) {
                errors.confirm = "Passwords do not match";
            }
            return errors;
        },
        onSubmit: async ({ name, email, password }) => {
            setError(false);
            setMessage(null);
            try {
                const message = await AuthService.passwordRegisterAuthPasswordRegisterPost({
                    email,
                    password,
                    name,
                });
                setMessage(message);
            } catch (e) {
                console.error(e);
                if (e.body?.detail) {
                    setMessage(e.body.detail.toString());
                } else if (e.status) {
                    setMessage(`The server returned an unexpected status code: ${e.status}`);
                } else {
                    setMessage("An unexpected error occurred. Check your Internet connection.");
                }
                setError(true);
            }
        },
    });
    const { handleSubmit, getFieldProps, touched, errors } = formik;

    return (
        <FormikProvider value={formik}>
            <Helmet>
                <title>Create account</title>
            </Helmet>

            <form onSubmit={handleSubmit}>
                <ColorBackgroundLayout>
                    <h1 className="text-center title">Register</h1>
                    <div className="mt-3 mb-4 text-center">Enter an email, name, and password.</div>

                    {message && <div className={`text-center border rounded ${isError ? "border-red-500 bg-red-500/30" : "border-blue-500 bg-blue-500/30"} p-2`}>{message}</div>}

                    <div>
                        <Input className="block w-full max-w-lg mt-4" id="email" type="email" {...getFieldProps("email")} placeholder="Email" error={touched.email && errors.email} />
                        <ErrorText>{touched.email && errors.email}</ErrorText>
                        <Input className="block w-full max-w-lg mt-4" id="name" {...getFieldProps("name")} placeholder="Name" error={touched.name && errors.name} />
                        <ErrorText>{touched.name && errors.name}</ErrorText>
                        <Input className="block w-full max-w-lg mt-4" id="password" type="password" {...getFieldProps("password")} placeholder="Password" error={touched.password && errors.password} />
                        <ErrorText className="max-w-lg block w-full">{touched.password && errors.password}</ErrorText>
                        <Input className="block w-full max-w-lg mt-4" id="confirm" type="password" {...getFieldProps("confirm")} placeholder="Confirm Password" error={touched.confirm && errors.confirm} />
                        <ErrorText>{touched.confirm && errors.confirm}</ErrorText>
                    </div>
                    
                    <Button className="mt-6" size="md" type="submit" kind="primary" disabled={!formik.isValid || formik.isSubmitting}>Create account</Button>
                </ColorBackgroundLayout>
            </form>
        </FormikProvider>
    );
}
