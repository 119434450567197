import { ErrorText, Input, Select, Textarea } from "../ui/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faTimes, faPlus, faCaretRight, faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { Field, FieldArray, useFormikContext } from "formik";
import { Button } from "../ui/Button";
import { RadioGroup, Switch } from "@headlessui/react";
import { FileUploader } from "./FileUploader";
import { FileLink } from "../FileLink";
import { useEvent } from "../EventLayout";
import { useRequest } from "../../lib/API";
import { LoadingText } from "../ui/Loading";
import { DateTime } from "luxon";
import { StyledSwitch } from "../ui/Switch";
import { faMarkdown } from "@fortawesome/free-brands-svg-icons";
import { StyledExternalLink } from "../ui/Link";
// import ReactMarkdown from "react-markdown";
// import SyntaxHighlighter from "react-syntax-highlighter";
// import { useEffect, useState } from "react";
// import { nightOwl as dark, atelierCaveLight as light } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CategoriesService, ScheduleService } from "../../client";
import MarkdownText from "../ui/MarkdownText";

function getMultipleChoiceDefaults({question_type, details}) {
    if (question_type === "radio" || question_type === "checkbox" || question_type === "select") return details;
    return {
        options: [
            "Option 1",
            "Option 2",
        ],
    };
}

function validateMultipleChoiceEditor({details: {options}}) {
    // Ensure that there are no duplicate options and that each option is an empty string.
    const seen = new Set();
    for (const option of options) {
        if (option === "") return "Options cannot be empty";
        if (seen.has(option)) return `Duplicate option: ${option}`;
        seen.add(option);
    }
}

function MultipleChoiceEditor({shape, prefix, id, details}) {
    const { getFieldProps } = useFormikContext();
    return <FieldArray name={`${prefix}.options`}>
        {({ remove }) => {
            const newID = `${id}-new`;
            return <div className="mt-2">
                {details.options.map(() => false).concat([true]).map((isNew, index) => {
                    return <div className="flex items-center mb-1" key={index}>
                        {isNew ? <label htmlFor={newID} className="rounded-full w-4 h-4 bg-green-500 flex items-center justify-center cursor-pointer">
                            <FontAwesomeIcon icon={faPlus} className="text-white text-xs" />
                        </label> : shape}
                        <Input id={isNew ? newID : undefined} {...getFieldProps(`${prefix}.options[${index}]`)} type="text" className={`mx-2 w-full max-w-sm ${isNew ? "italic" : ""}`} placeholder={isNew ? "Add an option..." : ""} value={isNew ? "" : details.options[index]} />
                        {!isNew && details.options.length > 1 && <Button size="icon" kind="destructive-text" onClick={() => remove(index)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>}
                    </div>;
                })}
            </div>;
        }}
    </FieldArray>;
}

/**
 * @type {Map<string, {
 *     name: string,
 *     Editor: (props: {prefix: string, errors: any, id: string, details: any}) => import("react").ReactElement<any, any>,
 *     defaultSettings: (question?: Omit<Question, "id" | "order">) => any,
 *     validateEditor: (question: Omit<Question, "id" | "order">) => any,
 *     Field: (props: {question: Question, name: string, id: string, value: any, disabled: boolean}) => import("react").ReactElement<any, any>,
 *     defaultResponse: (question: Question) => any,
 *     validateResponse: (question: Question, value: any) => any,
 *     Viewer: (props: {question: Question, value: any}) => import("react").ReactElement<any, any>,
 *     exportResponse: (question: Question, value: any) => any,
 * }>}
 */
export const questionTypes = new Map([
    ["number", {
        name: "Number",
        Editor: () => {
            return <div className="mt-2 italic muted">
                Respondents will be able to enter a number.
            </div>;
        },
        defaultSettings: () => ({}),
        validateEditor: () => ({}),
        Field: ({name, id, disabled}) => {
            return <Field as={Input} disabled={disabled} type="number" name={name} id={id} placeholder="Enter a value..." />;
        },
        defaultResponse: () => "",
        validateResponse: () => ({}),
        Viewer: ({value}) => value.toLocaleString(),
        exportResponse: (_, value) => value,
    }],
    ["range", {
        name: "Scale",
        Editor: ({prefix, errors}) => {
            return <>
                <div className="mt-2 flex justify-start items-center">
                    <label className="block italic text-gray-500 mr-2">Min:</label>
                    <Field as={Input} name={`${prefix}.min`} type="number" className="mr-1" error={errors} />
                    <span className={`w-16 text-center ${errors ? "text-red-500 dark:text-red-400" : "text-gray-500"}`}><FontAwesomeIcon className="mr-1" icon={faEllipsisH} /></span>
                    <label className="block italic text-gray-500 mr-2">Max:</label>
                    <Field as={Input} name={`${prefix}.max`} type="number" className="mr-1" error={errors} />
                </div>
                <ErrorText className="mt-1">{errors}</ErrorText>
            </>;
        },
        defaultSettings: () => ({min: 1, max: 5}),
        validateEditor: ({details}) => {
            if (typeof details.min !== "number") return "Min must be a number";
            if (typeof details.max !== "number") return "Max must be a number";
            if (details.min > details.max) return "Min must be less than or equal to max";

            // Enforce integers
            if (details.min % 1 !== 0) return "Min must be an integer";
            if (details.max % 1 !== 0) return "Max must be an integer";
        },
        Field: ({id, name, question: {required, details: {min, max}}, value, disabled}) => {
            const { setFieldValue } = useFormikContext();
            const options = [];
            for (let i = min; i <= max; i++) {
                options.push(i);
            }

            return <>
                <RadioGroup disabled={disabled} value={value} onChange={value => {
                    setFieldValue(name, value);
                }} aria-labelledby={id}>
                    {options.map(option => <RadioGroup.Option as="span" className="mr-1 last-of-type:mr-0" key={option} value={option}>{({ checked }) => {
                        return <RadioGroup.Label className={`transition-colors rounded inline-block cursor-pointer p-2 w-10 text-center ${checked ? "bg-blue-500 text-white" : "bg-gray-100 hover:bg-gray-200 active:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 dark:active:bg-gray-600"}`}>
                            {option}
                        </RadioGroup.Label>;
                    }}</RadioGroup.Option>)}
                </RadioGroup>
                {!disabled && !required && typeof value !== "undefined" && <Button size="sm" className="mt-1" kind="secondary" onClick={() => setFieldValue(name, undefined)}>Clear response</Button>}
            </>;
        },
        defaultResponse: () => undefined,
        validateResponse: () => ({}),
        Viewer: ({value, question: {details: {min, max}}}) => <>
            <>{value.toLocaleString()} <span className="opacity-50">({min.toLocaleString()}-{max.toLocaleString()})</span></>
        </>,
        exportResponse: (_, value) => value,
    }],
    ["radio", {
        name: "Multiple choice",
        Editor: ({prefix, details, id}) => {
            return <MultipleChoiceEditor id={id} prefix={prefix} details={details} shape={<div className="rounded-full w-4 h-4 border dark:border-gray-600" />} />;
        },
        defaultSettings: getMultipleChoiceDefaults,
        validateEditor: validateMultipleChoiceEditor,
        Field: ({ id, name, question: { required, details: { options } }, value, disabled }) => {
            const { setFieldValue } = useFormikContext();
            return <>
                {options.map((option, index) => {
                    const optionID = `${id}-${index}`;
                    return <div key={index}>
                        <Field type="radio" disabled={disabled} name={name} id={optionID} value={option} />
                        <label htmlFor={optionID} className="pl-2 cursor-pointer align-middle">{option}</label>
                    </div>;
                })}
                {!disabled && !required && typeof value !== "undefined" && <Button size="sm" className="mt-1" kind="secondary" onClick={() => setFieldValue(name, undefined)}>Clear response</Button>}
            </>;
        },
        defaultResponse: () => "",
        validateResponse: () => ({}),
        Viewer: ({value}) => value,
        exportResponse: (_, value) => value,
    }],
    ["checkbox", {
        name: "Checkboxes",
        Editor: ({ prefix, details, id }) => {
            return <MultipleChoiceEditor id={id} prefix={prefix} details={details} shape={<div className="rounded w-4 h-4 border dark:border-gray-600" />} />;
        },
        defaultSettings: getMultipleChoiceDefaults,
        validateEditor: validateMultipleChoiceEditor,
        Field: ({ id, name, question: { details: { options } }, disabled }) => {
            return <>
                {options.map((option, index) => {
                    const optionID = `${id}-${index}`;
                    return <div key={index}>
                        <Field type="checkbox" disabled={disabled} name={name} id={optionID} value={option} className="rounded" />
                        <label htmlFor={optionID} className="pl-2 cursor-pointer align-middle">{option}</label>
                    </div>;
                })}
            </>;
        },
        defaultResponse: () => [],
        validateResponse: () => ({}),
        Viewer: ({value}) => value.join(", "),
        exportResponse: (_, value) => value,
    }],
    ["select", {
        name: "Dropdown",
        Editor: ({ prefix, details, id }) => {
            return <MultipleChoiceEditor id={id} prefix={prefix} details={details} shape={<FontAwesomeIcon icon={faCaretRight} className="text-gray-100 dark:text-gray-600 w-4 text-center" />} />;
        },
        defaultSettings: getMultipleChoiceDefaults,
        validateEditor: validateMultipleChoiceEditor,
        Field: ({ id, name, question: { required, details: { options } }, disabled }) => {
            return <Field as={Select} disabled={disabled} name={name} id={id}>
                {<option value="" className="opacity-80" disabled={required}>Select one...</option>}
                {options.map((option, index) => {
                    return <option key={index} value={option}>{option}</option>;
                })}
            </Field>;
        },
        defaultResponse: () => "",
        validateResponse: () => ({}),
        Viewer: ({value}) => value,
        exportResponse: (_, value) => value,
    }],
    ["text", {
        name: "Small textbox",
        Editor: () => {
            return <div className="mt-2 italic muted">
                Respondents will be able to enter text.
            </div>;
        },
        defaultSettings: () => ({}),
        validateEditor: () => ({}),
        Field: ({name, id, disabled}) => {
            return <Field as={Input} disabled={disabled} type="text" name={name} id={id} placeholder="Type something..." />;
        },
        defaultResponse: () => "",
        validateResponse: () => ({}),
        Viewer: ({ value }) => {
            // If the value is a URL, render a link.
            if (value.match(/^https?:\/\//)) {
                try {
                    const url = new URL(value);
                    if (url.protocol === "http:" || url.protocol === "https:") {
                        return <StyledExternalLink href={value} target="_blank" rel="noopener noreferrer">{value}<FontAwesomeIcon className="ml-1" icon={faExternalLink} /></StyledExternalLink>;
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            return <div className="whitespace-pre-wrap">{value}</div>;
        },
        exportResponse: (_, value) => value,
    }],
    ["textarea", {
        name: "Large textbox",
        Editor: ({ prefix, details }) => {
            const { setFieldValue } = useFormikContext();
            return <div className="mt-2">
                <Switch.Group>
                    <StyledSwitch checked={details.markdown} onChange={checked => {
                        setFieldValue(`${prefix}.markdown`, checked);
                    }} />
                    <Switch.Label className="ml-2">Allow formatting with Markdown.</Switch.Label>
                </Switch.Group>
                <div className="mt-2 italic muted">
                    Respondents will be able to enter {details.markdown ? "formatted text" : "text"}.
                </div>
            </div>;
        },
        defaultSettings: () => ({
            markdown: false,
        }),
        validateEditor: () => ({}),
        Field: ({ name, id, disabled, question: { details: { markdown } } }) => {
            return <div>
                <Field className="w-full h-48 max-w-lg" as={Textarea} disabled={disabled} name={name} id={id} placeholder="Type something..." />
                {markdown && <div className="w-full max-w-lg text-right">
                    <StyledExternalLink kind="muted" href="https://commonmark.org/help/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faMarkdown} className="mr-1" />Markdown supported<FontAwesomeIcon icon={faExternalLink} className="ml-1" />
                    </StyledExternalLink>
                </div>}
            </div>;
        },
        defaultResponse: () => "",
        validateResponse: () => ({}),
        Viewer: ({ value, question: { details: { markdown } } }) => {
            if (markdown) {
                return <MarkdownText text={value} className="max-w-2xl" />;
            } else {
                return <div className="whitespace-pre-wrap">{value}</div>;
            }
        },
        exportResponse: (_, value) => value,
    }],
    ["file", {
        name: "File upload",
        Editor: () => {
            return <div className="mt-2 italic muted">
                Respondents will be able to upload files.
            </div>;
        },
        defaultSettings: () => ({}),
        validateEditor: () => ({}),
        Field: question => {
            return <FileUploader {...question} />;
        },
        defaultResponse: () => "",
        validateResponse: () => ({}),
        Viewer: ({value}) => {
            return <FileLink fileID={value} />;
        },
        exportResponse: (_, value) => ({_type: "file", id: value}),
    }],
    ["categories", {
        name: "Categories",
        Editor: () => {
            return <div className="mt-2 italic muted">
                Respondents will be able to select categories.
            </div>;
        },
        defaultSettings: () => ({}),
        validateEditor: () => ({}),
        Field: ({ id, name, disabled }) => {
            const { id: eventID } = useEvent();
            const { data, loading, error } = useRequest(() => CategoriesService.listEventCategoriesEventsEventIdCategoriesGet(eventID));
            return <>
                {data && data.map(category => {
                    const optionID = `${id}-${category.id}`;
                    return <div key={category.id}>
                        <Field type="checkbox" disabled={disabled} name={name} id={optionID} value={category.id} className="rounded" />
                        <label htmlFor={optionID} className="pl-2 cursor-pointer align-middle">{category.name}</label>
                    </div>;
                })}
                {loading && <LoadingText messages={["Loading categories..."]} />}
                <ErrorText>{error}</ErrorText>
            </>;
        },
        defaultResponse: () => [],
        validateResponse: () => ({}),
        Viewer: ({ value }) => {
            const { id: eventID } = useEvent();
            const { data, loading, error } = useRequest(() => CategoriesService.listEventCategoriesEventsEventIdCategoriesGet(eventID));
            if (data) {
                // Make a map of category IDs to category names
                const categoryMap = data.reduce((map, category) => {
                    map[category.id] = category.name;
                    return map;
                }, {});
                return value.map(id => categoryMap[id] ?? `Deleted Category ${id}`).join(", ");
            } else {
                return <>
                    {loading && <LoadingText messages={["Loading categories..."]} />}
                    <ErrorText>{error}</ErrorText>
                </>;
            }
        },
        exportResponse: (_, value) => ({_type: "categories", categories: value}),
    }],
    ["timeslots", {
        name: "Timeslots",
        Editor: () => {
            return <div className="mt-2 italic muted">
                Respondents will be able to select timeslots.
            </div>;
        },
        defaultSettings: () => ({}),
        validateEditor: () => ({}),
        Field: ({ id, name, disabled, value }) => {
            const { id: eventID } = useEvent();
            const { data, loading, error } = useRequest(async () => {
                const timeslots = await ScheduleService.listEventTimeslotsEventsEventIdScheduleTimeslotsGet(eventID);
                timeslots.sort((a, b) => {
                    if (new Date(a.start) < new Date(b.start)) {
                        return -1;
                    } else if (new Date(a.start) > new Date(b.start)) {
                        return 1;
                    }
                    return 0;
                });
                return timeslots;
            });
            const selectedIDs = new Set(value);
            const { setFieldValue } = useFormikContext();
            if (data) {
                const everythingSelected = data.every(timeslot => selectedIDs.has(timeslot.id));
                return <>
                    <Button kind="secondary" className="mb-1" onClick={() => {
                        if (everythingSelected) {
                            setFieldValue(name, []);
                        } else {
                            setFieldValue(name, data.map(timeslot => timeslot.id));
                        }
                    }}>{everythingSelected ? "Deselect" : "Select"} all</Button>
                    <div className="md:columns-2 lg:columns-3">
                        {data.map(timeslot => {
                            const optionID = `${id}-${timeslot.id}`;
                            let timeslotDescription = DateTime.fromISO(timeslot.start).toLocaleString(DateTime.DATETIME_MED);
                            if (timeslot.end) {
                                timeslotDescription += ` - ${DateTime.fromISO(timeslot.end).toLocaleString(DateTime.TIME_SIMPLE)}`;
                            }
                            return <div key={timeslot.id}>
                                <Field type="checkbox" disabled={disabled} name={name} id={optionID} value={timeslot.id} className="rounded" />
                                <label htmlFor={optionID} className="pl-2 cursor-pointer align-middle">{timeslotDescription}</label>
                            </div>;
                        })}
                    </div>
                </>;
            } else {
                return <>
                    {loading && <LoadingText messages={["Loading timeslots..."]} />}
                    <ErrorText>{error}</ErrorText>
                </>;
            }
        },
        defaultResponse: () => [],
        validateResponse: () => ({}),
        Viewer: ({ value }) => {
            const { id: eventID } = useEvent();
            const { data, loading, error } = useRequest(() => ScheduleService.listEventTimeslotsEventsEventIdScheduleTimeslotsGet(eventID));
            if (data) {
                // Make a map of category IDs to category names
                const timeslotMap = data.reduce((map, timeslot) => {
                    map[timeslot.id] = DateTime.fromISO(timeslot.start);
                    return map;
                }, {});
                return [...value].sort((a, b) => {
                    if (!timeslotMap[a] && !timeslotMap[b]) {
                        return 0;
                    } else if (!timeslotMap[a]) {
                        return 1;
                    } else if (!timeslotMap[b]) {
                        return -1;
                    }

                    if (timeslotMap[a] < timeslotMap[b]) {
                        return -1;
                    } else if (timeslotMap[a] > timeslotMap[b]) {
                        return 1;
                    }
                    return 0;
                }).map(id => timeslotMap[id] ? timeslotMap[id].toLocaleString(DateTime.DATETIME_MED) : `Deleted Timeslot ${id}`).join("; ");
            } else {
                return <>
                    {loading && <LoadingText messages={["Loading timeslots..."]} />}
                    <ErrorText>{error}</ErrorText>
                </>;
            }
        },
        exportResponse: (_, value) => ({_type: "timeslots", timeslots: value}),
    }],
]);
