import classNames from 'classnames';
import React, { forwardRef, useRef, useState, useEffect } from 'react';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';

// This is extremely overengineered, but hopefully it's easy enough to use:
// <LinkButton href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" kind="primary" size="lg" className="mr-1">Hello, world!</LinkButton>

/**
 * @typedef ButtonKind
 * @property {string} [className]
 * @property {string} [mouseEffectColor]
 * @property {string} [mouseEffectClassName]
 * @property {string} [mouseEffectHoverClassName]
 * @property {string} [mouseEffectActiveClassName]
 */

const kinds = {
    /** @type {ButtonKind} */
    primary: {
        className: "bg-blue-500 active:bg-blue-600 disabled:active:bg-blue-500 text-white",
    },

    /** @type {ButtonKind} */
    secondary: {
        className: classNames(
            "border",
            "border-gray-300 text-blue-500",
            "hover:bg-blue-50 hover:border-blue-300",
            "active:bg-blue-100 active:border-blue-400",
            "dark:border-gray-600 dark:text-blue-400",
            "dark:hover:bg-blue-900 dark:hover:border-blue-500",
            "dark:active:bg-blue-700 dark:active:border-blue-500",
            "disabled:hover:border-gray-300 dark:disabled:hover:border-gray-600 disabled:hover:bg-transparent",
            "disabled:active:border-gray-300 dark:disabled:active:border-gray-600 disabled:active:bg-transparent",
        ),
        mouseEffectColor: "#3b82f6",
        mouseEffectClassName: "mix-blend-normal opacity-0",
        mouseEffectHoverClassName: "mix-blend-normal opacity-20",
        mouseEffectActiveClassName: "mix-blend-normal opacity-30",
    },

    /** @type {ButtonKind} */
    "secondary-filled": {
        className: classNames(
            "bg-blue-100 text-blue-500",
            "hover:bg-blue-200",
            "active:bg-blue-300",
            "dark:bg-blue-800 dark:text-blue-400",
            "dark:hover:bg-blue-700",
            "dark:active:bg-blue-600",
            "disabled:hover:bg-transparent",
            "disabled:active:bg-transparent",
        ),
        mouseEffectColor: "#3b82f6",
        mouseEffectClassName: "mix-blend-normal opacity-0",
        mouseEffectHoverClassName: "mix-blend-normal opacity-20",
        mouseEffectActiveClassName: "mix-blend-normal opacity-30",
    },

    /** @type {ButtonKind} */
    success: {
        className: "bg-green-500 active:bg-green-600 disabled:active:bg-green-500 text-white",
    },

    /** @type {ButtonKind} */
    destructive: {
        className: "bg-red-500 active:bg-red-600 disabled:active:bg-red-500 text-white",
    },

    "primary-text": {
        className: "text-blue-500 dark:text-blue-400 hover:bg-blue-50 active:bg-blue-100 dark:hover:bg-blue-800 dark:active:bg-blue-700 disabled:hover:bg-transparent disabled:active:bg-transparent",
        mouseEffectColor: "#3b82f6",
        mouseEffectClassName: "mix-blend-normal opacity-0",
        mouseEffectHoverClassName: "mix-blend-normal opacity-20",
        mouseEffectActiveClassName: "mix-blend-normal opacity-30",
    },

    "success-text": {
        className: "text-green-600 dark:text-green-500 hover:bg-green-50 active:bg-green-100 dark:hover:bg-green-800 dark:active:bg-green-700 disabled:hover:bg-transparent disabled:active:bg-transparent",
        mouseEffectColor: "#22c55e",
        mouseEffectClassName: "mix-blend-normal opacity-0",
        mouseEffectHoverClassName: "mix-blend-normal opacity-20",
        mouseEffectActiveClassName: "mix-blend-normal opacity-30",
    },

    "destructive-text": {
        className: "text-red-500 hover:bg-red-50 active:bg-red-100 dark:hover:bg-red-800 dark:active:bg-red-700 disabled:hover:bg-transparent disabled:active:bg-transparent",
        mouseEffectColor: "#ef4444",
        mouseEffectClassName: "mix-blend-normal opacity-0",
        mouseEffectHoverClassName: "mix-blend-normal opacity-20",
        mouseEffectActiveClassName: "mix-blend-normal opacity-30",
    },

    "warning": {
        className: "bg-yellow-500 active:bg-yellow-600 disabled:active:bg-yellow-500 text-black",
    },
};

const sizes = {
    icon: {
        button: "w-8 h-8 rounded-full",
        canvas: "rounded-full",
    },
    "icon-lg": {
        button: "w-12 h-12 rounded-full text-xl",
        canvas: "rounded-full",
    },
    sm: {
        button: "py-1 px-2 text-sm rounded",
        canvas: "rounded",
    },
    md: {
        button: "py-2 px-3 rounded",
        canvas: "rounded",
    },
    lg: {
        button: "py-3 px-6 rounded-lg text-lg font-semibold tracking-wide uppercase font-display",
        canvas: "rounded-lg",
    },
    tablecell: {
        button: "w-20 h-20 rounded-lg text-lg font-semibold tracking-wide uppercase",
        canvas: "rounded-lg",
    },
    unsized: {
        button: "rounded",
        canvas: "rounded",
    },
    "unsized-unrounded": {
        button: "",
        canvas: "",
    },
};

/**
 * @typedef {ButtonKind & {size?: keyof sizes, kind?: keyof kinds, mouseEffect?: boolean}} ButtonProps
 */

/**
 * @param {React.PropsWithChildren<ButtonProps> & {disabled?: boolean}} buttonProps
 * @param {React.Ref<HTMLElement>} [ref]
 * @returns props for a button
 */
function useButtonProps({ kind: kindIdentifier, ...buttonProps }, ref) {
    const type = kinds[kindIdentifier] || {};
    const size = sizes[buttonProps.size || "md"];
    const mouseEffect = (buttonProps.mouseEffect === undefined ? true : buttonProps.mouseEffect) && !buttonProps.disabled;
    const mouseEffectColor = buttonProps.mouseEffectColor || type.mouseEffectColor || "white";
    const mouseEffectClassName = buttonProps.mouseEffectClassName || type.mouseEffectClassName || "mix-blend-overlay opacity-0";
    const mouseEffectHoverClassName = buttonProps.mouseEffectHoverClassName || type.mouseEffectHoverClassName || "mix-blend-overlay opacity-50";
    const mouseEffectActiveClassName = buttonProps.mouseEffectActiveClassName || type.mouseEffectActiveClassName || "mix-blend-overlay opacity-80";

    /** @type {React.Ref<HTMLCanvasElement>} */
    const canvasRef = useRef<HTMLCanvasElement>(null); // this makes canvas error go away
    const [mousePos, setMousePos] = useState(null);
    const [mouseDown, setMouseDown] = useState(false);

    useEffect(() => {
        if (mouseEffect) {
            const request = requestAnimationFrame(() => {
                if (mousePos && mouseEffect && canvasRef.current) {
                    canvasRef.current['width'] = canvasRef.current['clientWidth']; // resolves DNE error
                    canvasRef.current['height'] = canvasRef.current['clientHeight'];
                    const context = canvasRef.current.getContext("2d");
                    context.clearRect(0, 0, canvasRef.current['width'], canvasRef.current['height']);
                    const { x, y } = mousePos;
                    const gradient = context.createRadialGradient(x, y, 5, x, y, 250);
                    gradient.addColorStop(0, mouseEffectColor);
                    gradient.addColorStop(1, transparentize(1, mouseEffectColor));
                    context.fillStyle = gradient;
                    context.fillRect(0, 0, canvasRef.current['width'], canvasRef.current['height']);
                }
            });

            return () => cancelAnimationFrame(request);
        }
    }, [mousePos, mouseEffect, mouseEffectColor]);
    
    const props = {
        className: classNames(
            "relative transition-colors disabled:cursor-not-allowed disabled:opacity-50 inline-flex items-center justify-center",
            size.button,
            type.className,
            buttonProps.className,
        ),
        ref,
        disabled: buttonProps.disabled,
    };

    if (mouseEffect) {
        let mouseEffectClass = mouseEffectClassName;
        if (mouseDown) {
            mouseEffectClass = mouseEffectActiveClassName;
        } else if (mousePos) {
            mouseEffectClass = mouseEffectHoverClassName;
        }

        props['onMouseMove'] = event => {
            setMousePos({
                x: event.clientX - canvasRef.current.getBoundingClientRect().left,
                y: event.clientY - canvasRef.current.getBoundingClientRect().top,
            });
        };
        props['onMouseLeave'] = () => {
            setMousePos(null);
            setMouseDown(false);
        };
        props['onMouseDown'] = () => {
            setMouseDown(true);
        };
        props['onMouseUp'] = () => {
            setMouseDown(false);
        };
        props['children'] = <>
            {buttonProps.children}
            <canvas ref={canvasRef} className={classNames(
                "absolute top-0 left-0 w-full h-full overflow-hidden transition-opacity pointer-events-none",
                size.canvas,
                mouseEffectClass,
            )} />
        </>;
    } else {
        props['children'] = buttonProps.children;
    }

    return props;
}

{/* @ts-ignore */}
export const Button = forwardRef(/** @type {React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps & Omit<React.HTMLProps<HTMLButtonElement>, "size">>} */ (({ kind, children, size: sizeIdentifier, className, mouseEffect, mouseEffectColor, mouseEffectClassName, mouseEffectActiveClassName, mouseEffectHoverClassName, disabled, type = "button", ...props },
    ref,
) => {
    {/* @ts-ignore */}
    const { children: buttonChildren, ...buttonProps } = useButtonProps({ kind, children, size: sizeIdentifier, className, mouseEffect, mouseEffectColor, mouseEffectClassName, mouseEffectActiveClassName, mouseEffectHoverClassName, disabled }, ref);
    return <button
        type={type}
        {...props}
        {...buttonProps}
    >
        {buttonChildren}
    </button>;
}));

{/* @ts-ignore */}
export const ExternalLinkButton = forwardRef(/** @type {React.ForwardRefRenderFunction<HTMLAnchorElement, ButtonProps & Omit<React.HTMLProps<HTMLAnchorElement>, "size">>} */ (({ kind, children, size: sizeIdentifier, className, mouseEffect, mouseEffectColor, mouseEffectClassName, mouseEffectActiveClassName, mouseEffectHoverClassName, ...props },
    ref,
) => {
    {/* @ts-ignore */}
    const { children: buttonChildren, ...buttonProps } = useButtonProps({ kind, children, size: sizeIdentifier, className, mouseEffect, mouseEffectColor, mouseEffectClassName, mouseEffectActiveClassName, mouseEffectHoverClassName }, ref);
    return <a
        {...props}
        {...buttonProps}
    >
        {buttonChildren}
    </a>;
})); 

{/* @ts-ignore */}
export const RouterLinkButton = forwardRef(/** @type {React.ForwardRefRenderFunction<import('react-router-dom').Link, ButtonProps & Omit<import('react-router-dom').LinkProps, "size">>} */(({ kind, children, size: sizeIdentifier, className, mouseEffect, mouseEffectColor, mouseEffectClassName, mouseEffectActiveClassName, mouseEffectHoverClassName, ...props },
    ref,
) => {
    {/* @ts-ignore */}
    const { children: buttonChildren, ...buttonProps } = useButtonProps({ kind, children, size: sizeIdentifier, className, mouseEffect, mouseEffectColor, mouseEffectClassName, mouseEffectActiveClassName, mouseEffectHoverClassName }, ref);
    {/* @ts-ignore */}
    return <Link
        {...props}
        {...buttonProps}
    >
        {buttonChildren}
    </Link>;
}));
