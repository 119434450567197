import { Main } from '../components/ui/Main';
import { Button } from '../components/ui/Button';
import { useAPI, useRequest } from "../lib/API";
import { useAuth } from '../lib/auth.js';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { LoadingScreen, LoadingText } from '../components/ui/Loading';
import { ErrorText } from '../components/ui/Input';
import { useState } from 'react';
import { StyledRouterLink } from '../components/ui/Link';
import { AccountMenu } from '../components/AccountMenu';
import { getIndefiniteArticle } from '../lib/utils.js';
import Helmet from 'react-helmet';
import { InvitesService, SurveysService, SurveyType } from '../client';

export function InvitedWelcome() {
    const auth = useAuth();
    const { id } = useParams();
    const { data, loading, error } = useRequest(() => InvitesService.getInviteDetailsInvitesInviteIdGet(id));
    const [isJoining, setJoining] = useState(false);
    const [joinError, setJoinError] = useState(null);
    const navigate = useNavigate();
    const api = useAPI();
    const location = useLocation();

    if (loading) {
        return <>
            <Helmet>
                <title>Invitation</title>
            </Helmet>
            <LoadingScreen messages={['Loading invite...']} />
        </>;
    } else if (error) {
        return <div className="min-h-screen w-full p-4 flex items-center text-center justify-center">
            <Helmet>
                <title>Invitation</title>
            </Helmet>
            <ErrorText>Couldn't load invite. Try reloading the page.</ErrorText>
        </div>;
    } else {
        return <Main className="py-8">
            <Helmet>
                <title>Invitation - {data.event_name}</title>
            </Helmet>

            <div className="md:flex md:h-12 items-center mb-6">
                <h1 className="title">Welcome to Expodite!</h1>
                <span className="flex-grow"></span>
                <AccountMenu />
            </div>

            <div>
                <p>You were invited to <strong>{data.event_name}</strong> by <strong>{data.inviter_name}</strong>{data.role_name && <> as {getIndefiniteArticle(data.role_name)} <strong>{data.role_name}</strong></>}!{!data.role_name && " You were not assigned a role."}</p>

                {auth.state === "loading" && <LoadingText messages={["Getting ready..."]} />}
                {auth.state === "authenticated" && !isJoining && <>
                    <p className="mt-2">Select Join to participate!</p>
                    {joinError && joinError.status && <ErrorText>You're already in this event. <StyledRouterLink to={`/event/${data.event_id}`}>Manage it here.</StyledRouterLink></ErrorText>}
                    {joinError && !joinError.status && <ErrorText>An error occurred. Check your Internet connection.</ErrorText>}
                    <Button onClick={async () => {
                        setJoining(true);
                        setJoinError(null);
                        try {
                            const { id: eventID } = await InvitesService.joinEventWithInviteInvitesInviteIdJoinPut(id);
                            const questions = await SurveysService.getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(eventID, SurveyType.REGISTRATION);
                            if (questions.length === 0) {
                                navigate(`/event/${eventID}`);
                            } else {
                                navigate(`/event/${eventID}/users/${auth.user.id}`);
                            }
                        } catch (e) {
                            setJoinError(e);
                            setJoining(false);
                        }
                    }} size="lg" kind="success" className="p-2 mt-2">Join!</Button>
                </>}
                {auth.state === "error" && <ErrorText>Couldn't determine whether you are logged in. Try reloading the page.</ErrorText>}
                {auth.state === "unauthenticated" && <p><StyledRouterLink to="/login" state={{ from: location }}>Log in</StyledRouterLink> before joining an event.</p>}
            </div>
        </Main>;
    }
}
