/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum InviteType {
    LINK = 'link',
    EMAIL = 'email',
}
