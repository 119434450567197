import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useAPI, useRequest } from '../../../../lib/API.js';
import { SharedExportCache } from '../../../../lib/export';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { Button, RouterLinkButton } from "../../../../components/ui/Button";
import { StyledRouterLink } from '../../../../components/ui/Link';
import { Popover } from "@headlessui/react";
import { PopoverPanel } from "../../../../components/ui/Popover";
import { AddButtonPopoverContent } from "./AddButton";
import { hueForID } from '../../../../lib/utils.js';
import { ProjectsService } from '../../../../client';
import { ScheduleCellDetail } from './ScheduleCell';

function ProjectPopover({project, colorCode, colorType, hueID, setScheduleModel, scheduleModel, judgeID}) {
    const proj_url = `../projects/${project.id}`;
    const { data: authors } = useRequest(() => ProjectsService.getProjectUsersProjectsProjectIdUsersTypeGet(project.id, "member"));

    return <Popover key={project.id}>
        {() => (
            <>
                <Popover.Button as={Button} className={`rounded w-full px-3 py-2 h-full ${colorCode ? 'bg-settings-accent text-white dark:text-black' : 'border dark:border-gray-600'} opacity-100`} style={{ "--settings-accent-hue": hueForID(hueID) }}>
                    <div className="flex justify-between">
                        <div className="flex-grow">
                            <StyledRouterLink className="font-bold text-white no-underline dark:text-black hover:text-blue hover:underline" to={proj_url}>{project.name}</StyledRouterLink>
                            <div className="opacity-70">{project.category?.name ?? "No Category"}</div>
                        </div>
                    </div>
                </Popover.Button>

                <PopoverPanel className="w-screen max-w-sm lg:max-w-md max-h-lg overflow-auto">
                    <ScheduleCellDetail colorType={colorType} project={project} authors={authors} setScheduleModel={setScheduleModel} scheduleModel={scheduleModel} judgeID={judgeID} /> 
                </PopoverPanel>
            </>
        )}
    </Popover>;
}

function ScheduleCard ({scheduleModel, colorCode, colorType, setScheduleModel, showRole, cache, user, projects, categories}){
    const url = `../users/${user.id}`;
    return <div className="w-screen max-w-sm lg:max-w-md">
        <div className="flex">
            <div className="text-left flex-grow">
                <h4 className="text-lg font-bold">{user.name || "User Name"}</h4>
                <div className="muted">{user.roles.map(role => role.name).join(", ")}</div>
                <div className="mt-2 space-y-2 w-full">
                    <Fragment key={user.id}>{projects.map(project => {
                        const hueID = (!colorType && project?.category && project?.category?.id) ? project?.category?.id : project?.id;
                        return <ProjectPopover key={project.id} project={project} colorCode={colorCode} colorType={colorType} hueID={hueID} setScheduleModel={setScheduleModel} scheduleModel={scheduleModel} judgeID={user.id} />;
                    })}</Fragment>        
                </div>
            </div>
        </div>
        <div className="w-full py-2">
            <Popover>
                {({ open }) => (
                    <>
                        <Popover.Button as={Button} className={`w-full h-full ${open ? 'bg-blue-500/30' : ''} opacity-100`} size="unsized-unrounded">
                            <FontAwesomeIcon className="p-2" icon={faPlus} />
                        </Popover.Button>

                        <PopoverPanel className="w-screen max-w-sm lg:max-w-md max-h-lg overflow-auto">
                            <AddButtonPopoverContent scheduleModel={scheduleModel} setScheduleModel={setScheduleModel} judgeID={user.id} colorType={colorType} />
                        </PopoverPanel>
                    </>
                )}
            </Popover>
        </div>
        {/* <AddButton scheduleModel={scheduleModel} setScheduleModel={setScheduleModel} judgeID={user.id} cache={cache} categories={categories} colorType={colorType} /> */}
        <div className="flow-root mt-4">
            <span className="flex items-center">
                <RouterLinkButton to={url} kind="primary">View User</RouterLinkButton>
                <Button className="ml-5" kind="destructive" onClick={() => {
                    setScheduleModel(scheduleModel.removingAllProjectsFromUser(user.id));
                }}>Clear Projects</Button>
            </span>
        </div>
    </div>;
}
 
function DetermineHeader (timeslot, timeslotName) {
    if (timeslotName && timeslot?.name !== '') {
        return timeslot.name;
    }
    return DateTime.fromISO(timeslot.start).toLocaleString(DateTime.TIME_SIMPLE);
}

export function ScheduleCards({ scheduleModel, setScheduleModel, showRole, colorCode, colorType, categories }) {
    const api = useAPI();
    const [cache] = useState(new SharedExportCache(api));
    
    // creating a list of users and mapping projects to users
    const userProjectMap = new Map();
    const users = useMemo(() => {
        return (Object.entries(scheduleModel.getUsers()).filter(user => user));
    }, [scheduleModel]);
    useMemo(() => {
        users?.forEach(user => {
            const [__, userBody] = user;
            const projects = Object.entries(scheduleModel.getProjectsForUser(userBody.id)).flatMap(([_, projects]) => [...(projects ?? [])]).filter(project => project);
            userProjectMap.set(userBody, projects);
        });
    }, [scheduleModel, users]);

    return <div className="grid grid-cols-3 gap-x-4 p-5">
        {users?.map(user => {
            const [__, userBody] = user;
            return <ScheduleCard key={userBody.id} user={userBody} projects={userProjectMap.get(userBody)} scheduleModel={scheduleModel} setScheduleModel={setScheduleModel} showRole={showRole} colorCode={colorCode} colorType={colorType} categories={categories} cache={cache} />;
        })} 
    </div>;
}
