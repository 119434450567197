import { faFile, faFileVideo, faFileImage, faFileAudio, faFilePdf, faFileText, faFileArchive, faFileWord, faFileExcel, faFilePowerpoint } from "@fortawesome/free-solid-svg-icons";
import { useMemo, useState, useEffect } from "react";
import { useRequest } from "./API";

/**
 * Checks whether the given string is a UUID.
 * @param {string} input 
 * @returns true if input is a UUID, false otherwise
 */
export function isValidUUID(input) {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(input);
}

/**
 * Returns an appropriate indefinite article for the given noun.
 * @param {string} noun 
 * @returns {"a" | "an"} indefinite article
 */
export function getIndefiniteArticle(noun) {
    if (/^[aeiou]/i.test(noun.trim())) {
        return "an";
    } else {
        return "a";
    }
}

/**
 * 
 * @param {import('../client').EventReadForUser} event 
 * @param {string} [permission] 
 */
export function checkPermissions(event, permission) {
    if (event.admin) return true;
    return event.permissions.includes(permission);
}

/**
 * @template Singular
 * @template Plural
 * @param {number} count 
 * @param {Singular} singular 
 * @param {Plural} plural 
 * @returns {Singular | Plural}
 */
export function formatPlural(count, singular, plural) {
    return count === 1 ? singular : plural;
}

/**
 * @template {{id: string}} T
 * @param {T[] | undefined | null} items
 * @param {(items: T[]) => void} setItems
 */
export function useArrayUpdateContext(items, setItems) {
    return useMemo(() => {
        if (items) {
            return {
                /**
                 * @param {string} id
                 */
                remove(id) {
                    setItems(items.filter(item => item.id !== id));
                },
                /**
                 * @param {T} item
                 */
                upsert(item) {
                    if (items.some(i => i.id === item.id)) {
                        setItems(items.map(i => i.id === item.id ? item : i));
                    } else {
                        setItems([...items, item]);
                    }
                },
            };
        }
    }, [setItems, items]);
}

const fileTypes = [
    {
        regex: /^image\//,
        icon: faFileImage,
    },
    {
        regex: /^video\//,
        icon: faFileVideo,
    },
    {
        regex: /^audio\//,
        icon: faFileAudio,
    },
    {
        regex: /^application\/pdf$/,
        icon: faFilePdf,
    },
    {
        regex: /^text\//,
        icon: faFileText,
    },
    {
        regex: /^application\/(g?zip|x-tar)$/,
        icon: faFileArchive,
    },
    {
        regex: /^application\/(msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document)$/,
        icon: faFileWord,
    },
    {
        regex: /^application\/(vnd\.ms-excel|vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet)$/,
        icon: faFileExcel,
    },
    {
        regex: /^application\/(vnd\.ms-powerpoint|vnd\.openxmlformats-officedocument\.presentationml\.presentation)$/,
        icon: faFilePowerpoint,
    },
];

export function getFileIcon(type) {
    const fileType = fileTypes.find(t => t.regex.test(type));
    return fileType?.icon ?? faFile;
}

export function useCachedData(request, deps = []) {
    const { data: remoteData, loading, error } = useRequest(request, deps);
    const [data, setData] = useState(remoteData);

    useEffect(() => {
        if (remoteData) {
            setData(remoteData);
        }
    }, [remoteData]);

    return { data, setData, loading, error };
}

export function hueForID(id) {
    // Reverse the ID, get the first 6 characters, and convert to a number from 0 to 360.
    const hue = parseInt(id.split("").reverse().slice(0, 6).join(""), 16) / 0xffffff * 360;
    return `${hue}deg`;
}

export function tabClass(selectedColor, hoverColor) {
    return ({ selected }) => `mr-3 border-b ${selected ? `font-semibold border-b-2 ${selectedColor}` : `border-transparent text-black/50 dark:text-white/50 ${hoverColor}`} transition-colors`;
}
