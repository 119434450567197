/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually. 
 * Expodite
 * OpenAPI spec version: 0.0.1
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
export type UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutBody = {[key: string]: TimeslotUpdate};

export type CreateProjectDetailsProjectProjectIdDetailsPost200 = { [key: string]: any };

export type CreateProjectDetailsProjectProjectIdDetailsPostBody = { [key: string]: any };

export type GetProjectDetailsProjectProjectIdDetailsGet200 = { [key: string]: any };

export type CreateReviewProjectProjectIdReviewsPost200 = { [key: string]: any };

export type CreateReviewProjectProjectIdReviewsPostBody = { [key: string]: any };

export type GetReviewProjectProjectIdReviewsGet200 = { [key: string]: any };

export type GetRegistrationByUserEventsEventIdRegistrationsUserIdGet200 = { [key: string]: any };

export type CreateRegistrationEventsEventIdRegistrationsPost200 = { [key: string]: any };

export type CreateRegistrationEventsEventIdRegistrationsPostBody = { [key: string]: any };

export type GetRegistrationEventsEventIdRegistrationsGet200 = { [key: string]: any };

export type ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutBodyItem = Create | Update | Delete;

export type GetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetParams = {
list_all?: boolean;
};

export type GetApprovalCountsProjectProjectIdApprovalsCountGet200 = {[key: string]: ApprovalRoleCount};

export type GetEventScheduleEventsEventIdScheduleGetParams = {
all?: boolean;
};

export type AddUserToProjectProjectsProjectIdUsersTypePostParams = {
user_id: string;
};

export type CreateEventInviteEventsEventIdInvitesPostParams = {
role_id?: string;
};

export type CopyEventEventsCopyPostParams = {
name: string;
event_id: string;
};

export type PasswordResetAuthPasswordResetPostParams = {
token: string;
};

export type RequestPasswordResetAuthPasswordResetRequestPostParams = {
email: string;
};

export type OauthLoginAuthProviderGetParams = {
redirect?: string;
};

export type EmailVerifyAccountVerifyPostParams = {
token: string;
};

export type RequestEmailVerifyAccountVerifyRequestPostParams = {
email: string;
};

export interface ValidationError {
  loc: string[];
  msg: string;
  type: string;
}

export interface UserUpdate {
  name?: string;
}

export interface UserReadForEventAdmin {
  id?: string;
  email: string;
  name: string;
  roles: BasicRoleInfo[];
  projects: ProjectReadWithUserType[];
  admin: boolean;
}

export interface UserRead {
  id?: string;
  email: string;
  name: string;
}

/**
 * An enumeration.
 */
export type UserProjectType = typeof UserProjectType[keyof typeof UserProjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProjectType = {
  member: 'member',
  invited: 'invited',
  judge: 'judge',
} as const;

export type UpdateType = typeof UpdateType[keyof typeof UpdateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateType = {
  update: 'update',
} as const;

export interface Update {
  type: UpdateType;
  id: string;
  question: SurveyQuestionUpdate;
}

export interface TimeslotUpdate {
  name?: string;
  start?: string;
  end?: string;
}

export interface TimeslotRead {
  id?: string;
  name?: string;
  start?: string;
  end?: string;
}

export interface TimeslotCreate {
  name?: string;
  start?: string;
  end?: string;
}

/**
 * An enumeration.
 */
export type SurveyType = typeof SurveyType[keyof typeof SurveyType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SurveyType = {
  project: 'project',
  registration: 'registration',
  review: 'review',
  approval: 'approval',
} as const;

export type SurveyResponseReadSurveyAnswers = { [key: string]: any };

export interface SurveyResponseRead {
  id?: string;
  user: UserRead;
  survey_answers: SurveyResponseReadSurveyAnswers;
}

export type SurveyQuestionUpdateDetails = { [key: string]: any };

export interface SurveyQuestionUpdate {
  name?: string;
  description?: string;
  order?: number;
  required?: boolean;
  question_type?: QuestionType;
  details?: SurveyQuestionUpdateDetails;
  all_roles?: boolean;
  all_categories?: boolean;
  role_ids?: string[];
  category_ids?: string[];
}

export type SurveyQuestionReadDetails = { [key: string]: any };

export interface SurveyQuestionRead {
  id?: string;
  name: string;
  description: string;
  order?: number;
  required: boolean;
  question_type: QuestionType;
  details: SurveyQuestionReadDetails;
  all_roles: boolean;
  all_categories: boolean;
  roles: BasicRoleInfo[];
  categories: CategoryRead[];
}

export type SurveyQuestionCreateDetails = { [key: string]: any };

export interface SurveyQuestionCreate {
  name: string;
  description: string;
  order?: number;
  required: boolean;
  question_type: QuestionType;
  details: SurveyQuestionCreateDetails;
  all_roles: boolean;
  all_categories: boolean;
  role_ids?: string[];
  category_ids?: string[];
}

export interface SurveyLockUpdate {
  project?: boolean;
  registration?: boolean;
  review?: boolean;
}

export interface SurveyLock {
  project?: boolean;
  registration?: boolean;
  review?: boolean;
}

export interface RoleUpdate {
  name?: string;
  admin?: boolean;
  permissions?: string[];
  order?: number;
}

export interface RoleRead {
  id?: string;
  name: string;
  admin: boolean;
  permissions: string[];
  order?: number;
}

export interface RoleCreate {
  name: string;
  admin: boolean;
  permissions: string[];
  order?: number;
}

/**
 * An enumeration.
 */
export type QuestionType = typeof QuestionType[keyof typeof QuestionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionType = {
  number: 'number',
  range: 'range',
  text: 'text',
  textarea: 'textarea',
  radio: 'radio',
  checkbox: 'checkbox',
  select: 'select',
  boolean: 'boolean',
  file: 'file',
  categories: 'categories',
  timeslots: 'timeslots',
  url: 'url',
} as const;

export interface ProjectUpdate {
  name?: string;
  category_id?: string;
}

export interface ProjectReadWithUserType {
  id?: string;
  name: string;
  category?: CategoryRead;
  user_type?: UserProjectType;
}

export interface ProjectCreate {
  name: string;
  category_id?: string;
}

export interface ListUserForEventAdmin {
  id?: string;
  email: string;
  name: string;
  roles: BasicRoleInfo[];
}

export interface JudgeAssignmentRead {
  user_id: string;
  project_id: string;
  timeslot_id?: string;
}

export interface JudgeAddUpdate {
  judge_id: string;
  timeslot_id?: string;
}

/**
 * An enumeration.
 */
export type InviteType = typeof InviteType[keyof typeof InviteType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InviteType = {
  link: 'link',
  email: 'email',
} as const;

export interface InviteRead {
  id?: string;
  type: InviteType;
  email?: string;
  inviter: UserRead;
  role?: RoleRead;
  num_users: number;
}

export interface InviteInfo {
  id?: string;
  event_id: string;
  event_name: string;
  inviter_name: string;
  role_name?: string;
  survey_questions?: SurveyQuestionRead[];
}

export interface InviteCreate {
  type: InviteType;
  email?: string;
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface FileRead {
  id?: string;
  name: string;
  mime: string;
  size: number;
}

export interface EventSettingUpdate {
  survey_locks?: SurveyLockUpdate;
  auto_schedule?: AutoSchedule;
  approvals?: ApprovalSettings;
  checklist?: string;
}

export interface EventUpdate {
  name?: string;
  settings?: EventSettingUpdate;
}

export interface EventSetting {
  survey_locks?: SurveyLock;
  auto_schedule?: AutoSchedule;
  approvals?: ApprovalSettings;
  checklist?: string;
}

export interface EventReadForUser {
  id?: string;
  name: string;
  settings: EventSetting;
  roles: BasicRoleInfo[];
  admin: boolean;
  permissions: string[];
}

export interface EventCreate {
  name: string;
  settings: EventSetting;
}

export type DeleteType = typeof DeleteType[keyof typeof DeleteType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteType = {
  delete: 'delete',
} as const;

export interface Delete {
  type: DeleteType;
  id: string;
}

export type CreateType = typeof CreateType[keyof typeof CreateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateType = {
  create: 'create',
} as const;

export interface Create {
  type: CreateType;
  question: SurveyQuestionCreate;
}

export interface CategoryUpdate {
  name?: string;
}

export interface CategoryRead {
  id?: string;
  name: string;
}

export interface CategoryCreate {
  name: string;
}

export interface BodyUploadFileFilesUploadPost {
  file: Blob;
}

export interface BodyPasswordResetAuthPasswordResetPost {
  password: string;
}

export interface BodyPasswordRegisterAuthPasswordRegisterPost {
  email: string;
  password: string;
  name?: string;
}

export interface BodyPasswordLoginAuthPasswordLoginPost {
  email: string;
  password: string;
}

export interface BasicRoleInfo {
  id?: string;
  name: string;
  order?: number;
}

export interface AutoSchedule {
  minimum_judges_local?: number;
  minimum_judges_isef?: number;
  minimum_head_judges?: number;
  max_projects_per_timeslot?: number;
  isef_question?: string;
  isef_response?: string;
  categories_question?: string;
  timeslots_question?: string;
}

export interface ApprovalRoleCount {
  total?: number;
  approved?: number;
}

export type ApprovalSettingsRequiredRoles = {[key: string]: ApprovalRoleCount};

export interface ApprovalSettings {
  enabled?: boolean;
  required_roles?: ApprovalSettingsRequiredRoles;
}

export interface Approval {
  approved?: boolean;
  comments?: string;
}

export interface ApprovalRead {
  id?: string;
  user: UserRead;
  approval: Approval;
}

export type AddonStatusUpdateDetails = { [key: string]: any };

export interface AddonStatusUpdate {
  status?: string;
  details?: AddonStatusUpdateDetails;
}

export type AddonStatusReadDetails = { [key: string]: any };

export interface AddonStatusRead {
  status?: string;
  details?: AddonStatusReadDetails;
}




/**
 * @summary Request Email Verify
 */
export const requestEmailVerifyAccountVerifyRequestPost = (
    params: RequestEmailVerifyAccountVerifyRequestPostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/account/verify/request`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRequestEmailVerifyAccountVerifyRequestPostMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestEmailVerifyAccountVerifyRequestPost>>, TError,{params: RequestEmailVerifyAccountVerifyRequestPostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof requestEmailVerifyAccountVerifyRequestPost>>, TError,{params: RequestEmailVerifyAccountVerifyRequestPostParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof requestEmailVerifyAccountVerifyRequestPost>>, {params: RequestEmailVerifyAccountVerifyRequestPostParams}> = (props) => {
          const {params} = props ?? {};

          return  requestEmailVerifyAccountVerifyRequestPost(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RequestEmailVerifyAccountVerifyRequestPostMutationResult = NonNullable<Awaited<ReturnType<typeof requestEmailVerifyAccountVerifyRequestPost>>>
    
    export type RequestEmailVerifyAccountVerifyRequestPostMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Request Email Verify
 */
export const useRequestEmailVerifyAccountVerifyRequestPost = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestEmailVerifyAccountVerifyRequestPost>>, TError,{params: RequestEmailVerifyAccountVerifyRequestPostParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRequestEmailVerifyAccountVerifyRequestPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Email Verify
 */
export const emailVerifyAccountVerifyPost = (
    params: EmailVerifyAccountVerifyPostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/account/verify`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getEmailVerifyAccountVerifyPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof emailVerifyAccountVerifyPost>>, TError,{params: EmailVerifyAccountVerifyPostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof emailVerifyAccountVerifyPost>>, TError,{params: EmailVerifyAccountVerifyPostParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof emailVerifyAccountVerifyPost>>, {params: EmailVerifyAccountVerifyPostParams}> = (props) => {
          const {params} = props ?? {};

          return  emailVerifyAccountVerifyPost(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type EmailVerifyAccountVerifyPostMutationResult = NonNullable<Awaited<ReturnType<typeof emailVerifyAccountVerifyPost>>>
    
    export type EmailVerifyAccountVerifyPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Email Verify
 */
export const useEmailVerifyAccountVerifyPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof emailVerifyAccountVerifyPost>>, TError,{params: EmailVerifyAccountVerifyPostParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getEmailVerifyAccountVerifyPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update User
 */
export const updateUserAccountPut = (
    userUpdate: UserUpdate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/account`,
      userUpdate,options
    );
  }



export const getUpdateUserAccountPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserAccountPut>>, TError,{data: UserUpdate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateUserAccountPut>>, TError,{data: UserUpdate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserAccountPut>>, {data: UserUpdate}> = (props) => {
          const {data} = props ?? {};

          return  updateUserAccountPut(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateUserAccountPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserAccountPut>>>
    export type UpdateUserAccountPutMutationBody = UserUpdate
    export type UpdateUserAccountPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Update User
 */
export const useUpdateUserAccountPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserAccountPut>>, TError,{data: UserUpdate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateUserAccountPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Logout
 */
export const logoutAuthLogoutGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.get(
      `/auth/logout`,options
    );
  }


export const getLogoutAuthLogoutGetQueryKey = () => [`/auth/logout`] as const;
  

    
export const getLogoutAuthLogoutGetQueryOptions = <TData = Awaited<ReturnType<typeof logoutAuthLogoutGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof logoutAuthLogoutGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof logoutAuthLogoutGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLogoutAuthLogoutGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof logoutAuthLogoutGet>>> = ({ signal }) => logoutAuthLogoutGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type LogoutAuthLogoutGetQueryResult = NonNullable<Awaited<ReturnType<typeof logoutAuthLogoutGet>>>
export type LogoutAuthLogoutGetQueryError = AxiosError<unknown>

/**
 * @summary Logout
 */
export const useLogoutAuthLogoutGet = <TData = Awaited<ReturnType<typeof logoutAuthLogoutGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof logoutAuthLogoutGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLogoutAuthLogoutGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Oauth Login
 */
export const oauthLoginAuthProviderGet = (
    provider: string,
    params?: OauthLoginAuthProviderGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.get(
      `/auth/${provider}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getOauthLoginAuthProviderGetQueryKey = (provider: string,
    params?: OauthLoginAuthProviderGetParams,) => [`/auth/${provider}`, ...(params ? [params]: [])] as const;
  

    
export const getOauthLoginAuthProviderGetQueryOptions = <TData = Awaited<ReturnType<typeof oauthLoginAuthProviderGet>>, TError = AxiosError<unknown | void | HTTPValidationError>>(provider: string,
    params?: OauthLoginAuthProviderGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof oauthLoginAuthProviderGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof oauthLoginAuthProviderGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOauthLoginAuthProviderGetQueryKey(provider,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof oauthLoginAuthProviderGet>>> = ({ signal }) => oauthLoginAuthProviderGet(provider,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(provider), ...queryOptions}}

export type OauthLoginAuthProviderGetQueryResult = NonNullable<Awaited<ReturnType<typeof oauthLoginAuthProviderGet>>>
export type OauthLoginAuthProviderGetQueryError = AxiosError<unknown | void | HTTPValidationError>

/**
 * @summary Oauth Login
 */
export const useOauthLoginAuthProviderGet = <TData = Awaited<ReturnType<typeof oauthLoginAuthProviderGet>>, TError = AxiosError<unknown | void | HTTPValidationError>>(
 provider: string,
    params?: OauthLoginAuthProviderGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof oauthLoginAuthProviderGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOauthLoginAuthProviderGetQueryOptions(provider,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Oauth Callback
 */
export const oauthCallbackAuthProviderCallbackGet = (
    provider: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.get(
      `/auth/${provider}/callback`,options
    );
  }


export const getOauthCallbackAuthProviderCallbackGetQueryKey = (provider: string,) => [`/auth/${provider}/callback`] as const;
  

    
export const getOauthCallbackAuthProviderCallbackGetQueryOptions = <TData = Awaited<ReturnType<typeof oauthCallbackAuthProviderCallbackGet>>, TError = AxiosError<void | HTTPValidationError>>(provider: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof oauthCallbackAuthProviderCallbackGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof oauthCallbackAuthProviderCallbackGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOauthCallbackAuthProviderCallbackGetQueryKey(provider);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof oauthCallbackAuthProviderCallbackGet>>> = ({ signal }) => oauthCallbackAuthProviderCallbackGet(provider, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(provider), ...queryOptions}}

export type OauthCallbackAuthProviderCallbackGetQueryResult = NonNullable<Awaited<ReturnType<typeof oauthCallbackAuthProviderCallbackGet>>>
export type OauthCallbackAuthProviderCallbackGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Oauth Callback
 */
export const useOauthCallbackAuthProviderCallbackGet = <TData = Awaited<ReturnType<typeof oauthCallbackAuthProviderCallbackGet>>, TError = AxiosError<void | HTTPValidationError>>(
 provider: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof oauthCallbackAuthProviderCallbackGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOauthCallbackAuthProviderCallbackGetQueryOptions(provider,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List Auth Providers
 */
export const listAuthProvidersAuthInfoProvidersGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string[]>> => {
    return axios.get(
      `/auth/info/providers`,options
    );
  }


export const getListAuthProvidersAuthInfoProvidersGetQueryKey = () => [`/auth/info/providers`] as const;
  

    
export const getListAuthProvidersAuthInfoProvidersGetQueryOptions = <TData = Awaited<ReturnType<typeof listAuthProvidersAuthInfoProvidersGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAuthProvidersAuthInfoProvidersGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof listAuthProvidersAuthInfoProvidersGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAuthProvidersAuthInfoProvidersGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAuthProvidersAuthInfoProvidersGet>>> = ({ signal }) => listAuthProvidersAuthInfoProvidersGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListAuthProvidersAuthInfoProvidersGetQueryResult = NonNullable<Awaited<ReturnType<typeof listAuthProvidersAuthInfoProvidersGet>>>
export type ListAuthProvidersAuthInfoProvidersGetQueryError = AxiosError<unknown>

/**
 * @summary List Auth Providers
 */
export const useListAuthProvidersAuthInfoProvidersGet = <TData = Awaited<ReturnType<typeof listAuthProvidersAuthInfoProvidersGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAuthProvidersAuthInfoProvidersGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAuthProvidersAuthInfoProvidersGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Password Register
 */
export const passwordRegisterAuthPasswordRegisterPost = (
    bodyPasswordRegisterAuthPasswordRegisterPost: BodyPasswordRegisterAuthPasswordRegisterPost, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/auth/password/register`,
      bodyPasswordRegisterAuthPasswordRegisterPost,options
    );
  }



export const getPasswordRegisterAuthPasswordRegisterPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof passwordRegisterAuthPasswordRegisterPost>>, TError,{data: BodyPasswordRegisterAuthPasswordRegisterPost}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof passwordRegisterAuthPasswordRegisterPost>>, TError,{data: BodyPasswordRegisterAuthPasswordRegisterPost}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof passwordRegisterAuthPasswordRegisterPost>>, {data: BodyPasswordRegisterAuthPasswordRegisterPost}> = (props) => {
          const {data} = props ?? {};

          return  passwordRegisterAuthPasswordRegisterPost(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PasswordRegisterAuthPasswordRegisterPostMutationResult = NonNullable<Awaited<ReturnType<typeof passwordRegisterAuthPasswordRegisterPost>>>
    export type PasswordRegisterAuthPasswordRegisterPostMutationBody = BodyPasswordRegisterAuthPasswordRegisterPost
    export type PasswordRegisterAuthPasswordRegisterPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Password Register
 */
export const usePasswordRegisterAuthPasswordRegisterPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof passwordRegisterAuthPasswordRegisterPost>>, TError,{data: BodyPasswordRegisterAuthPasswordRegisterPost}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPasswordRegisterAuthPasswordRegisterPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Password Login
 */
export const passwordLoginAuthPasswordLoginPost = (
    bodyPasswordLoginAuthPasswordLoginPost: BodyPasswordLoginAuthPasswordLoginPost, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/auth/password/login`,
      bodyPasswordLoginAuthPasswordLoginPost,options
    );
  }



export const getPasswordLoginAuthPasswordLoginPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof passwordLoginAuthPasswordLoginPost>>, TError,{data: BodyPasswordLoginAuthPasswordLoginPost}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof passwordLoginAuthPasswordLoginPost>>, TError,{data: BodyPasswordLoginAuthPasswordLoginPost}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof passwordLoginAuthPasswordLoginPost>>, {data: BodyPasswordLoginAuthPasswordLoginPost}> = (props) => {
          const {data} = props ?? {};

          return  passwordLoginAuthPasswordLoginPost(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PasswordLoginAuthPasswordLoginPostMutationResult = NonNullable<Awaited<ReturnType<typeof passwordLoginAuthPasswordLoginPost>>>
    export type PasswordLoginAuthPasswordLoginPostMutationBody = BodyPasswordLoginAuthPasswordLoginPost
    export type PasswordLoginAuthPasswordLoginPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Password Login
 */
export const usePasswordLoginAuthPasswordLoginPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof passwordLoginAuthPasswordLoginPost>>, TError,{data: BodyPasswordLoginAuthPasswordLoginPost}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPasswordLoginAuthPasswordLoginPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Request Password Reset
 */
export const requestPasswordResetAuthPasswordResetRequestPost = (
    params: RequestPasswordResetAuthPasswordResetRequestPostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/auth/password/reset/request`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRequestPasswordResetAuthPasswordResetRequestPostMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestPasswordResetAuthPasswordResetRequestPost>>, TError,{params: RequestPasswordResetAuthPasswordResetRequestPostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof requestPasswordResetAuthPasswordResetRequestPost>>, TError,{params: RequestPasswordResetAuthPasswordResetRequestPostParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof requestPasswordResetAuthPasswordResetRequestPost>>, {params: RequestPasswordResetAuthPasswordResetRequestPostParams}> = (props) => {
          const {params} = props ?? {};

          return  requestPasswordResetAuthPasswordResetRequestPost(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RequestPasswordResetAuthPasswordResetRequestPostMutationResult = NonNullable<Awaited<ReturnType<typeof requestPasswordResetAuthPasswordResetRequestPost>>>
    
    export type RequestPasswordResetAuthPasswordResetRequestPostMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Request Password Reset
 */
export const useRequestPasswordResetAuthPasswordResetRequestPost = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestPasswordResetAuthPasswordResetRequestPost>>, TError,{params: RequestPasswordResetAuthPasswordResetRequestPostParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRequestPasswordResetAuthPasswordResetRequestPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Password Reset
 */
export const passwordResetAuthPasswordResetPost = (
    bodyPasswordResetAuthPasswordResetPost: BodyPasswordResetAuthPasswordResetPost,
    params: PasswordResetAuthPasswordResetPostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/auth/password/reset`,
      bodyPasswordResetAuthPasswordResetPost,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPasswordResetAuthPasswordResetPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof passwordResetAuthPasswordResetPost>>, TError,{data: BodyPasswordResetAuthPasswordResetPost;params: PasswordResetAuthPasswordResetPostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof passwordResetAuthPasswordResetPost>>, TError,{data: BodyPasswordResetAuthPasswordResetPost;params: PasswordResetAuthPasswordResetPostParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof passwordResetAuthPasswordResetPost>>, {data: BodyPasswordResetAuthPasswordResetPost;params: PasswordResetAuthPasswordResetPostParams}> = (props) => {
          const {data,params} = props ?? {};

          return  passwordResetAuthPasswordResetPost(data,params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PasswordResetAuthPasswordResetPostMutationResult = NonNullable<Awaited<ReturnType<typeof passwordResetAuthPasswordResetPost>>>
    export type PasswordResetAuthPasswordResetPostMutationBody = BodyPasswordResetAuthPasswordResetPost
    export type PasswordResetAuthPasswordResetPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Password Reset
 */
export const usePasswordResetAuthPasswordResetPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof passwordResetAuthPasswordResetPost>>, TError,{data: BodyPasswordResetAuthPasswordResetPost;params: PasswordResetAuthPasswordResetPostParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPasswordResetAuthPasswordResetPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Password Change
 */
export const passwordChangeAuthPasswordChangePost = (
    passwordChangeAuthPasswordChangePostBody: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/auth/password/change`,
      passwordChangeAuthPasswordChangePostBody,options
    );
  }



export const getPasswordChangeAuthPasswordChangePostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof passwordChangeAuthPasswordChangePost>>, TError,{data: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof passwordChangeAuthPasswordChangePost>>, TError,{data: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof passwordChangeAuthPasswordChangePost>>, {data: string}> = (props) => {
          const {data} = props ?? {};

          return  passwordChangeAuthPasswordChangePost(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PasswordChangeAuthPasswordChangePostMutationResult = NonNullable<Awaited<ReturnType<typeof passwordChangeAuthPasswordChangePost>>>
    export type PasswordChangeAuthPasswordChangePostMutationBody = string
    export type PasswordChangeAuthPasswordChangePostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Password Change
 */
export const usePasswordChangeAuthPasswordChangePost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof passwordChangeAuthPasswordChangePost>>, TError,{data: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getPasswordChangeAuthPasswordChangePostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Event Categories
 */
export const listEventCategoriesEventsEventIdCategoriesGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CategoryRead[]>> => {
    return axios.get(
      `/events/${eventId}/categories`,options
    );
  }


export const getListEventCategoriesEventsEventIdCategoriesGetQueryKey = (eventId: string,) => [`/events/${eventId}/categories`] as const;
  

    
export const getListEventCategoriesEventsEventIdCategoriesGetQueryOptions = <TData = Awaited<ReturnType<typeof listEventCategoriesEventsEventIdCategoriesGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventCategoriesEventsEventIdCategoriesGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof listEventCategoriesEventsEventIdCategoriesGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEventCategoriesEventsEventIdCategoriesGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEventCategoriesEventsEventIdCategoriesGet>>> = ({ signal }) => listEventCategoriesEventsEventIdCategoriesGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type ListEventCategoriesEventsEventIdCategoriesGetQueryResult = NonNullable<Awaited<ReturnType<typeof listEventCategoriesEventsEventIdCategoriesGet>>>
export type ListEventCategoriesEventsEventIdCategoriesGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary List Event Categories
 */
export const useListEventCategoriesEventsEventIdCategoriesGet = <TData = Awaited<ReturnType<typeof listEventCategoriesEventsEventIdCategoriesGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventCategoriesEventsEventIdCategoriesGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEventCategoriesEventsEventIdCategoriesGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Event Category
 */
export const createEventCategoryEventsEventIdCategoriesPost = (
    eventId: string,
    categoryCreate: CategoryCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CategoryRead>> => {
    return axios.post(
      `/events/${eventId}/categories`,
      categoryCreate,options
    );
  }



export const getCreateEventCategoryEventsEventIdCategoriesPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventCategoryEventsEventIdCategoriesPost>>, TError,{eventId: string;data: CategoryCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createEventCategoryEventsEventIdCategoriesPost>>, TError,{eventId: string;data: CategoryCreate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEventCategoryEventsEventIdCategoriesPost>>, {eventId: string;data: CategoryCreate}> = (props) => {
          const {eventId,data} = props ?? {};

          return  createEventCategoryEventsEventIdCategoriesPost(eventId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateEventCategoryEventsEventIdCategoriesPostMutationResult = NonNullable<Awaited<ReturnType<typeof createEventCategoryEventsEventIdCategoriesPost>>>
    export type CreateEventCategoryEventsEventIdCategoriesPostMutationBody = CategoryCreate
    export type CreateEventCategoryEventsEventIdCategoriesPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Event Category
 */
export const useCreateEventCategoryEventsEventIdCategoriesPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventCategoryEventsEventIdCategoriesPost>>, TError,{eventId: string;data: CategoryCreate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateEventCategoryEventsEventIdCategoriesPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Category
 */
export const getCategoryCategoriesCategoryIdGet = (
    categoryId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CategoryRead>> => {
    return axios.get(
      `/categories/${categoryId}`,options
    );
  }


export const getGetCategoryCategoriesCategoryIdGetQueryKey = (categoryId: string,) => [`/categories/${categoryId}`] as const;
  

    
export const getGetCategoryCategoriesCategoryIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getCategoryCategoriesCategoryIdGet>>, TError = AxiosError<void | HTTPValidationError>>(categoryId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategoryCategoriesCategoryIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getCategoryCategoriesCategoryIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoryCategoriesCategoryIdGetQueryKey(categoryId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategoryCategoriesCategoryIdGet>>> = ({ signal }) => getCategoryCategoriesCategoryIdGet(categoryId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(categoryId), ...queryOptions}}

export type GetCategoryCategoriesCategoryIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCategoryCategoriesCategoryIdGet>>>
export type GetCategoryCategoriesCategoryIdGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Category
 */
export const useGetCategoryCategoriesCategoryIdGet = <TData = Awaited<ReturnType<typeof getCategoryCategoriesCategoryIdGet>>, TError = AxiosError<void | HTTPValidationError>>(
 categoryId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategoryCategoriesCategoryIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCategoryCategoriesCategoryIdGetQueryOptions(categoryId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Category
 */
export const updateCategoryCategoriesCategoryIdPut = (
    categoryId: string,
    categoryUpdate: CategoryUpdate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CategoryRead>> => {
    return axios.put(
      `/categories/${categoryId}`,
      categoryUpdate,options
    );
  }



export const getUpdateCategoryCategoriesCategoryIdPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCategoryCategoriesCategoryIdPut>>, TError,{categoryId: string;data: CategoryUpdate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateCategoryCategoriesCategoryIdPut>>, TError,{categoryId: string;data: CategoryUpdate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCategoryCategoriesCategoryIdPut>>, {categoryId: string;data: CategoryUpdate}> = (props) => {
          const {categoryId,data} = props ?? {};

          return  updateCategoryCategoriesCategoryIdPut(categoryId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateCategoryCategoriesCategoryIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateCategoryCategoriesCategoryIdPut>>>
    export type UpdateCategoryCategoriesCategoryIdPutMutationBody = CategoryUpdate
    export type UpdateCategoryCategoriesCategoryIdPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Update Category
 */
export const useUpdateCategoryCategoriesCategoryIdPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCategoryCategoriesCategoryIdPut>>, TError,{categoryId: string;data: CategoryUpdate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateCategoryCategoriesCategoryIdPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Category
 */
export const deleteCategoryCategoriesCategoryIdDelete = (
    categoryId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/categories/${categoryId}`,options
    );
  }



export const getDeleteCategoryCategoriesCategoryIdDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCategoryCategoriesCategoryIdDelete>>, TError,{categoryId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCategoryCategoriesCategoryIdDelete>>, TError,{categoryId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCategoryCategoriesCategoryIdDelete>>, {categoryId: string}> = (props) => {
          const {categoryId} = props ?? {};

          return  deleteCategoryCategoriesCategoryIdDelete(categoryId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteCategoryCategoriesCategoryIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCategoryCategoriesCategoryIdDelete>>>
    
    export type DeleteCategoryCategoriesCategoryIdDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete Category
 */
export const useDeleteCategoryCategoriesCategoryIdDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCategoryCategoriesCategoryIdDelete>>, TError,{categoryId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteCategoryCategoriesCategoryIdDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Events
 */
export const listEventsEventsGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EventReadForUser[]>> => {
    return axios.get(
      `/events`,options
    );
  }


export const getListEventsEventsGetQueryKey = () => [`/events`] as const;
  

    
export const getListEventsEventsGetQueryOptions = <TData = Awaited<ReturnType<typeof listEventsEventsGet>>, TError = AxiosError<void>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventsEventsGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof listEventsEventsGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEventsEventsGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEventsEventsGet>>> = ({ signal }) => listEventsEventsGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListEventsEventsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listEventsEventsGet>>>
export type ListEventsEventsGetQueryError = AxiosError<void>

/**
 * @summary List Events
 */
export const useListEventsEventsGet = <TData = Awaited<ReturnType<typeof listEventsEventsGet>>, TError = AxiosError<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventsEventsGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEventsEventsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Event
 */
export const createEventEventsPost = (
    eventCreate: EventCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EventReadForUser>> => {
    return axios.post(
      `/events`,
      eventCreate,options
    );
  }



export const getCreateEventEventsPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventEventsPost>>, TError,{data: EventCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createEventEventsPost>>, TError,{data: EventCreate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEventEventsPost>>, {data: EventCreate}> = (props) => {
          const {data} = props ?? {};

          return  createEventEventsPost(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateEventEventsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createEventEventsPost>>>
    export type CreateEventEventsPostMutationBody = EventCreate
    export type CreateEventEventsPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Event
 */
export const useCreateEventEventsPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventEventsPost>>, TError,{data: EventCreate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateEventEventsPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Event
 */
export const getEventEventsEventIdGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EventReadForUser>> => {
    return axios.get(
      `/events/${eventId}`,options
    );
  }


export const getGetEventEventsEventIdGetQueryKey = (eventId: string,) => [`/events/${eventId}`] as const;
  

    
export const getGetEventEventsEventIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getEventEventsEventIdGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventEventsEventIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getEventEventsEventIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventEventsEventIdGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventEventsEventIdGet>>> = ({ signal }) => getEventEventsEventIdGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type GetEventEventsEventIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getEventEventsEventIdGet>>>
export type GetEventEventsEventIdGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Event
 */
export const useGetEventEventsEventIdGet = <TData = Awaited<ReturnType<typeof getEventEventsEventIdGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventEventsEventIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventEventsEventIdGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Event
 */
export const updateEventEventsEventIdPut = (
    eventId: string,
    eventUpdate: EventUpdate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EventReadForUser>> => {
    return axios.put(
      `/events/${eventId}`,
      eventUpdate,options
    );
  }



export const getUpdateEventEventsEventIdPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventEventsEventIdPut>>, TError,{eventId: string;data: EventUpdate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateEventEventsEventIdPut>>, TError,{eventId: string;data: EventUpdate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEventEventsEventIdPut>>, {eventId: string;data: EventUpdate}> = (props) => {
          const {eventId,data} = props ?? {};

          return  updateEventEventsEventIdPut(eventId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateEventEventsEventIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateEventEventsEventIdPut>>>
    export type UpdateEventEventsEventIdPutMutationBody = EventUpdate
    export type UpdateEventEventsEventIdPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Update Event
 */
export const useUpdateEventEventsEventIdPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventEventsEventIdPut>>, TError,{eventId: string;data: EventUpdate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateEventEventsEventIdPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Copy Event
 */
export const copyEventEventsCopyPost = (
    params: CopyEventEventsCopyPostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EventReadForUser>> => {
    return axios.post(
      `/events/copy`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCopyEventEventsCopyPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof copyEventEventsCopyPost>>, TError,{params: CopyEventEventsCopyPostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof copyEventEventsCopyPost>>, TError,{params: CopyEventEventsCopyPostParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof copyEventEventsCopyPost>>, {params: CopyEventEventsCopyPostParams}> = (props) => {
          const {params} = props ?? {};

          return  copyEventEventsCopyPost(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CopyEventEventsCopyPostMutationResult = NonNullable<Awaited<ReturnType<typeof copyEventEventsCopyPost>>>
    
    export type CopyEventEventsCopyPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Copy Event
 */
export const useCopyEventEventsCopyPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof copyEventEventsCopyPost>>, TError,{params: CopyEventEventsCopyPostParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCopyEventEventsCopyPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Files
 */
export const listFilesFilesGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FileRead[]>> => {
    return axios.get(
      `/files`,options
    );
  }


export const getListFilesFilesGetQueryKey = () => [`/files`] as const;
  

    
export const getListFilesFilesGetQueryOptions = <TData = Awaited<ReturnType<typeof listFilesFilesGet>>, TError = AxiosError<void>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listFilesFilesGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof listFilesFilesGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListFilesFilesGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFilesFilesGet>>> = ({ signal }) => listFilesFilesGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListFilesFilesGetQueryResult = NonNullable<Awaited<ReturnType<typeof listFilesFilesGet>>>
export type ListFilesFilesGetQueryError = AxiosError<void>

/**
 * @summary List Files
 */
export const useListFilesFilesGet = <TData = Awaited<ReturnType<typeof listFilesFilesGet>>, TError = AxiosError<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listFilesFilesGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFilesFilesGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Upload File
 */
export const uploadFileFilesUploadPost = (
    bodyUploadFileFilesUploadPost: BodyUploadFileFilesUploadPost, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FileRead>> => {const formData = new FormData();
formData.append('file', bodyUploadFileFilesUploadPost.file)

    return axios.post(
      `/files/upload`,
      formData,options
    );
  }



export const getUploadFileFilesUploadPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFileFilesUploadPost>>, TError,{data: BodyUploadFileFilesUploadPost}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof uploadFileFilesUploadPost>>, TError,{data: BodyUploadFileFilesUploadPost}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadFileFilesUploadPost>>, {data: BodyUploadFileFilesUploadPost}> = (props) => {
          const {data} = props ?? {};

          return  uploadFileFilesUploadPost(data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UploadFileFilesUploadPostMutationResult = NonNullable<Awaited<ReturnType<typeof uploadFileFilesUploadPost>>>
    export type UploadFileFilesUploadPostMutationBody = BodyUploadFileFilesUploadPost
    export type UploadFileFilesUploadPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Upload File
 */
export const useUploadFileFilesUploadPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFileFilesUploadPost>>, TError,{data: BodyUploadFileFilesUploadPost}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUploadFileFilesUploadPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get File
 */
export const getFileFilesFileIdGet = (
    fileId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FileRead>> => {
    return axios.get(
      `/files/${fileId}`,options
    );
  }


export const getGetFileFilesFileIdGetQueryKey = (fileId: string,) => [`/files/${fileId}`] as const;
  

    
export const getGetFileFilesFileIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getFileFilesFileIdGet>>, TError = AxiosError<void | HTTPValidationError>>(fileId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFileFilesFileIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getFileFilesFileIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFileFilesFileIdGetQueryKey(fileId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFileFilesFileIdGet>>> = ({ signal }) => getFileFilesFileIdGet(fileId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(fileId), ...queryOptions}}

export type GetFileFilesFileIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getFileFilesFileIdGet>>>
export type GetFileFilesFileIdGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get File
 */
export const useGetFileFilesFileIdGet = <TData = Awaited<ReturnType<typeof getFileFilesFileIdGet>>, TError = AxiosError<void | HTTPValidationError>>(
 fileId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFileFilesFileIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFileFilesFileIdGetQueryOptions(fileId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Delete File
 */
export const deleteFileFilesFileIdDelete = (
    fileId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/files/${fileId}`,options
    );
  }



export const getDeleteFileFilesFileIdDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFileFilesFileIdDelete>>, TError,{fileId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFileFilesFileIdDelete>>, TError,{fileId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFileFilesFileIdDelete>>, {fileId: string}> = (props) => {
          const {fileId} = props ?? {};

          return  deleteFileFilesFileIdDelete(fileId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteFileFilesFileIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFileFilesFileIdDelete>>>
    
    export type DeleteFileFilesFileIdDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete File
 */
export const useDeleteFileFilesFileIdDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFileFilesFileIdDelete>>, TError,{fileId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteFileFilesFileIdDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Download File
 */
export const downloadFileFilesFileIdDownloadGet = (
    fileId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.get(
      `/files/${fileId}/download`,options
    );
  }


export const getDownloadFileFilesFileIdDownloadGetQueryKey = (fileId: string,) => [`/files/${fileId}/download`] as const;
  

    
export const getDownloadFileFilesFileIdDownloadGetQueryOptions = <TData = Awaited<ReturnType<typeof downloadFileFilesFileIdDownloadGet>>, TError = AxiosError<void | HTTPValidationError>>(fileId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadFileFilesFileIdDownloadGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof downloadFileFilesFileIdDownloadGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadFileFilesFileIdDownloadGetQueryKey(fileId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadFileFilesFileIdDownloadGet>>> = ({ signal }) => downloadFileFilesFileIdDownloadGet(fileId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(fileId), ...queryOptions}}

export type DownloadFileFilesFileIdDownloadGetQueryResult = NonNullable<Awaited<ReturnType<typeof downloadFileFilesFileIdDownloadGet>>>
export type DownloadFileFilesFileIdDownloadGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Download File
 */
export const useDownloadFileFilesFileIdDownloadGet = <TData = Awaited<ReturnType<typeof downloadFileFilesFileIdDownloadGet>>, TError = AxiosError<void | HTTPValidationError>>(
 fileId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadFileFilesFileIdDownloadGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadFileFilesFileIdDownloadGetQueryOptions(fileId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List Event Invites
 */
export const listEventInvitesEventsEventIdInvitesGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InviteRead[]>> => {
    return axios.get(
      `/events/${eventId}/invites`,options
    );
  }


export const getListEventInvitesEventsEventIdInvitesGetQueryKey = (eventId: string,) => [`/events/${eventId}/invites`] as const;
  

    
export const getListEventInvitesEventsEventIdInvitesGetQueryOptions = <TData = Awaited<ReturnType<typeof listEventInvitesEventsEventIdInvitesGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventInvitesEventsEventIdInvitesGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof listEventInvitesEventsEventIdInvitesGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEventInvitesEventsEventIdInvitesGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEventInvitesEventsEventIdInvitesGet>>> = ({ signal }) => listEventInvitesEventsEventIdInvitesGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type ListEventInvitesEventsEventIdInvitesGetQueryResult = NonNullable<Awaited<ReturnType<typeof listEventInvitesEventsEventIdInvitesGet>>>
export type ListEventInvitesEventsEventIdInvitesGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary List Event Invites
 */
export const useListEventInvitesEventsEventIdInvitesGet = <TData = Awaited<ReturnType<typeof listEventInvitesEventsEventIdInvitesGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventInvitesEventsEventIdInvitesGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEventInvitesEventsEventIdInvitesGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Event Invite
 */
export const createEventInviteEventsEventIdInvitesPost = (
    eventId: string,
    inviteCreate: InviteCreate,
    params?: CreateEventInviteEventsEventIdInvitesPostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InviteRead>> => {
    return axios.post(
      `/events/${eventId}/invites`,
      inviteCreate,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateEventInviteEventsEventIdInvitesPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventInviteEventsEventIdInvitesPost>>, TError,{eventId: string;data: InviteCreate;params?: CreateEventInviteEventsEventIdInvitesPostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createEventInviteEventsEventIdInvitesPost>>, TError,{eventId: string;data: InviteCreate;params?: CreateEventInviteEventsEventIdInvitesPostParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEventInviteEventsEventIdInvitesPost>>, {eventId: string;data: InviteCreate;params?: CreateEventInviteEventsEventIdInvitesPostParams}> = (props) => {
          const {eventId,data,params} = props ?? {};

          return  createEventInviteEventsEventIdInvitesPost(eventId,data,params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateEventInviteEventsEventIdInvitesPostMutationResult = NonNullable<Awaited<ReturnType<typeof createEventInviteEventsEventIdInvitesPost>>>
    export type CreateEventInviteEventsEventIdInvitesPostMutationBody = InviteCreate
    export type CreateEventInviteEventsEventIdInvitesPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Event Invite
 */
export const useCreateEventInviteEventsEventIdInvitesPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventInviteEventsEventIdInvitesPost>>, TError,{eventId: string;data: InviteCreate;params?: CreateEventInviteEventsEventIdInvitesPostParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateEventInviteEventsEventIdInvitesPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Resend Event Invite
 */
export const resendEventInviteInvitesInviteIdResendPut = (
    inviteId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/invites/${inviteId}/resend`,undefined,options
    );
  }



export const getResendEventInviteInvitesInviteIdResendPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resendEventInviteInvitesInviteIdResendPut>>, TError,{inviteId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof resendEventInviteInvitesInviteIdResendPut>>, TError,{inviteId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resendEventInviteInvitesInviteIdResendPut>>, {inviteId: string}> = (props) => {
          const {inviteId} = props ?? {};

          return  resendEventInviteInvitesInviteIdResendPut(inviteId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ResendEventInviteInvitesInviteIdResendPutMutationResult = NonNullable<Awaited<ReturnType<typeof resendEventInviteInvitesInviteIdResendPut>>>
    
    export type ResendEventInviteInvitesInviteIdResendPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Resend Event Invite
 */
export const useResendEventInviteInvitesInviteIdResendPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resendEventInviteInvitesInviteIdResendPut>>, TError,{inviteId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getResendEventInviteInvitesInviteIdResendPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Invite Details
 */
export const getInviteDetailsInvitesInviteIdGet = (
    inviteId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InviteInfo>> => {
    return axios.get(
      `/invites/${inviteId}`,options
    );
  }


export const getGetInviteDetailsInvitesInviteIdGetQueryKey = (inviteId: string,) => [`/invites/${inviteId}`] as const;
  

    
export const getGetInviteDetailsInvitesInviteIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getInviteDetailsInvitesInviteIdGet>>, TError = AxiosError<void | HTTPValidationError>>(inviteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInviteDetailsInvitesInviteIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getInviteDetailsInvitesInviteIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInviteDetailsInvitesInviteIdGetQueryKey(inviteId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteDetailsInvitesInviteIdGet>>> = ({ signal }) => getInviteDetailsInvitesInviteIdGet(inviteId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(inviteId), ...queryOptions}}

export type GetInviteDetailsInvitesInviteIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteDetailsInvitesInviteIdGet>>>
export type GetInviteDetailsInvitesInviteIdGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Invite Details
 */
export const useGetInviteDetailsInvitesInviteIdGet = <TData = Awaited<ReturnType<typeof getInviteDetailsInvitesInviteIdGet>>, TError = AxiosError<void | HTTPValidationError>>(
 inviteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getInviteDetailsInvitesInviteIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInviteDetailsInvitesInviteIdGetQueryOptions(inviteId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Delete Invite
 */
export const deleteInviteInvitesInviteIdDelete = (
    inviteId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/invites/${inviteId}`,options
    );
  }



export const getDeleteInviteInvitesInviteIdDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInviteInvitesInviteIdDelete>>, TError,{inviteId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteInviteInvitesInviteIdDelete>>, TError,{inviteId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteInviteInvitesInviteIdDelete>>, {inviteId: string}> = (props) => {
          const {inviteId} = props ?? {};

          return  deleteInviteInvitesInviteIdDelete(inviteId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteInviteInvitesInviteIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteInviteInvitesInviteIdDelete>>>
    
    export type DeleteInviteInvitesInviteIdDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete Invite
 */
export const useDeleteInviteInvitesInviteIdDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInviteInvitesInviteIdDelete>>, TError,{inviteId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteInviteInvitesInviteIdDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Join Event With Invite
 */
export const joinEventWithInviteInvitesInviteIdJoinPut = (
    inviteId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EventReadForUser>> => {
    return axios.put(
      `/invites/${inviteId}/join`,undefined,options
    );
  }



export const getJoinEventWithInviteInvitesInviteIdJoinPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof joinEventWithInviteInvitesInviteIdJoinPut>>, TError,{inviteId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof joinEventWithInviteInvitesInviteIdJoinPut>>, TError,{inviteId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof joinEventWithInviteInvitesInviteIdJoinPut>>, {inviteId: string}> = (props) => {
          const {inviteId} = props ?? {};

          return  joinEventWithInviteInvitesInviteIdJoinPut(inviteId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type JoinEventWithInviteInvitesInviteIdJoinPutMutationResult = NonNullable<Awaited<ReturnType<typeof joinEventWithInviteInvitesInviteIdJoinPut>>>
    
    export type JoinEventWithInviteInvitesInviteIdJoinPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Join Event With Invite
 */
export const useJoinEventWithInviteInvitesInviteIdJoinPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof joinEventWithInviteInvitesInviteIdJoinPut>>, TError,{inviteId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getJoinEventWithInviteInvitesInviteIdJoinPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Root
 */
export const rootGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.get(
      `/`,options
    );
  }


export const getRootGetQueryKey = () => [`/`] as const;
  

    
export const getRootGetQueryOptions = <TData = Awaited<ReturnType<typeof rootGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof rootGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof rootGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRootGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof rootGet>>> = ({ signal }) => rootGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type RootGetQueryResult = NonNullable<Awaited<ReturnType<typeof rootGet>>>
export type RootGetQueryError = AxiosError<unknown>

/**
 * @summary Root
 */
export const useRootGet = <TData = Awaited<ReturnType<typeof rootGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof rootGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRootGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Me
 */
export const meMeGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserRead>> => {
    return axios.get(
      `/me`,options
    );
  }


export const getMeMeGetQueryKey = () => [`/me`] as const;
  

    
export const getMeMeGetQueryOptions = <TData = Awaited<ReturnType<typeof meMeGet>>, TError = AxiosError<void>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof meMeGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof meMeGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMeMeGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof meMeGet>>> = ({ signal }) => meMeGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type MeMeGetQueryResult = NonNullable<Awaited<ReturnType<typeof meMeGet>>>
export type MeMeGetQueryError = AxiosError<void>

/**
 * @summary Me
 */
export const useMeMeGet = <TData = Awaited<ReturnType<typeof meMeGet>>, TError = AxiosError<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof meMeGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getMeMeGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List Projects
 */
export const listProjectsEventsEventIdProjectsGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ProjectReadWithUserType[]>> => {
    return axios.get(
      `/events/${eventId}/projects`,options
    );
  }


export const getListProjectsEventsEventIdProjectsGetQueryKey = (eventId: string,) => [`/events/${eventId}/projects`] as const;
  

    
export const getListProjectsEventsEventIdProjectsGetQueryOptions = <TData = Awaited<ReturnType<typeof listProjectsEventsEventIdProjectsGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listProjectsEventsEventIdProjectsGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof listProjectsEventsEventIdProjectsGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListProjectsEventsEventIdProjectsGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjectsEventsEventIdProjectsGet>>> = ({ signal }) => listProjectsEventsEventIdProjectsGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type ListProjectsEventsEventIdProjectsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listProjectsEventsEventIdProjectsGet>>>
export type ListProjectsEventsEventIdProjectsGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary List Projects
 */
export const useListProjectsEventsEventIdProjectsGet = <TData = Awaited<ReturnType<typeof listProjectsEventsEventIdProjectsGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listProjectsEventsEventIdProjectsGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListProjectsEventsEventIdProjectsGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Project
 */
export const createProjectEventsEventIdProjectsPost = (
    eventId: string,
    projectCreate: ProjectCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ProjectReadWithUserType>> => {
    return axios.post(
      `/events/${eventId}/projects`,
      projectCreate,options
    );
  }



export const getCreateProjectEventsEventIdProjectsPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectEventsEventIdProjectsPost>>, TError,{eventId: string;data: ProjectCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createProjectEventsEventIdProjectsPost>>, TError,{eventId: string;data: ProjectCreate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProjectEventsEventIdProjectsPost>>, {eventId: string;data: ProjectCreate}> = (props) => {
          const {eventId,data} = props ?? {};

          return  createProjectEventsEventIdProjectsPost(eventId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateProjectEventsEventIdProjectsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createProjectEventsEventIdProjectsPost>>>
    export type CreateProjectEventsEventIdProjectsPostMutationBody = ProjectCreate
    export type CreateProjectEventsEventIdProjectsPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Project
 */
export const useCreateProjectEventsEventIdProjectsPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectEventsEventIdProjectsPost>>, TError,{eventId: string;data: ProjectCreate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateProjectEventsEventIdProjectsPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Project
 */
export const getProjectProjectsProjectIdGet = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ProjectReadWithUserType>> => {
    return axios.get(
      `/projects/${projectId}`,options
    );
  }


export const getGetProjectProjectsProjectIdGetQueryKey = (projectId: string,) => [`/projects/${projectId}`] as const;
  

    
export const getGetProjectProjectsProjectIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getProjectProjectsProjectIdGet>>, TError = AxiosError<void | HTTPValidationError>>(projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectsProjectIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectsProjectIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectProjectsProjectIdGetQueryKey(projectId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectsProjectIdGet>>> = ({ signal }) => getProjectProjectsProjectIdGet(projectId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions}}

export type GetProjectProjectsProjectIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectProjectsProjectIdGet>>>
export type GetProjectProjectsProjectIdGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Project
 */
export const useGetProjectProjectsProjectIdGet = <TData = Awaited<ReturnType<typeof getProjectProjectsProjectIdGet>>, TError = AxiosError<void | HTTPValidationError>>(
 projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectsProjectIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectProjectsProjectIdGetQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Project Details
 */
export const updateProjectDetailsProjectsProjectIdPut = (
    projectId: string,
    projectUpdate: ProjectUpdate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ProjectReadWithUserType>> => {
    return axios.put(
      `/projects/${projectId}`,
      projectUpdate,options
    );
  }



export const getUpdateProjectDetailsProjectsProjectIdPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectDetailsProjectsProjectIdPut>>, TError,{projectId: string;data: ProjectUpdate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateProjectDetailsProjectsProjectIdPut>>, TError,{projectId: string;data: ProjectUpdate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectDetailsProjectsProjectIdPut>>, {projectId: string;data: ProjectUpdate}> = (props) => {
          const {projectId,data} = props ?? {};

          return  updateProjectDetailsProjectsProjectIdPut(projectId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateProjectDetailsProjectsProjectIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectDetailsProjectsProjectIdPut>>>
    export type UpdateProjectDetailsProjectsProjectIdPutMutationBody = ProjectUpdate
    export type UpdateProjectDetailsProjectsProjectIdPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Update Project Details
 */
export const useUpdateProjectDetailsProjectsProjectIdPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectDetailsProjectsProjectIdPut>>, TError,{projectId: string;data: ProjectUpdate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateProjectDetailsProjectsProjectIdPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Project
 */
export const deleteProjectProjectsProjectIdDelete = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/projects/${projectId}`,options
    );
  }



export const getDeleteProjectProjectsProjectIdDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectProjectsProjectIdDelete>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectProjectsProjectIdDelete>>, TError,{projectId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectProjectsProjectIdDelete>>, {projectId: string}> = (props) => {
          const {projectId} = props ?? {};

          return  deleteProjectProjectsProjectIdDelete(projectId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectProjectsProjectIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectProjectsProjectIdDelete>>>
    
    export type DeleteProjectProjectsProjectIdDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete Project
 */
export const useDeleteProjectProjectsProjectIdDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectProjectsProjectIdDelete>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteProjectProjectsProjectIdDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Project Users
 */
export const getProjectUsersProjectsProjectIdUsersTypeGet = (
    projectId: string,
    type: UserProjectType, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserRead[]>> => {
    return axios.get(
      `/projects/${projectId}/users/${type}`,options
    );
  }


export const getGetProjectUsersProjectsProjectIdUsersTypeGetQueryKey = (projectId: string,
    type: UserProjectType,) => [`/projects/${projectId}/users/${type}`] as const;
  

    
export const getGetProjectUsersProjectsProjectIdUsersTypeGetQueryOptions = <TData = Awaited<ReturnType<typeof getProjectUsersProjectsProjectIdUsersTypeGet>>, TError = AxiosError<void | HTTPValidationError>>(projectId: string,
    type: UserProjectType, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectUsersProjectsProjectIdUsersTypeGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getProjectUsersProjectsProjectIdUsersTypeGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectUsersProjectsProjectIdUsersTypeGetQueryKey(projectId,type);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectUsersProjectsProjectIdUsersTypeGet>>> = ({ signal }) => getProjectUsersProjectsProjectIdUsersTypeGet(projectId,type, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(projectId && type), ...queryOptions}}

export type GetProjectUsersProjectsProjectIdUsersTypeGetQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectUsersProjectsProjectIdUsersTypeGet>>>
export type GetProjectUsersProjectsProjectIdUsersTypeGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Project Users
 */
export const useGetProjectUsersProjectsProjectIdUsersTypeGet = <TData = Awaited<ReturnType<typeof getProjectUsersProjectsProjectIdUsersTypeGet>>, TError = AxiosError<void | HTTPValidationError>>(
 projectId: string,
    type: UserProjectType, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectUsersProjectsProjectIdUsersTypeGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectUsersProjectsProjectIdUsersTypeGetQueryOptions(projectId,type,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Add User To Project
 */
export const addUserToProjectProjectsProjectIdUsersTypePost = (
    projectId: string,
    type: UserProjectType,
    params: AddUserToProjectProjectsProjectIdUsersTypePostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/projects/${projectId}/users/${type}`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getAddUserToProjectProjectsProjectIdUsersTypePostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addUserToProjectProjectsProjectIdUsersTypePost>>, TError,{projectId: string;type: UserProjectType;params: AddUserToProjectProjectsProjectIdUsersTypePostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addUserToProjectProjectsProjectIdUsersTypePost>>, TError,{projectId: string;type: UserProjectType;params: AddUserToProjectProjectsProjectIdUsersTypePostParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addUserToProjectProjectsProjectIdUsersTypePost>>, {projectId: string;type: UserProjectType;params: AddUserToProjectProjectsProjectIdUsersTypePostParams}> = (props) => {
          const {projectId,type,params} = props ?? {};

          return  addUserToProjectProjectsProjectIdUsersTypePost(projectId,type,params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddUserToProjectProjectsProjectIdUsersTypePostMutationResult = NonNullable<Awaited<ReturnType<typeof addUserToProjectProjectsProjectIdUsersTypePost>>>
    
    export type AddUserToProjectProjectsProjectIdUsersTypePostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Add User To Project
 */
export const useAddUserToProjectProjectsProjectIdUsersTypePost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addUserToProjectProjectsProjectIdUsersTypePost>>, TError,{projectId: string;type: UserProjectType;params: AddUserToProjectProjectsProjectIdUsersTypePostParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getAddUserToProjectProjectsProjectIdUsersTypePostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Accept Or Decline Invite
 */
export const acceptOrDeclineInviteProjectsProjectIdInviteActionPut = (
    projectId: string,
    action: 'accept' | 'decline', options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/projects/${projectId}/invite/${action}`,undefined,options
    );
  }



export const getAcceptOrDeclineInviteProjectsProjectIdInviteActionPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptOrDeclineInviteProjectsProjectIdInviteActionPut>>, TError,{projectId: string;action: 'accept' | 'decline'}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof acceptOrDeclineInviteProjectsProjectIdInviteActionPut>>, TError,{projectId: string;action: 'accept' | 'decline'}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof acceptOrDeclineInviteProjectsProjectIdInviteActionPut>>, {projectId: string;action: 'accept' | 'decline'}> = (props) => {
          const {projectId,action} = props ?? {};

          return  acceptOrDeclineInviteProjectsProjectIdInviteActionPut(projectId,action,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AcceptOrDeclineInviteProjectsProjectIdInviteActionPutMutationResult = NonNullable<Awaited<ReturnType<typeof acceptOrDeclineInviteProjectsProjectIdInviteActionPut>>>
    
    export type AcceptOrDeclineInviteProjectsProjectIdInviteActionPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Accept Or Decline Invite
 */
export const useAcceptOrDeclineInviteProjectsProjectIdInviteActionPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptOrDeclineInviteProjectsProjectIdInviteActionPut>>, TError,{projectId: string;action: 'accept' | 'decline'}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getAcceptOrDeclineInviteProjectsProjectIdInviteActionPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Remove User From Project
 */
export const removeUserFromProjectProjectsProjectIdUsersUserIdDelete = (
    projectId: string,
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/projects/${projectId}/users/${userId}`,options
    );
  }



export const getRemoveUserFromProjectProjectsProjectIdUsersUserIdDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeUserFromProjectProjectsProjectIdUsersUserIdDelete>>, TError,{projectId: string;userId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof removeUserFromProjectProjectsProjectIdUsersUserIdDelete>>, TError,{projectId: string;userId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeUserFromProjectProjectsProjectIdUsersUserIdDelete>>, {projectId: string;userId: string}> = (props) => {
          const {projectId,userId} = props ?? {};

          return  removeUserFromProjectProjectsProjectIdUsersUserIdDelete(projectId,userId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemoveUserFromProjectProjectsProjectIdUsersUserIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof removeUserFromProjectProjectsProjectIdUsersUserIdDelete>>>
    
    export type RemoveUserFromProjectProjectsProjectIdUsersUserIdDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Remove User From Project
 */
export const useRemoveUserFromProjectProjectsProjectIdUsersUserIdDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeUserFromProjectProjectsProjectIdUsersUserIdDelete>>, TError,{projectId: string;userId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRemoveUserFromProjectProjectsProjectIdUsersUserIdDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Event Roles
 */
export const listEventRolesEventsEventIdRolesGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RoleRead[]>> => {
    return axios.get(
      `/events/${eventId}/roles`,options
    );
  }


export const getListEventRolesEventsEventIdRolesGetQueryKey = (eventId: string,) => [`/events/${eventId}/roles`] as const;
  

    
export const getListEventRolesEventsEventIdRolesGetQueryOptions = <TData = Awaited<ReturnType<typeof listEventRolesEventsEventIdRolesGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventRolesEventsEventIdRolesGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof listEventRolesEventsEventIdRolesGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEventRolesEventsEventIdRolesGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEventRolesEventsEventIdRolesGet>>> = ({ signal }) => listEventRolesEventsEventIdRolesGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type ListEventRolesEventsEventIdRolesGetQueryResult = NonNullable<Awaited<ReturnType<typeof listEventRolesEventsEventIdRolesGet>>>
export type ListEventRolesEventsEventIdRolesGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary List Event Roles
 */
export const useListEventRolesEventsEventIdRolesGet = <TData = Awaited<ReturnType<typeof listEventRolesEventsEventIdRolesGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventRolesEventsEventIdRolesGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEventRolesEventsEventIdRolesGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Event Role
 */
export const createEventRoleEventsEventIdRolesPost = (
    eventId: string,
    roleCreate: RoleCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RoleRead>> => {
    return axios.post(
      `/events/${eventId}/roles`,
      roleCreate,options
    );
  }



export const getCreateEventRoleEventsEventIdRolesPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventRoleEventsEventIdRolesPost>>, TError,{eventId: string;data: RoleCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createEventRoleEventsEventIdRolesPost>>, TError,{eventId: string;data: RoleCreate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEventRoleEventsEventIdRolesPost>>, {eventId: string;data: RoleCreate}> = (props) => {
          const {eventId,data} = props ?? {};

          return  createEventRoleEventsEventIdRolesPost(eventId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateEventRoleEventsEventIdRolesPostMutationResult = NonNullable<Awaited<ReturnType<typeof createEventRoleEventsEventIdRolesPost>>>
    export type CreateEventRoleEventsEventIdRolesPostMutationBody = RoleCreate
    export type CreateEventRoleEventsEventIdRolesPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Event Role
 */
export const useCreateEventRoleEventsEventIdRolesPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventRoleEventsEventIdRolesPost>>, TError,{eventId: string;data: RoleCreate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateEventRoleEventsEventIdRolesPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Role
 */
export const getRoleRolesRoleIdGet = (
    roleId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RoleRead>> => {
    return axios.get(
      `/roles/${roleId}`,options
    );
  }


export const getGetRoleRolesRoleIdGetQueryKey = (roleId: string,) => [`/roles/${roleId}`] as const;
  

    
export const getGetRoleRolesRoleIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getRoleRolesRoleIdGet>>, TError = AxiosError<void | HTTPValidationError>>(roleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRoleRolesRoleIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getRoleRolesRoleIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRoleRolesRoleIdGetQueryKey(roleId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoleRolesRoleIdGet>>> = ({ signal }) => getRoleRolesRoleIdGet(roleId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(roleId), ...queryOptions}}

export type GetRoleRolesRoleIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRoleRolesRoleIdGet>>>
export type GetRoleRolesRoleIdGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Role
 */
export const useGetRoleRolesRoleIdGet = <TData = Awaited<ReturnType<typeof getRoleRolesRoleIdGet>>, TError = AxiosError<void | HTTPValidationError>>(
 roleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRoleRolesRoleIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRoleRolesRoleIdGetQueryOptions(roleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Event Role
 */
export const updateEventRoleRolesRoleIdPut = (
    roleId: string,
    roleUpdate: RoleUpdate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RoleRead>> => {
    return axios.put(
      `/roles/${roleId}`,
      roleUpdate,options
    );
  }



export const getUpdateEventRoleRolesRoleIdPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventRoleRolesRoleIdPut>>, TError,{roleId: string;data: RoleUpdate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateEventRoleRolesRoleIdPut>>, TError,{roleId: string;data: RoleUpdate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEventRoleRolesRoleIdPut>>, {roleId: string;data: RoleUpdate}> = (props) => {
          const {roleId,data} = props ?? {};

          return  updateEventRoleRolesRoleIdPut(roleId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateEventRoleRolesRoleIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateEventRoleRolesRoleIdPut>>>
    export type UpdateEventRoleRolesRoleIdPutMutationBody = RoleUpdate
    export type UpdateEventRoleRolesRoleIdPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Update Event Role
 */
export const useUpdateEventRoleRolesRoleIdPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventRoleRolesRoleIdPut>>, TError,{roleId: string;data: RoleUpdate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateEventRoleRolesRoleIdPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Event Role
 */
export const deleteEventRoleRolesRoleIdDelete = (
    roleId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/roles/${roleId}`,options
    );
  }



export const getDeleteEventRoleRolesRoleIdDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventRoleRolesRoleIdDelete>>, TError,{roleId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEventRoleRolesRoleIdDelete>>, TError,{roleId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEventRoleRolesRoleIdDelete>>, {roleId: string}> = (props) => {
          const {roleId} = props ?? {};

          return  deleteEventRoleRolesRoleIdDelete(roleId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteEventRoleRolesRoleIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEventRoleRolesRoleIdDelete>>>
    
    export type DeleteEventRoleRolesRoleIdDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete Event Role
 */
export const useDeleteEventRoleRolesRoleIdDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventRoleRolesRoleIdDelete>>, TError,{roleId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteEventRoleRolesRoleIdDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Start Scheduler For Event
 */
export const startSchedulerForEventEventsEventIdScheduleStartGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.get(
      `/events/${eventId}/schedule/start`,options
    );
  }


export const getStartSchedulerForEventEventsEventIdScheduleStartGetQueryKey = (eventId: string,) => [`/events/${eventId}/schedule/start`] as const;
  

    
export const getStartSchedulerForEventEventsEventIdScheduleStartGetQueryOptions = <TData = Awaited<ReturnType<typeof startSchedulerForEventEventsEventIdScheduleStartGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof startSchedulerForEventEventsEventIdScheduleStartGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof startSchedulerForEventEventsEventIdScheduleStartGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStartSchedulerForEventEventsEventIdScheduleStartGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof startSchedulerForEventEventsEventIdScheduleStartGet>>> = ({ signal }) => startSchedulerForEventEventsEventIdScheduleStartGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type StartSchedulerForEventEventsEventIdScheduleStartGetQueryResult = NonNullable<Awaited<ReturnType<typeof startSchedulerForEventEventsEventIdScheduleStartGet>>>
export type StartSchedulerForEventEventsEventIdScheduleStartGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Start Scheduler For Event
 */
export const useStartSchedulerForEventEventsEventIdScheduleStartGet = <TData = Awaited<ReturnType<typeof startSchedulerForEventEventsEventIdScheduleStartGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof startSchedulerForEventEventsEventIdScheduleStartGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getStartSchedulerForEventEventsEventIdScheduleStartGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Event Schedule
 */
export const getEventScheduleEventsEventIdScheduleGet = (
    eventId: string,
    params?: GetEventScheduleEventsEventIdScheduleGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<JudgeAssignmentRead[]>> => {
    return axios.get(
      `/events/${eventId}/schedule`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetEventScheduleEventsEventIdScheduleGetQueryKey = (eventId: string,
    params?: GetEventScheduleEventsEventIdScheduleGetParams,) => [`/events/${eventId}/schedule`, ...(params ? [params]: [])] as const;
  

    
export const getGetEventScheduleEventsEventIdScheduleGetQueryOptions = <TData = Awaited<ReturnType<typeof getEventScheduleEventsEventIdScheduleGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string,
    params?: GetEventScheduleEventsEventIdScheduleGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventScheduleEventsEventIdScheduleGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getEventScheduleEventsEventIdScheduleGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventScheduleEventsEventIdScheduleGetQueryKey(eventId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventScheduleEventsEventIdScheduleGet>>> = ({ signal }) => getEventScheduleEventsEventIdScheduleGet(eventId,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type GetEventScheduleEventsEventIdScheduleGetQueryResult = NonNullable<Awaited<ReturnType<typeof getEventScheduleEventsEventIdScheduleGet>>>
export type GetEventScheduleEventsEventIdScheduleGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Event Schedule
 */
export const useGetEventScheduleEventsEventIdScheduleGet = <TData = Awaited<ReturnType<typeof getEventScheduleEventsEventIdScheduleGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string,
    params?: GetEventScheduleEventsEventIdScheduleGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventScheduleEventsEventIdScheduleGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventScheduleEventsEventIdScheduleGetQueryOptions(eventId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Reset Event Schedule
 */
export const resetEventScheduleEventsEventIdScheduleDelete = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/events/${eventId}/schedule`,options
    );
  }



export const getResetEventScheduleEventsEventIdScheduleDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetEventScheduleEventsEventIdScheduleDelete>>, TError,{eventId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof resetEventScheduleEventsEventIdScheduleDelete>>, TError,{eventId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetEventScheduleEventsEventIdScheduleDelete>>, {eventId: string}> = (props) => {
          const {eventId} = props ?? {};

          return  resetEventScheduleEventsEventIdScheduleDelete(eventId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ResetEventScheduleEventsEventIdScheduleDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof resetEventScheduleEventsEventIdScheduleDelete>>>
    
    export type ResetEventScheduleEventsEventIdScheduleDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Reset Event Schedule
 */
export const useResetEventScheduleEventsEventIdScheduleDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetEventScheduleEventsEventIdScheduleDelete>>, TError,{eventId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getResetEventScheduleEventsEventIdScheduleDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Project Schedule
 */
export const getProjectScheduleProjectsProjectIdScheduleGet = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<JudgeAssignmentRead[]>> => {
    return axios.get(
      `/projects/${projectId}/schedule`,options
    );
  }


export const getGetProjectScheduleProjectsProjectIdScheduleGetQueryKey = (projectId: string,) => [`/projects/${projectId}/schedule`] as const;
  

    
export const getGetProjectScheduleProjectsProjectIdScheduleGetQueryOptions = <TData = Awaited<ReturnType<typeof getProjectScheduleProjectsProjectIdScheduleGet>>, TError = AxiosError<void | HTTPValidationError>>(projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectScheduleProjectsProjectIdScheduleGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getProjectScheduleProjectsProjectIdScheduleGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectScheduleProjectsProjectIdScheduleGetQueryKey(projectId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectScheduleProjectsProjectIdScheduleGet>>> = ({ signal }) => getProjectScheduleProjectsProjectIdScheduleGet(projectId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions}}

export type GetProjectScheduleProjectsProjectIdScheduleGetQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectScheduleProjectsProjectIdScheduleGet>>>
export type GetProjectScheduleProjectsProjectIdScheduleGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Project Schedule
 */
export const useGetProjectScheduleProjectsProjectIdScheduleGet = <TData = Awaited<ReturnType<typeof getProjectScheduleProjectsProjectIdScheduleGet>>, TError = AxiosError<void | HTTPValidationError>>(
 projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectScheduleProjectsProjectIdScheduleGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectScheduleProjectsProjectIdScheduleGetQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Add Or Update Project Judges
 */
export const addOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPut = (
    projectId: string,
    judgeAddUpdate: JudgeAddUpdate[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<JudgeAssignmentRead[]>> => {
    return axios.put(
      `/projects/${projectId}/users/judges`,
      judgeAddUpdate,options
    );
  }



export const getAddOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPut>>, TError,{projectId: string;data: JudgeAddUpdate[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPut>>, TError,{projectId: string;data: JudgeAddUpdate[]}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPut>>, {projectId: string;data: JudgeAddUpdate[]}> = (props) => {
          const {projectId,data} = props ?? {};

          return  addOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPut(projectId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPutMutationResult = NonNullable<Awaited<ReturnType<typeof addOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPut>>>
    export type AddOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPutMutationBody = JudgeAddUpdate[]
    export type AddOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Add Or Update Project Judges
 */
export const useAddOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPut>>, TError,{projectId: string;data: JudgeAddUpdate[]}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getAddOrUpdateProjectJudgesProjectsProjectIdUsersJudgesPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Remove Judges From Project
 */
export const removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDelete = (
    projectId: string,
    removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDeleteBody: string[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/projects/${projectId}/users/judges/delete`,{data:
      removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDeleteBody, ...options}
    );
  }



export const getRemoveJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDelete>>, TError,{projectId: string;data: string[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDelete>>, TError,{projectId: string;data: string[]}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDelete>>, {projectId: string;data: string[]}> = (props) => {
          const {projectId,data} = props ?? {};

          return  removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDelete(projectId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemoveJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDelete>>>
    export type RemoveJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDeleteMutationBody = string[]
    export type RemoveJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Remove Judges From Project
 */
export const useRemoveJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDelete>>, TError,{projectId: string;data: string[]}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getRemoveJudgesFromProjectProjectsProjectIdUsersJudgesDeleteDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Event Schedule Status
 */
export const getEventScheduleStatusEventsEventIdScheduleStatusGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AddonStatusRead>> => {
    return axios.get(
      `/events/${eventId}/schedule/status`,options
    );
  }


export const getGetEventScheduleStatusEventsEventIdScheduleStatusGetQueryKey = (eventId: string,) => [`/events/${eventId}/schedule/status`] as const;
  

    
export const getGetEventScheduleStatusEventsEventIdScheduleStatusGetQueryOptions = <TData = Awaited<ReturnType<typeof getEventScheduleStatusEventsEventIdScheduleStatusGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventScheduleStatusEventsEventIdScheduleStatusGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getEventScheduleStatusEventsEventIdScheduleStatusGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventScheduleStatusEventsEventIdScheduleStatusGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventScheduleStatusEventsEventIdScheduleStatusGet>>> = ({ signal }) => getEventScheduleStatusEventsEventIdScheduleStatusGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type GetEventScheduleStatusEventsEventIdScheduleStatusGetQueryResult = NonNullable<Awaited<ReturnType<typeof getEventScheduleStatusEventsEventIdScheduleStatusGet>>>
export type GetEventScheduleStatusEventsEventIdScheduleStatusGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Event Schedule Status
 */
export const useGetEventScheduleStatusEventsEventIdScheduleStatusGet = <TData = Awaited<ReturnType<typeof getEventScheduleStatusEventsEventIdScheduleStatusGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventScheduleStatusEventsEventIdScheduleStatusGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventScheduleStatusEventsEventIdScheduleStatusGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Event Schedule Status
 */
export const updateEventScheduleStatusEventsEventIdScheduleStatusPut = (
    eventId: string,
    addonStatusUpdate: AddonStatusUpdate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AddonStatusRead>> => {
    return axios.put(
      `/events/${eventId}/schedule/status`,
      addonStatusUpdate,options
    );
  }



export const getUpdateEventScheduleStatusEventsEventIdScheduleStatusPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventScheduleStatusEventsEventIdScheduleStatusPut>>, TError,{eventId: string;data: AddonStatusUpdate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateEventScheduleStatusEventsEventIdScheduleStatusPut>>, TError,{eventId: string;data: AddonStatusUpdate}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEventScheduleStatusEventsEventIdScheduleStatusPut>>, {eventId: string;data: AddonStatusUpdate}> = (props) => {
          const {eventId,data} = props ?? {};

          return  updateEventScheduleStatusEventsEventIdScheduleStatusPut(eventId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateEventScheduleStatusEventsEventIdScheduleStatusPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateEventScheduleStatusEventsEventIdScheduleStatusPut>>>
    export type UpdateEventScheduleStatusEventsEventIdScheduleStatusPutMutationBody = AddonStatusUpdate
    export type UpdateEventScheduleStatusEventsEventIdScheduleStatusPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Update Event Schedule Status
 */
export const useUpdateEventScheduleStatusEventsEventIdScheduleStatusPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventScheduleStatusEventsEventIdScheduleStatusPut>>, TError,{eventId: string;data: AddonStatusUpdate}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateEventScheduleStatusEventsEventIdScheduleStatusPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Scheduler Login
 */
export const schedulerLoginSchedulerLoginPost = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.post(
      `/scheduler/login`,undefined,options
    );
  }



export const getSchedulerLoginSchedulerLoginPostMutationOptions = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulerLoginSchedulerLoginPost>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof schedulerLoginSchedulerLoginPost>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulerLoginSchedulerLoginPost>>, TVariables> = () => {
          

          return  schedulerLoginSchedulerLoginPost(axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SchedulerLoginSchedulerLoginPostMutationResult = NonNullable<Awaited<ReturnType<typeof schedulerLoginSchedulerLoginPost>>>
    
    export type SchedulerLoginSchedulerLoginPostMutationError = AxiosError<unknown>

    /**
 * @summary Scheduler Login
 */
export const useSchedulerLoginSchedulerLoginPost = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulerLoginSchedulerLoginPost>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getSchedulerLoginSchedulerLoginPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Approval
 */
export const getApprovalProjectProjectIdApprovalsGet = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Approval>> => {
    return axios.get(
      `/project/${projectId}/approvals`,options
    );
  }


export const getGetApprovalProjectProjectIdApprovalsGetQueryKey = (projectId: string,) => [`/project/${projectId}/approvals`] as const;
  

    
export const getGetApprovalProjectProjectIdApprovalsGetQueryOptions = <TData = Awaited<ReturnType<typeof getApprovalProjectProjectIdApprovalsGet>>, TError = AxiosError<void | HTTPValidationError>>(projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApprovalProjectProjectIdApprovalsGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getApprovalProjectProjectIdApprovalsGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApprovalProjectProjectIdApprovalsGetQueryKey(projectId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApprovalProjectProjectIdApprovalsGet>>> = ({ signal }) => getApprovalProjectProjectIdApprovalsGet(projectId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions}}

export type GetApprovalProjectProjectIdApprovalsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApprovalProjectProjectIdApprovalsGet>>>
export type GetApprovalProjectProjectIdApprovalsGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Approval
 */
export const useGetApprovalProjectProjectIdApprovalsGet = <TData = Awaited<ReturnType<typeof getApprovalProjectProjectIdApprovalsGet>>, TError = AxiosError<void | HTTPValidationError>>(
 projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApprovalProjectProjectIdApprovalsGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApprovalProjectProjectIdApprovalsGetQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Approval
 */
export const createApprovalProjectProjectIdApprovalsPost = (
    projectId: string,
    approval: Approval, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Approval>> => {
    return axios.post(
      `/project/${projectId}/approvals`,
      approval,options
    );
  }



export const getCreateApprovalProjectProjectIdApprovalsPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApprovalProjectProjectIdApprovalsPost>>, TError,{projectId: string;data: Approval}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createApprovalProjectProjectIdApprovalsPost>>, TError,{projectId: string;data: Approval}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createApprovalProjectProjectIdApprovalsPost>>, {projectId: string;data: Approval}> = (props) => {
          const {projectId,data} = props ?? {};

          return  createApprovalProjectProjectIdApprovalsPost(projectId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateApprovalProjectProjectIdApprovalsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createApprovalProjectProjectIdApprovalsPost>>>
    export type CreateApprovalProjectProjectIdApprovalsPostMutationBody = Approval
    export type CreateApprovalProjectProjectIdApprovalsPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Approval
 */
export const useCreateApprovalProjectProjectIdApprovalsPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createApprovalProjectProjectIdApprovalsPost>>, TError,{projectId: string;data: Approval}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateApprovalProjectProjectIdApprovalsPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Approval
 */
export const deleteApprovalProjectProjectIdApprovalsDelete = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/project/${projectId}/approvals`,options
    );
  }



export const getDeleteApprovalProjectProjectIdApprovalsDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApprovalProjectProjectIdApprovalsDelete>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApprovalProjectProjectIdApprovalsDelete>>, TError,{projectId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApprovalProjectProjectIdApprovalsDelete>>, {projectId: string}> = (props) => {
          const {projectId} = props ?? {};

          return  deleteApprovalProjectProjectIdApprovalsDelete(projectId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApprovalProjectProjectIdApprovalsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApprovalProjectProjectIdApprovalsDelete>>>
    
    export type DeleteApprovalProjectProjectIdApprovalsDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete Approval
 */
export const useDeleteApprovalProjectProjectIdApprovalsDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApprovalProjectProjectIdApprovalsDelete>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteApprovalProjectProjectIdApprovalsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get All Approvals
 */
export const getAllApprovalsProjectProjectIdApprovalsAllGet = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ApprovalRead[]>> => {
    return axios.get(
      `/project/${projectId}/approvals/all`,options
    );
  }


export const getGetAllApprovalsProjectProjectIdApprovalsAllGetQueryKey = (projectId: string,) => [`/project/${projectId}/approvals/all`] as const;
  

    
export const getGetAllApprovalsProjectProjectIdApprovalsAllGetQueryOptions = <TData = Awaited<ReturnType<typeof getAllApprovalsProjectProjectIdApprovalsAllGet>>, TError = AxiosError<void | HTTPValidationError>>(projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllApprovalsProjectProjectIdApprovalsAllGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getAllApprovalsProjectProjectIdApprovalsAllGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllApprovalsProjectProjectIdApprovalsAllGetQueryKey(projectId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllApprovalsProjectProjectIdApprovalsAllGet>>> = ({ signal }) => getAllApprovalsProjectProjectIdApprovalsAllGet(projectId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions}}

export type GetAllApprovalsProjectProjectIdApprovalsAllGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAllApprovalsProjectProjectIdApprovalsAllGet>>>
export type GetAllApprovalsProjectProjectIdApprovalsAllGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get All Approvals
 */
export const useGetAllApprovalsProjectProjectIdApprovalsAllGet = <TData = Awaited<ReturnType<typeof getAllApprovalsProjectProjectIdApprovalsAllGet>>, TError = AxiosError<void | HTTPValidationError>>(
 projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllApprovalsProjectProjectIdApprovalsAllGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllApprovalsProjectProjectIdApprovalsAllGetQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Approval Counts
 */
export const getApprovalCountsProjectProjectIdApprovalsCountGet = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetApprovalCountsProjectProjectIdApprovalsCountGet200>> => {
    return axios.get(
      `/project/${projectId}/approvals/count`,options
    );
  }


export const getGetApprovalCountsProjectProjectIdApprovalsCountGetQueryKey = (projectId: string,) => [`/project/${projectId}/approvals/count`] as const;
  

    
export const getGetApprovalCountsProjectProjectIdApprovalsCountGetQueryOptions = <TData = Awaited<ReturnType<typeof getApprovalCountsProjectProjectIdApprovalsCountGet>>, TError = AxiosError<void | HTTPValidationError>>(projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApprovalCountsProjectProjectIdApprovalsCountGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getApprovalCountsProjectProjectIdApprovalsCountGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApprovalCountsProjectProjectIdApprovalsCountGetQueryKey(projectId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApprovalCountsProjectProjectIdApprovalsCountGet>>> = ({ signal }) => getApprovalCountsProjectProjectIdApprovalsCountGet(projectId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions}}

export type GetApprovalCountsProjectProjectIdApprovalsCountGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApprovalCountsProjectProjectIdApprovalsCountGet>>>
export type GetApprovalCountsProjectProjectIdApprovalsCountGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Approval Counts
 */
export const useGetApprovalCountsProjectProjectIdApprovalsCountGet = <TData = Awaited<ReturnType<typeof getApprovalCountsProjectProjectIdApprovalsCountGet>>, TError = AxiosError<void | HTTPValidationError>>(
 projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApprovalCountsProjectProjectIdApprovalsCountGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApprovalCountsProjectProjectIdApprovalsCountGetQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Questions
 */
export const getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet = (
    eventId: string,
    surveyType: SurveyType,
    params?: GetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SurveyQuestionRead[]>> => {
    return axios.get(
      `/events/${eventId}/surveys/${surveyType}/questions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetQueryKey = (eventId: string,
    surveyType: SurveyType,
    params?: GetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetParams,) => [`/events/${eventId}/surveys/${surveyType}/questions`, ...(params ? [params]: [])] as const;
  

    
export const getGetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetQueryOptions = <TData = Awaited<ReturnType<typeof getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string,
    surveyType: SurveyType,
    params?: GetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetQueryKey(eventId,surveyType,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet>>> = ({ signal }) => getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(eventId,surveyType,params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId && surveyType), ...queryOptions}}

export type GetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet>>>
export type GetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Questions
 */
export const useGetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet = <TData = Awaited<ReturnType<typeof getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string,
    surveyType: SurveyType,
    params?: GetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQuestionsEventsEventIdSurveysSurveyTypeQuestionsGetQueryOptions(eventId,surveyType,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Manage Questions
 */
export const manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPut = (
    eventId: string,
    surveyType: SurveyType,
    manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutBodyItem: ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutBodyItem[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SurveyQuestionRead[]>> => {
    return axios.put(
      `/events/${eventId}/surveys/${surveyType}/questions`,
      manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutBodyItem,options
    );
  }



export const getManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPut>>, TError,{eventId: string;surveyType: SurveyType;data: ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutBodyItem[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPut>>, TError,{eventId: string;surveyType: SurveyType;data: ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutBodyItem[]}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPut>>, {eventId: string;surveyType: SurveyType;data: ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutBodyItem[]}> = (props) => {
          const {eventId,surveyType,data} = props ?? {};

          return  manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPut(eventId,surveyType,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutMutationResult = NonNullable<Awaited<ReturnType<typeof manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPut>>>
    export type ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutMutationBody = ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutBodyItem[]
    export type ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Manage Questions
 */
export const useManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof manageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPut>>, TError,{eventId: string;surveyType: SurveyType;data: ManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutBodyItem[]}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getManageQuestionsEventsEventIdSurveysSurveyTypeQuestionsPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Registration
 */
export const getRegistrationEventsEventIdRegistrationsGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetRegistrationEventsEventIdRegistrationsGet200>> => {
    return axios.get(
      `/events/${eventId}/registrations`,options
    );
  }


export const getGetRegistrationEventsEventIdRegistrationsGetQueryKey = (eventId: string,) => [`/events/${eventId}/registrations`] as const;
  

    
export const getGetRegistrationEventsEventIdRegistrationsGetQueryOptions = <TData = Awaited<ReturnType<typeof getRegistrationEventsEventIdRegistrationsGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationEventsEventIdRegistrationsGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getRegistrationEventsEventIdRegistrationsGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegistrationEventsEventIdRegistrationsGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationEventsEventIdRegistrationsGet>>> = ({ signal }) => getRegistrationEventsEventIdRegistrationsGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type GetRegistrationEventsEventIdRegistrationsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationEventsEventIdRegistrationsGet>>>
export type GetRegistrationEventsEventIdRegistrationsGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Registration
 */
export const useGetRegistrationEventsEventIdRegistrationsGet = <TData = Awaited<ReturnType<typeof getRegistrationEventsEventIdRegistrationsGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationEventsEventIdRegistrationsGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRegistrationEventsEventIdRegistrationsGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Registration
 */
export const createRegistrationEventsEventIdRegistrationsPost = (
    eventId: string,
    createRegistrationEventsEventIdRegistrationsPostBody: CreateRegistrationEventsEventIdRegistrationsPostBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CreateRegistrationEventsEventIdRegistrationsPost200>> => {
    return axios.post(
      `/events/${eventId}/registrations`,
      createRegistrationEventsEventIdRegistrationsPostBody,options
    );
  }



export const getCreateRegistrationEventsEventIdRegistrationsPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRegistrationEventsEventIdRegistrationsPost>>, TError,{eventId: string;data: CreateRegistrationEventsEventIdRegistrationsPostBody}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createRegistrationEventsEventIdRegistrationsPost>>, TError,{eventId: string;data: CreateRegistrationEventsEventIdRegistrationsPostBody}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRegistrationEventsEventIdRegistrationsPost>>, {eventId: string;data: CreateRegistrationEventsEventIdRegistrationsPostBody}> = (props) => {
          const {eventId,data} = props ?? {};

          return  createRegistrationEventsEventIdRegistrationsPost(eventId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateRegistrationEventsEventIdRegistrationsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createRegistrationEventsEventIdRegistrationsPost>>>
    export type CreateRegistrationEventsEventIdRegistrationsPostMutationBody = CreateRegistrationEventsEventIdRegistrationsPostBody
    export type CreateRegistrationEventsEventIdRegistrationsPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Registration
 */
export const useCreateRegistrationEventsEventIdRegistrationsPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRegistrationEventsEventIdRegistrationsPost>>, TError,{eventId: string;data: CreateRegistrationEventsEventIdRegistrationsPostBody}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateRegistrationEventsEventIdRegistrationsPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Registration
 */
export const deleteRegistrationEventsEventIdRegistrationsDelete = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/events/${eventId}/registrations`,options
    );
  }



export const getDeleteRegistrationEventsEventIdRegistrationsDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationEventsEventIdRegistrationsDelete>>, TError,{eventId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationEventsEventIdRegistrationsDelete>>, TError,{eventId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRegistrationEventsEventIdRegistrationsDelete>>, {eventId: string}> = (props) => {
          const {eventId} = props ?? {};

          return  deleteRegistrationEventsEventIdRegistrationsDelete(eventId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteRegistrationEventsEventIdRegistrationsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRegistrationEventsEventIdRegistrationsDelete>>>
    
    export type DeleteRegistrationEventsEventIdRegistrationsDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete Registration
 */
export const useDeleteRegistrationEventsEventIdRegistrationsDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationEventsEventIdRegistrationsDelete>>, TError,{eventId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteRegistrationEventsEventIdRegistrationsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get All Registrations
 */
export const getAllRegistrationsEventsEventIdRegistrationsAllGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SurveyResponseRead[]>> => {
    return axios.get(
      `/events/${eventId}/registrations/all`,options
    );
  }


export const getGetAllRegistrationsEventsEventIdRegistrationsAllGetQueryKey = (eventId: string,) => [`/events/${eventId}/registrations/all`] as const;
  

    
export const getGetAllRegistrationsEventsEventIdRegistrationsAllGetQueryOptions = <TData = Awaited<ReturnType<typeof getAllRegistrationsEventsEventIdRegistrationsAllGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllRegistrationsEventsEventIdRegistrationsAllGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getAllRegistrationsEventsEventIdRegistrationsAllGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllRegistrationsEventsEventIdRegistrationsAllGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllRegistrationsEventsEventIdRegistrationsAllGet>>> = ({ signal }) => getAllRegistrationsEventsEventIdRegistrationsAllGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type GetAllRegistrationsEventsEventIdRegistrationsAllGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAllRegistrationsEventsEventIdRegistrationsAllGet>>>
export type GetAllRegistrationsEventsEventIdRegistrationsAllGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get All Registrations
 */
export const useGetAllRegistrationsEventsEventIdRegistrationsAllGet = <TData = Awaited<ReturnType<typeof getAllRegistrationsEventsEventIdRegistrationsAllGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllRegistrationsEventsEventIdRegistrationsAllGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllRegistrationsEventsEventIdRegistrationsAllGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Registration By User
 */
export const getRegistrationByUserEventsEventIdRegistrationsUserIdGet = (
    eventId: string,
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetRegistrationByUserEventsEventIdRegistrationsUserIdGet200>> => {
    return axios.get(
      `/events/${eventId}/registrations/${userId}`,options
    );
  }


export const getGetRegistrationByUserEventsEventIdRegistrationsUserIdGetQueryKey = (eventId: string,
    userId: string,) => [`/events/${eventId}/registrations/${userId}`] as const;
  

    
export const getGetRegistrationByUserEventsEventIdRegistrationsUserIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getRegistrationByUserEventsEventIdRegistrationsUserIdGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string,
    userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationByUserEventsEventIdRegistrationsUserIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getRegistrationByUserEventsEventIdRegistrationsUserIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegistrationByUserEventsEventIdRegistrationsUserIdGetQueryKey(eventId,userId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationByUserEventsEventIdRegistrationsUserIdGet>>> = ({ signal }) => getRegistrationByUserEventsEventIdRegistrationsUserIdGet(eventId,userId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId && userId), ...queryOptions}}

export type GetRegistrationByUserEventsEventIdRegistrationsUserIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationByUserEventsEventIdRegistrationsUserIdGet>>>
export type GetRegistrationByUserEventsEventIdRegistrationsUserIdGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Registration By User
 */
export const useGetRegistrationByUserEventsEventIdRegistrationsUserIdGet = <TData = Awaited<ReturnType<typeof getRegistrationByUserEventsEventIdRegistrationsUserIdGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string,
    userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationByUserEventsEventIdRegistrationsUserIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRegistrationByUserEventsEventIdRegistrationsUserIdGetQueryOptions(eventId,userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Review
 */
export const getReviewProjectProjectIdReviewsGet = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetReviewProjectProjectIdReviewsGet200>> => {
    return axios.get(
      `/project/${projectId}/reviews`,options
    );
  }


export const getGetReviewProjectProjectIdReviewsGetQueryKey = (projectId: string,) => [`/project/${projectId}/reviews`] as const;
  

    
export const getGetReviewProjectProjectIdReviewsGetQueryOptions = <TData = Awaited<ReturnType<typeof getReviewProjectProjectIdReviewsGet>>, TError = AxiosError<void | HTTPValidationError>>(projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReviewProjectProjectIdReviewsGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getReviewProjectProjectIdReviewsGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReviewProjectProjectIdReviewsGetQueryKey(projectId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReviewProjectProjectIdReviewsGet>>> = ({ signal }) => getReviewProjectProjectIdReviewsGet(projectId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions}}

export type GetReviewProjectProjectIdReviewsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getReviewProjectProjectIdReviewsGet>>>
export type GetReviewProjectProjectIdReviewsGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Review
 */
export const useGetReviewProjectProjectIdReviewsGet = <TData = Awaited<ReturnType<typeof getReviewProjectProjectIdReviewsGet>>, TError = AxiosError<void | HTTPValidationError>>(
 projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReviewProjectProjectIdReviewsGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetReviewProjectProjectIdReviewsGetQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Review
 */
export const createReviewProjectProjectIdReviewsPost = (
    projectId: string,
    createReviewProjectProjectIdReviewsPostBody: CreateReviewProjectProjectIdReviewsPostBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CreateReviewProjectProjectIdReviewsPost200>> => {
    return axios.post(
      `/project/${projectId}/reviews`,
      createReviewProjectProjectIdReviewsPostBody,options
    );
  }



export const getCreateReviewProjectProjectIdReviewsPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createReviewProjectProjectIdReviewsPost>>, TError,{projectId: string;data: CreateReviewProjectProjectIdReviewsPostBody}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createReviewProjectProjectIdReviewsPost>>, TError,{projectId: string;data: CreateReviewProjectProjectIdReviewsPostBody}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createReviewProjectProjectIdReviewsPost>>, {projectId: string;data: CreateReviewProjectProjectIdReviewsPostBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  createReviewProjectProjectIdReviewsPost(projectId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateReviewProjectProjectIdReviewsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createReviewProjectProjectIdReviewsPost>>>
    export type CreateReviewProjectProjectIdReviewsPostMutationBody = CreateReviewProjectProjectIdReviewsPostBody
    export type CreateReviewProjectProjectIdReviewsPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Review
 */
export const useCreateReviewProjectProjectIdReviewsPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createReviewProjectProjectIdReviewsPost>>, TError,{projectId: string;data: CreateReviewProjectProjectIdReviewsPostBody}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateReviewProjectProjectIdReviewsPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Review
 */
export const deleteReviewProjectProjectIdReviewsDelete = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/project/${projectId}/reviews`,options
    );
  }



export const getDeleteReviewProjectProjectIdReviewsDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteReviewProjectProjectIdReviewsDelete>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteReviewProjectProjectIdReviewsDelete>>, TError,{projectId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteReviewProjectProjectIdReviewsDelete>>, {projectId: string}> = (props) => {
          const {projectId} = props ?? {};

          return  deleteReviewProjectProjectIdReviewsDelete(projectId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteReviewProjectProjectIdReviewsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteReviewProjectProjectIdReviewsDelete>>>
    
    export type DeleteReviewProjectProjectIdReviewsDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete Review
 */
export const useDeleteReviewProjectProjectIdReviewsDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteReviewProjectProjectIdReviewsDelete>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteReviewProjectProjectIdReviewsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get All Reviews
 */
export const getAllReviewsProjectProjectIdReviewsAllGet = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SurveyResponseRead[]>> => {
    return axios.get(
      `/project/${projectId}/reviews/all`,options
    );
  }


export const getGetAllReviewsProjectProjectIdReviewsAllGetQueryKey = (projectId: string,) => [`/project/${projectId}/reviews/all`] as const;
  

    
export const getGetAllReviewsProjectProjectIdReviewsAllGetQueryOptions = <TData = Awaited<ReturnType<typeof getAllReviewsProjectProjectIdReviewsAllGet>>, TError = AxiosError<void | HTTPValidationError>>(projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllReviewsProjectProjectIdReviewsAllGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getAllReviewsProjectProjectIdReviewsAllGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllReviewsProjectProjectIdReviewsAllGetQueryKey(projectId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllReviewsProjectProjectIdReviewsAllGet>>> = ({ signal }) => getAllReviewsProjectProjectIdReviewsAllGet(projectId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions}}

export type GetAllReviewsProjectProjectIdReviewsAllGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAllReviewsProjectProjectIdReviewsAllGet>>>
export type GetAllReviewsProjectProjectIdReviewsAllGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get All Reviews
 */
export const useGetAllReviewsProjectProjectIdReviewsAllGet = <TData = Awaited<ReturnType<typeof getAllReviewsProjectProjectIdReviewsAllGet>>, TError = AxiosError<void | HTTPValidationError>>(
 projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllReviewsProjectProjectIdReviewsAllGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllReviewsProjectProjectIdReviewsAllGetQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Project Details
 */
export const getProjectDetailsProjectProjectIdDetailsGet = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetProjectDetailsProjectProjectIdDetailsGet200>> => {
    return axios.get(
      `/project/${projectId}/details`,options
    );
  }


export const getGetProjectDetailsProjectProjectIdDetailsGetQueryKey = (projectId: string,) => [`/project/${projectId}/details`] as const;
  

    
export const getGetProjectDetailsProjectProjectIdDetailsGetQueryOptions = <TData = Awaited<ReturnType<typeof getProjectDetailsProjectProjectIdDetailsGet>>, TError = AxiosError<void | HTTPValidationError>>(projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectDetailsProjectProjectIdDetailsGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getProjectDetailsProjectProjectIdDetailsGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectDetailsProjectProjectIdDetailsGetQueryKey(projectId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectDetailsProjectProjectIdDetailsGet>>> = ({ signal }) => getProjectDetailsProjectProjectIdDetailsGet(projectId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(projectId), ...queryOptions}}

export type GetProjectDetailsProjectProjectIdDetailsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectDetailsProjectProjectIdDetailsGet>>>
export type GetProjectDetailsProjectProjectIdDetailsGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Project Details
 */
export const useGetProjectDetailsProjectProjectIdDetailsGet = <TData = Awaited<ReturnType<typeof getProjectDetailsProjectProjectIdDetailsGet>>, TError = AxiosError<void | HTTPValidationError>>(
 projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectDetailsProjectProjectIdDetailsGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectDetailsProjectProjectIdDetailsGetQueryOptions(projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Project Details
 */
export const createProjectDetailsProjectProjectIdDetailsPost = (
    projectId: string,
    createProjectDetailsProjectProjectIdDetailsPostBody: CreateProjectDetailsProjectProjectIdDetailsPostBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CreateProjectDetailsProjectProjectIdDetailsPost200>> => {
    return axios.post(
      `/project/${projectId}/details`,
      createProjectDetailsProjectProjectIdDetailsPostBody,options
    );
  }



export const getCreateProjectDetailsProjectProjectIdDetailsPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectDetailsProjectProjectIdDetailsPost>>, TError,{projectId: string;data: CreateProjectDetailsProjectProjectIdDetailsPostBody}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createProjectDetailsProjectProjectIdDetailsPost>>, TError,{projectId: string;data: CreateProjectDetailsProjectProjectIdDetailsPostBody}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProjectDetailsProjectProjectIdDetailsPost>>, {projectId: string;data: CreateProjectDetailsProjectProjectIdDetailsPostBody}> = (props) => {
          const {projectId,data} = props ?? {};

          return  createProjectDetailsProjectProjectIdDetailsPost(projectId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateProjectDetailsProjectProjectIdDetailsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createProjectDetailsProjectProjectIdDetailsPost>>>
    export type CreateProjectDetailsProjectProjectIdDetailsPostMutationBody = CreateProjectDetailsProjectProjectIdDetailsPostBody
    export type CreateProjectDetailsProjectProjectIdDetailsPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Project Details
 */
export const useCreateProjectDetailsProjectProjectIdDetailsPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectDetailsProjectProjectIdDetailsPost>>, TError,{projectId: string;data: CreateProjectDetailsProjectProjectIdDetailsPostBody}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateProjectDetailsProjectProjectIdDetailsPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Project Details
 */
export const deleteProjectDetailsProjectProjectIdDetailsDelete = (
    projectId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/project/${projectId}/details`,options
    );
  }



export const getDeleteProjectDetailsProjectProjectIdDetailsDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectDetailsProjectProjectIdDetailsDelete>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectDetailsProjectProjectIdDetailsDelete>>, TError,{projectId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectDetailsProjectProjectIdDetailsDelete>>, {projectId: string}> = (props) => {
          const {projectId} = props ?? {};

          return  deleteProjectDetailsProjectProjectIdDetailsDelete(projectId,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectDetailsProjectProjectIdDetailsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectDetailsProjectProjectIdDetailsDelete>>>
    
    export type DeleteProjectDetailsProjectProjectIdDetailsDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete Project Details
 */
export const useDeleteProjectDetailsProjectProjectIdDetailsDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectDetailsProjectProjectIdDetailsDelete>>, TError,{projectId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteProjectDetailsProjectProjectIdDetailsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Event Timeslots
 */
export const listEventTimeslotsEventsEventIdScheduleTimeslotsGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TimeslotRead[]>> => {
    return axios.get(
      `/events/${eventId}/schedule/timeslots`,options
    );
  }


export const getListEventTimeslotsEventsEventIdScheduleTimeslotsGetQueryKey = (eventId: string,) => [`/events/${eventId}/schedule/timeslots`] as const;
  

    
export const getListEventTimeslotsEventsEventIdScheduleTimeslotsGetQueryOptions = <TData = Awaited<ReturnType<typeof listEventTimeslotsEventsEventIdScheduleTimeslotsGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventTimeslotsEventsEventIdScheduleTimeslotsGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof listEventTimeslotsEventsEventIdScheduleTimeslotsGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEventTimeslotsEventsEventIdScheduleTimeslotsGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEventTimeslotsEventsEventIdScheduleTimeslotsGet>>> = ({ signal }) => listEventTimeslotsEventsEventIdScheduleTimeslotsGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type ListEventTimeslotsEventsEventIdScheduleTimeslotsGetQueryResult = NonNullable<Awaited<ReturnType<typeof listEventTimeslotsEventsEventIdScheduleTimeslotsGet>>>
export type ListEventTimeslotsEventsEventIdScheduleTimeslotsGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary List Event Timeslots
 */
export const useListEventTimeslotsEventsEventIdScheduleTimeslotsGet = <TData = Awaited<ReturnType<typeof listEventTimeslotsEventsEventIdScheduleTimeslotsGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventTimeslotsEventsEventIdScheduleTimeslotsGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEventTimeslotsEventsEventIdScheduleTimeslotsGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Event Timeslots
 */
export const updateEventTimeslotsEventsEventIdScheduleTimeslotsPut = (
    eventId: string,
    updateEventTimeslotsEventsEventIdScheduleTimeslotsPutBody: UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutBody, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TimeslotRead[]>> => {
    return axios.put(
      `/events/${eventId}/schedule/timeslots`,
      updateEventTimeslotsEventsEventIdScheduleTimeslotsPutBody,options
    );
  }



export const getUpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventTimeslotsEventsEventIdScheduleTimeslotsPut>>, TError,{eventId: string;data: UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutBody}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateEventTimeslotsEventsEventIdScheduleTimeslotsPut>>, TError,{eventId: string;data: UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutBody}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEventTimeslotsEventsEventIdScheduleTimeslotsPut>>, {eventId: string;data: UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutBody}> = (props) => {
          const {eventId,data} = props ?? {};

          return  updateEventTimeslotsEventsEventIdScheduleTimeslotsPut(eventId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateEventTimeslotsEventsEventIdScheduleTimeslotsPut>>>
    export type UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutMutationBody = UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutBody
    export type UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Update Event Timeslots
 */
export const useUpdateEventTimeslotsEventsEventIdScheduleTimeslotsPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventTimeslotsEventsEventIdScheduleTimeslotsPut>>, TError,{eventId: string;data: UpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutBody}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getUpdateEventTimeslotsEventsEventIdScheduleTimeslotsPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Event Timeslots
 */
export const createEventTimeslotsEventsEventIdScheduleTimeslotsPost = (
    eventId: string,
    timeslotCreate: TimeslotCreate[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TimeslotRead[]>> => {
    return axios.post(
      `/events/${eventId}/schedule/timeslots`,
      timeslotCreate,options
    );
  }



export const getCreateEventTimeslotsEventsEventIdScheduleTimeslotsPostMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventTimeslotsEventsEventIdScheduleTimeslotsPost>>, TError,{eventId: string;data: TimeslotCreate[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createEventTimeslotsEventsEventIdScheduleTimeslotsPost>>, TError,{eventId: string;data: TimeslotCreate[]}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEventTimeslotsEventsEventIdScheduleTimeslotsPost>>, {eventId: string;data: TimeslotCreate[]}> = (props) => {
          const {eventId,data} = props ?? {};

          return  createEventTimeslotsEventsEventIdScheduleTimeslotsPost(eventId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateEventTimeslotsEventsEventIdScheduleTimeslotsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createEventTimeslotsEventsEventIdScheduleTimeslotsPost>>>
    export type CreateEventTimeslotsEventsEventIdScheduleTimeslotsPostMutationBody = TimeslotCreate[]
    export type CreateEventTimeslotsEventsEventIdScheduleTimeslotsPostMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Create Event Timeslots
 */
export const useCreateEventTimeslotsEventsEventIdScheduleTimeslotsPost = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventTimeslotsEventsEventIdScheduleTimeslotsPost>>, TError,{eventId: string;data: TimeslotCreate[]}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateEventTimeslotsEventsEventIdScheduleTimeslotsPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Event Timeslots
 */
export const deleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete = (
    eventId: string,
    deleteEventTimeslotsEventsEventIdScheduleTimeslotsDeleteBody: string[], options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/events/${eventId}/schedule/timeslots`,{data:
      deleteEventTimeslotsEventsEventIdScheduleTimeslotsDeleteBody, ...options}
    );
  }



export const getDeleteEventTimeslotsEventsEventIdScheduleTimeslotsDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete>>, TError,{eventId: string;data: string[]}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete>>, TError,{eventId: string;data: string[]}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete>>, {eventId: string;data: string[]}> = (props) => {
          const {eventId,data} = props ?? {};

          return  deleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete(eventId,data,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteEventTimeslotsEventsEventIdScheduleTimeslotsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete>>>
    export type DeleteEventTimeslotsEventsEventIdScheduleTimeslotsDeleteMutationBody = string[]
    export type DeleteEventTimeslotsEventsEventIdScheduleTimeslotsDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Delete Event Timeslots
 */
export const useDeleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete>>, TError,{eventId: string;data: string[]}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteEventTimeslotsEventsEventIdScheduleTimeslotsDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Event Users
 */
export const listEventUsersEventsEventIdUsersGet = (
    eventId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ListUserForEventAdmin[]>> => {
    return axios.get(
      `/events/${eventId}/users`,options
    );
  }


export const getListEventUsersEventsEventIdUsersGetQueryKey = (eventId: string,) => [`/events/${eventId}/users`] as const;
  

    
export const getListEventUsersEventsEventIdUsersGetQueryOptions = <TData = Awaited<ReturnType<typeof listEventUsersEventsEventIdUsersGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventUsersEventsEventIdUsersGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof listEventUsersEventsEventIdUsersGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListEventUsersEventsEventIdUsersGetQueryKey(eventId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listEventUsersEventsEventIdUsersGet>>> = ({ signal }) => listEventUsersEventsEventIdUsersGet(eventId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId), ...queryOptions}}

export type ListEventUsersEventsEventIdUsersGetQueryResult = NonNullable<Awaited<ReturnType<typeof listEventUsersEventsEventIdUsersGet>>>
export type ListEventUsersEventsEventIdUsersGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary List Event Users
 */
export const useListEventUsersEventsEventIdUsersGet = <TData = Awaited<ReturnType<typeof listEventUsersEventsEventIdUsersGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEventUsersEventsEventIdUsersGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListEventUsersEventsEventIdUsersGetQueryOptions(eventId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Event User
 */
export const getEventUserEventsEventIdUsersUserIdGet = (
    eventId: string,
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserReadForEventAdmin>> => {
    return axios.get(
      `/events/${eventId}/users/${userId}`,options
    );
  }


export const getGetEventUserEventsEventIdUsersUserIdGetQueryKey = (eventId: string,
    userId: string,) => [`/events/${eventId}/users/${userId}`] as const;
  

    
export const getGetEventUserEventsEventIdUsersUserIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getEventUserEventsEventIdUsersUserIdGet>>, TError = AxiosError<void | HTTPValidationError>>(eventId: string,
    userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventUserEventsEventIdUsersUserIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getEventUserEventsEventIdUsersUserIdGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventUserEventsEventIdUsersUserIdGetQueryKey(eventId,userId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventUserEventsEventIdUsersUserIdGet>>> = ({ signal }) => getEventUserEventsEventIdUsersUserIdGet(eventId,userId, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, enabled: !!(eventId && userId), ...queryOptions}};

export type GetEventUserEventsEventIdUsersUserIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getEventUserEventsEventIdUsersUserIdGet>>>
export type GetEventUserEventsEventIdUsersUserIdGetQueryError = AxiosError<void | HTTPValidationError>

/**
 * @summary Get Event User
 */
export const useGetEventUserEventsEventIdUsersUserIdGet = <TData = Awaited<ReturnType<typeof getEventUserEventsEventIdUsersUserIdGet>>, TError = AxiosError<void | HTTPValidationError>>(
 eventId: string,
    userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventUserEventsEventIdUsersUserIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventUserEventsEventIdUsersUserIdGetQueryOptions(eventId,userId,options);

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
};


/**
 * @summary Kick User From Event
 */
export const kickUserFromEventEventsEventIdUsersUserIdDelete = (
    eventId: string,
    userId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/events/${eventId}/users/${userId}`,options
    );
  }



export const getKickUserFromEventEventsEventIdUsersUserIdDeleteMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof kickUserFromEventEventsEventIdUsersUserIdDelete>>, TError,{eventId: string;userId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof kickUserFromEventEventsEventIdUsersUserIdDelete>>, TError,{eventId: string;userId: string}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof kickUserFromEventEventsEventIdUsersUserIdDelete>>, {eventId: string;userId: string}> = (props) => {
          const {eventId,userId} = props ?? {};

          return  kickUserFromEventEventsEventIdUsersUserIdDelete(eventId,userId,axiosOptions)
        }

        

 
    return  { mutationFn, ...mutationOptions }};

    export type KickUserFromEventEventsEventIdUsersUserIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof kickUserFromEventEventsEventIdUsersUserIdDelete>>>
    
    export type KickUserFromEventEventsEventIdUsersUserIdDeleteMutationError = AxiosError<void | HTTPValidationError>

    /**
 * @summary Kick User From Event
 */
export const useKickUserFromEventEventsEventIdUsersUserIdDelete = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof kickUserFromEventEventsEventIdUsersUserIdDelete>>, TError,{eventId: string;userId: string}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getKickUserFromEventEventsEventIdUsersUserIdDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    };
    
/**
 * @summary Update User Roles
 */
export const updateUserRolesEventsEventIdUsersUserIdRolesPut = (
    eventId: string,
    userId: string,
    updateUserRolesEventsEventIdUsersUserIdRolesPutBody: string[], options?: AxiosRequestConfig,
 ): Promise<AxiosResponse<UserReadForEventAdmin>> => {
    return axios.put(
      `/events/${eventId}/users/${userId}/roles`,
      updateUserRolesEventsEventIdUsersUserIdRolesPutBody,options
    );
  };



export const getUpdateUserRolesEventsEventIdUsersUserIdRolesPutMutationOptions = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRolesEventsEventIdUsersUserIdRolesPut>>, TError,{eventId: string;userId: string;data: string[]}, TContext>, axios?: AxiosRequestConfig,}
): UseMutationOptions<Awaited<ReturnType<typeof updateUserRolesEventsEventIdUsersUserIdRolesPut>>, TError,{eventId: string;userId: string;data: string[]}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserRolesEventsEventIdUsersUserIdRolesPut>>, {eventId: string;userId: string;data: string[]}> = (props) => {
          const {eventId,userId,data} = props ?? {};

          return  updateUserRolesEventsEventIdUsersUserIdRolesPut(eventId,userId,data,axiosOptions);
        };

        

 
    return  { mutationFn, ...mutationOptions }};

export type UpdateUserRolesEventsEventIdUsersUserIdRolesPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserRolesEventsEventIdUsersUserIdRolesPut>>>
export type UpdateUserRolesEventsEventIdUsersUserIdRolesPutMutationBody = string[]
export type UpdateUserRolesEventsEventIdUsersUserIdRolesPutMutationError = AxiosError<void | HTTPValidationError>

/**
 * @summary Update User Roles
 */
export const useUpdateUserRolesEventsEventIdUsersUserIdRolesPut = <TError = AxiosError<void | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRolesEventsEventIdUsersUserIdRolesPut>>, TError,{eventId: string;userId: string;data: string[]}, TContext>, axios?: AxiosRequestConfig,}
) => {
    
      const mutationOptions = getUpdateUserRolesEventsEventIdUsersUserIdRolesPutMutationOptions(options);
     
      return useMutation(mutationOptions);
};
    
