import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import Helmet from 'react-helmet';
import { FormikProvider, useFormik } from "formik";
import { useState } from 'react';
import { ErrorText } from "../components/ui/Input";
import { ColorBackgroundLayout } from '../components/ColorBackgroundLayout.tsx';
import { AuthService } from '../client';

export function Forgot() {
    const [serverError, setServerError] = useState(null);
    const [submitAttempted, setSubmitAttempted] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validate: ({ email }) => {
            const errors = {};
            if (!email) {
                errors.email = "Required";
            }
            return errors;
        },
        onSubmit: async ({ email }) => {
            setServerError(null);
            try {
                await AuthService.requestPasswordResetAuthPasswordResetRequestPost(email);
                setSubmitAttempted(true);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
    });
    const { handleSubmit, getFieldProps, touched, errors } = formik;

    return (
        <FormikProvider value={formik}>
            <Helmet>
                <title>Create account</title>
            </Helmet>

            <form onSubmit={handleSubmit}>
                <ColorBackgroundLayout>
                    <h1 className="title text-center">Reset Password</h1>
                    <div className="mt-3 mb-5 text-center">You will get a password reset email if the account exists.</div>
                    <div className="flex-col">
                        <Input className="w-full max-w-lg mt-2" id="email" type="email" {...getFieldProps("email")} placeholder="email@email.com" disabled={submitAttempted} error={touched.email && errors.email} />
                        <ErrorText>{errors.email}</ErrorText>
                    </div>
                    <Button className="mt-2" size="md" type="submit" kind="primary" disabled={submitAttempted || !formik.isValid || formik.isSubmitting}>{submitAttempted ? "Email sent!" : "Send Email"}</Button>
                    {submitAttempted && <div className="mt-2">Your reset request has been sent. Navigate to the link in your email to reset password.</div>}
                    {serverError && <ErrorText className="mt-2">{serverError.body?.detail?.toString() ?? "An unknown error occurred."}</ErrorText>}
                </ColorBackgroundLayout>
            </form>
        </FormikProvider>
    );
}
