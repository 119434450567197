import { faBars, faClock, faDownload, faGear, faHome, faTh, faUsers, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { NavLink, Outlet, useLocation, Navigate, useParams } from 'react-router-dom';
import { BackContext } from './BackButton';
import { useAuth } from '../lib/auth';
import { LoadingScreen } from './ui/Loading';
import { AuthErrorDialog } from './RequiresAuth';
import { checkPermissions, isValidUUID } from '../lib/utils';
import { NotFound } from '../pages/404';
import permissions from '../permissions.json';
import { ErrorBoundary } from './ErrorBoundary';
import Helmet from 'react-helmet';
import { expoditeXIcon } from '../lib/icons';
import { pages } from '../pages/event/settings';
import { EventReadForUser } from '../client';
import { useGetEventEventsEventIdGet } from 'src/codegen';

function Item({ icon, label, small, path, end, colorful, setShowNav }: {
    icon: IconDefinition,
    label: string,
    small?: boolean,
    path: string,
    end?: boolean,
    colorful?: boolean,
    setShowNav: (showNav: boolean) => void,
}) {
    const itemRef = useRef<HTMLAnchorElement>();
    const [tooltipPosition, setTooltipPosition] = useState(null);

    const handleMouseEnter = () => {
        const rect = itemRef.current!.getBoundingClientRect();
        setTooltipPosition((rect.top + rect.bottom) / 2);
    };

    return <div className="w-full lg:text-center mb-4 group">
        <NavLink end={end} to={path} ref={itemRef} onMouseEnter={handleMouseEnter} className={({ isActive }) => classNames(
            "flex items-center lg:block relative transition-colors",
            isActive ? "text-gray-900 dark:text-white" : "text-gray-400 lg:text-gray-400 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300",
        )} onClick={() => setShowNav(false)}>
            <FontAwesomeIcon className={`${small ? "text-base" : "text-2xl"} w-10 mr-1 lg:mr-0 ${colorful ? "text-green-600 dark:text-green-400" : ""}`} icon={icon} />
            <span style={{top: `${tooltipPosition ?? 0}px`}} className={`${small ? "lg:text-sm text-base" : "lg:text-base text-lg"} lg:shadow whitespace-nowrap lg:px-2 lg:py-1 lg:bg-white lg:fixed lg:text-black lg:dark:bg-gray-800 lg:dark:text-white lg:left-14 lg:group-hover:left-16 lg:transform lg:-translate-y-1/2 lg:opacity-0 lg:group-hover:opacity-100 lg:transition-[left,opacity] lg:pointer-events-none rounded`}>{label}</span>
        </NavLink>
    </div>;
}

function EventNavigation({showNav, setShowNav}) {
    const check = usePermissionChecker();

    return <nav className={classNames(
        "fixed overflow-x-hidden overflow-y-auto lg:left-0 top-0 h-full lg:w-14 w-64 z-40",
        "px-2 py-4 flex flex-col",
        "font-display transition-all",
        "bg-gray-100 dark:bg-gray-800",
        "border-r border-gray-300 dark:border-gray-600",
        showNav ? "left-0" : "-left-64",
    )}>
        <div className="lg:hidden block">
            <div className="w-full lg:text-center mb-4 group">
                <button type="button" className="flex items-center relative text-gray-500 lg:text-gray-400 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 transition-colors" onClick={() => setShowNav(false)}>
                    <FontAwesomeIcon className="w-10 mr-1" icon={faBars} />
                    <span className="whitespace-nowrap">Close Menu</span>
                </button>
            </div>
            <hr className="mb-4 border-gray-300 dark:border-gray-600" />
        </div>
        <Item path="/events" icon={expoditeXIcon} label="All Events" colorful setShowNav={setShowNav} />
        <hr className="mb-4 border-gray-300 dark:border-gray-600" />
        <Item path="" end icon={faHome} label="Home" setShowNav={setShowNav} />
        <Item path="users" icon={faUsers} label="Users" setShowNav={setShowNav} />
        <Item path="projects" icon={faTh} label="Projects" setShowNav={setShowNav} />
        {check() && <Item path="scheduling" icon={faClock} label="Scheduling" setShowNav={setShowNav} />}
        {/* check() && <Item path="scoring" icon={faTrophy} label="Scoring" /> */}
        {(pages.some(({ permission }) => check(permission)) || check(permissions.manageRoles)) && <Item path="settings" icon={faGear} label="Settings" setShowNav={setShowNav} />}
        {check(permissions.export) && <Item path="export" icon={faDownload} label="Export" setShowNav={setShowNav} />}
    </nav>;
}

export const EventContext = createContext<{ event: EventReadForUser } | undefined>(undefined);

export function useEvent() {
    const context = useContext(EventContext);
    if (context === null || context.event === null) {
        throw new Error("useEvent() must be used within an EventLayout");
    }
    return context.event;
}

export function usePermissionChecker() {
    const event = useEvent();
    return (permission?: string) => checkPermissions(event, permission);
}

export const EventLayoutContext = createContext<{setShowNav: (showNav: boolean) => void} | undefined>(undefined);

function EventLayoutContent() {
    const [showNav, setShowNav] = useState(false);
    const {pathname} = useLocation();

    // When the escape key is pressed while the menu is open, close it.
    useEffect(() => {
        const keyDown = e => {
            if (e.key === "Escape" && showNav) {
                setShowNav(false);
            }
        };
        document.addEventListener("keydown", keyDown);

        return () => document.removeEventListener("keydown", keyDown);
    }, [showNav]);

    return <div className="lg:ml-14">
        <EventNavigation showNav={showNav} setShowNav={setShowNav} />
        <div className={`lg:hidden fixed inset-0 z-30 bg-black ${showNav ? "opacity-50" : "opacity-0 pointer-events-none"} transition-opacity`} onClick={() => setShowNav(false)}></div>
        <EventLayoutContext.Provider value={{setShowNav}}>
            <BackContext.Provider value={{icon: faBars, label: "Menu", className: "lg:hidden", action() {
                setShowNav(true);
            }}}>
                <ErrorBoundary pathname={pathname} showBack fillScreen>
                    <Outlet />
                </ErrorBoundary>
            </BackContext.Provider>
        </EventLayoutContext.Provider>
    </div>;
}

export function EventLayout() {
    const { eventID } = useParams();
    const auth = useAuth();
    const location = useLocation();
    const isUUID = isValidUUID(eventID);
    const { data: response, error, refetch } = useGetEventEventsEventIdGet(eventID, {
        query: { enabled: isUUID },
    });

    const retry = () => {
        if (auth.state === "error") {
            auth.retry();
        }
        if (error) {
            refetch();
        }
    };

    if (auth.state === "unauthenticated") {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else if (!isUUID || (error as {status: number})?.status === 404) {
        return <NotFound />;
    } else {
        return <>
            {(auth.state === "authenticated" && response?.data) ? <EventContext.Provider value={{ event: response.data }}>
                <Helmet 
                    titleTemplate={`%s - ${response.data.name} | Expodite`}
                    defaultTitle={`${response.data.name} | Expodite`} />
                <EventLayoutContent />
                {/* @ts-ignore */}
            </EventContext.Provider> : <LoadingScreen />}
            <AuthErrorDialog error={auth.state === "error" ? { ...auth.error, retry } : (error ? { ...error, retry } : undefined)} />
        </>;
    }
}
