import { faCalendarWeek, faCheckToSlot, faClipboardQuestion, faClock } from "@fortawesome/free-solid-svg-icons";
import { OneColumnLayout } from "../../../components/OneColumnLayout";
import { SettingsPanel } from "../../../components/SettingsPanel";
import { Main } from "../../../components/ui/Main";
import { useState, useEffect, useMemo } from "react";
import { ErrorText, Input, Select } from "../../../components/ui/Input";
import { FieldArray, FormikProvider, useFormik, useFormikContext } from "formik";
import { DateTime } from "luxon";
import { Button } from "../../../components/ui/Button";
import { LoadingText } from "../../../components/ui/Loading";
import { useRequest } from "../../../lib/API";
import { useEvent } from "../../../components/EventLayout";
import { Tab } from "@headlessui/react";
import { SaveChanges } from "../../../components/SaveChanges";
import Helmet from "react-helmet";
import { SurveysService, ScheduleService } from "../../../client";
import { tabClass } from "../../../lib/utils";
import permissions from "../../../permissions.json";
import { useQueryClient } from "@tanstack/react-query";
import { getGetEventEventsEventIdGetQueryKey, updateEventEventsEventIdPut } from "src/codegen";

/**
 * @param {string} timeString
 * @returns {{hour: number, minute: number} | null}
 */
function getTimeFromString(timeString) {
    const [hour, minute] = timeString.split(":").map(x => parseInt(x));
    if (isNaN(hour) || isNaN(minute)) return;
    return {hour, minute};
}

/**
 * @param {number} numTimeslots 
 * @param {number} timeslotDuration 
 * @param {number} breakDuration 
 * @param {{hour: number, minute: number}} startTime 
 * @param {DateTime} startDate 
 * @param {DateTime} endDate 
 * @return {{start: DateTime, end?: DateTime}[]}
 */
function generateTimeslots(numTimeslots, timeslotDuration, breakDuration, startTime, startDate, endDate) {
    const timeslots = [];
    let currentDate = startDate;
    while (endDate.diff(currentDate, "days").days >= 0) {
        for (let i = 0; i < numTimeslots; i++) {
            const start = currentDate.set({hour: startTime.hour, minute: startTime.minute}).plus({minutes: i * (timeslotDuration + breakDuration)});
            if (timeslotDuration === 0) {
                timeslots.push({start});
            } else {
                const end = start.plus({minutes: timeslotDuration});
                timeslots.push({start, end});
            }
        }

        currentDate = currentDate.plus({days: 1});
    }

    return timeslots;
}

function validateAutomaticTimeslotForm({ numTimeslots: rawNumTimeslots, timeslotDuration: rawTimeslotDuration, breakDuration: rawBreakDuration, startTime, startDate, endDate }) {
    const errors = { descriptions: [] };
    const numTimeslots = rawNumTimeslots || 0;
    const timeslotDuration = rawTimeslotDuration || 0;
    const breakDuration = rawBreakDuration || 0;

    if (numTimeslots < 1) {
        errors.numTimeslots = true;
        errors.descriptions.push("Number of timeslots must be positive");
    }

    if (Math.floor(numTimeslots) !== numTimeslots) {
        errors.numTimeslots = true;
        errors.descriptions.push("Number of timeslots must be an integer");
    }

    if (timeslotDuration < 0) {
        errors.timeslotDuration = true;
        errors.descriptions.push("Timeslot durations cannot be negative");
    }

    if (breakDuration < 0) {
        errors.breakDuration = true;
        errors.descriptions.push("Break durations cannot be negative");
    }

    if (!startTime) {
        errors.startTime = true;
        errors.descriptions.push("Start time must be specified");
    }

    if (!getTimeFromString(startTime)) {
        errors.startTime = true;
        errors.descriptions.push("Start time must be in the format HH:MM");
    }

    if (!startDate) {
        errors.startDate = true;
        errors.descriptions.push("Start date must be specified");
    }

    if (!endDate) {
        errors.endDate = true;
        errors.descriptions.push("End date must be specified");
    }

    if (timeslotDuration > 24 * 60) {
        errors.timeslotDuration = true;
        errors.descriptions.push("Timeslots cannot be longer than 24 hours");
    } else if (breakDuration > 24 * 60) {
        errors.breakDuration = true;
        errors.descriptions.push("Breaks cannot be longer than 24 hours");
    } else if (timeslotDuration + breakDuration > 24 * 60) {
        errors.timeslotDuration = true;
        errors.breakDuration = true;
        errors.descriptions.push("Timeslots and breaks cannot be longer than 24 hours");
    } else if (numTimeslots * (timeslotDuration + breakDuration) > 24 * 60) {
        errors.numTimeslots = true;
        errors.timeslotDuration = true;
        errors.breakDuration = true;
        errors.descriptions.push("Total duration of timeslots exceeds 24 hours");
    }

    const startDateTime = DateTime.fromISO(startDate, { zone: "UTC" });
    const endDateTime = DateTime.fromISO(endDate, { zone: "UTC" });
    const days = endDateTime.diff(startDateTime, "days").days;
    if (days < 0) {
        errors.startDate = true;
        errors.endDate = true;
        errors.descriptions.push("End date must be after start date");
    } else if (numTimeslots * days > 200) {
        errors.numTimeslots = true;
        errors.startDate = true;
        errors.endDate = true;
        errors.descriptions.push("Cannot generate more than 200 timeslots at once");
    }

    if (errors.descriptions.length === 0) {
        delete errors.descriptions;
    }
    return errors;
}

function AutomaticTimeslotGenerator({ onGenerate, formName = "automatic-timeslot-generator", setSubmitHandler }) {
    const [now] = useState(() => new Date());
    const date = now.toISOString().split("T")[0];

    const formik = useFormik({
        initialValues: {
            numTimeslots: 5,
            timeslotDuration: 25,
            breakDuration: 5,
            startTime: "09:00",
            startDate: date,
            endDate: date,
        },
        validate: validateAutomaticTimeslotForm,
        async onSubmit(values, { setSubmitting }) {
            const start = DateTime.fromISO(values.startDate);
            const end = DateTime.fromISO(values.endDate);
            const timeslots = generateTimeslots(values.numTimeslots || 0, values.timeslotDuration || 0, values.breakDuration || 0, getTimeFromString(values.startTime), start, end);
            onGenerate(timeslots.map(({ start, end }) => ({ start: start.toISO(), end: end && end.toISO(), id: Math.random() })));
            setSubmitting(false);
        },
    });
    const { getFieldProps, errors, values, isValid, isValidating, isSubmitting, handleSubmit } = formik;

    const [timeslotPreview, setTimeslotPreview] = useState(null);
    useEffect(() => {
        if (validateAutomaticTimeslotForm(values).descriptions) {
            setTimeslotPreview(null);
        } else {
            const start = DateTime.fromISO(values.startDate);
            const timeslots = generateTimeslots(values.numTimeslots || 0, values.timeslotDuration || 0, values.breakDuration || 0, getTimeFromString(values.startTime), start, start);
            setTimeslotPreview(timeslots.map(({start}) => start.toLocaleString(DateTime.TIME_SIMPLE)).join(", "));
        }
    }, [values]);

    useEffect(() => {
        if (setSubmitHandler) {
            setSubmitHandler(handleSubmit);
            return () => setSubmitHandler(null);
        }
    }, [handleSubmit, setSubmitHandler]);

    return <div>
        <div className="mb-2">
            Generate <Input form={formName} className="w-20" type="number" {...getFieldProps("numTimeslots")} error={errors.numTimeslots} /> timeslots per day,
            starting at <Input form={formName} type="time" {...getFieldProps("startTime")} error={errors.startTime} />.
        </div>
        <div className="mb-2">
            Each timeslot is <Input form={formName} type="number" className="w-20" {...getFieldProps("timeslotDuration")} error={errors.timeslotDuration} /> minutes,
            followed by a <Input form={formName} type="number" className="w-20" {...getFieldProps("breakDuration")} error={errors.breakDuration} />-minute break.
        </div>
        <div>Do this every day from <Input form={formName} type="date" {...getFieldProps("startDate")} error={errors.startDate} /> to <Input type="date" {...getFieldProps("endDate")} error={errors.endDate} />.</div>
        {errors.descriptions && errors.descriptions.map((error, index) => {
            return <ErrorText key={index} className="mt-2">{error}</ErrorText>;
        })}
        {timeslotPreview && <div className="mt-2 muted">Preview: {timeslotPreview}</div>}
        <div className="flex items-center mt-2">
            <Button form={formName} className="mr-2" kind="primary" disabled={!isValid || isValidating || isSubmitting} type="submit">Generate</Button>
            {isSubmitting && <LoadingText messages={["Generating..."]} />}
        </div>
    </div>;
}

function ManualTimeslotEditor() {
    const { values, getFieldProps, errors: { timeslots: timeslotErrors } } = useFormikContext();
    // the sorting probably doesn't work?..
    const sortedTimeslots = useMemo(() => {
        return values.timeslots.map(({id, start, name}, index) => {
            return {id, start, name, index};
        }).sort((a, b) => {
            const aStart = DateTime.fromISO(a.start, {setZone: true});
            const bStart = DateTime.fromISO(b.start, {setZone: true});
            if (aStart.diff(bStart).milliseconds !== 0) {
                return aStart.diff(bStart).milliseconds;
            } else {
                const aName = a.name.toLowerCase();
                const bName = b.name.toLowerCase();
                if (aName < bName) {
                    return -1;
                }
                if (aName > bName) {
                    return 1;
                }
                return 0;
            }
        });
    }, [values.timeslots]);

    return <FieldArray name="timeslots" render={({ push, remove }) => {
        return <>
            {sortedTimeslots.map(({ id, index }) => {
                return <div key={id} className="mb-2">
                    <div className="flex items-center">
                        <Input type="text" placeholder="Name (optional)" className="flex-basis-54 mr-2 flex-grow-0 flex-shrink-[2]" {...getFieldProps(`timeslots.${index}.name`)} error={timeslotErrors && timeslotErrors[index]?.name} />
                        <Input type="datetime-local" className="w-54 flex-shrink-1" {...getFieldProps(`timeslots.${index}.start`)} error={timeslotErrors && timeslotErrors[index]?.start} />
                        -
                        <Input type="datetime-local" className="w-54 flex-shrink-1" {...getFieldProps(`timeslots.${index}.end`)} error={timeslotErrors && timeslotErrors[index]?.end} />
                        <Button className="mx-2" kind="destructive" onClick={() => remove(index)}>Remove</Button>

                        {/* Name and start have the same error currently
                        <ErrorText>{timeslotErrors && timeslotErrors[index]?.name}</ErrorText> */}
                        <ErrorText>{timeslotErrors && timeslotErrors[index]?.start}</ErrorText> 
                        <ErrorText>{timeslotErrors && timeslotErrors[index]?.end}</ErrorText>
                    </div>
                </div>;
            })}
            <Button className="mt-2" kind="primary" onClick={() => push({
                name: undefined,
                start: undefined,
                end: undefined,
                id: Math.random(),
            })}>Add timeslot</Button>
        </>;
    }} />;
}

function parseTimeslot({ name, start, end }) {
    return {
        name: name || "",
        start: start ? DateTime.fromISO(start, { setZone: true }) : undefined,
        end: (end && DateTime.fromISO(end, { setZone: true })) || undefined,
    };
}

function prepareTimeslotForEditing({ name, start, end, ...timeslot }) {
    return {
        name: name ?? "",
        start: (start && DateTime.fromISO(start).toISO({includeOffset: false})) || "",
        end: (end && DateTime.fromISO(end).toISO({includeOffset: false})) || "",
        ...timeslot,
    };
}

function TimeslotEditor({ setAutoSubmitHandler }) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { setFieldValue, values: { timeslots } } = useFormikContext();

    useEffect(() => {
        if (timeslots.length > 0) {
            setSelectedIndex(1);
        }
    }, [timeslots.length]);

    return <div className="p-4">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className={`mb-3 text-lg ${timeslots.length > 0 ? "hidden" : ""}`}>
                <Tab className={tabClass("border-settings-accent", "hover:border-settings-accent/50 hover:text-settings-accent")}>Automatic</Tab>
                <Tab className={tabClass("border-settings-accent", "hover:border-settings-accent/50 hover:text-settings-accent")}>Manual</Tab>
            </Tab.List>
            <Tab.Panels>
                <Tab.Panel>
                    <AutomaticTimeslotGenerator onGenerate={timeslots => {
                        setFieldValue("timeslots", timeslots.map(prepareTimeslotForEditing));
                    }} setSubmitHandler={setAutoSubmitHandler} />
                </Tab.Panel>
                <Tab.Panel>
                    <ManualTimeslotEditor />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    </div>;
}

function SchedulingSettingsContent({ timeslots, setTimeslots }) {
    const { id: eventID, settings: { auto_schedule: autoSettings, ...otherSettings } } = useEvent();
    const { minimum_judges_local: min_j, minimum_head_judges: min_hj, minimum_judges_isef: min_sj } = autoSettings;
    const [isSaved, setSaved] = useState(false);
    const [serverError, setServerError] = useState(null);
    const queryClient = useQueryClient();

    const min_judges = min_j || 0;
    const min_special_judges = min_sj || 0;
    const min_head_judges = min_hj || 0;

    const formik = useFormik({
        initialValues: {
            timeslots: timeslots.map(prepareTimeslotForEditing),
            min_reviewers: min_judges,
            min_special_cat_reviewers: min_special_judges,
            min_high_priority_reviewers: min_head_judges,
            isef_question: autoSettings.isef_question || "",
            categories_question: autoSettings.categories_question || "",
            timeslots_question: autoSettings.timeslots_question || "",
        },
        validate: ({ timeslots }) => {
            const startTimes = new Set();
            const timeslotErrors = timeslots.map(rawTimeslot => {
                if (!rawTimeslot.start && !rawTimeslot.name) {
                    return { start: "Start time or name is required", name: "Start time or name is required"};
                }
                if (rawTimeslot.start && !rawTimeslot.end) {
                    return { end: "End time is required" };
                }
                const timeslot = parseTimeslot(rawTimeslot);
                if (timeslot.end && timeslot.end < timeslot.start) {
                    return { end: "End time must be after start time" };
                }
                if (timeslot.start) {
                    const startMillis = timeslot.start.toMillis();
                    if (startTimes.has(startMillis)) {
                        return { start: "Time slots cannot start at the same time" };
                    } else {
                        startTimes.add(startMillis);
                    }
                }
                return null;
            });

            if (timeslotErrors.some(error => error)) {
                return { timeslots: timeslotErrors };
            } else {
                return {};
            }
        },
        async onSubmit(values) {
            try {
                const { min_reviewers, min_special_cat_reviewers, min_high_priority_reviewers } = values;
                await updateEventEventsEventIdPut(eventID, {
                    settings: {
                        auto_schedule: {
                            minimum_judges_local: min_reviewers,
                            minimum_judges_isef: min_special_cat_reviewers,
                            minimum_head_judges: min_high_priority_reviewers,
                            isef_question: values.isef_question || null,
                            isef_response: "ISEF", // TODO: Fix
                            categories_question: values.categories_question || null,
                            timeslots_question: values.timeslots_question || null,
                        },
                        otherSettings,
                    },
                });
                await queryClient.invalidateQueries(getGetEventEventsEventIdGetQueryKey(eventID));

                // Build a map of timeslot IDs to timeslots.
                const timeslotMap = new Map();
                timeslots.forEach(timeslot => {
                    timeslotMap.set(timeslot.id, parseTimeslot(timeslot));
                });

                const updates = {};
                const creates = [];
                let newCachedTimeslots = [];
                const seenIDs = new Set();
                values.timeslots.forEach(rawTimeslot => {
                    const id = rawTimeslot.id;
                    const timeslot = parseTimeslot(rawTimeslot);

                    // If a timeslot has an existing ID, compare it to the server equivalent.
                    // If they differ, update the timeslot.
                    // If the ID doesn't already exist, create it.
                    if (timeslotMap.has(id)) {
                        const serverTimeslot = timeslotMap.get(id);
                        if (serverTimeslot.start.toMillis() !== timeslot.start.toMillis() || serverTimeslot.end?.toMillis() !== timeslot.end?.toMillis() || serverTimeslot.name !== timeslot.name) {
                            updates[id] = { name: timeslot.name, start: timeslot.start.toISO(), end: timeslot.end?.toISO() };
                        } else {
                            newCachedTimeslots.push(rawTimeslot);
                        }
                        seenIDs.add(id);
                    } else {
                        creates.push({ name: timeslot.name, start: timeslot.start?.toISO(), end: timeslot.end?.toISO() });
                    }
                });

                // Delete any timeslots that weren't in the new list.
                const deletes = [];
                timeslots.forEach(timeslot => {
                    if (!seenIDs.has(timeslot.id)) {
                        deletes.push(timeslot.id);
                    }
                });

                // Update the server.
                if (Object.keys(updates).length > 0) {
                    newCachedTimeslots = newCachedTimeslots.concat(await ScheduleService.updateEventTimeslotsEventsEventIdScheduleTimeslotsPut(eventID, updates));
                }
                if (creates.length > 0) {
                    newCachedTimeslots = newCachedTimeslots.concat(await ScheduleService.createEventTimeslotsEventsEventIdScheduleTimeslotsPost(eventID, creates));
                }
                if (deletes.length > 0) {
                    await ScheduleService.deleteEventTimeslotsEventsEventIdScheduleTimeslotsDelete(eventID, deletes);
                }

                // Set the new timeslots.
                setTimeslots(newCachedTimeslots);
                setSaved(true);
            } catch (e) {
                setServerError(e);
                console.error(e);
            }
        },
        enableReinitialize: true,
    });

    const { getFieldProps } = formik;
    const [automaticTimeslotSubmitHandler, setAutomaticTimeslotSubmitHandler] = useState(null);

    const { data: projectQuestions } = useRequest(() => SurveysService.getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(eventID, "project", {list_all: true})); // {list_all: true}
    const { data: userQuestions } = useRequest(() => SurveysService.getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(eventID, "registration", {list_all: true})); // {list_all: true}
    
    return <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
            <SettingsPanel className="mb-3" title="Timeslots" icon={faCalendarWeek} hueFraction={0}>
                <TimeslotEditor setAutoSubmitHandler={handler => setAutomaticTimeslotSubmitHandler(() => handler)} />
            </SettingsPanel>

            <SettingsPanel className="mb-3" icon={faCheckToSlot} title="Reviewers" hueFraction={1 / 4}>
                <div className="p-4">
                    <label className="block" htmlFor="min-reviewers">Set Minimum Reviewers Per Project</label>
                    <Input id="min-reviewers" {...getFieldProps("min_reviewers")} type="number" placeholder={min_judges} className="mr-1" />

                    <label className="block mt-4" htmlFor="min-special-cat-reviewers">Set Minimum Reviewers Per ISEF Project</label>
                    <Input id="min-special-cat-reviewers" {...getFieldProps("min_special_cat_reviewers")} type="number" placeholder={min_special_judges} className="mr-1" />

                    <label className="block mt-4" htmlFor="min-priority-reviewers">Select Minimum Priority-Level Reviewers Per Project</label>
                    <Input id="min-priority-reviewers" {...getFieldProps("min_high_priority_reviewers")} type="number" placeholder={min_head_judges} className="mr-1" />
                </div>
            </SettingsPanel>

            <SettingsPanel className="mb-3" icon={faClipboardQuestion} title="Questions" hueFraction={2 / 4}>
                <div className="p-4">
                    <label className="block" htmlFor="isef-question">ISEF/Local Question</label>
                    <Select id="isef-question" {...getFieldProps("isef_question")}>
                        <option value="">None</option>
                        {projectQuestions?.filter(({question_type}) => {
                            return question_type === "radio" || question_type === "select";
                        }).map(question => <option key={question.id} value={question.id}>{question.name}</option>)}
                    </Select>

                    <label className="block mt-4" htmlFor="categories-question">Categories Question</label>
                    <Select id="categories-question" {...getFieldProps("categories_question")}>
                        <option value="">None</option>
                        {userQuestions?.filter(({question_type}) => {
                            return question_type === "categories";
                        }).map(question => <option key={question.id} value={question.id}>{question.name}</option>)}
                    </Select>

                    <label className="block mt-4" htmlFor="timeslots-question">Timeslots Question</label>
                    <Select id="timeslots-question" {...getFieldProps("timeslots_question")}>
                        <option value="">None</option>
                        {userQuestions?.filter(({question_type}) => {
                            return question_type === "timeslots";
                        }).map(question => <option key={question.id} value={question.id}>{question.name}</option>)}
                    </Select>
                </div>
            </SettingsPanel>
            <SaveChanges isSaved={isSaved} serverError={serverError}/>
        </form>
        <form id="automatic-timeslot-generator" onSubmit={event => {
            if (automaticTimeslotSubmitHandler) {
                automaticTimeslotSubmitHandler(event);
            } else {
                event.preventDefault();
            }
        }} />
    </FormikProvider>;
}

export function SchedulingSettings() {
    const { id } = useEvent();
    const { data, loading, error } = useRequest(async () => {
        const [timeslots] = await Promise.all([
            ScheduleService.listEventTimeslotsEventsEventIdScheduleTimeslotsGet(id),
        ]);
        return {timeslots};
    });
    const [timeslots, setTimeslots] = useState(data?.timeslots);
    useEffect(() => {
        setTimeslots(data?.timeslots);
    }, [data]);

    return <OneColumnLayout title="Scheduling Settings">
        <Helmet>
            <title>Scheduling Settings</title>
        </Helmet>
        <Main>
            {timeslots && <SchedulingSettingsContent timeslots={timeslots} setTimeslots={setTimeslots} />}
        </Main>
    </OneColumnLayout>;
}

export const SchedulePageDefinition = {
    title: "Scheduling",
    content: <SchedulingSettings />,
    icon: faClock,
    route: "scheduling",
    permission: permissions.manageTimeslots,
};
