import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { AccountService } from "../client";
import { SaveChanges } from '../components/SaveChanges';
import { RouterLinkButton } from '../components/ui/Button';
import { ErrorText } from "../components/ui/Input";
import { Input } from '../components/ui/Input';
import { Main } from '../components/ui/Main';
import { useUser } from '../lib/auth.js';

export function Account({ onUpdateUser }) {
    const user = useUser();
    const { state } = /** @type {{state: {from: Location}}} */ (useLocation());
    const { name, email } = user;
    const [serverError, setServerError] = useState(null);
    const [isSaved, setSaved] = useState(false);

    const formik = useFormik({
        initialValues: {
            name,
            // password: "",
        },
        validate: ({ name }) => {
            const errors = {};
            if (!name) {
                errors.name = "Required";
            }
            /* if (!password) {
                errors.password = "Required";
            } */
            return errors;
        },
        onSubmit: async ({ name }) => {
            setServerError(null);
            try {
                if (user.name !== name) {
                    await AccountService.updateUserAccountPut({
                        name,
                    });
                }
                // await api.post(`/auth/password/change`, {password});
                onUpdateUser({ ...user, name });
                setSaved(true);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
        enableReinitialize: true,
    });
    const { handleSubmit, getFieldProps, errors } = formik;

    return (
        <Main className="flex py-8">
            <Helmet>
                <title>Account</title>
            </Helmet>
            <div className="mr-3">
                <RouterLinkButton to={state?.from ?? "/events"} size="icon-lg" kind="secondary-filled">
                    <FontAwesomeIcon icon={faChevronLeft} />
                </RouterLinkButton>
            </div>
            <FormikProvider value={formik}>
                <form className="flex-grow" onSubmit={handleSubmit}>
                    <div className="flex h-12 items-center mb-6">
                        <div className="flex-grow">
                            <h1 className="subtitle font-bold">{name}</h1>
                            <h2 className="subsubheading">Account Settings</h2>
                        </div>
                        <RouterLinkButton size="md" to="/logout" kind="destructive" className="mr-1">Log out</RouterLinkButton>
                    </div>
                    <label htmlFor="name" className="block">Name</label>
                    <div><Input type="text" id="name" {...getFieldProps("name")} className="flex-grow w-1/2 mr-1" required /></div>
                    <ErrorText>{errors.name}</ErrorText>
                    <label htmlFor="email" className="block mt-2">Email</label>
                    <div className="mb-5"><Input type="text" id="email" defaultValue={email} className="flex-grow w-1/2 mr-1" disabled /></div>

                    <SaveChanges isSaved={isSaved} serverError={serverError} />

                    {/* <SettingsPanel className="mb-3" icon={faBolt} title="Password Reset" hueFraction={1.4}>
                        <div className="p-4">
                            <Input className="w-full max-w-lg mt-2" id="password" {...getFieldProps("password")} type="password" placeholder="Password" error={touched.password && errors.password} />
                            <ErrorText>{errors.password}</ErrorText>
                            <br />
                        </div>
                </SettingsPanel> */}
                </form>
            </FormikProvider>
        </Main>
    );
}
