import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { nightOwl as dark, atelierCaveLight as light } from "react-syntax-highlighter/dist/esm/styles/hljs"; import { StyledExternalLink } from "./Link";
import { useEffect, useState } from "react";
import classNames from "classnames";

export default function MarkdownText({ text, className }) {
    const [isDarkMode, setDarkMode] = useState(window.matchMedia?.("(prefers-color-scheme: dark)").matches);
    useEffect(() => {
        if (!window.matchMedia) return;
        const handler = event => setDarkMode(event.matches);
        const mq = window.matchMedia("(prefers-color-scheme: dark)");
        mq.addEventListener("change", handler);
        return () => mq.removeEventListener("change", handler);
    });

    return <ReactMarkdown components={{
        a(props) {
            return <StyledExternalLink {...props} target="_blank" rel="noopener noreferrer" />;
        },
        code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            if (!inline && match) {
                return <>
                    {/* @ts-ignore */}
                    <SyntaxHighlighter children={children} language={match[1]} style={isDarkMode ? dark : light} PreTag="div" />
                    <span className="flex font-sans text-sm px-2 py-1 bg-gray-200 dark:bg-gray-700 space-x-2">
                        <div className="text-gray-600 dark:text-gray-300 flex-grow">{match[1]}</div>
                        {/* @ts-ignore */}
                        <StyledExternalLink href="#" onClick={() => { navigator.clipboard.writeText(children);
                        }}>Copy</StyledExternalLink>
                    </span>
                </>;
            } else if (inline) {
                return <code className={className} {...props}>{children}</code>;
            } else {
                return <div className="bg-gray-200 dark:bg-gray-800">
                    {children}
                </div>;
            }
        },
    }} className={classNames("markdown", className)}>{text}</ReactMarkdown>;
}
