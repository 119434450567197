/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserUpdate } from '../models/UserUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountService {

    /**
     * Request Email Verify
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static requestEmailVerifyAccountVerifyRequestPost(
        email: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/verify/request',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Email Verify
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static emailVerifyAccountVerifyPost(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/verify',
            query: {
                'token': token,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateUserAccountPut(
        requestBody: UserUpdate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }

}
