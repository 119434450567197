import { useParams, useNavigate } from "react-router-dom";
import { OneColumnLayout } from "../../../components/OneColumnLayout";
import { Main } from "../../../components/ui/Main";
import { faInfoCircle, faUserShield, faClose, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LoadingScreen } from "../../../components/ui/Loading";
import { useContext, useState, useEffect, useMemo } from "react";
import { useAPI, useRequest } from "../../../lib/API";
import { submitSurvey } from "../../../lib/surveys";
import { useUser } from "../../../lib/auth";
import { useEvent, usePermissionChecker } from "../../../components/EventLayout";
import { ProjectsContext } from "./list";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { SaveChanges } from '../../../components/SaveChanges';
import { ErrorText, Input, Select } from '../../../components/ui/Input';
import { SettingsPanel } from "../../../components/SettingsPanel";
import { Survey, validateSurveyResponse } from "../../../components/surveys/Survey";
import { BackContext } from "../../../components/BackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../components/ui/Button";
import Helmet from "react-helmet";
import permissions from '../../../permissions.json';
import { ProjectsService, SurveysService, UsersService, CategoriesService } from '../../../client';

function CatSelect({categories, ...props}) {
    return <Select {...props}>
        <option value="">Select a category...</option>
        {categories.map(cat => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
    </Select>;
}

function UserSelect({users, ...props}) {
    const sortedUsers = useMemo(() => {
        return [...users].sort((a, b) => a.name.localeCompare(b.name));
    }, [users]);

    return <Select {...props}>
        <option value="">Select a user...</option>
        {sortedUsers.map(user => <option key={user.id} value={user.id}>{user.name}</option>)}
    </Select>;
}

function ProjectEditorContent({project, members, setProject, setMembers, users, categories, details, setDetails, questions}) {
    const api = useAPI();
    const [serverError, setServerError] = useState(null);
    const projectsContext = useContext(ProjectsContext);
    const [isSaved, setSaved] = useState(false);
    const navigate = useNavigate();
    const check = usePermissionChecker();
    const currentUser = useUser();
    const myProject = members.find(member => member === currentUser.id) !== undefined;

    // Build a mapping of user IDs to names using the users array.
    const userMap = useMemo(() => {
        const map = {};
        users.forEach(user => map[user.id] = `${user.name} (${user.email})`);
        return map;
    }, [users]);

    const formik = useFormik({
        initialValues: {
            name: project.name,
            category_id: project.category?.id ?? "",
            details,
            members,
        },
        validate: ({ name, category_id, details }) => {
            const errors = {};
            if (!name) {
                errors.name = "Required";
            }
            if (!category_id && categories.length > 0) {
                errors.category_id = "Required";
            }

            if (questions) {
                const surveyErrors = validateSurveyResponse(questions, details);
                if (surveyErrors) {
                    errors.details = surveyErrors;
                }
            }

            return errors;
        },
        onSubmit: async ({name, category_id, members: newMembers, details}) => {
            setServerError(null);
            try {
                const newProject = await ProjectsService.updateProjectDetailsProjectsProjectIdPut(project.id, {
                    name,
                    category_id: category_id || undefined,
                });
                setProject(newProject);
                projectsContext.upsert(newProject);
                //TODO there are issues with this if people make projects without details and then admin adds project details questions
                //editing does not work well - the creation and deletion happen in a weird order that does not make sense, but 
                //makes this not functional
                await SurveysService.deleteProjectDetailsProjectProjectIdDetailsDelete(project.id);
                // TODO
                // await SurveysService.createProjectDetailsProjectProjectIdDetailsPost(project.id,
                //  {questions, details,}
                // );
                await submitSurvey(api, "post", `/project/${project.id}/details`, questions, details);

                // For any newMembers not in members, invite them to the project.
                const newMembersToAdd = newMembers.filter(newMember => !members.find(member => member === newMember));
                for (const member of newMembersToAdd) {
                    await ProjectsService.addUserToProjectProjectsProjectIdUsersTypePost(project.id, "member", member);
                }

                // For any members not in newMembers, remove them from the project.
                const membersToRemove = members.filter(member => !newMembers.find(newMember => newMember === member));
                for (const member of membersToRemove) {
                    await ProjectsService.removeUserFromProjectProjectsProjectIdUsersUserIdDelete(project.id, member);
                }

                setDetails(details);
                setSaved(true);
                setMembers(newMembers);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
        onReset: () => {
            setServerError(null);
        },
        enableReinitialize: true,
    });
    const { getFieldProps, touched, errors, handleSubmit, values } = formik;
    const [invitee, setInvitee] = useState(undefined);

    return <BackContext.Provider value={{icon: faClose, label: "Close", action: () => navigate("..")}}>
        <OneColumnLayout alwaysSmall={true} title={`Edit "${project?.name}"`} >
            <Helmet>
                <title>{`Edit ${project ? `"${project.name}"` : "Project"}`}</title>
            </Helmet>
            <Main>
                <FormikProvider value={formik}>
                    <form onSubmit={handleSubmit}>
                        <SettingsPanel defaultOpen={true} className="mb-3" title="Project Details" icon={faInfoCircle} hueFraction={0}>
                            <div className="p-4">
                                <label className="block subheading mb-2" htmlFor="name">Name</label>
                                <Input {...getFieldProps("name")} type="text" placeholder="Project Name" className="w-full max-w-lg" id="name" error={touched.name && errors.name} />
                                <ErrorText className="mt-1">{errors.name}</ErrorText>
                                <div>
                                    {categories.length > 0 && <>
                                        <label className="block subheading mt-4 mb-2" htmlFor="category">Category</label>
                                        <CatSelect categories={categories} {...getFieldProps("category_id")} id="category" />
                                        <ErrorText className="mt-1">{errors.category_id}</ErrorText>
                                    </>}
                                </div>
                                <div className="mt-4">
                                    <Survey questions={questions} name="details" />
                                </div>
                            </div>
                        </SettingsPanel>

                        {((check(permissions.editUsersOwnProject) && myProject) || check(permissions.editUsersAllProject)) && <SettingsPanel className="mb-3" title="Users &amp; Access" icon={faUserShield} hueFraction={1 / 3}>
                            <div className="p-4">
                                <FieldArray name="members" render={({push, remove}) => <>
                                    {users.length > 0 && <>
                                        <label className="block subheading mb-2" htmlFor="invited">Invite a collaborator!</label>
                                        <UserSelect users={users} value={invitee} onChange={e => setInvitee(e.target.value)} id="invited" />
                                        <Button className="grow" size="icon" kind="success-text" onClick={() => {
                                            push(invitee);
                                            setInvitee(undefined);
                                        }} disabled={!invitee || values.members.includes(invitee)}>+</Button>
                                        <ErrorText className="mt-1">{errors.invitee}</ErrorText>
                                    </>}
                                    {values.members.length > 0 && <>
                                        <label className="block subheading mt-4" htmlFor="collaborators">Manage current collaborators</label>
                                        {values.members && <ul className="mb-0 list-none space-y-2">
                                            {values.members.map((id, index) => {
                                                return <li className="p-0" key={id}>
                                                    <div className="py-1 flex items-center">
                                                        <span>{userMap[id] ?? id}</span>
                                                        <Button size="icon" kind="destructive-text" onClick={_ => remove(index)} className="text-center"><FontAwesomeIcon icon={faTimes} /></Button>                        
                                                    </div>
                                                </li>;
                                            })}
                                        </ul>}
                                    </>}
                                </>} />
                            </div>
                        </SettingsPanel>}
                        <SaveChanges serverError={serverError} isSaved={isSaved} />
                    </form>
                </FormikProvider>
            </Main>
        </OneColumnLayout>
    </BackContext.Provider>;
}

export function ProjectEditor() {
    const { id } = useParams();
    const { id: eventID } = useEvent();
    const { data } = useRequest(async api => {
    // const { data } = useRequest(async () => {
        const [project, members, details, users, categories, questions] = await Promise.all([
            ProjectsService.getProjectProjectsProjectIdGet(id),
            ProjectsService.getProjectUsersProjectsProjectIdUsersTypeGet(id, "member"),
            (async () => {
                try {
                    // return await api.get(`/project/${id}/details`);
                    return await SurveysService.getProjectDetailsProjectProjectIdDetailsGet(id);
                } catch (e) {
                    if (e.status === 404) {
                        return {};
                    } else {
                        throw e;
                    }
                }
            })(),
            UsersService.listEventUsersEventsEventIdUsersGet(eventID),
            CategoriesService.listEventCategoriesEventsEventIdCategoriesGet(eventID),
            SurveysService.getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(eventID, "project"),
        ]);

        return { project, members, details, users, categories, questions };
    }, [eventID, id]);

    const [project, setProject] = useState(data?.project);
    const [details, setDetails] = useState(data?.details);
    const [members, setMembers] = useState(data?.members?.map(member => member.id));
    useEffect(() => {
        setProject(data?.project);
        setDetails(data?.details);
        setMembers(data?.members?.map(member => member.id));
    }, [data]);

    if (project) {
        return <ProjectEditorContent project={project} setProject={setProject} members={members} setMembers={setMembers} details={details} setDetails={setDetails} users={data.users} categories={data.categories} questions={data.questions} />;
    } else {
        return <LoadingScreen />;
    }
}
