import { Main } from '../../components/ui/Main';
import { Button } from '../../components/ui/Button';
import { OneColumnLayout } from '../../components/OneColumnLayout';
import { Field, FormikProvider, useFormik } from 'formik';
import { ErrorText } from '../../components/ui/Input';
import { LoadingText } from '../../components/ui/Loading';
import { useState } from 'react';
import { useEvent } from '../../components/EventLayout.tsx';
import { dataTypes as exportableData, formats, performExport } from '../../lib/export.js';
import { useAPI } from '../../lib/API.js';
import Helmet from 'react-helmet';

export function Export() {
    const [serverError, setServerError] = useState(null);
    const { id } = useEvent();
    const api = useAPI();

    const formik = useFormik({
        initialValues: {
            data: [],
            format: formats[0].id,
        },
        validate: values => {
            const errors = {};
            if (values.data.length === 0) {
                errors.data = "Please select at least one data type";
            }
            const format = formats.find(f => f.id === values.format);
            if (values.data.length > 1 && !format.supportsMulti) {
                errors.data = `${format.name} does not support multiple data types`;
            }
            return errors;
        },
        onSubmit: async ({data, format}) => {
            setServerError(null);
            try {
                await performExport(data, format, api, id);
            } catch (err) {
                console.error(err);
                setServerError(err);
            }
        },
    });
    const { handleSubmit, values, errors, isSubmitting, isValid } = formik;

    return <OneColumnLayout title="Export">
        <Helmet>
            <title>Export</title>
        </Helmet>
        <Main>
            <FormikProvider value={formik}>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <h3 className="heading">What data would you like to export?</h3>
                        {exportableData.map(data => (
                            <div key={data.id} className="flex items-baseline mt-1">
                                <Field className="rounded cursor-pointer" type="checkbox" id={`data-${data.id}`} name="data" value={data.id} />
                                <label className="ml-2 cursor-pointer" htmlFor={`data-${data.id}`}>
                                    <div className="subheading">{data.name}</div>
                                    <div className="muted">{data.description}</div>
                                </label>
                            </div>
                        ))}
                        <ErrorText className="mt-2">{errors.data}</ErrorText>
                    </div>
                    <div className="mb-6">
                        <h3 className="heading">How would you like your data?</h3>
                        {formats.map(format => {
                            const disabled = !format.supportsMulti && values.data.length > 1;
                            return <div key={format.id} className={`flex items-baseline mt-1 ${disabled ? "opacity-50" : ""}`}>
                                <Field disabled={disabled} className={disabled ? "cursor-not-allowed" : "cursor-pointer"} type="radio" id={`format-${format.id}`} name="format" value={format.id} />
                                <label className={`ml-2 ${disabled ? "cursor-not-allowed": "cursor-pointer" }`} htmlFor={`format-${format.id}`}>
                                    <div className="subheading">{format.name}</div>
                                    <div className="muted">{format.description}</div>
                                </label>
                            </div>;
                        })}
                    </div>
                    <div className="flex items-center mt-6">
                        <Button disabled={isSubmitting || !isValid} type="submit" size="lg" href="#" kind="primary" className="mr-3">Export &amp; Download</Button>
                        {isSubmitting && <LoadingText messages={["Exporting..."]} />}
                    </div>
                    {serverError && <ErrorText className="mt-2">An error occurred while exporting. Try again?</ErrorText>}
                </form>
            </FormikProvider>
        </Main>
    </OneColumnLayout>;
}
