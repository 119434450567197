import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useRequest } from "../../lib/API";
import { useEvent } from "../EventLayout";
import { Button } from "../ui/Button";
import { ErrorText } from "../ui/Input";
import { LoadingText } from "../ui/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Survey, useEmptyResponse, validateSurveyResponse } from "./Survey";

function SurveyFormContent({ editable, surveyType, questions, onSubmit, onDelete, existingResponses, disabled, hint, emptyText }) {
    const [serverError, setServerError] = useState(null);    
    const emptyResponse = useEmptyResponse(questions);
    const { settings } = useEvent();
    const review_lock = settings.survey_locks.review; 
    const registration_lock = settings.survey_locks.registration;
    const lock = (surveyType === "review") ? review_lock : registration_lock;
    const [disable, setDisable] = useState(disabled);
    const [openEdit, setOpenEdit] = useState(false);
    const [clicked, setClicked] = useState(false);

    const formik = useFormik({
        initialValues: {
            survey: {...emptyResponse, ...existingResponses},
        },
        validate: values => {
            if (disable) return {};
            const errors = validateSurveyResponse(questions, values.survey);
            if (errors) return {survey: errors};
            return {};
        },
        onSubmit: async (values) => {
            const { survey } = values;
            setServerError(null);
            try {
                if (openEdit){
                    await onDelete();
                    setOpenEdit(false);
                }
                await onSubmit(survey, !!existingResponses, questions);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
        onReset: () => {
            setServerError(null);
        },
        enableReinitialize: true,
    });
    return <FormikProvider value={formik}>
        {questions.length === 0 ? <p className="mb-2">{emptyText}</p> : <form onSubmit={formik.handleSubmit}>
            <Survey questions={questions} disabled={disable} name="survey" />
            <div>
                {!lock && !openEdit && <Button onClick={() => {setClicked(true);}} disabled={disable || formik.isSubmitting || !formik.isValid || existingResponses} type="submit" kind={existingResponses || formik.isSubmitting || (clicked && formik.isValid) ? "success" : "primary"}>{existingResponses || formik.isSubmitting || (clicked && formik.isValid) ? "Submitted" : "Submit"}</Button>}
                {!lock && editable && !openEdit && existingResponses && <Button className="ml-3" kind="primary" disabled={formik.isSubmitting || !formik.isValid} onClick={() => { setDisable(); setOpenEdit(true); }}><FontAwesomeIcon className="mr-1" icon={faPencil} /> Edit</Button>}
                {openEdit && <Button kind="primary" disabled={formik.isSubmitting || !formik.isValid} type="submit" onClick={() => {setDisable(true);}}>Save</Button>}
            </div>
            {hint && <p className="mt-2">{hint}</p>}
            {lock && <div className="ml-1 mr-2 italic">Editing and submissions disabled</div>}
            {serverError && <ErrorText>An error occurred. Try again?</ErrorText>}
        </form>}
    </FormikProvider>;
}

/**
 * @param {{surveyType: string, onSubmit: (survey: Record<string, any>, isExisting: boolean) => PromiseLike<void>, existingResponseURL?: string, disabled?: boolean | ((isExisting: boolean) => boolean), hint?: string | ((isExisting: boolean) => string | undefined), emptyText: string}} props 
 */
export function SurveyForm({ editable, surveyType, onSubmit, onDelete, existingResponseURL, disabled, hint, emptyText }) {
    const { id: eventID } = useEvent();
    const { data, error } = useRequest(api => Promise.all([
        api.get(`/events/${eventID}/surveys/${surveyType}/questions`),
        existingResponseURL ? (async () => {
            try {
                return await api.get(existingResponseURL);
            } catch (e) {
                if (e.status === 404) return null;
                throw e;
            }
        })() : null,
    ]), [eventID, surveyType, existingResponseURL]);

    const isExisting = !!(data && data[1]);
    const isDisabled = typeof disabled === "function" ? disabled(isExisting) : !!disabled;
    const hintText = typeof hint === "function" ? hint(isExisting) : hint;

    return <>
        {!data && !error && <LoadingText />}
        {error && <ErrorText>Couldn't load questions. Try reloading the page.</ErrorText>}
        {data && <SurveyFormContent editable={editable} surveyType={surveyType} questions={data[0]} existingResponses={data[1]} onSubmit={onSubmit} onDelete={onDelete} disabled={isDisabled} hint={hintText} emptyText={emptyText} />}
    </>;
}
