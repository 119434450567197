import { faArrowRight, faCheckCircle, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioGroup } from "@headlessui/react";
import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import { EventsService } from "../client";
import { AccountMenu } from "../components/AccountMenu";
import { Button, RouterLinkButton } from "../components/ui/Button";
import { ErrorText, Input, Select } from "../components/ui/Input";
import { LoadingText } from "../components/ui/Loading";
import { Main } from "../components/ui/Main";
import { useAPI, useRequest } from "../lib/API";
import { TemplateContext, templates } from "../lib/templates/templates";

function EventSelect({events, ...props}) {
    return <Select {...props}>
        <option value="">Select an event...</option>
        {events.map(event => <option key={event.id} value={event.id}>{event.name}</option>)}
    </Select>;
}

export function NewEventForm( ) {
    const api = useAPI();
    const [previousEvent, setPreviousEvent] = useState("no");
    const [serverError, setServerError] = useState(null);
    const { data : events } = useRequest(api => api.get(`/events`));

    const formik = useFormik({
        initialValues: {
            name: "",
            event_id: "",
            template: templates[0].id,
        },
        validate: ({ name }) => {
            const errors = {};
            if (!name) {
                errors.name = "Required";
            }
            return errors;
        },
        onSubmit: async ({ name, event_id }) => {
            const template = templates.find(template => template.id === formik.values.template);
            setServerError(null);
            try {
                if (previousEvent === "yes") {
                    const event = await EventsService.copyEventEventsCopyPost(name, event_id);
                    navigate(`/event/${event.id}`);
                } else {
                    const event = await EventsService.createEventEventsPost({
                        name,
                        settings: {},
                    });
                    const templateContext = new TemplateContext(api, event);
                    await template.create(templateContext);
                    navigate(`/event/${event.id}`);
                }
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
    });
    const { handleSubmit, getFieldProps, touched, errors, isSubmitting, isValid, setFieldValue, values } = formik;
    const navigate = useNavigate();

    return <Main className="flex py-8"><FormikProvider value={formik}>
        <Helmet>
            <title>New event</title>
        </Helmet>
        <div className="mr-3">
            <RouterLinkButton to="/events" size="icon-lg" kind="secondary-filled">
                <FontAwesomeIcon icon={faChevronLeft} />
            </RouterLinkButton>
        </div>
        <form onSubmit={handleSubmit} className="flex-grow">
            <div className="flex h-12 items-center mb-6">
                <h1 className="text-2xl font-bold flex-grow">Create New Event</h1>
                <AccountMenu />
            </div>
            <label htmlFor="name">What's the name?</label>
            <br/>
            <Input className="w-full max-w-lg mt-1" id="name" placeholder="My New Event" {...getFieldProps("name")} error={touched.name && errors.name} />
            <ErrorText className="mt-1">{touched.name && errors.name}</ErrorText>
            <div className="mt-6 mb-2">How would you like to begin?</div>
            <Select className="mb-3" value={previousEvent} onChange={e => setPreviousEvent(e.target.value)}>
                <option value="no">Use template</option>
                <option value="yes">Copy an existing event</option>   
            </Select>

            {previousEvent === "no" && <RadioGroup className="mb-4 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-3" value={values.template} onChange={value => setFieldValue("template", value)}>
                {templates.map(({ id, name, description, checkedClassName }) => (
                    <RadioGroup.Option key={id} value={id}>
                        {({ checked }) => <Button size="unsized" className={`w-full h-full p-4 relative rounded shadow-lg hover:shadow-xl dark:bg-gray-800 cursor-pointer ${checked ? checkedClassName : "active:bg-gray-100 active:dark:bg-gray-700"}`}>
                            <div className="w-full h-full text-left">
                                <h4 className="subheading mr-8">{name}</h4>
                                <p className="opacity-70">{description}</p>
                                {checked && <FontAwesomeIcon className="absolute top-5 right-4" icon={faCheckCircle} />}
                            </div>
                        </Button>}
                    </RadioGroup.Option>
                ))}
            </RadioGroup>}
            {events && previousEvent === "yes" && <>
                <div className="flex items-start mb-2">
                    <label htmlFor="reuse" className="flex flex-col">
                        <span className="mb-2">Reuse settings from a previous event:</span>
                        <EventSelect events={events} {...getFieldProps("event_id")} />
                        {/* <ErrorText className="mt-1">{errors.event_id}</ErrorText> */}
                    </label>
                </div>
            </>}
            {!events && previousEvent === "yes" && <div>Unfortunately, you are not a member of any events.
                Select the template option to proceed.</div>}

            <div className="mt-6 flex justify-start items-center">
                <Button type="submit" kind="primary" disabled={!isValid || isSubmitting}>Create event <FontAwesomeIcon className="ml-3" icon={faArrowRight} /></Button>
                {serverError && <ErrorText className="ml-4">Couldn't create the event. Try again?</ErrorText>}
                {isSubmitting && <LoadingText className="ml-4" messages={[
                    "Creating event...",
                    "Assembling event...",
                    "Baking event...",
                    "Constructing event...",
                    "Performing machine learning on event...",
                ]}></LoadingText>}
                
            </div>
        </form>
    </FormikProvider></Main>;
}
