import { BackButton } from "../components/BackButton";
import { useState, useEffect, useContext } from "react";
import { RouterLinkButton } from "../components/ui/Button";
import { EventContext } from "../components/EventLayout";
import Helmet from "react-helmet";

const messages = [
    "We're sorry, but the page you were looking at has teleported into a parallel universe.",
    "Your page was last seen being carried away by a pigeon. We apologize for the inconvenience.",
    "The page you were looking at has become the subject of a secretive research study.",
    "Perhaps drinking water will help.",
    "You are now manually breathing.",
    "Apologies, but we have run out of errors to give.",
    "We're sorry, but the page you were looking at has been [DATA EXPUNGED].",
    "Have you tried turning it on and off again?",
    "Have you tried forcing an unexpected reboot?",
    "✨ computers ✨",
    "Error: Page is 120% complete and slightly off nominal.",
    "Sorry, but the page you were looking at suffered a rapid unscheduled disassembly.",
    "The page you were looking at exists in an alternate dimension, and as such we were unable to retrieve it at this time.",
];

export function NotFound() {
    const [message, setMessage] = useState();
    const eventContext = useContext(EventContext);

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * messages.length);
        setMessage(messages[randomIndex]);
    }, []);

    const title = "404 (Not found)";

    return <div className="flex flex-col min-h-screen p-4">
        <Helmet><title>{title}</title></Helmet>
        <BackButton />
        <div className="grow flex flex-col justify-center items-center text-center">
            <h1 className="large-title mb-2 bg-gradient-to-tr from-red-600 to-red-400 dark:from-red-500 dark:to-red-300 bg-clip-text text-transparent">404</h1>
            <p className="subtitle mb-6">Not Found</p>
            <p className="italic container mb-6">{message}</p>
            {eventContext?.event ?
                <RouterLinkButton to="../" kind="primary">Go back to event home</RouterLinkButton> :
                <RouterLinkButton to="/" kind="primary">Go back to the homepage</RouterLinkButton>}
        </div>
    </div>;
}
