import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthService } from "../client";
import { LoadingScreen } from "../components/ui/Loading";
import { useRequest } from "../lib/API";
import { useQueryClient } from "@tanstack/react-query";

/**
 * @param {{onLogout: () => void}} param0 
 */
export function Logout({ onLogout: handleLogout }) {
    const [ isDone, setDone ] = useState(false);
    const queryClient = useQueryClient();
    const { data, error } = useRequest(async () => {
        const result = await AuthService.logoutAuthLogoutGet();
        queryClient.clear();
        return result;
    });
    
    useEffect(() => {
        if (!isDone && data) {
            handleLogout();
            setDone(true);
        }
    }, [isDone, data, handleLogout]);

    if (isDone) {
        return <Navigate to="/login" replace />;
    } else if (error) {
        return <div className="w-full min-h-screen flex justify-center items-center p-4 text-center">
            <div className="bg-red-500 text-white text-center p-4 rounded">
                <p>Couldn't log you out</p>
            </div>
        </div>;
    } else {
        return <LoadingScreen messages={["Logging you out..."]} />;
    }
}
