import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import classNames from "classnames";

/**
 * @typedef StyledDialogProps
 * @property {string} [className]
 * @property {boolean} open
 * @property {string} [borderClassName]
 */

/**
 * @param {import("react").PropsWithChildren<StyledDialogProps & Parameters<import("@headlessui/react").Dialog>[0]>} props 
 * @returns a styled dialog
 */
export function StyledDialog({className, children, open, borderClassName, onCloseF, ...props}) {
    return <Transition as={Fragment} appear show={open}>
        {/* @ts-ignore */}
        <Dialog className="fixed z-50 inset-0 overflow-y-auto" {...props}>
            <div className="flex justify-center items-center min-h-screen">
                <Transition.Child
                    enter="transition-opacity duration-100 ease-in"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-100 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 dark:opacity-40" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition duration-100 ease-out"
                    enterFrom="opacity-0 transform scale-110"
                    enterTo="opacity-100 transform scale-100"
                    leave="transition duration-100 ease-in"
                    leaveFrom="opacity-100 transform scale-100"
                    leaveTo="opacity-0 transform scale-95"
                >
                    <div className={classNames("relative p-6 bg-white/90 dark:bg-gray-800/90 backdrop-blur shadow-xl rounded-lg max-w-sm mx-auto border-t-8", borderClassName || "border-blue-500", className)}>
                        {children}
                    </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition>;
}
