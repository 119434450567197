import { faObjectGroup, faTimes, faList, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEvent } from "../../../components/EventLayout.tsx";
import { ErrorText, Input } from "../../../components/ui/Input";
import { LoadingText } from "../../../components/ui/Loading";
import { useRequest } from "../../../lib/API.js";
import { useFormik } from "formik";
import { Button, ExternalLinkButton } from "../../../components/ui/Button";
import { useArrayUpdateContext } from "../../../lib/utils.js";
import { SettingsPanel } from '../../../components/SettingsPanel';
import { OneColumnLayout } from '../../../components/OneColumnLayout';
import { Main } from "../../../components/ui/Main";
import { StyledDialog } from "../../../components/ui/Dialog";
import { BigIcon } from "../../../components/ui/BigIcon";
import { Dialog } from "@headlessui/react";
import Helmet from "react-helmet";
import permissions from "../../../permissions.json";
import { CategoriesService } from "../../../client";

/** @type {import('react').Context<import("../../../lib/API.js").RequestResult<Category[]> | null>} */
export const SettingsCategoriesContext = createContext(null);

export function Category() {
    const { id: eventID, name : eventName } = useEvent();
    const navigate = useNavigate();
    const [serverError, setServerError] = useState(null);
    
    const { data, error } = useRequest(() => /** @type {Promise<Category[]>} */ CategoriesService.listEventCategoriesEventsEventIdCategoriesGet(eventID));
    const [cats, setCats] = useState(data);

    useEffect(() => {
        setCats(data);
    }, [data]);

    const catsContext = useArrayUpdateContext(cats, setCats);
    
    const [isDeleting, setDeleting] = useState(false);
    const [isDeleteError, setDeleteError] = useState(false);
    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [deleteID, setDeleteID] = useState(null);
    const [deleteName, setDeleteName] = useState(null);

    const close = () => {
        setDeleteOpen(false);
        setDeleteError(false);
    };

    const { handleSubmit, getFieldProps, touched, errors, isSubmitting, isValid, resetForm, setTouched } = useFormik({
        initialValues: {
            name: "",
        },
        validate: ({ name }) => {
            const errors = {};
            if (!name) {
                errors.name = "Required";
            }
            return errors;
        },
        onSubmit: async ({ name }, { resetForm }) => {
            setServerError(null);
            try {
                const newCat = await CategoriesService.createEventCategoryEventsEventIdCategoriesPost(eventID, {name});
                catsContext.upsert(newCat);
                resetForm();
                setTouched({}, false);
                navigate(`/event/${eventID}/settings/category`);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
    });

    return <OneColumnLayout title="Categories">
        <Helmet>
            <title>Categories</title>
        </Helmet>
        <Main>
            <SettingsPanel className="mb-3" icon={faList} title="Categories" hueFraction={1 / 4}>
                <div className="p-4">
                    {!cats && !error && <LoadingText messages={["Loading categories..."]} className="mx-4" />}
                    {error && <ErrorText className="mx-4">Couldn't load categories. Try reloading the page.</ErrorText>}
                    {cats && <ul className="list-none space-y-2">
                        <li className="p-0">
                            <form onSubmit={handleSubmit}>     
                                <Input type="text" value="Category Name..." placeholder="Category Name" className="mr-1" {...getFieldProps("name")}/>
                                <Button className="grow" size="icon" kind="success-text" type="submit" disabled={isSubmitting || !isValid}>+</Button>
                                <ErrorText>{touched.name && errors.name}</ErrorText>
                                {serverError && <ErrorText>Couldn't add the category. Try again?</ErrorText>}      
                            </form>
                        </li>
                        {cats.map(({ eventID, name, id }) => {
                            return <li className="p-0" key={eventID}>
                                <div className="px-2 py-1 rounded active:bg-blue-200 dark:active:bg-blue-800 block transition-colors">
                                    <span className="rounded px-4 py-2 mt-1 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors dark:bg-gray-900 opacity-50">{name}</span>
                                    <ExternalLinkButton size="icon" kind="destructive-text" href="#" onClick={() => {setDeleteOpen(true); setDeleteID(id); setDeleteName(name);}} className="text-center"><FontAwesomeIcon icon={faTimes} /></ExternalLinkButton>                        
                                </div>
                            </li>;
                        })}

                        <StyledDialog open={isDeleteOpen} onClose={() => {
                            if (!isDeleting) close();
                        }} borderClassName="border-red-500 dark:border-red-400">
                            <BigIcon className="bg-red-500 dark:bg-red-400 mx-auto mb-4 text-white" icon={faExclamationTriangle}></BigIcon>
                            <Dialog.Title className="heading mb-1 text-center">{`Delete ${deleteName}?`}</Dialog.Title>
                            <Dialog.Description as="div" className="mb-6 w-full text-center">
                                {isDeleting ? <LoadingText messages={[
                                    "Removing category...",
                                ]} /> : ("All projects from this category will be moved to a None category, and relevant feedback and scoring data will be deleted.")}
                            </Dialog.Description>
                            {isDeleteError && <ErrorText className="w-full text-center">Couldn't remove this category. Try again?</ErrorText>}
                            <div className="flex mt-4 w-full">
                                <Button className="grow" kind="secondary" onClick={() => close()} disabled={isDeleting}>Cancel</Button>
                                <div className="w-4"></div>
                                <Button className="grow" kind="destructive" onClick={async () => {
                                    setDeleting(true);
                                    setDeleteError(false);
                                    try {
                                        await CategoriesService.deleteCategoryCategoriesCategoryIdDelete(deleteID);
                                        close();
                                        navigate("..");
                                    } catch (e) {
                                        setDeleting(false);
                                        setDeleteError(true);
                                        console.error(e);
                                    }
                                }} disabled={isDeleting}>Remove</Button>
                            </div>
                        </StyledDialog>
                    </ul>}
                </div>
            </SettingsPanel>
        </Main>
    </OneColumnLayout>;
};

export const CategoryPageDefinition = {
    title: "Categories",
    content: <Category />,
    icon: faObjectGroup, // please change this if you have a better alternate
    route: "category",
    permission: permissions.manageCategories,
};
