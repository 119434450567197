/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SurveyType {
    PROJECT = 'project',
    REGISTRATION = 'registration',
    REVIEW = 'review',
    APPROVAL = 'approval',
}
