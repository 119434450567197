import { Button } from '../components/ui/Button';
import { useParams } from 'react-router-dom';
import { ErrorText } from '../components/ui/Input';
import { useState } from 'react';
import { StyledRouterLink } from '../components/ui/Link';
import Helmet from 'react-helmet';
import { LoadingText } from '../components/ui/Loading';
import { ColorBackgroundLayout } from '../components/ColorBackgroundLayout.tsx';
import { AccountService } from '../client/index.ts';

export function VerifyEmail() {
    const { token } = useParams();
    const [serverError, setServerError] = useState(null);
    const [status, setStatus] = useState("pending");

    return (
        <ColorBackgroundLayout>
            <Helmet>
                <title>Verify email</title>
            </Helmet>
            {status !== "success" && <>
                <div className="mb-4">Click the button to verify your email.</div>
                {serverError && <ErrorText className="mb-2">{serverError.body?.detail?.toString() ?? "An unknown error occurred."}</ErrorText>}
                {status === "pending" && <Button onClick={async () => {
                    setStatus("loading");
                    try {
                        await AccountService.emailVerifyAccountVerifyPost(token);
                        setStatus("success");
                    } catch (e) {
                        setServerError(e);
                        setStatus("pending");
                    }
                }} kind="primary">Verify Email</Button>}
                {status === "loading" && <LoadingText messages={["Verifying email..."]} />}
            </>}
            {status === "success" && <>
                <div className="mb-4 heading">Welcome to Expodite!</div>
                <div><StyledRouterLink to="/login">Log in</StyledRouterLink> and let's get started.</div>
            </>}
        </ColorBackgroundLayout>
    );
}
