/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProjectCreate } from '../models/ProjectCreate';
import type { ProjectReadWithUserType } from '../models/ProjectReadWithUserType';
import type { ProjectUpdate } from '../models/ProjectUpdate';
import type { UserProjectType } from '../models/UserProjectType';
import type { UserRead } from '../models/UserRead';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectsService {

    /**
     * List Projects
     * @param eventId
     * @returns ProjectReadWithUserType Successful Response
     * @throws ApiError
     */
    public static listProjectsEventsEventIdProjectsGet(
        eventId: string,
    ): CancelablePromise<Array<ProjectReadWithUserType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/projects',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Project
     * @param eventId
     * @param requestBody
     * @returns ProjectReadWithUserType Successful Response
     * @throws ApiError
     */
    public static createProjectEventsEventIdProjectsPost(
        eventId: string,
        requestBody: ProjectCreate,
    ): CancelablePromise<ProjectReadWithUserType> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/events/{event_id}/projects',
            path: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project
     * @param projectId
     * @returns ProjectReadWithUserType Successful Response
     * @throws ApiError
     */
    public static getProjectProjectsProjectIdGet(
        projectId: string,
    ): CancelablePromise<ProjectReadWithUserType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Project Details
     * @param projectId
     * @param requestBody
     * @returns ProjectReadWithUserType Successful Response
     * @throws ApiError
     */
    public static updateProjectDetailsProjectsProjectIdPut(
        projectId: string,
        requestBody: ProjectUpdate,
    ): CancelablePromise<ProjectReadWithUserType> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Project
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteProjectProjectsProjectIdDelete(
        projectId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Users
     * @param projectId
     * @param type
     * @returns UserRead Successful Response
     * @throws ApiError
     */
    public static getProjectUsersProjectsProjectIdUsersTypeGet(
        projectId: string,
        type: UserProjectType,
    ): CancelablePromise<Array<UserRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{project_id}/users/{type}',
            path: {
                'project_id': projectId,
                'type': type,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add User To Project
     * @param projectId
     * @param type
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addUserToProjectProjectsProjectIdUsersTypePost(
        projectId: string,
        type: UserProjectType,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects/{project_id}/users/{type}',
            path: {
                'project_id': projectId,
                'type': type,
            },
            query: {
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Accept Or Decline Invite
     * @param projectId
     * @param action
     * @returns any Successful Response
     * @throws ApiError
     */
    public static acceptOrDeclineInviteProjectsProjectIdInviteActionPut(
        projectId: string,
        action: 'accept' | 'decline',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/projects/{project_id}/invite/{action}',
            path: {
                'project_id': projectId,
                'action': action,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove User From Project
     * @param projectId
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeUserFromProjectProjectsProjectIdUsersUserIdDelete(
        projectId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{project_id}/users/{user_id}',
            path: {
                'project_id': projectId,
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
