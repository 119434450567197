import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, useFormikContext } from "formik";
import { Button } from "../ui/Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { SurveyQuestionDraggableEditor, SurveyQuestionEditor } from "./SurveyQuestionEditor";
import { questionTypes } from "./questionTypes";
import { useMemo, useState } from "react";
import { useSensors, useSensor, PointerSensor, KeyboardSensor, DndContext, closestCenter, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import equal from "fast-deep-equal";
import { useRequest } from "../../lib/API";
import { useEvent } from "../EventLayout";
import { CategoriesService, RolesService } from "../../client";

/**
 * @param {Question[]} questions 
 */
export function getInitialValues(questions) {
    const value = {order: questions.sort((a, b) => a.order - b.order).map(({id}) => id)};
    questions.forEach(({id, order: _, roles, categories, ...question}) => {
        value[id] = {roles: roles?.map(role => role.id) ?? [], categories: categories?.map(cat => cat.id) ?? [], ...question};
    });
    return value;
}

/**
 * 
 * @param {Question[]} oldQuestions 
 * @param {Record<string, Omit<Question, "id" | "order"> & {isNew?: true}> & {order: string[]}} questionValues 
 * @returns 
 */
export function compileChanges(oldQuestions, questionValues) {
    // Start by looking through the old questions.
    // A question is deleted if we find it in oldQuestions, but not in questionValues.
    // If a question isn't deleted, add it to our map.

    /** @type {({type: "delete", id: string} | {type: "update", id: string, question: Omit<Question, "id">} | {type: "create", question: Omit<Question, "id">})[]} */
    const changes = [];

    /** @type {Map<string, Question>} */
    const oldMap = new Map();

    oldQuestions.forEach(question => {
        if (questionValues[question.id]) {
            oldMap.set(question.id, {...question, id: undefined, role_ids: question.roles?.map(role => role.id), category_ids: question.categories?.map(cat => cat.id)});
        } else {
            changes.push({type: "delete", id: question.id});
        }
    });

    // Iterate through each of the new questions. Assign orders as we go.
    // Any questions marked isNew should be added.
    // Any questions not marked isNew should be updated if they have changed.

    let i = 0;
    for (const id of questionValues.order) {
        const question = {...questionValues[id], order: i, role_ids: questionValues[id].roles, category_ids: questionValues[id].categories};
        if (question.all_roles) delete question.role_ids;
        if (question.all_categories) delete question.category_ids;
        if (question.isNew) {
            delete question.isNew;
            changes.push({type: "create", question});
        } else {
            const oldQuestion = oldMap.get(id);
            if (!equal(oldQuestion, question)) {
                changes.push({type: "update", id, question});
            }
        }
        i++;
    }

    return changes;
}

/**
 * @param {Record<string, Omit<Question, "id" | "order"> & {isNew?: true}> & {order: string[]}} questionValues
 */
export function validateSurvey(questionValues) {
    const errors = {};
    questionValues.order.forEach(id => {
        const question = questionValues[id];
        const type = questionTypes.get(question.question_type);
        if (type) {
            const error = type.validateEditor(question);
            if (error && Object.keys(error).length > 0) {
                errors[id] = error;
            }
        } else {
            errors[id] = "Invalid question type.";
        }
    });
    if (Object.keys(errors).length > 0) return errors;
}

export function SurveyEditor({name = "questions", className, everyoneText, filterRoles, filterCats, supportsCategories = true}) {
    const { values, setValues } = useFormikContext();
    const questions = values[name];
    const defaultType = useMemo(() => {
        for (const [id, {defaultSettings: makeDefault}] of questionTypes) {
            return { question_type: id, details: makeDefault() };
        }
    }, []);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const [nextID, setNextID] = useState(0);
    const [activeID, setActiveID] = useState(null);

    const { id: eventID } = useEvent();
    const { data: roles, error: rolesError } = useRequest(() => RolesService.listEventRolesEventsEventIdRolesGet(eventID));
    const { data: cats, error: catsError } = useRequest(() => CategoriesService.listEventCategoriesEventsEventIdCategoriesGet(eventID));

    return <div className={className}>
        <FieldArray name={`${name}.order`}>
            {({ push, insert, move }) => {
                return <>
                    <div>
                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={event => {
                            setActiveID(event.active.id);
                        }} onDragEnd={event => {
                            const {active, over} = event;
                            if (active.id !== over.id) {
                                move(questions.order.indexOf(active.id), questions.order.indexOf(over.id));
                            }
                            setActiveID(null);
                        }} modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
                            <SortableContext items={questions.order} strategy={verticalListSortingStrategy}>
                                {questions.order.map((id, index) => {
                                    return <SurveyQuestionDraggableEditor id={id} key={id} name={name} onDuplicate={() => {
                                        const newValues = { ...values };
                                        newValues[name] = { ...newValues[name] };
                                        const newID = `new-question-${nextID}`;
                                        newValues[name][newID] = {
                                            ...questions[id],
                                            isNew: true,
                                        };
                                        setValues(newValues);
                                        insert(index + 1, newID);
                                        setNextID(nextID + 1);
                                    }} onRemove={() => {
                                        const newValues = { ...values };
                                        newValues[name] = { ...newValues[name] };
                                        newValues[name].order = newValues[name].order.filter(questionID => questionID !== id);
                                        delete newValues[name][id];
                                        setValues(newValues);
                                    }} isDragging={activeID === id} roles={roles} rolesError={rolesError} cats={cats} catsError={catsError} everyoneText={everyoneText} filterRoles={filterRoles} filterCats={filterCats} supportsCategories={supportsCategories} />;
                                })}
                                <DragOverlay>
                                    {activeID === null ? null : <SurveyQuestionEditor id={activeID} name={name} isOverlay={true} roles={roles} rolesError={rolesError} cats={cats} catsError={catsError} everyoneText={everyoneText} filterRoles={filterRoles} filterCats={filterCats} supportsCategories={supportsCategories} />}
                                </DragOverlay>
                            </SortableContext>
                        </DndContext>
                    </div>
                    <Button type="button" kind="primary" onClick={() => {
                        const newValues = {...values};
                        newValues[name] = {...newValues[name]};
                        const id = `new-question-${nextID}`;
                        newValues[name][id] = {
                            isNew: true,
                            name: `Question ${questions.order.length + 1}`,
                            description: "",
                            all_roles: true,
                            roles: [],
                            all_categories: true,
                            categories: [],
                            required: false,
                            ...defaultType,
                        };
                        setValues(newValues);
                        push(id);
                        setNextID(nextID + 1);
                    }}><FontAwesomeIcon className="mr-1" icon={faPlus} /> Add Question</Button>
                </>;
            }}
        </FieldArray>
    </div>;
}
