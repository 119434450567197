import { faExclamationTriangle, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { Fragment, useMemo } from 'react';
import { createContext, useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { ProjectsService } from "../../../client";
// import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { useEvent, usePermissionChecker } from "../../../components/EventLayout";
import { OneColumnLayout } from "../../../components/OneColumnLayout";
import { BigIcon } from "../../../components/ui/BigIcon";
import { Button, RouterLinkButton } from "../../../components/ui/Button";
import { StyledDialog } from "../../../components/ui/Dialog";
import { ErrorText } from "../../../components/ui/Input";
import { LoadingScreen, LoadingText } from "../../../components/ui/Loading";
import { Main } from "../../../components/ui/Main";
import { useRequest } from "../../../lib/API";
import { useUser } from "../../../lib/auth";
import { tabClass } from '../../../lib/utils';
import permissions from '../../../permissions.json';
import { ProjectsContext } from "./list";
import approvalsTabDefinition from './tabs/approvals';
import detailsTabDefinition from './tabs/details';
import reviewsTabDefinition from './tabs/reviews';

export const projectTabs = [
    detailsTabDefinition,
    approvalsTabDefinition,
    reviewsTabDefinition,
];

export const ProjectContext = createContext(null);

export function Project() {
    const { id } = useParams();
    const { id: eventID, settings } = useEvent();
    const proj_lock = settings.survey_locks.project;
    const check = usePermissionChecker();
    const currentUser = useUser();
    const navigate = useNavigate();
    const projectsContext = useContext(ProjectsContext);    

    const {data, error} = useRequest(() => ProjectsService.getProjectProjectsProjectIdGet(id), [eventID, id]);
    const [project, setProject] = useState(data);
    useEffect(() => {
        setProject(data);
    }, [data]);

    const cat = project?.category ?? null;

    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeleteError, setDeleteError] = useState(false);

    const members = useRequest(() => ProjectsService.getProjectUsersProjectsProjectIdUsersTypeGet(id, "member"), [id]);
    const names = members.data?.map(member => member.name).join(', ') ?? "";
    const myProject = members.data?.find(member => member.id === currentUser.id) !== undefined;

    const close = () => {
        setDeleteOpen(false);
        setDeleteError(false);
    };
    
    const canEdit = check(permissions.editAllProject) || (check(permissions.editOwnProject) && myProject);

    const contextValue = useMemo(() => ({
        project,
        members,
    }), [project, members]);

    return <OneColumnLayout
        title={project?.name ?? "Project"}
        secondaryContent={project && members && <div className="w-full overflow-x-auto">
            {projectTabs.map(tab => {
                const shouldShow = tab.showTab({
                    project,
                    checkPermission: check,
                    settings,
                });

                if (!shouldShow) return <Fragment key={tab.path} />;

                return <NavLink end={true} to={tab.path} key={tab.path} className={({ isActive }) => {
                    return tabClass("border-white dark:border-blue-400", "hover:border-blue-500/50 hover:text-blue-500 dark:hover:border-blue-400/50 dark:hover:text-blue-500")({
                        selected: isActive,
                    });
                }}>{tab.name}</NavLink>;
            })}
        </div>}
    >
        {!project && !error && <LoadingScreen messages={["Loading projects..."]} />}
        {project && <Main className="md:pt-0">
            <Helmet>
                <title>{project.name}</title>
            </Helmet>
            <div className="flex flex-row flex-wrap items-center mb-4 mt-2">
                <div className="flex-auto">
                    {members.data && <span className="italic">
                        {members.data.length === 0 ? "No users" : 
                            (members.data.length === 1 ? "Author: " + members.data[0].name : "Collaborators: " + names) }
                    </span>}
                    {members.error && <ErrorText>Couldn't load users for this project.</ErrorText>}
                    {members.loading && <LoadingText messages={["Loading users..."]} />}
                </div>
                <p className="flex-auto italic">{cat?.name ?? "No Category"}</p>

                <div>
                    {canEdit && !proj_lock && 
                    <RouterLinkButton disabled className="ml-1 mr-2" size="md" kind="secondary" to="edit">
                        <FontAwesomeIcon className="mr-1" icon={faPencil} />Edit
                    </RouterLinkButton>}
                    {proj_lock && <div className="ml-1 mr-2 italic">Editing disabled</div>}
                </div>
                {(check(permissions.deleteAllProject) || (check(permissions.deleteOwnProject) && myProject)) && <>
                    <hr className="mt-2 mb-4 border-gray-200 dark:border-gray-700" />
                    <div className="flex flex-row">
                        <Button kind="destructive" onClick={() => setDeleteOpen(true)}>{"Delete Project"}</Button>
                    </div>
                </>} 
            </div>

            {(project && members) && <>
                <ProjectContext.Provider value={contextValue}>
                    <Outlet />
                </ProjectContext.Provider>
            </>}
        </Main>}
        {error && <div className="w-full h-full min-h-screen flex justify-center items-center text-center">
            <ErrorText>Couldn't load this project. Try reloading the page.</ErrorText>
        </div>}
    
        <StyledDialog open={isDeleteOpen} onClose={() => {
            if (!isDeleting) close();
        }} borderClassName="border-red-500 dark:border-red-400">
            <BigIcon className="bg-red-500 dark:bg-red-400 mx-auto mb-4 text-white" icon={faExclamationTriangle}></BigIcon>
            <Dialog.Title className="heading mb-1 text-center">{`Delete "${project?.name ?? "Project"}"?`}</Dialog.Title>
            <Dialog.Description className="mb-6 w-full text-center">
                {isDeleting ? <LoadingText messages={[
                    "Removing project...",
                ]} /> : ("You will need to be re-invited. You can't undo this action.")}
            </Dialog.Description>
            {isDeleteError && <ErrorText className="w-full text-center">Couldn't delete this project. Try again?</ErrorText>}
            <div className="flex mt-4 w-full">
                <Button className="grow" kind="secondary" onClick={() => close()} disabled={isDeleting}>Cancel</Button>
                <div className="w-4"></div>
                <Button className="grow" kind="destructive" onClick={async () => {
                    setDeleting(true);
                    try {
                        await ProjectsService.deleteProjectProjectsProjectIdDelete(id);
                        navigate(`/event/${eventID}/projects`);
                        projectsContext.remove(id);
                        setDeleting(false);
                        close();
                    } catch (e) {
                        setDeleting(false);
                        setDeleteError(true);
                        console.error(e);
                    }
                }} disabled={isDeleting}>Delete</Button>
            </div>
        </StyledDialog>
    </OneColumnLayout>;
}
