/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserRead } from '../models/UserRead';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Root
     * @returns any Successful Response
     * @throws ApiError
     */
    public static rootGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

    /**
     * Me
     * @returns UserRead Successful Response
     * @throws ApiError
     */
    public static meMeGet(): CancelablePromise<UserRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Scheduler Login
     * @returns any Successful Response
     * @throws ApiError
     */
    public static schedulerLoginSchedulerLoginPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scheduler/login',
        });
    }

}
