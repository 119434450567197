import { uploadFile } from "./file.js";

/**
 * Submits a survey to the server.
 * @param {import('./API').APIContext} api 
 * @param {"put" | "post"} method
 * @param {string} url 
 * @param {any[]} questions
 * @param {Record<string, any>} questionValues 
 * @param {import('./file').FileCache} [fileCache]
 */
export async function submitSurvey(api, method, url, questions, questionValues, fileCache) {
    const body = {};
    for (const question of questions) {
        const value = questionValues[question.id];
        if (question.question_type === "file") {
            if (value instanceof File) {
                const file = await uploadFile(api, value);
                body[question.id] = file.id;
                if (fileCache) fileCache.addFile(file);
            } else {
                body[question.id] = value;
            }
        } else {
            body[question.id] = value;
        }
    }

    return await api[method](url, body);
}
