import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import { ProjectsService } from '../../../../client';
import { Button, RouterLinkButton } from "../../../../components/ui/Button";
import { PopoverPanel } from '../../../../components/ui/Popover';
import { useRequest } from '../../../../lib/API.js';
import { hueForID } from '../../../../lib/utils.js';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { AddButtonPopoverContent } from './AddButton';
import { useEffect } from 'react';

function ScheduleCellProject({ colorCode, colorType, project, displayAs, authors }) {
    const names = authors?.map(author => author?.name).join(', ');
    const hueID = (!colorType && project.category && project.category.id) ? project.category?.id : project.id;
    let displayText = "";
    if (displayAs === 'title') {
        displayText = project?.name ?? "Project name";
    }
    if (displayAs === 'author') {
        displayText = names ?? "Authors Unknown";
    }
    if (displayAs === 'category') {
        displayText = project?.category?.name ?? "Category Unknown";
    }

    return <div className={`px-2 py-1 w-full h-full flex items-center justify-center ${colorCode ? "bg-settings-accent text-white dark:text-black" : "bg-blue-500 text-white"}`} style={{ "--settings-accent-hue": hueForID(hueID) }}>
        {displayText}
    </div>;
}

function ScheduleCellDetails({ colorType, project, authors, setScheduleModel, scheduleModel, judgeID, timeslotID }) {
    const names = authors?.map(author => author?.name).join(', ');
    const hueID = (!colorType && project.category && project.category.id) ? project.category?.id : project.id;
    const url = `../projects/${project.id}`;

    return <>
        <div className="flex">
            <span className="block w-6 h-6 flex-shrink-0 rounded-full bg-settings-accent mr-3" style={{ "--settings-accent-hue": hueForID(hueID) }} />
            <div className="text-left">
                <h4 className="text-lg font-bold">{project?.name ? project?.name : "Project Name"}</h4>
                <div className="muted">{project?.category?.name ? project?.category?.name : "Category Unknown"}</div>
                {names && <div className="italic">By: {names}</div>}
            </div>
        </div>
        <div className="pt-4">
            <div className="flow-root">
                <span className="flex items-center">
                    <RouterLinkButton to={project.id ? url : "../projects"} kind="primary">View Project</RouterLinkButton>
                    <Button className="ml-5" kind="destructive" onClick={() => setScheduleModel(scheduleModel.unassigningProject(judgeID, timeslotID, project.id))}>Remove</Button>
                </span>
            </div>
        </div>
    </>;
}

export function ScheduleCellDetail({ colorType, project, authors, setScheduleModel, scheduleModel, judgeID, timeslotID }) {
    const names = authors?.map(author => author?.name).join(', ');
    const hueID = (!colorType && project.category && project.category.id) ? project.category?.id : project.id;
    const url = `../projects/${project.id}`;

    return <>
        <div className="flex">
            <span className="block w-6 h-6 flex-shrink-0 rounded-full bg-settings-accent mr-3" style={{ "--settings-accent-hue": hueForID(hueID) }} />
            <div className="text-left">
                <h4 className="text-lg font-bold">{project?.name ? project?.name : "Project Name"}</h4>
                <div className="muted">{project?.category?.name ? project?.category?.name : "Category Unknown"}</div>
                {names && <div className="italic">By: {names}</div>}
            </div>
        </div>
        <div className="pt-4">
            <div className="flow-root">
                <span className="flex items-center">
                    <RouterLinkButton to={project.id ? url : "../projects"} kind="primary">View Project</RouterLinkButton>
                    <Button className="ml-5" kind="destructive" onClick={() => setScheduleModel(scheduleModel.unassigningProject(judgeID, timeslotID, project.id))}>Remove</Button>
                </span>
            </div>
        </div>
    </>;
}

function CloseWatcher({ open, onClose }) {
    useEffect(() => {
        if (!open) onClose();
    }, [open, onClose]);
    return <></>;
}

export function ScheduleCell({ judgeID, timeslotID, scheduleModel, setScheduleModel, displayAs, colorCode, colorType, categories }) {
    const projects = scheduleModel.getProjectsAt(judgeID, timeslotID);
    const { data: authors } = useRequest(() => {
        return Promise.all(projects.map(project => {
            return ProjectsService.getProjectUsersProjectsProjectIdUsersTypeGet(project.id, "member");
        }));
    });

    const [isAdding, setAdding] = useState(false);

    return (
        <div className="w-full h-full">
            <Popover className="h-full" >
                {({ open }) => (
                    <>
                        <CloseWatcher open={open} onClose={() => setAdding(false)} />

                        <Popover.Button className={`w-full h-full`} size="unsized-unrounded" as={Button}>
                            {projects.length > 3 ? <div className="bg-blue-500 text-white w-full h-full flex items-center justify-center">
                                {projects.length} projects...
                            </div> : projects.map((project, index) => <ScheduleCellProject {...{colorCode, colorType, project, displayAs}} authors={authors?.[index]} key={project.id} />)}
                        </Popover.Button>

                        <PopoverPanel usePadding={false}>
                            {!isAdding && <>
                                {projects.map((project, index) => <div className="border-b dark:border-gray-600 p-4">
                                    <ScheduleCellDetails
                                        {...{ colorType, project, setScheduleModel, scheduleModel, judgeID, timeslotID }}
                                        authors={authors?.[index]}
                                        key={project.id} />
                                </div>)}
                                <Button className="py-2 px-4 w-full" size="unsized-unrounded" onClick={() => setAdding(true)}>
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Project...
                                </Button>
                            </>}
                            {isAdding && <>
                                <Button className="py-2 px-4 w-full" size="unsized-unrounded" onClick={() => setAdding(false)}>
                                    <FontAwesomeIcon icon={faTimes} className="mr-2" /> Cancel
                                </Button>
                                <div className="p-4 border-t dark:border-gray-600">
                                    <AddButtonPopoverContent {...{ judgeID, timeslotID, categories, colorType, scheduleModel, setScheduleModel }} />
                                </div>
                            </>}
                        </PopoverPanel>
                    </>
                )}
            </Popover>
        </div>
    );
}
