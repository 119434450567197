import { faBoxesPacking, faFileCirclePlus, faFile, faClose, faFileCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "../ui/Button";
import { StyledDialog } from "../ui/Dialog";
import { Dialog } from "@headlessui/react";
import { BigIcon } from "../ui/BigIcon";
import fileSize from "filesize";
import { getFileIcon } from "../../lib/utils";
import { useFileCache } from "../../lib/file";
import { LoadingText } from "../ui/Loading";
import { ErrorText } from "../ui/Input";

function FileDropzone({ getRootProps, getInputProps, isDragActive, id }) {
    return <Button id={id} size="unsized" {...getRootProps()} className={classNames(
        "border-2 border-dashed rounded-lg p-8 w-full max-w-sm flex flex-col items-center justify-center transition-colors text-center",
        isDragActive ? "bg-blue-500/50 border-blue-500" : "border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-800 active:bg-gray-200 dark:active:bg-gray-700",
    )}>
        <input {...getInputProps()} />
        <FontAwesomeIcon className={`muted text-4xl mb-4 transition-transform transform ${isDragActive ? "-translate-y-1" : ""}`} icon={faFileCirclePlus} />
        <h5 className="lead">{isDragActive ? "Drop your file here" : "Drag a file here"}</h5>
        <p className="muted">or click to select a file</p>
    </Button>;
}

export function FilePreview({file, id, onRemove}) {
    const fileCache = useFileCache();

    let name, icon, size;
    if (file instanceof File) {
        name = file.name ?? "File";
        icon = file.type ? getFileIcon(file.type) : faFile;
        size = file.size;
    } else {
        const fileResult = fileCache.getFile(file, true);
        if (!fileResult) {
            name = <LoadingText messages={["Loading file details..."]} />;
            icon = faFile;
        } else if (fileResult.error) {
            name = <ErrorText>Couldn't load file {file}. Try reloading the page.</ErrorText>;
            icon = faFileCircleXmark;
        } else {
            name = fileResult.file.name;
            icon = getFileIcon(fileResult.file.mime);
            size = fileResult.file.size;
        }
    }

    return <div className="flex items-center border rounded-lg border-gray-300 dark:border-gray-600 p-4 w-fit" id={id}>
        <FontAwesomeIcon icon={icon} className="mr-4 text-2xl dark:text-blue-400 text-blue-500" />
        <div>
            <h4 className="font-medium">{name}</h4>
            {typeof size === "number" && <div className="muted text-xs">{fileSize(size)}</div>}
        </div>
        <Button className="ml-2" size="icon" kind="destructive-text" onClick={onRemove}>
            <FontAwesomeIcon icon={faClose} />
        </Button>
    </div>;
}

export function FileUploader({name, id, value}) {
    const { setFieldValue } = useFormikContext();
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            if (acceptedFiles.length > 1) {
                setTooManyFilesOpen(true);
            }
            const file = acceptedFiles[0];
            setFieldValue(name, file);
        }
    }, [setFieldValue, name]);

    const dropzone = useDropzone({ onDrop });
    const [isTooManyFilesOpen, setTooManyFilesOpen] = useState(false);

    return <>
        {value ? <FilePreview file={value} id={id} onRemove={() => setFieldValue(name, undefined)} /> : <FileDropzone {...dropzone} id={id} />}
        <StyledDialog open={isTooManyFilesOpen} onClose={() => setTooManyFilesOpen(false)} borderClassName="border-blue-500 dark:border-blue-400">
            <BigIcon className="bg-blue-500 mx-auto mb-4 text-white" icon={faBoxesPacking}></BigIcon>
            <Dialog.Title className="heading mb-1 text-center">You added more than one file</Dialog.Title>
            <Dialog.Description className="mb-6 w-full text-center">Only the first file uploaded will be used.</Dialog.Description>
            <Button className="w-full" kind="primary" onClick={() => setTooManyFilesOpen(false)}>Alright then</Button>
        </StyledDialog>
    </>;
}
