/// <reference path="./typings.d.ts" />

import { createContext, useContext } from "react";

/**
 * @typedef LoadingAuthState
 * @property {"loading"} state
 */

/**
 * @typedef ErrorAuthState
 * @property {"error"} state
 * @property {Error | import("./API.js").APIError} error
 * @property {() => void} [retry]
 */

/**
 * @typedef UnauthenticatedAuthState
 * @property {"unauthenticated"} state
 */

/**
 * @typedef AuthenticatedAuthState
 * @property {"authenticated"} state
 * @property {import('../client').UserRead} user
 */

/**
 * @typedef {LoadingAuthState | ErrorAuthState | UnauthenticatedAuthState | AuthenticatedAuthState} AuthState
 */

/** @type {import("react").Context<AuthState>} */
export const AuthContext = createContext(/** @type {AuthState} */ ({state: "loading"}));

/**
 * @returns {AuthState}
 */
export function useAuth() {
    return useContext(AuthContext);
}

/**
 * DO NOT CALL THIS FUNCTION IF THE USER MAY BE UNAUTHENTICATED. If you're not sure, use `useAuth()` instead.
 * 
 * Returns the currently authenticated user, and throws if there is none.
 * 
 * @throws if a user is not authenticated
 * 
 * @returns {User}
 */
export function useUser() {
    const auth = useAuth();
    if (auth.state !== "authenticated") {
        throw new Error("useUser() called when auth state is not authenticated");
    }
    return auth.user;
}
