/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_password_login_auth_password_login_post } from '../models/Body_password_login_auth_password_login_post';
import type { Body_password_register_auth_password_register_post } from '../models/Body_password_register_auth_password_register_post';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public static logoutAuthLogoutGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/logout',
        });
    }

    /**
     * Oauth Login
     * @param provider
     * @param redirect
     * @returns void
     * @throws ApiError
     */
    public static oauthLoginAuthProviderGet(
        provider: string,
        redirect?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/{provider}',
            path: {
                'provider': provider,
            },
            query: {
                'redirect': redirect,
            },
            errors: {
                302: `Successful Response`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Oauth Callback
     * @param provider
     * @returns any Successful Response
     * @throws ApiError
     */
    public static oauthCallbackAuthProviderCallbackGet(
        provider: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/{provider}/callback',
            path: {
                'provider': provider,
            },
            errors: {
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Auth Providers
     * @returns string Successful Response
     * @throws ApiError
     */
    public static listAuthProvidersAuthInfoProvidersGet(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/info/providers',
        });
    }

    /**
     * Password Register
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static passwordRegisterAuthPasswordRegisterPost(
        requestBody: Body_password_register_auth_password_register_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Password Login
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static passwordLoginAuthPasswordLoginPost(
        requestBody: Body_password_login_auth_password_login_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Request Password Reset
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static requestPasswordResetAuthPasswordResetRequestPost(
        email: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password/reset/request',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Password Reset
     * @param token
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static passwordResetAuthPasswordResetPost(
        token: string,
        requestBody: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password/reset',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Password Change
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static passwordChangeAuthPasswordChangePost(
        requestBody: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/password/change',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

}
