import { Button } from '../components/ui/Button';
import { useParams } from 'react-router-dom';
import { ErrorText, Input } from '../components/ui/Input';
import { useState } from 'react';
import { StyledRouterLink } from '../components/ui/Link';
import Helmet from 'react-helmet';
import { FormikProvider, useFormik } from "formik";
import { ColorBackgroundLayout } from '../components/ColorBackgroundLayout.tsx';
import { AuthService } from '../client/index.ts';

export function ResetWelcome() {
    const { id: token } = useParams();
    const [serverError, setServerError] = useState(null);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    
    const formik = useFormik({
        initialValues: {
            password: "",
        },
        validate: ({ password }) => {
            const errors = {};
            if (!password) {
                errors.password = "Required";
            }
            return errors;
        },
        onSubmit: async ({ password }) => {
            setServerError(null);
            try {
                await AuthService.passwordResetAuthPasswordResetPost(token, { password });
                setSubmitAttempted(true);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
    });
    const { handleSubmit, getFieldProps, touched, errors } = formik;

    return (
        <FormikProvider value={formik}>
            <Helmet>
                <title>Password Reset</title>
            </Helmet>

            <form onSubmit={handleSubmit}>
                <ColorBackgroundLayout>
                    <h1 className="title text-center">Reset Password</h1>
                    <div className="mt-3 mb-5 text-center">Enter a new password, then click <strong>Set New Password</strong>.</div>
                    <div>
                        <Input className="w-full max-w-lg mt-2" id="password" type="password" {...getFieldProps("password")} placeholder="New Password" error={touched.password && errors.password} />
                        <ErrorText>{errors.password}</ErrorText>
                    </div>
                    <Button className="mt-5" size="md" type="submit" kind="primary" onClick={() => setSubmitAttempted(true)} disabled={submitAttempted || !formik.isValid || formik.isSubmitting}>{submitAttempted? "Reset Complete" : "Set New Password"}</Button>
                    {submitAttempted && <div className="mt-2">Your password has been reset. Go to <StyledRouterLink to="/login">login</StyledRouterLink>.</div>}
                    {serverError && <ErrorText className="mt-2">{serverError.body?.detail?.toString() ?? "An unknown error occurred."}</ErrorText>}
                </ColorBackgroundLayout>
            </form>
        </FormikProvider>
    );
}
