import classNames from "classnames";
import { useContext } from "react";
import { TwoColumnLayoutContext } from "./TwoColumnLayout";
import { BackButton, BackContext } from "./BackButton";

/**
 * @param {import("react").PropsWithChildren<{title?: import("react").ReactNode}>} props 
 * @returns a one column page
 */
export function OneColumnLayout({children, title, className, actions, secondaryContent}) {
    const twoColumnLayoutContext = useContext(TwoColumnLayoutContext);

    return <div className={className}>
        <div className={classNames(
            "sticky top-0 z-10 flex align-top bg-gray-100 dark:bg-gray-800 pl-3 pr-2 w-full border-b border-gray-300 dark:border-gray-700",
        )}>
            <div className="flex items-center h-11">
                <BackButton size="sm" className="mr-1" />
            </div>
            <div className="grow ml-1">
                <div className="flex align-baseline min-h-[2.5rem]">
                    <h1 className={classNames(
                        "font-display text-lg font-semibold grow mt-2",
                        twoColumnLayoutContext ? "md:text-xl" : "lg:text-xl",
                    )}>{title}</h1>
                    {actions && <div className="ml-1 mt-[0.375rem]">{actions}</div>}
                </div>
                {secondaryContent && <div className="mb-2">{secondaryContent}</div>}
            </div>
        </div>
        {children}
    </div>;
}
