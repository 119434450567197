import { Switch } from "@headlessui/react";
import React from "react";
import classNames from "classnames";

export function StyledSwitch({checked, className, enabledClassName = "bg-blue-500", ...props}) {
    {/* @ts-ignore */}
    return <Switch className={classNames(
        "align-middle inline-flex flex-shrink-0 h-6 w-10 border-2 items-center border-black/10 dark:border-white/20 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-600/80 dark:focus-visible:ring-white/80",
        checked ? enabledClassName : "dark:bg-gray-700 bg-gray-200",
        className,
    )} checked={checked} {...props}>
        <span className={`${checked ? "translate-x-4" : "translate-x-0"} rounded-full bg-white shadow-lg ring-0 transition-transform ease-in-out duration-200 pointer-events-none inline-block h-5 w-5`} />
    </Switch>;
}

export function SwitchWithLabel({action, item, ...props}) {
    return <Switch.Group>
        <div className="flex items-start mb-2 last-of-type:mb-0">
            {/* @ts-ignore */}
            <StyledSwitch {...props} />
            <Switch.Label className="ml-2">
                <div>Allow this user to <b>{action}</b> {item} in this event.</div>
            </Switch.Label>
        </div>
    </Switch.Group>;
}
