import { faAdd, faUserShield, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { createContext, useEffect, useState, useMemo, Fragment } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useEvent, usePermissionChecker } from "../../../components/EventLayout.tsx";
import { MasterDetailLayout } from "../../../components/MasterDetailLayout";
import { StyledDialog } from "../../../components/ui/Dialog";
import { ErrorText, Input } from "../../../components/ui/Input";
import { LoadingText } from "../../../components/ui/Loading";
import { useAPI, useRequest } from "../../../lib/API.js";
import { GeneralPageDefinition } from './general';
import { ReviewCriteriaPageDefinition } from './review';
import { useFormik } from "formik";
import { Button } from "../../../components/ui/Button";
import { useArrayUpdateContext } from "../../../lib/utils.js";
import { QuestionnairePageDefinition } from "./questionnaire";
import { ProjectDetailsPageDefinition } from "./projectdetails";
import { CategoryPageDefinition } from "./category";
import { SchedulePageDefinition } from "./scheduling";
import Helmet from "react-helmet";
import permissions from "../../../permissions.json";
import { RolesService } from "../../../client";
import { OneColumnLayout } from "../../../components/OneColumnLayout";

export const pages = [
    GeneralPageDefinition,
    CategoryPageDefinition,
    SchedulePageDefinition,
    ProjectDetailsPageDefinition,
    ReviewCriteriaPageDefinition,
    QuestionnairePageDefinition,
];

/** @type {import('react').Context<import("../../../lib/API.js").RequestResult<Role[]> | null>} */
export const SettingsRolesContext = createContext(null);

function AddRoleDialog({ isAddingRole, onCancel, onAdd, nextOrder }) {
    const navigate = useNavigate();
    const { id: eventID } = useEvent();
    const api = useAPI();
    const [serverError, setServerError] = useState(null);
    const { handleSubmit, getFieldProps, touched, errors, isSubmitting, isValid, resetForm, setTouched } = useFormik({
        initialValues: {
            name: "",
        },
        validate: ({ name }) => {
            const errors = {};
            if (!name) {
                errors.name = "Required";
            }
            return errors;
        },
        onSubmit: async ({ name }, { resetForm }) => {
            setServerError(null);
            try {
                const newRole = await RolesService.createEventRoleEventsEventIdRolesPost(eventID, {
                    name,
                    admin: false,
                    permissions: [],
                    order: nextOrder,
                });
                onAdd(newRole);
                resetForm();
                setTouched({}, false);
                navigate(`/event/${eventID}/settings/roles/${newRole.id}`);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
    });

    return <StyledDialog open={isAddingRole} onClose={() => {
        if (!isSubmitting) {
            onCancel();
            resetForm();
            setTouched({}, false);
        }
    }}>
        <form onSubmit={handleSubmit}>
            <Dialog.Title className="heading">Add role</Dialog.Title>
            <Dialog.Description>Give your role a name.</Dialog.Description>
            <div className="mt-4 rounded border border-gray-300 dark:border-gray-600">
                <Input placeholder="Role Name" {...getFieldProps("name")} />
            </div>
            <ErrorText>{touched.name && errors.name}</ErrorText>
            {serverError && <ErrorText>Couldn't add the role. Try again?</ErrorText>}
            <div className="flex mt-4 w-full">
                <Button type="button" className="grow" kind="secondary" onClick={() => {
                    onCancel();
                    resetForm();
                    setTouched({}, false);
                }} disabled={isSubmitting}>Cancel</Button>
                <div className="w-4"></div>
                <Button className="grow" kind="primary" type="submit" disabled={isSubmitting || !isValid}>Add role</Button>
            </div>
        </form>
    </StyledDialog>;
}

export function Settings() {
    const { id } = useEvent();
    const check = usePermissionChecker();
    const { data, error } = useRequest(() => /** @type {Promise<Role[]>} */ RolesService.listEventRolesEventsEventIdRolesGet(id));
    const [roles, setRoles] = useState(data);

    useEffect(() => {
        setRoles(data);
    }, [data]);

    const [isAddingRole, setAddingRole] = useState(false);

    const nextOrder = useMemo(() => roles?.reduce((acc, cur) => Math.max(acc, cur.order), 0) + 1, [roles]);
    
    const rolesContext = useArrayUpdateContext(roles, setRoles);

    return <SettingsRolesContext.Provider value={rolesContext}>
        <Helmet>
            <title>Settings</title>
        </Helmet>
        <MasterDetailLayout>
            <OneColumnLayout title={<span className="group">Settin<span className="inline-block group-hover:translate-y-[0.5em] transition-transform duration-400 ease-out">g</span>s</span>}>
                <ul className="mx-2 list-none space-y-1 mt-2">
                    {pages.map(({ title, icon, route, permission }) => <Fragment key={route}>
                        {check(permission) && <li className="p-0" key={route}>
                            <NavLink to={route} className={({ isActive }) => `px-2 py-1 rounded ${isActive ? "bg-blue-500 text-white" : "hover:bg-blue-100 active:bg-blue-200 dark:hover:bg-blue-900 dark:active:bg-blue-800"} block transition-colors`}>
                                <FontAwesomeIcon className="w-4 mr-1" icon={icon} />
                                <span className="subsubheading">{title}</span>
                            </NavLink>
                        </li>}
                    </Fragment>)}
                </ul>
                {check(permissions.manageRoles) && <>
                    <h2 className="mx-4 mt-6 mb-1 over muted">Users &amp; Access</h2>
                    {!roles && !error && <LoadingText messages={["Loading roles..."]} className="mx-4" />}
                    {error && <ErrorText className="mx-4">Couldn't load roles. Try reloading the page.</ErrorText>}
                    {roles && <ul className="mx-2 mb-4 list-none space-y-1">
                        {roles.sort((a, b) => a.order - b.order).map(({ id, name, admin }) => {
                            const icon = admin ? faUserShield : faUser;
                            return <li className="p-0" key={id}>
                                <NavLink to={`roles/${id}`} className={({ isActive }) => `px-2 py-1 rounded ${isActive ? "bg-blue-500 text-white" : "hover:bg-blue-100 active:bg-blue-200 dark:hover:bg-blue-900 dark:active:bg-blue-800"} block transition-colors`}>
                                    <FontAwesomeIcon className="w-4 mr-1" icon={icon} />
                                    <span className="subsubheading">{name}</span>
                                </NavLink>
                            </li>;
                        })}
                        <li className="p-0">
                            <button className="w-full text-left px-2 py-1 rounded hover:bg-blue-100 active:bg-blue-200 dark:hover:bg-blue-900 dark:active:bg-blue-800 block transition-colors" onClick={() => setAddingRole(true)}>
                                <span className="opacity-60">
                                    <FontAwesomeIcon className="w-4 mr-1" icon={faAdd} />
                                    <span className="subsubheading italic">Add role...</span>
                                </span>
                            </button>
                        </li>
                    </ul>}
                </>}
            </OneColumnLayout>
        </MasterDetailLayout>
        <AddRoleDialog isAddingRole={isAddingRole} onCancel={() => setAddingRole(false)} onAdd={role => {
            setAddingRole(false);
            rolesContext.upsert(role);
        }} nextOrder={nextOrder} />
    </SettingsRolesContext.Provider>;
}
