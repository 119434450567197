import React, { CSSProperties, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { Login } from './pages/Login.js';
import { Create } from './pages/Create.js';
import { Forgot } from './pages/Forgot';
import { Logout } from './pages/Logout';
import { Account } from './pages/Account.js';
import { Events } from './pages/Events.js';
import { NewEventForm } from './pages/NewEventForm.js';
import { NotFound } from './pages/404.js';
import { EventHome } from './pages/event/home.js';
import { User } from './pages/event/user/view';
import { SelectUser, Users } from './pages/event/user/list';
import { NewUserForm } from './pages/event/user/invite';
import { InvitedWelcome } from './pages/invited_welcome';
import { ResetWelcome } from './pages/reset_welcome';
import { Project, projectTabs } from './pages/event/project/view';
import { SelectProject, Projects } from './pages/event/project/list';
import { NewProjectForm } from './pages/event/project/new';
import { ProjectEditor } from './pages/event/project/edit';
import { Scheduling } from './pages/event/scheduling/index.jsx';
import { SelectCategory, Scoring } from './pages/event/scoring.js';
import { Export } from './pages/event/export.js';
import { EventLayout } from './components/EventLayout';
import { Test } from './pages/internal/TestPage';
import { FileUploaderTest } from './pages/internal/FileUploaderTest';
import { AuthContext, AuthState } from './lib/auth';
import { RequiresAuth } from './components/RequiresAuth';
import { useState } from 'react';
import { useRequest } from './lib/API';
import { Settings, pages as settingsPages } from './pages/event/settings/index';
import { Role } from './pages/event/settings/role';
import { ErrorBoundary } from './components/ErrorBoundary';
import { FileCacheProvider } from './lib/file';
import Helmet from 'react-helmet';
import { VerifyEmail } from './pages/verifyemail';
import { DefaultService, OpenAPI } from './client';
import { MenuDuJour } from './pages/menu';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

OpenAPI.BASE = process.env.REACT_APP_API_URL || "https://api.expodite.app";
OpenAPI.WITH_CREDENTIALS = true;

axios.defaults.baseURL = OpenAPI.BASE;
axios.defaults.withCredentials = true;

const queryClient = new QueryClient();

function ExpoditeApp() {
    const [auth, setAuth] = useState<AuthState>({state: "loading"});
    useRequest(async () => {
        if (auth.state === "loading") {
            try {
                const user = await DefaultService.meMeGet();
                setAuth(auth => auth.state === "loading" ? { state: "authenticated", user } : auth);
            } catch (e) {
                if (e.status === 401) {
                    setAuth(auth => auth.state === "loading" ? { state: "unauthenticated" } : auth);
                } else {
                    setAuth(auth => auth.state === "loading" ? { state: "error", error: e, retry: () => setAuth({ state: "loading" }) } : auth);
                }
            }
        }
    }, [auth]);

    return <InnerHeightMeasuring>
        <QueryClientProvider client={queryClient}>
            <AuthContext.Provider value={auth}>
                <Helmet
                    titleTemplate="%s | Expodite"
                    defaultTitle="Expodite" />
                <FileCacheProvider>
                    <Router>
                        <Routes>
                            <Route index element={<RequiresAuth><Navigate to="/events" /></RequiresAuth>} />

                            <Route path="/test" element={<Test />} />
                            <Route path="/test/fileuploader" element={<FileUploaderTest />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Create />} />
                            <Route path="/forgot" element={<Forgot />} />
                            <Route path="/logout" element={<Logout onLogout={() => setAuth({ state: "unauthenticated" })} />} />
                            <Route path="/account" element={<RequiresAuth><Account onUpdateUser={user => setAuth({ state: "authenticated", user })} /></RequiresAuth>} />
                            <Route path="/events" element={<RequiresAuth><Events /></RequiresAuth>} />
                            <Route path="/newevent" element={<RequiresAuth><NewEventForm /></RequiresAuth>} />
                            <Route path="/invite/:id" element={<InvitedWelcome />} />
                            <Route path="/reset-password/:id" element={<ResetWelcome />} />
                            <Route path="/verify-email/:token" element={<VerifyEmail />} />

                            <Route path="/event/:eventID" element={<EventLayout />}>
                                <Route index element={<EventHome />} />
                                <Route path="users" element={<Users />}>
                                    <Route index element={<SelectUser />} />
                                    <Route path="new" element={<NewUserForm />} />
                                    <Route path=":id" element={<User />} />
                                </Route>
                                <Route path="projects" element={<Projects />} >
                                    <Route index element={<SelectProject />} />
                                    <Route path="new" element={<NewProjectForm />} />
                                    <Route path=":id">
                                        <Route path="edit" element={<ProjectEditor />} />
                                        <Route element={<Project />}>
                                            {projectTabs.map(({ component: Component, path }) => {
                                                const element = <Component />;
                                                if (path === ".") {
                                                    return <Route key="" index element={element} />;
                                                } else {
                                                    return <Route key={path} path={path} element={element} />;
                                                }
                                            })}
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path="scoring" element={<Scoring />}>
                                    <Route index element={<SelectCategory />} />
                                </Route>

                                <Route path="settings" element={<Settings />}>
                                    {settingsPages.map(({ content, route }) => <Route key={route} path={route} element={content} />)}
                                    <Route path="roles/:roleID" element={<Role />} />
                                </Route>

                                <Route path="scheduling" element={<Scheduling />} />
                                <Route path="export" element={<Export />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>

                            <Route path="menu" element={<MenuDuJour />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Router>
                </FileCacheProvider>
            </AuthContext.Provider>
        </QueryClientProvider>
    </InnerHeightMeasuring>;
}

function InnerHeightMeasuring({ children }: PropsWithChildren<{}>) {
    const [height, setHeight] = useState(window.innerHeight);
    
    useEffect(() => {
        const listener = () => setHeight(window.innerHeight);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, []);

    return <div style={{"--window-inner-height": `${height}px`} as CSSProperties}>
        {children}
    </div>;
}

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary fillScreen>
            <ExpoditeApp />
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
