import { useEvent } from "../EventLayout";
import { useAPI, useRequest } from "../../lib/API";
import { useMemo, useState, useEffect } from "react";
import { validateSurvey, getInitialValues, compileChanges, SurveyEditor } from "./SurveyEditor";
import { useFormik, FormikProvider } from "formik";
import { SaveChanges } from "../SaveChanges";
import { ErrorText } from "../ui/Input";
import { LoadingText } from "../ui/Loading";
import { OneColumnLayout } from "../OneColumnLayout";
import { Main } from "../ui/Main";

function SurveyEditorFormContent({ questions, setQuestions, type, supportsCategories }) {
    const [isSaved, setSaved] = useState(false);
    const [serverError, setServerError] = useState(null);
    const api = useAPI();
    const { id } = useEvent();

    const initialQuestions = useMemo(() => getInitialValues(questions), [questions]);

    const formik = useFormik({
        initialValues: {
            questions: /** @type {Parameters<validateSurvey>[0]} */ (initialQuestions),
        },
        validate: values => {
            const questionErrors = validateSurvey(values.questions);
            if (questionErrors) return { questions: questionErrors };
            return {};
        },
        onSubmit: async (values) => {
            setServerError(null);
            try {
                setQuestions(await api.put(`/events/${id}/surveys/${type}/questions`, compileChanges(questions, values.questions)));
                setSaved(true);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
        onReset: () => {
            setServerError(null);
        },
        enableReinitialize: true,
    });

    return <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
            <SurveyEditor className="mb-4" everyoneText="All roles" supportsCategories={supportsCategories} />
            <SaveChanges serverError={serverError} isSaved={isSaved} />
        </form>
    </FormikProvider>;
}

export function SurveyEditorForm({title, description, surveyType, supportsCategories}) {
    const { id } = useEvent();
    const { data, error } = useRequest(api => api.get(`/events/${id}/surveys/${surveyType}/questions`, { list_all: true }));
    const [questions, setQuestions] = useState(null);

    useEffect(() => {
        setQuestions(data);
    }, [data]);

    return <OneColumnLayout title={title}>
        <Main>
            <div className="mb-4">{description}</div>
            {!questions && !error && <LoadingText />}
            {error && <ErrorText>Couldn't load questions. Try reloading the page.</ErrorText>}
            {questions && <SurveyEditorFormContent supportsCategories={supportsCategories} questions={questions} setQuestions={setQuestions} type={surveyType} />}
        </Main>
    </OneColumnLayout>;
}
