import { faBug, faCheckCircle, faCircleNotch, faExclamationCircle, faInfoCircle, faWarning, faExclamationTriangle, faUserShield} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition, Dialog } from "@headlessui/react";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { LoadingText } from "../components/ui/Loading";
import { Button } from "../components/ui/Button";
import { useContext } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import { StyledDialog } from "./ui/Dialog";
import { BigIcon } from "./ui/BigIcon";
import classNames from "classnames";

const statuses = {
    saved: {
        icon: <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 dark:text-green-400" />,
        borderClass: "border-green-500 dark:border-green-400",
        text: "Saved.",
    },
    save: {
        icon: <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500 dark:text-blue-400" />,
        borderClass: "border-blue-500 dark:border-blue-400",
        text: "You have unsaved changes.",
    },
    saving: {
        icon: <FontAwesomeIcon icon={faCircleNotch} className="text-blue-500 dark:text-blue-400" spin />,
        borderClass: "border-blue-500 dark:border-blue-400",
        text: <LoadingText messages={["Saving...", "Talking with the server...", "Give us a bit..."]} />,
    },
    validationError: {
        icon: <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 dark:text-red-400" />,
        borderClass: "border-red-500 dark:border-red-400",
        text: "Please fix errors before saving.",
    },
    serverError: {
        icon: <FontAwesomeIcon icon={faBug} className="text-red-500 dark:text-red-400" />,
        borderClass: "border-red-500 dark:border-red-400",
        text: "We couldn't save your changes. Try again?",
    },
    permsError: {
        icon: <FontAwesomeIcon icon={faUserShield} className="text-red-500 dark:text-red-400" />,
        borderClass: "border-red-500 dark:border-red-400",
        text: "You do not have permissions to edit this page.",
    },
    //TODO 
    // timeslotError: {
    //     icon: <FontAwesomeIcon icon={faCalendarXmark} className="text-red-500 dark:text-red-400" />,
    //     borderClass: "border-red-500 dark:border-red-400",
    //     text: "Attempting deletion of filled timeslot.",
    // }
};

export function SaveChanges({ serverError, isSaved, disabled, isChanged: isChangedMode = "dirty", fixed}) {
    const formik = useFormikContext();
    const {dirty, isValid, isSubmitting, resetForm, submitForm} = formik;
    const [isDiscarding, setDiscarding] = useState(false);
    const isChanged = (isChangedMode === "dirty") ? dirty : isChangedMode;
    
    /** @type {keyof statuses} */
    let status;
    if (isSubmitting) {
        status = "saving";
    } else if (isChanged && !isValid) {
        status = "validationError";
    } else if (serverError && serverError.status === 403) {
        status = "permsError";
    } else if (serverError) {
        status = "serverError";
    } else if (isSaved && !isChanged) {
        status = "saved";
    } else {
        status = "save";
    }

    const showBar = !!(isSubmitting || serverError || isChanged || isSaved);

    useEffect(() => {
        if (status === "saving" || !isChanged) setDiscarding(false);
    }, [status, isChanged]);

    const { navigator } = useContext(UNSAFE_NavigationContext);
    const [retryTransition, setRetryTransition] = useState(null);
    useEffect(() => {
        if (!isChanged) return;
        const unblock = navigator.block(tx => {
            setRetryTransition(() => () => {
                unblock();
                tx.retry();
                setRetryTransition(null);
            });
        });
        return unblock;
    }, [navigator, isChanged]);

    useEffect(() => {
        if (status === "saved" && retryTransition) retryTransition();
    }, [status, retryTransition]);

    return <>
        <Transition show={showBar}
            className={classNames(
                "overflow-hidden bottom-4 md:h-16 bg-white/90 dark:bg-gray-800/90 backdrop-blur shadow-xl rounded-lg z-20",
                fixed ? "fixed lg:left-4 lg:ml-14 left-4 right-4" : "sticky w-full",
            )}
            enter="transition-all duration-200 ease-in-out transform"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-200 ease-out transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
        >
            <div className={`transition-colors flex flex-col md:flex-row md:items-center h-full w-full border-t-8 px-4 py-3 md:py-0 ${isDiscarding ? "border-yellow-500 dark:border-yellow-400" : statuses[status].borderClass}`}>
                <div className="flex items-center flex-grow mb-2 md:mb-0">
                    {isDiscarding ?
                        <>
                            <FontAwesomeIcon icon={faWarning} className="text-yellow-500 dark:text-yellow-400" />
                            <span className="mx-3 flex-grow font-bold">Discard changes?</span>
                        </> :
                        <>
                            {statuses[status].icon}
                            <span className="mx-3 flex-grow">{statuses[status].text}</span>
                        </>}
                </div>
                <div className="flex items-center">
                    {isDiscarding ?
                        <>
                            <Button className="whitespace-nowrap basis-1/2" type="button" kind="secondary" onClick={_ => {
                                setDiscarding(false);
                            }}>Cancel</Button>
                            <Button type="button" kind="warning" className="ml-2 whitespace-nowrap basis-1/2" onClick={e => {
                                e.preventDefault();
                                resetForm();
                            }}>Discard</Button>
                        </> :
                        <>
                            <Button className="whitespace-nowrap basis-1/2" type="button" kind="secondary" onClick={_ => {
                                setDiscarding(true);
                            }} disabled={status === "saving" || status === "saved" || disabled}>Discard</Button>
                            <Button kind="primary" className="ml-2 whitespace-nowrap basis-1/2" type="submit" onClick={e => {
                                e.preventDefault();
                                submitForm();
                            }} disabled={status === "saving" || status === "saved" || status === "validationError"}>Save changes</Button>
                        </>}
                </div>
            </div>
        </Transition>
        <StyledDialog open={!!retryTransition} onClose={() => setRetryTransition(null)} borderClassName="border-yellow-500 dark:border-yellow-400">
            <BigIcon className="bg-yellow-500 dark:bg-yellow-400 mx-auto mb-4 text-black" icon={faExclamationTriangle}></BigIcon>
            <Dialog.Title className="heading mb-1 text-center">Discard changes?</Dialog.Title>
            <Dialog.Description className="mb-6 w-full text-center">Your unsaved changes will be lost if you leave this page.</Dialog.Description>
            <div className="flex mt-4 w-full">
                <Button className="grow basis-1/2" kind="secondary" onClick={() => setRetryTransition(null)}>Cancel</Button>
                <div className="w-4"></div>
                <Button className="grow basis-1/2" kind="warning" onClick={retryTransition}>Discard</Button>
            </div>
        </StyledDialog>
    </>;
}
