// @ts-check

import { TwoColumnLayout, TwoColumnLayoutContext } from './TwoColumnLayout.jsx';
import { useContext, useEffect } from 'react';
import { Outlet, useLocation, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { BackContext } from './BackButton';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ErrorBoundary } from './ErrorBoundary';

function RoutingHandler() {
    const { pathname: currentPathname } = useLocation();
    const match = useMatch(currentPathname);
    const { pathname: basePathname } = useResolvedPath("");
    const twoColumnLayoutContext = useContext(TwoColumnLayoutContext);

    useEffect(() => {
        if (match.pathnameBase === basePathname) {
            twoColumnLayoutContext.setCurrent("primary");
        } else {
            twoColumnLayoutContext.setCurrent("secondary");
        }
    }, [basePathname, twoColumnLayoutContext, match.pathnameBase]);

    return <></>;
}

export function MasterDetailLayout({children}) {
    const navigate = useNavigate();
    const {pathname} = useLocation();

    return <TwoColumnLayout>
        <RoutingHandler />
        <TwoColumnLayout.Primary>
            {children}
        </TwoColumnLayout.Primary>
        <TwoColumnLayout.Secondary>
            <BackContext.Provider value={{icon: faArrowLeft, label: "Back", className: "md:hidden", action() {
                navigate("");
            }}}>
                <ErrorBoundary pathname={pathname} showBack>
                    <Outlet />
                </ErrorBoundary>
            </BackContext.Provider>
        </TwoColumnLayout.Secondary>
    </TwoColumnLayout>;
}
