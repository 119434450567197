import { ErrorText } from "../ui/Input";
import { questionTypes } from "./questionTypes";

/**
 * @param {{question: Question, name: string, value: any, error: any, disabled?: boolean}} props
 */
export function SurveyQuestion({ question, name, value, error, disabled }) {
    const type = questionTypes.get(question.question_type);
    const id = question.id;

    return <div className="mb-4">
        <label htmlFor={id} className="block mb-2">
            <h3 className="subheading">{question.name}{question.required && <span className="text-red-500"> *</span>}</h3>
            <div className="muted leading-tight whitespace-pre-wrap">{question.description}</div>
        </label>
        {type && <type.Field question={question} name={name} id={id} value={value} disabled={disabled} />}
        <ErrorText className="mt-1">{error}</ErrorText>
    </div>;
}
