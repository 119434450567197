import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { createContext, useContext, useState } from "react";
import { BackContext } from "./BackButton";

/** @type {import('react').Context<{current: "primary" | "secondary", setCurrent: (current: "primary" | "secondary") => void}>} */
export const TwoColumnLayoutContext = createContext();

export const TwoColumnLayout = ({ children }) => {
    const [current, setCurrent] = useState("primary");

    return <div className="flex w-full items-start overflow-hidden md:overflow-visible">
        <TwoColumnLayoutContext.Provider value={{ current, setCurrent }}>
            {children}
        </TwoColumnLayoutContext.Provider>
    </div>;
};

TwoColumnLayout.Primary = ({ children }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { current } = useContext(TwoColumnLayoutContext);
    return <div className={`bg-white dark:bg-gray-900 md:border-r border-gray-200 dark:border-gray-700 w-full h-window overflow-auto shrink-0 md:w-72 transform ${current === "primary" ? "translate-0" : "-translate-x-full"} md:transform-none transition-transform`}>
        {children}
    </div>;
};

/**
 * @param {import('react').PropsWithChildren<{visibleOnMount?: boolean}} props 
 * @returns a secondary pane
 */
TwoColumnLayout.Secondary = ({ children }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { current, setCurrent } = useContext(TwoColumnLayoutContext);

    return <div className={`bg-white dark:bg-gray-900 w-full h-window overflow-auto shrink-0 md:shrink md:grow ${current === "secondary" ? "-translate-x-full" : "translate-0"} md:transform-none transition-transform`}>
        <BackContext.Provider value={{icon: faArrowLeft, label: "Back", className: "md:hidden", action() { setCurrent("primary"); }}}>
            {children}
        </BackContext.Provider>
    </div>;
};
