/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum QuestionType {
    NUMBER = 'number',
    RANGE = 'range',
    TEXT = 'text',
    TEXTAREA = 'textarea',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    SELECT = 'select',
    BOOLEAN = 'boolean',
    FILE = 'file',
    CATEGORIES = 'categories',
    TIMESLOTS = 'timeslots',
    URL = 'url',
}
