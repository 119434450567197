import permissions from '../../permissions.json';

export const id = "researchExpo";
export const name = "Research Expo";
export const description = "Assign judges to projects based on time constraints.";
export const checkedClassName = "text-white bg-gradient-to-r from-pink-600 to-red-600";

/**
 * @param {import('./templates').TemplateContext} ctx
 */
export async function create(ctx) {
    const { id: judgeRole } = await ctx.createRole({
        name: "Judge",
        admin: false,
        permissions: [],
        order: 2,
    });

    const { id: approvalRole } = await ctx.createRole({
        name: "Approval Manager",
        admin: false,
        permissions: [
            permissions.viewAllProject,
            permissions.viewAllApproval,
            permissions.createAllApproval,
        ],
        order: 3,
    });

    await ctx.createRole({
        name: "Participant",
        admin: false,
        permissions: [
            permissions.createProject,
            permissions.editOwnProject,
            permissions.editUsersOwnProject,
            permissions.viewProjectApproval,
        ],
        order: 4,
    });

    const projectQuestions = await ctx.createSurvey("project", [
        {
            name: "Type",
            question_type: "radio",
            description: "",
            required: true,
            details: {
                options: [
                    "Local",
                    "ISEF",
                ],
            },
            all_roles: true,
            all_categories: true,
        },
        {
            name: "Abstract",
            question_type: "textarea",
            description: "",
            required: true,
            details: {},
            all_roles: true,
            all_categories: true,
        },
        {
            name: "Presentation/Paper",
            question_type: "file",
            description: "",
            required: true,
            details: {},
            all_roles: true,
            all_categories: true,
        },
    ]);

    const registrationQuestions = await ctx.createSurvey("registration", [
        {
            name: "Agreement and Availability",
            question_type: "radio",
            description: "By selecting Yes you confirm your ability and agreement to judge the event on the following day(s).",
            required: true,
            details: {
                options: [
                    "Yes",
                    "No",
                ],
            },
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Phone Number",
            question_type: "text",
            description: "Enter your preferred contact phone number:",
            required: true,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Education: Level",
            question_type: "radio",
            description: "Please select your highest academic degree in a relevant science, technical or engineering field.",
            required: true,
            details: {
                options: [
                    "Highschool graduate",
                    "Current college student",
                    "College graduate",
                    "Currently in gradutate or professional school",
                    "Masters or equivalent degree",
                    "M.D. or equivalent professional degree",
                    "PhD or equivalent degree",
                ],
            },
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Education: Details",
            question_type: "text",
            description: "Elaborate with class year, degree, and institution name relevant to your previous response.",
            required: false,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Area of Expertise",
            question_type: "text",
            description: "List your current or primary area of expertise.",
            required: true,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Job: Last Title",
            question_type: "text",
            description: "Indicate your current job title or enter Retired and last position title, if applicable.",
            required: true,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Job: Years of Experience",
            question_type: "number",
            description: "Years of proffessoinal experience in current/last position",
            required: true,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Job: Additional information",
            question_type: "textarea",
            description: "You can indicate additional relevant professional or other experience below.",
            required: false,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Judging Experience",
            question_type: "radio",
            description: "Have you acted as a judge or presenter at previous science fairs?",
            required: true,
            details: {
                options: [
                    "Previously participated in this fair",
                    "Previously participated in other fairs",
                    "Judged this and a few other science fairs",
                    "Judged few science fairs",
                    "Judged many science fairs",
                    "Judged this and many other science fairs",
                ],
            },
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Affiliation: Type",
            question_type: "checkbox",
            description: "This question is used to determine potential conflicts of interest in this fair.",
            required: true,
            details: {
                options: [
                    "Faculty",
                    "Current student",
                    "Alum",
                    "Parent of current student",
                    "Parent of alum",
                    "Mentor",
                    "Friend of participant",
                    "Friend of school",
                ],
            },
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Affiliation: Details",
            question_type: "textarea",
            description: "If you selected any of the options for the above question, provide details regarding the relationship (names, years).",
            required: false,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Categories",
            question_type: "categories",
            description: "Select the categories you're qualified to judge.",
            required: true,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Availability",
            question_type: "timeslots",
            description: "Select the timeslots you're available to judge.",
            required: true,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
        {
            name: "Additional comments and information",
            question_type: "textarea",
            description: "If you did not get to share some information you consider important to event organizers above, please do so here.",
            required: false,
            details: {},
            all_roles: false,
            all_categories: true,
            role_ids: [judgeRole],
        },
    ]);

    await ctx.updateSettings({
        auto_schedule: {
            minimum_judges_local: 1,
            minimum_judges_isef: 1,
            minimum_head_judges: 0,
            isef_question: projectQuestions[0].id || null,
            isef_response: "ISEF",
            categories_question: registrationQuestions[0].id || null,
            timeslots_question: registrationQuestions[1].id || null,
        },
        approvals: {
            enabled: true,
            required_roles: {
                [approvalRole]: {
                    approved: 1,
                    total: 0,
                },
            },
        },
        checklist: "expo",
    });

    await ctx.createSurvey("review", [
        {
            name: "Rating",
            question_type: "range",
            description: "",
            required: true,
            details: {min: 1, max: 5},
            all_roles: true,
            all_categories: true,
        },
        {
            name: "Other Comments",
            question_type: "textarea",
            description: "",
            required: false,
            details: {},
            all_roles: true,
            all_categories: true,
        },
    ]);
}
