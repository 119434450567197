/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleCreate } from '../models/RoleCreate';
import type { RoleRead } from '../models/RoleRead';
import type { RoleUpdate } from '../models/RoleUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolesService {

    /**
     * List Event Roles
     * @param eventId
     * @returns RoleRead Successful Response
     * @throws ApiError
     */
    public static listEventRolesEventsEventIdRolesGet(
        eventId: string,
    ): CancelablePromise<Array<RoleRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/roles',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event Role
     * @param eventId
     * @param requestBody
     * @returns RoleRead Successful Response
     * @throws ApiError
     */
    public static createEventRoleEventsEventIdRolesPost(
        eventId: string,
        requestBody: RoleCreate,
    ): CancelablePromise<RoleRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/events/{event_id}/roles',
            path: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Role
     * @param roleId
     * @returns RoleRead Successful Response
     * @throws ApiError
     */
    public static getRoleRolesRoleIdGet(
        roleId: string,
    ): CancelablePromise<RoleRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event Role
     * @param roleId
     * @param requestBody
     * @returns RoleRead Successful Response
     * @throws ApiError
     */
    public static updateEventRoleRolesRoleIdPut(
        roleId: string,
        requestBody: RoleUpdate,
    ): CancelablePromise<RoleRead> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Event Role
     * @param roleId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteEventRoleRolesRoleIdDelete(
        roleId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/roles/{role_id}',
            path: {
                'role_id': roleId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
