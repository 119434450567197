/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SurveyQuestionUpdate } from './SurveyQuestionUpdate';

export type Update = {
    type: Update.type;
    id: string;
    question: SurveyQuestionUpdate;
};

export namespace Update {

    export enum type {
        UPDATE = 'update',
    }


}

