import { Main } from '../../../components/ui/Main';
import { faCircleCheck, faFilter, faPlus, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';
import { BackButton } from '../../../components/BackButton';
import { MasterDetailLayout } from '../../../components/MasterDetailLayout';
import { useLocation, useMatch } from "react-router-dom";
import { useAPI, useRequest } from "../../../lib/API.js";
import { LoadingText } from "../../../components/ui/Loading";
import { ErrorText } from "../../../components/ui/Input";
import { createContext, Fragment, useEffect, useMemo, useState } from "react";
import { Button, RouterLinkButton } from "../../../components/ui/Button";
import { useEvent, usePermissionChecker } from "../../../components/EventLayout.tsx";
import { useArrayUpdateContext, tabClass } from "../../../lib/utils.js";
import permissions from "../../../permissions.json";
import { DateTime } from 'luxon';
import Helmet from 'react-helmet';
import { EventsService, ProjectsService, ScheduleService } from '../../../client';
import { OneColumnLayout } from 'src/components/OneColumnLayout';

export const ProjectsContext = createContext(null);

function ProjectInList({project, timeslotMap, scheduleMap, approvalsEnabled}){
    //checking if approved based on roles and numbers satisfied
    const {data: approvalCounts} = useRequest(api => api.get(`/project/${project.id}/approvals/count`));
    let approvalsNeeded = 0;
    let approvalsReceived = 0;
    for (var key in approvalCounts) {
        approvalsNeeded += approvalCounts[key].total;
        approvalsReceived += approvalCounts[key].approved;
    }

    const approved = approvalsNeeded === approvalsReceived || approvalsNeeded < approvalsReceived;
    const pending = (approvalsNeeded/2) < approvalsReceived;
    const approvalFraction = approvalsReceived + "/" + approvalsNeeded;
    const approvalColor = pending ? "yellow" : "red";
    const approvalText = pending ? "Approval Pending" : (approved ? "Approved" : "Approval Declined"); // this should become visible on hover

    return <NavLink to={project.id} className={({ isActive }) => `p-1 rounded ${isActive ? "bg-blue-500 text-white" : "hover:bg-blue-100 active:bg-blue-200 dark:hover:bg-blue-900 dark:active:bg-blue-800"} block transition-colors`}>
        <div className="flex items-center justify-between">
            <h2 className="subsubheading font-bold">{project.name}</h2>
            {approvalsEnabled && approved && <FontAwesomeIcon icon={faCircleCheck} className={`text-green-500 dark:text-green-400 w-4`} />}
            {approvalsEnabled && !approved && <div className={`rounded text-${approvalColor}-500 dark:text-${approvalColor}-400 w-4 mr-2`}>{approvalFraction}</div>}
        </div>
        {scheduleMap[project.id] && <p className="opacity-80 text-sm">
            {timeslotMap[scheduleMap[project.id]]?.string ?? "Assigned"}
        </p>}
        {/* we can make optional views that display the user and the project cat ID, where applicable
        <p className="italic">SOME USER</p> */}
        {/* <div className="flex flex-row justify-between">
            <p className="italic">CAT ID</p>
        </div> */}
    </NavLink>;
    //can be another sort filter thing
}

export function Projects() {
    const { pathname: currentPathname } = useLocation();
    const match = useMatch(currentPathname);
    const { id , settings } = useEvent();
    const proj_lock = settings.survey_locks.project;
    const check = usePermissionChecker();

    /** @type {import("../../../lib/API.js").RequestResult<Projects[]>} */
    const { data, loading, error } = useRequest(() => ProjectsService.listProjectsEventsEventIdProjectsGet(id));
    const { data: scheduleData} = useRequest(() => ScheduleService.getEventScheduleEventsEventIdScheduleGet(id));
    const { data: timeslotData} = useRequest(() => ScheduleService.listEventTimeslotsEventsEventIdScheduleTimeslotsGet(id));
    const { data: eventData } = useRequest(() => EventsService.getEventEventsEventIdGet(id));

    const scheduleMap = useMemo(() => {
        if (!scheduleData) return {};
        return scheduleData.reduce((acc, cur) => {
            acc[cur.project_id] = cur.timeslot_id;
            return acc;
        }, {});
    }, [scheduleData]);
    const timeslotMap = useMemo(() => {
        if (!timeslotData) return {};
        return timeslotData.reduce((acc, cur) => {
            const date = DateTime.fromISO(cur.start);
            acc[cur.id] = {
                date,
                string: date.toLocaleString({
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                }),
            };
            return acc;
        }, {});
    }, [timeslotData]);

    const [projects, setProjects] = useState(data);
    const [filterType, setFilterType] = useState(check(permissions.viewAllProject) ? "all" : "owned"); // owned = assigned?
    const [sortType, setSortType] = useState("cat");

    useEffect(() => {
        if (data) setProjects(data);
    }, [data]);

    //Group projects by category ID.
    const groups = useMemo(() => {
        const groups = {};
        if (projects) {
            const filteredProjects = projects.filter(project => {
                if (filterType === "all") return true;
                if (filterType === "owned" && project.user_type === "member") return true;
                if (filterType === "assigned" && scheduleMap[project.id]) return true;
                return false;
            });
            
            if (sortType === "alpha") {
                filteredProjects.forEach(project => {
                    const group = project.name.length > 0 ? project.name[0].toUpperCase() : "";
                    if (!groups[group]) groups[group] = [];
                    groups[group].push(project);
                });

                return Object.entries(groups).map(([name, projects]) => {
                    return {
                        title: name,
                        id: name,
                        projects: projects.sort((a, b) => a.name.localeCompare(b.name, undefined, {
                            sensitivity: "base",
                            numeric: true,
                        })),
                    };
                }).sort((a, b) => a.title.localeCompare(b.title));
            } else if (sortType === "cat") {
                filteredProjects.forEach(project => {
                    const cat = project.category === "none" ? null : project.category;
                    if (cat != null) {
                        const catID = cat.id;
                        if (!groups[catID]) {
                            groups[catID] = [];
                        }
                        groups[catID].push(project);
                    }
                    else{
                        if (!groups.none) {
                            groups.none = [];
                        }
                        groups.none.push(project);
                    }
                });

                return Object.entries(groups).map(([catID, projects]) => {
                    return {
                        title: catID === "none" ? null : projects[0].category?.name,
                        id: catID,
                        projects: projects.sort((a, b) => {
                            return a.name.localeCompare(b.name, undefined, {
                                sensitivity: "base",
                                numeric: true,
                            });
                        }),
                    };
                }).sort((a, b) => {
                    if (a.title === null) return -1;
                    if (b.title === null) return 1;
                    return a.title.localeCompare(b.title, undefined, {
                        sensitivity: "base",
                        numeric: true,
                    });
                });
            } else if (sortType === "time") {
                filteredProjects.forEach(project => {
                    const group = (scheduleMap && scheduleMap[project.id]) ?? "none";
                    if (!groups[group]) groups[group] = [];
                    groups[group].push(project);
                });

                return Object.entries(groups).map(([timeslotID, projects]) => {
                    const timeslot = timeslotMap && timeslotMap[timeslotID];
                    return {
                        title: timeslot?.string ?? (timeslotMap && scheduleMap ? "Not Assigned" : undefined),
                        date: timeslot?.date,
                        id: timeslotID,
                        projects: projects.sort((a, b) => {
                            return a.name.localeCompare(b.name, undefined, {
                                sensitivity: "base",
                                numeric: true,
                            });
                        }),
                    };
                }).sort((a, b) => {
                    if (!a.date) return 1;
                    if (!b.date) return -1;
                    return a.date - b.date;
                });
            }
        }
    }, [projects, filterType, sortType, scheduleMap, timeslotMap]);

    const context = useArrayUpdateContext(projects, setProjects);

    return <ProjectsContext.Provider value={context}>
        <Helmet>
            <title>Projects</title>
        </Helmet>
        <MasterDetailLayout>
            <OneColumnLayout title="Projects" actions={(proj_lock || (!(check(permissions.createProject)) && (match.pathnameBase.endsWith("/new"))) ?
                <Button size="md" kind="primary" className="mr-1" disabled>
                    <FontAwesomeIcon icon={faPlus} />
                </Button> :
                <RouterLinkButton size="md" to="new" kind="primary" className="mr-1">
                    <FontAwesomeIcon icon={faPlus} />
                </RouterLinkButton>)}
            secondaryContent={<div className="w-full overflow-x-auto">
                <a href="#" onClick={e => {
                    setFilterType("assigned");
                    e.preventDefault();
                }} className={tabClass("inline border-white dark:border-blue-400", "hover:border-blue-500/50 hover:text-blue-500 dark:hover:border-blue-400/50 dark:hover:text-blue-500")({
                    selected: filterType === "assigned",
                })}>Assigned</a>
                <a href="#" onClick={e => {
                    setFilterType("yours");
                    e.preventDefault();
                }} className={tabClass("inline border-white dark:border-blue-400", "hover:border-blue-500/50 hover:text-blue-500 dark:hover:border-blue-400/50 dark:hover:text-blue-500")({
                    selected: filterType === "yours",
                })}>Yours</a>
                {check(permissions.viewAllProject) && <a href="#" onClick={e => {
                    setFilterType("all");
                    e.preventDefault();
                }} className={tabClass("inline border-white dark:border-blue-400", "hover:border-blue-500/50 hover:text-blue-500 dark:hover:border-blue-400/50 dark:hover:text-blue-500")({
                    selected: filterType === "all",
                })}>All</a>}
            </div>}
            >
                <div className="px-2 my-2 border-b border-gray-300 dark:border-gray-700">
                    <div className="flex items-center my-2">
                        <label htmlFor="projects-sort" className="block pr-2"><FontAwesomeIcon icon={faSort} className="text-blue-500 dark:text-blue-400 w-4" /> Sort:</label>
                        <select id="projects-sort" className="block flex-grow text-sm rounded bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-900 dark:hover:bg-gray-700 px-3 py-1" value={sortType} onChange={e => setSortType(e.target.value)}>
                            <option value="alpha">Name</option>
                            <option value="cat">Category</option>
                            <option value="time">Time</option>
                        </select>
                    </div>
                </div>
                <div className="mx-2 my-2">
                    {loading && <LoadingText className="text-center py-8 px-4" messages={["Loading projects..."]} />}
                    {error && <ErrorText className="text-center py-8 px-4">Couldn't load projects. Try reloading the page.</ErrorText>}
                    {projects && <ul className="list-none space-y-1">
                        {groups.map((({ title, id, projects }) => <Fragment key={id ?? "none"}>
                            <li className="over font-medium rounded bg-gray-100 dark:bg-gray-800 px-1 sticky top-20 z-10">{title}</li>
                            {projects.map(project => {
                                return <li className="p-0" key={project.id}>
                                    <ProjectInList project={project} timeslotMap={timeslotMap} scheduleMap={scheduleMap} approvalsEnabled={eventData?.settings?.approvals?.enabled} />
                                </li>;
                            })}
                        </Fragment>))}
                    </ul>}
                </div>
            </OneColumnLayout>
        </MasterDetailLayout>
    </ProjectsContext.Provider>;
}

export function SelectProject() {
    return <Main className="flex justify-center items-center h-full">
        <p>Select a project to see the details.</p>
    </Main>;
}
