/** @type {import('@fortawesome/fontawesome-svg-core').IconDefinition} */
export const expoditeXIcon = {
    prefix: "fae",
    iconName: "expodite-x",
    icon: [
        47.27,
        64.90,
        [],
        "",
        (
            "M33.25,22.42l-12.67,17.09h-.32l-11.01,14.91H3.3l13.95-18.37h.38l9.6-13.63h6.02Zm-22.78,0l9.54,13.38,.32,.45,13.12,18.18h-6.27l-9.92-13.89-.58-.7L4.07,22.42h6.4Z" +
            "M1.2,59.71c-.43-.11-.9-.23-1.05-.38-1.43-1.33,8-13.17,8.97-14.39,2.88-3.62,7.4-9.31,13.15-16.6,6.16-8.16,13.37-17.14,14.87-16.21,.32,.2,.29,.79,.26,1.15-.64,6.18-9.66,16.97-14.11,23.23,0,0-15.35,21.64-18.13,22.91-.73,.33-1.47,.45-1.47,.45-.19,.03-1.2,.17-2.49-.17Z" +
            "M25.1,9.03c-.13-1.49,2.87-2.51,14.44-7.1,4.37-1.73,6.07-2.43,7.16-1.55,.55,.44,.85,1.14,.17,7.85-.55,5.41-.78,6.91-2.13,8.03-.89,.75-2.32,1.36-3.03,.87-.46-.32-.49-1.04-.03-5.12,.36-3.24,.64-5.06-.21-5.57-.21-.12-.42-.13-.57-.13-3.16,.05-9.93,5.86-13.85,4.52-.76-.26-1.87-.93-1.95-1.8Z"
        ),
    ],
};
