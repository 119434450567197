/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserProjectType {
    MEMBER = 'member',
    INVITED = 'invited',
    JUDGE = 'judge',
}
