import { faCalendarAlt, faClipboardCheck, faGear } from "@fortawesome/free-solid-svg-icons";
import { Switch } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { getGetEventEventsEventIdGetQueryKey, updateEventEventsEventIdPut } from 'src/codegen';
import { RolesService } from '../../../client';
import { useEvent } from '../../../components/EventLayout.tsx';
import { OneColumnLayout } from '../../../components/OneColumnLayout';
import { SaveChanges } from '../../../components/SaveChanges';
import { SettingsPanel } from '../../../components/SettingsPanel';
import { ErrorText, Input } from '../../../components/ui/Input';
import { LoadingText } from '../../../components/ui/Loading';
import { Main } from '../../../components/ui/Main';
import { StyledSwitch } from '../../../components/ui/Switch';
import { useRequest } from '../../../lib/API.js';

export function General() {
    const { name, id: eventID, settings: { survey_locks: surveySettings, approvals, ...otherSettings } } = useEvent();
    const queryClient = useQueryClient();
    const [serverError, setServerError] = useState(false);
    const [isSaved, setSaved] = useState(false);

    const [lockedProjects, setLP] = useState(surveySettings.project);
    const [lockedRegistrations, setLReg] = useState(surveySettings.registration);
    const [lockedReviews, setLRev] = useState(surveySettings.review);

    const rolesRequest = useRequest(() => RolesService.listEventRolesEventsEventIdRolesGet(eventID), [eventID]);

    const formik = useFormik({
        initialValues: {
            name: name,
            lockedProj: lockedProjects,
            lockedReg: lockedRegistrations,
            lockedRev: lockedReviews,
            approvals: {
                enabled: approvals?.enabled ?? false,
                required_roles: approvals?.required_roles ?? {},
            },
        },
        validate: ({ name }) => {
            const errors = {};
            if (!name) {
                errors.name = "Required";
            }

            return errors;
        },
        onSubmit: async (values) => {
            setServerError(null);
            try {
                await updateEventEventsEventIdPut(eventID, {
                    name: values.name,
                    settings: {
                        survey_locks: {
                            project: lockedProjects,
                            registration: lockedRegistrations,
                            review: lockedReviews,
                        },
                        approvals: values.approvals,
                        ...otherSettings,
                    },
                });
                await queryClient.invalidateQueries(getGetEventEventsEventIdGetQueryKey(eventID));
                setSaved(true);
            } catch (e) {
                setServerError(e);
                console.error(e);
            }
        },
        onReset: () => {
            setServerError(null);
        },
        enableReinitialize: true,
    });
    const { getFieldProps, touched, errors, handleSubmit, values, setFieldValue } = formik;

    useEffect(() => {
        setSaved(false);
    }, [eventID, lockedProjects, lockedRegistrations, lockedReviews]);
    
    return <OneColumnLayout title="General">
        <Helmet>
            <title>General Settings</title>
        </Helmet>
        <Main>
            <FormikProvider value={formik}>
                <form onSubmit={handleSubmit}>
                    <SettingsPanel className="mb-3" icon={faCalendarAlt} title="Event Details" hueFraction={0 / 4}>
                        <div className="p-4">
                            <label className="block" htmlFor="id">Event Name</label>
                            <Input {...getFieldProps("name")} type="text" placeholder="Event Name" className="block" id="name" error={touched.name && errors.name} />
                            <ErrorText>{errors.name}</ErrorText>
                        </div>
                    </SettingsPanel>

                    <SettingsPanel className="mb-3" icon={faClipboardCheck} title="Approvals" hueFraction={1 / 4}>
                        <div className="p-4">
                            <Switch.Group>
                                <div className="flex items-start mb-2 last-of-type:mb-0">
                                    <StyledSwitch checked={values.approvals.enabled} onChange={value => {
                                        setFieldValue("approvals.enabled", value);
                                    }} />
                                    <Switch.Label className="ml-2">
                                        <div>Enable approvals</div>
                                        <div className="muted italic">Let designated reviewers approve and comment on projects before the event.</div>
                                    </Switch.Label>
                                </div>
                            </Switch.Group>

                            {values.approvals.enabled && <div className="mt-4">
                                <div className="font-bold mb-2">Require a certain number of approvals from these roles:</div>
                                {rolesRequest.data && [...rolesRequest.data].sort((a, b) => (a.order ?? 0) - (b.order ?? 0)).map(role => <div className="flex last:mb-0" key={role.id}>
                                    <input className="rounded mt-1" id={`approvals-${role.id}`} type="checkbox" checked={!!values.approvals.required_roles[role.id]} onChange={e => {
                                        if (e.target.checked) {
                                            setFieldValue(`approvals.required_roles.${role.id}`, {
                                                total: 0,
                                                approved: 1,
                                            });
                                        } else {
                                            const newRoles = { ...values.approvals.required_roles };
                                            delete newRoles[role.id];
                                            setFieldValue("approvals.required_roles", newRoles);
                                        }
                                    }} />
                                    <div className="flex-grow ml-2">
                                        <label htmlFor={`approvals-${role.id}`}>
                                            {role.name}
                                        </label>
                                        {values.approvals.required_roles[role.id] && <div>
                                            <div className="flex items-center mb-1">
                                                <Input {...getFieldProps(`approvals.required_roles.${role.id}.approved`)} type="number" className="w-32" id={`approvals-${role.id}-approved`} />
                                                <label className="ml-2" htmlFor={`approvals-${role.id}-approved`}>approved</label>
                                            </div>
                                            <div className="flex items-center mb-1">
                                                <Input {...getFieldProps(`approvals.required_roles.${role.id}.total`)} type="number" className="w-32" id={`approvals-${role.id}-total`} />
                                                <label className="ml-2" htmlFor={`approvals-${role.id}-total`}>total</label>
                                            </div>
                                        </div>}
                                    </div>
                                </div>)}
                                {rolesRequest.loading && <LoadingText messages={["Loading roles..."]} />}
                                {rolesRequest.error && <ErrorText>Couldn't load roles. Try reloading the page.</ErrorText>}
                            </div>}

                            {/* <label className="block" htmlFor="review-date">Add Review Timepoints</label>
                            <div className="flex flex-row">
                                <Input name="review-date" type="date" className="mr-1" />
                                <ExternalLinkButton size="icon" kind="success-text" href="#" onClick={event => { event.preventDefault(); alert("Not implemented yet!"); }} >+</ExternalLinkButton>
                            </div>

                            <div className="flex flex-row">
                                <ExternalLinkButton size="icon" kind="destructive-text" href="#" onClick={event => { event.preventDefault(); alert("Not implemented yet!"); }} className="text-center"><FontAwesomeIcon icon={faTimes} /></ExternalLinkButton>
                                <span className="rounded px-4 py-2 mt-1 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors dark:bg-gray-900 opacity-50">01/01/22</span>
                                </div> */}
                        </div>
                    </SettingsPanel>
                    
                    <SaveChanges serverError={serverError} isSaved={isSaved} />
                </form>
            </FormikProvider>
        </Main>
    </OneColumnLayout>;
}

export const GeneralPageDefinition = {
    title: "General",
    content: <General />,
    icon: faGear,
    route: "general",
};
