import { useContext } from "react";
import { SurveysService } from "../../../../client";
import { usePermissionChecker } from "../../../../components/EventLayout";
import { useRequest } from "../../../../lib/API";
import { useUser } from "../../../../lib/auth";
import { ProjectContext } from "../view";
import permissions from '../../../../permissions.json';
// import { SurveyViewer } from "../../../../components/surveys/SurveyViewer";
import { LoadingText } from "../../../../components/ui/Loading";
import { ErrorText, Textarea } from "../../../../components/ui/Input";
import { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { StyledExternalLink } from "../../../../components/ui/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMarkdown } from "@fortawesome/free-brands-svg-icons";
import { faCheck, faExclamationTriangle, faExternalLink, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../../components/ui/Button";
import { BigIcon } from "../../../../components/ui/BigIcon";
import classNames from "classnames";
import MarkdownText from "../../../../components/ui/MarkdownText";
import { StyledDialog } from "../../../../components/ui/Dialog";
import { Dialog } from "@headlessui/react";

function ApprovalForm({ initialData = { approved: undefined, comments: "" }, onCancel, onSubmit }) {
    const [serverError, setServerError] = useState(null);
    const [tryingToCancel, setTryingToCancel] = useState(false);

    return <Formik initialValues={{
        ...initialData,
        approved: initialData.approved === undefined ? null : (initialData.approved ? "true" : "false"),
    }} validate={({ approved }) => {
        let errors = {};
        if (approved === undefined) {
            errors.approved = "Required";
        }
        return errors;
    }} onSubmit={async values => {
        try {
            await onSubmit({
                ...values,
                approved: values.approved === "true",
            });
        } catch (e) {
            setServerError(e);
        }
    }} enableReinitialize={true}>
        {({ handleSubmit, isValid, isSubmitting, errors, dirty }) => {
            return <form onSubmit={handleSubmit}>
                <div className="mt-2 flex">
                    <Field type="radio" name="approved" value="true" id="approve" className="mt-1" />
                    <label className="ml-2" htmlFor="approve">
                        <div className="font-bold">Approve this project</div>
                        <div className="muted">Signal to judges and administrators that this project is ready for judging.</div>
                    </label>
                </div>
                <div className="mt-1 flex">
                    <Field type="radio" name="approved" value="false" id="disapprove" className="mt-1" />
                    <label className="ml-2" htmlFor="disapprove">
                        <div className="font-bold">Disapprove this project</div>
                        <div className="muted">Request changes to be made before judging.</div>
                    </label>
                </div>
                <ErrorText>{errors.approved}</ErrorText>

                <label htmlFor="comments" className="mt-4 block">
                    <div className="font-bold">Comments</div>
                    <div className="muted">Leave an optional comment for your approval or disapproval.</div>
                </label>
                <Field className={`w-full h-48 max-w-lg ${onCancel ? "border dark:border-gray-700" : ""}`} as={Textarea} name="comments" id="comments" placeholder="Type something..." />
                <div className="w-full max-w-lg text-right">
                    <StyledExternalLink kind="muted" href="https://commonmark.org/help/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faMarkdown} className="mr-1" />Markdown supported<FontAwesomeIcon icon={faExternalLink} className="ml-1" />
                    </StyledExternalLink>
                </div>

                <ErrorText>{serverError && "An error occurred."}</ErrorText>

                {!tryingToCancel && <div className="mt-2 space-x-2 flex w-full">
                    {onCancel && <Button className="flex-basis-1/2 flex-grow" type="button" kind="secondary" onClick={() => {
                        if (dirty) {
                            setTryingToCancel(true);
                        } else {
                            onCancel();
                        }
                    }} disabled={isSubmitting}>Cancel</Button>}
                    <Button className="flex-basis-1/2 flex-grow" type="submit" kind="primary" disabled={!isValid || isSubmitting}>Submit</Button>
                </div>}
                {tryingToCancel && <div className="mt-2 space-x-2 flex w-full">
                    <Button className="flex-basis-1/2 flex-grow" type="button" kind="secondary" onClick={() => {
                        setTryingToCancel(false);
                    }} disabled={isSubmitting}>Go back</Button>
                    <Button className="flex-basis-1/2 flex-grow" type="button" kind="warning" onClick={() => {
                        onCancel();
                    }} disabled={isSubmitting}>Discard changes</Button>
                </div>}
            </form>;
        }}
    </Formik>;
}

function Approval({ user, isOwn, approval, onEdit, onDelete, projectID }) {
    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeleteError, setDeleteError] = useState(false);
    const [isEditOpen, setEditOpen] = useState(false);

    const close = () => {
        setDeleteOpen(false);
        setDeleteError(false);
    };

    return <div className={`border dark:border-gray-700 rounded p-4 flex ${approval.comments ? "items-top" : "items-center"} max-2-xl mb-4`}>
        <div className={classNames(
            "w-12 h-12 rounded-full text-white flex items-center justify-center flex-shrink-0 mr-4",
            approval.approved ? "bg-green-500" : "bg-red-500",
        )}>
            <FontAwesomeIcon icon={approval.approved ? faCheck : faTimes} size="lg" />
        </div>
        <div className="flex-1">
            <div className="text-lg">{isOwn ? <strong className="underline dark:decoration-white/60 decoration-black/60 decoration-2">You</strong> : <strong>{user.name}</strong>} {approval.approved ? "approved" : "disapproved"} this project{approval.comments && " and said:"}</div>
            {approval.comments && <MarkdownText className="my-2" text={approval.comments} />}
            {isOwn && <div className="mt-2 space-x-2">
                <Button kind="primary" onClick={() => setEditOpen(true)}>Edit</Button>
                <Button kind="destructive" onClick={() => setDeleteOpen(true)}>Delete</Button>
            </div>}
            <StyledDialog open={isEditOpen} onClose={() => {}}>
                <Dialog.Title className="heading mb-1">Edit {approval.approved ? "approval" : "disapproval"}</Dialog.Title>
                <ApprovalForm initialData={approval} onCancel={() => setEditOpen(false)} onSubmit={async data => {
                    try {
                        await SurveysService.deleteApprovalProjectProjectIdApprovalsDelete(projectID);
                    } catch (e) {
                        console.error(e);
                    }
                    await SurveysService.createApprovalProjectProjectIdApprovalsPost(projectID, data);
                    onEdit(data);
                    setEditOpen(false);
                }}></ApprovalForm>
            </StyledDialog>
            <StyledDialog open={isDeleteOpen} onClose={() => {
                if (!isDeleting) close();
            }} borderClassName="border-red-500 dark:border-red-400">
                <BigIcon className="bg-red-500 dark:bg-red-400 mx-auto mb-4 text-white" icon={faExclamationTriangle}></BigIcon>
                <Dialog.Title className="heading mb-1 text-center">Delete this {approval.approved ? "approval" : "disapproval"}?</Dialog.Title>
                <Dialog.Description as="div" className="mb-6 w-full text-center">
                    {isDeleting ? <LoadingText messages={[
                        "Deleting role...",
                    ]} /> : "You can't undo this action."}
                </Dialog.Description>
                {isDeleteError && <ErrorText className="w-full text-center">Couldn't delete this {approval.approved ? "approval" : "disapproval"}. Try again?</ErrorText>}
                <div className="flex mt-2 w-full">
                    <Button className="grow" kind="secondary" onClick={() => close()} disabled={isDeleting}>Cancel</Button>
                    <div className="w-4"></div>
                    <Button className="grow" kind="destructive" onClick={async () => {
                        setDeleting(true);
                        setDeleteError(false);
                        try {
                            await SurveysService.deleteApprovalProjectProjectIdApprovalsDelete(projectID);
                            close();
                            onDelete();
                        } catch (e) {
                            setDeleting(false);
                            setDeleteError(true);
                            console.error(e);
                        }
                    }} disabled={isDeleting}>Delete</Button>
                </div>
            </StyledDialog>
        </div>
    </div>;
}

export function ApprovalsTab() {
    const { project } = useContext(ProjectContext);
    const currentUser = useUser();

    const check = usePermissionChecker();
    let canViewAll = false;
    if (check(permissions.viewAllApproval)) {
        canViewAll = true;
    } else if (project.user_type === "judge" && check(permissions.viewAssignedApproval)) {
        canViewAll = true;
    } else if (project.user_type === "member" && check(permissions.viewOwnApproval)) {
        canViewAll = true;
    }

    let canCreate = false;
    if (check(permissions.createAllApproval)) {
        canCreate = true;
    } else if (project.user_type === "judge" && check(permissions.createAssignedApproval)) {
        canCreate = true;
    }

    const [approvals, setApprovals] = useState([]);
    const { data, error } = useRequest(async () => {
        if (canViewAll) {
            return await SurveysService.getAllApprovalsProjectProjectIdApprovalsAllGet(project.id);
        } else {
            try {
                const approval = await SurveysService.getApprovalProjectProjectIdApprovalsGet(project.id);
                return [{
                    ...approval,
                    user: currentUser,
                }];
            } catch (e) {
                if (e.status === 404) {
                    return [];
                } else {
                    throw e;
                }
            }
        }
    }, [canViewAll, project.id]);

    useEffect(() => {
        if (data) {
            setApprovals(data);
        }
    }, [data]);

    const hasOwnApproval = approvals.find(approval => approval.user.id === currentUser.id) !== undefined;

    return <div>
        {data && <div>
            {(!canViewAll && approvals.length === 0 && !canCreate) && <div className="mb-4">You don't have permission to see approvals for this project.</div>}
            {(!canViewAll && (approvals.length > 0 || canCreate)) && <div className="mb-4">You don't have permission to see other approvasls for this project.</div>}
            {(canViewAll || canCreate) && approvals.length === 0 && <div className="mb-4">There aren't any approvals yet.</div>}
            {approvals.map(({ id, user, approval }) => <Approval key={id ?? "own"} user={user} approval={approval} isOwn={user.id === currentUser.id} projectID={project.id} onDelete={() => {
                setApprovals(approvals.filter(approval => approval.id !== id));
            }} onEdit={data => {
                setApprovals(approvals.map(approval => approval.id === id ? { ...approval, approval: data } : approval));
            }} />)}
            {(canCreate && !hasOwnApproval) && <div className="border dark:border-gray-700 rounded p-4">
                <h4 className="subheading">Approve or disapprove this project</h4>
                <ApprovalForm onSubmit={async approval => {
                    await SurveysService.createApprovalProjectProjectIdApprovalsPost(project.id, approval);
                    setApprovals([...approvals, {
                        approval,
                        user: currentUser,
                    }]);
                }} />
            </div>}
        </div>}
        {(!approvals && !error) && <LoadingText messages={["Loading approvals..."]} />}
        {error && <p>Couldn't load details. Try reloading the page.</p>}
    </div>;
}

const approvalsTabDefinition = {
    path: "approvals",
    name: "Approvals",
    showTab: ({
        settings,
    }) => {
        return !!(settings.approvals?.enabled);
    },
    component: ApprovalsTab,
};

export default approvalsTabDefinition;
