/// <reference path="./typings.d.ts" />

import { useAPI } from "./API";
import { useMemo, useReducer, useEffect, createContext, useContext } from "react";

/**
 * Upload a file.
 * @param {import('./API.js').APIContext} api 
 * @param {File} file
 * @returns {Promise<UploadedFile>}
 */
export async function uploadFile(api, file) {
    const formData = new FormData();
    formData.append("file", file);
    return await api.fetch(`${api.url}/files/upload`, {
        method: "POST",
        body: formData,
    });
}

/**
 * Get the URL for a file ID.
 * @param {import('./API.js').APIContext} api
 * @param {string} fileID
 * @returns {string}
 */
export function getFileURL(api, fileID) {
    return `${api.url}/files/${fileID}/download`;
}

/**
 * Cache for files.
 */
export class FileCache extends EventTarget {
    /**
     * @param {import('./API.js').APIContext} api 
     */
    constructor(api) {
        super();
        this.api = api;
        this.cache = new Map();
        this.ongoingFetches = new Set();
    }

    /**
     * Clear the cache.
     */
    clearCache() {
        this.cache.clear();
        this.dispatchEvent(new Event("cacheModified"));
    }

    /**
     * Get a file from the cache.
     * @param {string} id
     * @param {boolean} [triggerFetch=false]
     * @returns {{file: (UploadedFile & {url: string})} | {error: Error} | null}
     */
    getFile(id, triggerFetch = false) {
        if (!this.cache.has(id)) {
            if (triggerFetch && !this.ongoingFetches.has(id)) {
                this.fetchFile(id);
            }
            return null;
        }
        return this.cache.get(id);
    }

    /**
     * Fetch a file from the server.
     * @param {string} id
     * @returns {Promise<UploadedFile & {url: string}>}
     */
    async fetchFile(id) {
        if (this.ongoingFetches.has(id)) {
            throw new Error("File is already being fetched");
        }
        this.ongoingFetches.add(id);
        try {
            const file = await this.api.fetch(`${this.api.url}/files/${id}`);
            const toAdd = { ...file, url: getFileURL(this.api, id) };
            this.addFile(toAdd);
            return toAdd;
        } catch (err) {
            this.cache.set(id, { error: err });
            this.dispatchEvent(new Event("cacheModified"));
            throw err;
        } finally {
            this.ongoingFetches.delete(id);
        }
    }

    /**
     * Adds a file to the cache.
     * @returns {Promise<UploadedFile & {url: string}>}
     */
    addFile(file) {
        this.cache.set(file.id, { file });
        this.dispatchEvent(new Event("cacheModified"));
    }
}

/**
 * Context for the file cache.
 * @type {import('react').Context<FileCache | null>}
 */
export const FileCacheContext = createContext(null);

/**
 * Component that provides a file cache.
 * @param {import('React').PropsWithChildren<{}>} props
 * @returns {JSX.Element}
 */
export function FileCacheProvider({ children }) {
    const api = useAPI();
    const cache = useMemo(() => new FileCache(api), [api]);
    // eslint-disable-next-line no-unused-vars
    const [_, update] = useReducer(x => x + 1, 0);
    useEffect(() => {
        const listener = () => update();
        cache.addEventListener("cacheModified", listener);
        return () => cache.removeEventListener("cacheModified", listener);
    }, [cache, update]);

    return <FileCacheContext.Provider value={{cache}}>
        {children}
    </FileCacheContext.Provider>;
}

/**
 * Hook for retrieving the file cache from the context.
 * @return {FileCache}
 */
export function useFileCache() {
    const context = useContext(FileCacheContext);
    if (!context) throw new Error("FileCacheProvider not found");
    return context.cache;
}
