import permissions from '../../permissions.json';

export const id = "peerReview";
export const name = "Peer Review";
export const description = "Pair students with each other for peer review.";
export const checkedClassName = "text-white bg-gradient-to-r from-green-600 to-emerald-600";

/**
 * @param {import('./templates').TemplateContext} ctx
 */
export async function create(ctx) {
    await ctx.createRole({
        name: "Participant",
        admin: false,
        permissions: [
            permissions.createProject,
            permissions.editOwnProject,
            permissions.createAssignedFeedback,
            permissions.editOwnFeedback,
            permissions.viewProjectFeedback,
            permissions.editOwnRegistration,
        ],
        order: 1,
    });

    await ctx.createSurvey("project", [
        {
            name: "Upload",
            question_type: "file",
            description: "Upload your project here.",
            required: true,
            details: {},
            all_roles: true,
            all_categories: true,
        },
    ]);

    await ctx.updateSettings({
        approvals: {
            enabled: false,
            required_roles: {},
        },
        checklist: "peer",
    });

    await ctx.createSurvey("review", [
        {
            name: "Feedback & Comments",
            question_type: "textarea",
            description: "",
            required: true,
            details: {},
            all_roles: true,
            all_categories: true,
        },
    ]);
}
