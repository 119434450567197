import { Main } from '../components/ui/Main';
import { faPlus, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouterLinkButton } from 'src/components/ui/Button';
import { StyledExternalLink, StyledRouterLink } from 'src/components/ui/Link';
import { AccountMenu } from 'src/components/AccountMenu';
import { LoadingText } from 'src/components/ui/Loading';
import { ErrorText } from 'src/components/ui/Input';
import Helmet from 'react-helmet';
import logo from 'src/logo.svg';
import { useListEventsEventsGet } from 'src/codegen';

export function Events() {
    const { data: response, loading, error } = useListEventsEventsGet();
    const events = response?.data;
    
    return (
        <Main>
            <Helmet>
                <title>Events</title>
            </Helmet>
            <div className="sm:flex itxems-center mt-6 mb-8">
                <img className="h-20" src={logo} alt="Expodite logo" />
                <h1 className="sr-only">Expodite</h1>
                <div className="flex-grow"></div>
                <AccountMenu />
            </div>
            <div className="mb-8">
                <RouterLinkButton size="md" to="/newevent" kind="primary" className="mr-1"><FontAwesomeIcon className="mr-1" icon={faPlus} /> New Event</RouterLinkButton>
            </div>
            <div className="flex mb-4 items-center">
                <h3 className="subheading uppercase tracking-wide opacity-80 mr-2">Your Events</h3>
                {/* <a className="text-blue-500 hover:text-blue-600 transition-colors" href="#" onClick={event => {event.preventDefault(); alert("Not implemented yet!");}}><FontAwesomeIcon className="mr-1" icon={faPencilAlt} /> Edit</a> */}
            </div>
            {loading && <LoadingText messages={[
                "Loading events...",
                "Eventfully loading...",
            ]} />}
            {events && events.length > 0 && <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 my-4 items-stretch">
                {events && events.map(({id, name}) => <RouterLinkButton size="unsized" kind="primary" key={id} to={`/event/${id}`}>
                    <div className="w-full text-white rounded shadow-md hover:shadow-xl transition-all p-3 flex flex-col h-full">
                        <h4 className="subheading font-bold">{name}</h4>
                        <div className="flex-grow"></div>
                        <div className="font-medium tracking-wide uppercase pt-6 opacity-80">Manage <FontAwesomeIcon className="mr-1" icon={faArrowRight} /></div>
                    </div>
                </RouterLinkButton>)}
            </div>}
            {events && events.length === 0 && <div className="text-lg italic muted">
                You don't have any events yet. Why not <StyledRouterLink to="/newevent">create one</StyledRouterLink>?
            </div>}
            {error && <ErrorText>Couldn't load events. Try reloading the page.</ErrorText>}
            <div className="muted mt-10">&copy; 2022-2023 <StyledExternalLink href="https://github.com/stoneroof" kind="muted" target="_blank" rel="noopener noreferrer">Stoneroof</StyledExternalLink>.</div>
        </Main>
    );
}
