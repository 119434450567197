import { faArrowRight, faRocket, faCheck, faPersonWalking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, RouterLinkButton } from '../../../components/ui/Button';
import { StyledRouterLink } from '../../../components/ui/Link';
import classNames from 'classnames';

function ListItem({ checked, active, number, title, link, linktext, openText, children }) {
    const color = !checked ? (active ? "bg-blue-500" : "bg-gray-500") : "bg-green-500";

    return <section className="w-full flex flex-row align-items-stretch mb-2">
        <div className="flex flex-col mr-3">
            <div className={classNames("w-10 h-10 text-center rounded-full text-white flex justify-center items-center", color)}>
                {checked && <FontAwesomeIcon icon={faCheck} />}
                {!checked && <div className="font-medium text-lg">{number}</div>}
            </div> 
        </div>
        <div className="flex-grow">
            {!active && <div className="flex items-center">
                <h3 className="h-10 text-lg leading-10">{title}</h3>
                {link && <StyledRouterLink to={link} className="ml-3" underline="hover">Go to {linktext}
                    <FontAwesomeIcon className="mr-1 ml-2" icon={faArrowRight} /></StyledRouterLink>}
            </div>}
            {active && <div>
                <h3 className="h-10 text-lg leading-10">{title}</h3>                
                {children}
            </div>}
        </div>
    </section>;
}

/**
 * @param {{ onAutomatic: () => void, onManual: () => void, scheduleModel: import('../../../lib/ScheduleModel').ScheduleModel }} props
 */
export function Onboarding({ onAutomatic, onManual, scheduleModel }) {
    const fastHue = 159;
    const slowHue = 50;

    const projectsAdded = scheduleModel.getProjectIDs().length > 0;
    const schedulingSetUp = scheduleModel.getTimeslotIDs().length > 0;

    return <>
        <p className="mb-4 lead">Welcome to Expodite Scheduling!</p>

        <p className="mb-4">Here's some next steps:</p>

        <div>
            <ListItem checked={projectsAdded} active={!projectsAdded} number="1" title={
                projectsAdded ? "Projects added!" : "Add projects"
            } link="../projects" linktext="Projects">
                <p>To create a schedule, you'll need some projects. You can create projects on the Projects page.</p>
                <RouterLinkButton to="../projects" kind="primary" className="mt-2 mb-6">Go to Projects <FontAwesomeIcon className="ml-3" icon={faArrowRight} /></RouterLinkButton>
            </ListItem>
            <ListItem checked={schedulingSetUp} active={projectsAdded && !schedulingSetUp} number="2" title={
                schedulingSetUp ? "Scheduling settings configured!" : "Configure scheduling settings"
            } link="../settings/scheduling" linktext="Scheduling Settings">
                <p>Set up timeslots and configure settings for the automatic scheduler in Settings.</p>
                <RouterLinkButton to="../settings/scheduling" kind="primary" className="mt-2 mb-6">Go to Scheduling Settings <FontAwesomeIcon className="ml-3" icon={faArrowRight} /></RouterLinkButton>
            </ListItem>
            <ListItem checked={false} active={projectsAdded && schedulingSetUp} number="3" title="Assign judges to projects" onAutomatic={onAutomatic} onManual={onManual}>
                <p>Check that all student and judge information is valid, then press "Expodite!" to automatically generate a schedule.
                    <br />Otherwise, press "Manual" to redirect to the custom schedule creation mode.</p>
                <div className="flex items-center mt-5 mb-5">
                    <Button onClick={() => onAutomatic()} size="lg" className="bg-white dark:bg-gray-800 dark:active:bg-gray-700 transition-all px-2 py-6 sm:py-2 flex flex-col items-center justify-center shadow-lg hover:shadow-xl text-center group sm:h-40 mr-10" style={{ "--settings-accent-hue": `${fastHue}deg` }}
                        mouseEffect mouseEffectColor={`hsl(${fastHue}, 100%, 50%)`} mouseEffectClassName="mix-blend-normal opacity-0" mouseEffectHoverClassName="mix-blend-normal opacity-20" mouseEffectActiveClassName="mix-blend-normal opacity-30">
                        <FontAwesomeIcon icon={faRocket} className="text-5xl mb-4 text-settings-accent" />
                        <div className="font-medium">Expodite!<FontAwesomeIcon icon={faArrowRight} className="ml-1 relative left-0 group-hover:left-1 transition-all" /></div>
                    </Button>
                    <span>OR</span>
                    <Button onClick={() => onManual()} size="lg" className="bg-white dark:bg-gray-800 dark:active:bg-gray-700 transition-all px-2 py-6 sm:py-2 flex flex-col items-center justify-center shadow-lg hover:shadow-xl text-center group sm:h-40 ml-10" style={{ "--settings-accent-hue": `${slowHue}deg` }}
                        mouseEffect mouseEffectColor={`hsl(${slowHue}, 100%, 50%)`} mouseEffectClassName="mix-blend-normal opacity-0" mouseEffectHoverClassName="mix-blend-normal opacity-20" mouseEffectActiveClassName="mix-blend-normal opacity-30">
                        <FontAwesomeIcon icon={faPersonWalking} className="text-5xl mb-4 text-settings-accent" />
                        <div className="font-medium">Manual<FontAwesomeIcon icon={faArrowRight} className="ml-1 relative left-0 group-hover:left-1 transition-all" /></div>
                    </Button>
                </div>
            </ListItem>
        </div>
    </>;
}
