/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventReadForUser } from '../models/EventReadForUser';
import type { InviteCreate } from '../models/InviteCreate';
import type { InviteInfo } from '../models/InviteInfo';
import type { InviteRead } from '../models/InviteRead';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvitesService {

    /**
     * List Event Invites
     * @param eventId
     * @returns InviteRead Successful Response
     * @throws ApiError
     */
    public static listEventInvitesEventsEventIdInvitesGet(
        eventId: string,
    ): CancelablePromise<Array<InviteRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/invites',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event Invite
     * @param eventId
     * @param requestBody
     * @param roleId
     * @returns InviteRead Successful Response
     * @throws ApiError
     */
    public static createEventInviteEventsEventIdInvitesPost(
        eventId: string,
        requestBody: InviteCreate,
        roleId?: string,
    ): CancelablePromise<InviteRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/events/{event_id}/invites',
            path: {
                'event_id': eventId,
            },
            query: {
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Resend Event Invite
     * @param inviteId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resendEventInviteInvitesInviteIdResendPut(
        inviteId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/invites/{invite_id}/resend',
            path: {
                'invite_id': inviteId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Invite Details
     * @param inviteId
     * @returns InviteInfo Successful Response
     * @throws ApiError
     */
    public static getInviteDetailsInvitesInviteIdGet(
        inviteId: string,
    ): CancelablePromise<InviteInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invites/{invite_id}',
            path: {
                'invite_id': inviteId,
            },
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Invite
     * @param inviteId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteInviteInvitesInviteIdDelete(
        inviteId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/invites/{invite_id}',
            path: {
                'invite_id': inviteId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Join Event With Invite
     * @param inviteId
     * @returns EventReadForUser Successful Response
     * @throws ApiError
     */
    public static joinEventWithInviteInvitesInviteIdJoinPut(
        inviteId: string,
    ): CancelablePromise<EventReadForUser> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/invites/{invite_id}/join',
            path: {
                'invite_id': inviteId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
