import { faPlus, faSort, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';
import { BackButton } from '../../../components/BackButton';
import { MasterDetailLayout } from '../../../components/MasterDetailLayout';
import { Button, RouterLinkButton } from '../../../components/ui/Button';
import { Main } from '../../../components/ui/Main';
import { useLocation, useMatch } from "react-router-dom";
import { useRequest } from "../../../lib/API.js";
import { LoadingText } from "../../../components/ui/Loading";
import { ErrorText } from "../../../components/ui/Input";
import { createContext, Fragment, useEffect, useMemo, useState } from "react";
import { useEvent, usePermissionChecker } from "../../../components/EventLayout.tsx";
import { useArrayUpdateContext } from "../../../lib/utils.js";
import permissions from "../../../permissions.json";
import { useUser } from "../../../lib/auth.js";
import Helmet from "react-helmet";
import { Select } from "../../../components/ui/Input";
import { UsersService, RolesService } from "../../../client";

export const UsersContext = createContext(null);

function RoleSelect({roles, ...props}) {
    return <Select {...props}>
        <option value="all">All roles</option>
        {roles?.map(role => <option key={role.id} value={role.id}>{role.name}</option>)}
    </Select>;
}

export function Users() {
    const { pathname: currentPathname } = useLocation();
    const match = useMatch(currentPathname);
    const { id } = useEvent();
    const { id: currentUserID } = useUser();
    const check = usePermissionChecker();

    /** @type {import("../../../lib/API.js").RequestResult<User[]>} */
    const { data, loading, error } = useRequest(() => UsersService.listEventUsersEventsEventIdUsersGet(id));
    const { data : roles} = useRequest(() => RolesService.listEventRolesEventsEventIdRolesGet(id));
    
    const [users, setUsers] = useState(data);
    const [sortType, setSortType] = useState("role");
    const [filterType, setFilterType] = useState("all");

    useEffect(() => {
        if (data) setUsers(data);
    }, [data]);

    // Group users by role ID.
    const groups = useMemo(() => {
        const groups = {};
        if (users) {
            let filteredUsers = users.filter(({ roles }) => {
                if (filterType === "all") return true;
                return roles.some(role => role.id === filterType);
            });
            if (!check(permissions.viewUserAll)) {
                filteredUsers = users.filter(({id}) => currentUserID === id);
            }

            if (sortType === "role") {
                filteredUsers.forEach(user => {
                    const roleID = user.roles.length === 0 ? null : user.roles.sort((a, b) => a.order - b.order)[0].id;
                    if (roleID != null) {
                        if (!groups[roleID]) {
                            groups[roleID] = [];
                        }
                        groups[roleID].push(user);
                    } else {
                        if (!groups.none) {
                            groups.none = [];
                        }
                        groups.none.push(user);
                    }
                });

                return Object.entries(groups).map(([roleID, users]) => {
                    return {
                        title: roleID === "none" ? "None" : users[0].roles[0].name,
                        id: roleID,
                        users: users.sort((a, b) => {
                            return a.name.localeCompare(b.name, undefined, {
                                sensitivity: "base",
                                numeric: true,
                            });
                        }),
                    };
                }).sort((a, b) => {
                    if (a.title === null) return -1;
                    if (b.title === null) return 1;
                    return a.title.localeCompare(b.title, undefined, {
                        sensitivity: "base",
                        numeric: true,
                    });
                });
            } else if (sortType === "alpha") {
                filteredUsers.forEach(user => {
                    const group = user.name.length > 0 ? user.name[0].toUpperCase() : "";
                    if (!groups[group]) groups[group] = [];
                    groups[group].push(user);
                });

                return Object.entries(groups).map(([name, users]) => {
                    return {
                        title: name,
                        id: name,
                        users: users.sort((a, b) => a.name.localeCompare(b.name, undefined, {
                            sensitivity: "base",
                            numeric: true,
                        })),
                    };
                }).sort((a, b) => a.title.localeCompare(b.title));
            }
        }
    }, [users, filterType, sortType]);

    // the sorts/filters I want to implement:
    // sort alphabetically by name within role
        // maybe by last name might be better??

    const context = useArrayUpdateContext(users, setUsers);

    return <UsersContext.Provider value={context}>
        <Helmet><title>Users</title></Helmet>
        <MasterDetailLayout>
            <div className="mx-4 my-4">
                <div className="flex items-center mt-3 mb-6">
                    <BackButton />
                    <h1 className="title font-semibold flex-grow font-display">Users</h1>
                    {check(permissions.manageInvites) && (match.pathnameBase.endsWith("/new") ?
                        <Button size="md" kind="primary" className="mr-1" disabled>
                            <FontAwesomeIcon className="mr-1" icon={faPlus} /> Invite
                        </Button> :
                        <RouterLinkButton size="md" to="new" kind="primary" className="mr-1">
                            <FontAwesomeIcon className="mr-1" icon={faPlus} /> Invite
                        </RouterLinkButton>)}
                </div>
                <div className="flex items-center mb-2">
                    <label htmlFor="users-filter" className="block pr-2"><FontAwesomeIcon icon={faFilter} className="text-blue-500 dark:text-blue-400 w-4" /> Filter:</label>
                    <RoleSelect id="users-filter" roles={roles} value={filterType} onChange={e => setFilterType(e.target.value)} className="block flex-grow text-sm rounded bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-900 dark:hover:bg-gray-700 px-3 py-1"/>
                </div>
                <div className="flex items-center mb-2">
                    <label htmlFor="users-sort" className="block pr-2"><FontAwesomeIcon icon={faSort} className="text-blue-500 dark:text-blue-400 w-4" /> Sort:</label>
                    <select id="users-sort" className="block flex-grow text-sm rounded bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-900 dark:hover:bg-gray-700 px-3 py-1" value={sortType} onChange={e => setSortType(e.target.value)}>
                        <option value="alpha">Name</option>
                        <option value="role">Role</option>
                    </select>
                </div>
                {loading && <LoadingText className="text-center py-8 px-4" messages={["Loading users..."]} />}
                {error && <ErrorText className="text-center py-8 px-4">Couldn't load users. Try reloading the page.</ErrorText>}
                {users && <ul className="list-none space-y-1">
                    {groups?.map((({ title, id, users }) => <Fragment key={id ?? "none"}>
                        <li className="over font-medium rounded bg-gray-100 dark:bg-gray-800 px-1 sticky top-0">{title ?? "No Role"} ({users.length})</li>
                        {users.map(user => <li className="p-0" key={user.id}>
                            <NavLink to={user.id} className={({ isActive }) => `p-1 rounded ${isActive ? "bg-blue-500 text-white" : "hover:bg-blue-100 active:bg-blue-200 dark:hover:bg-blue-900 dark:active:bg-blue-800"} block transition-colors`}>
                                <h2 className="subsubheading font-bold">{user.name}</h2>
                            </NavLink>
                        </li>)}
                    </Fragment>))}
                </ul>}
            </div>
        </MasterDetailLayout>
    </UsersContext.Provider>;
}

export function SelectUser() {
    return <Main className="flex justify-center items-center h-full">
        <p>Select a user to see their details.</p>
    </Main>;
}
