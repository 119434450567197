import { SurveyQuestion } from './SurveyQuestion';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { questionTypes } from './questionTypes';

export function useEmptyResponse(questions) {
    return useMemo(() => {
        const response = {};
        questions?.forEach(question => {
            const type = questionTypes.get(question.question_type);
            if (type) {
                response[question.id] = type.defaultResponse(question);
            } else {
                response[question.id] = "";
            }
        });
        return response;
    }, [questions]);
}

export function validateSurveyResponse(questions, questionValues) {
    const errors = {};
    questions.forEach(question => {
        if (question.required && (questionValues[question.id] === undefined || questionValues[question.id] === null || questionValues[question.id] === "")) {
            errors[question.id] = "Required";
        } else {
            const type = questionTypes.get(question.question_type);
            if (type) {
                const error = type.validateResponse(question, questionValues[question.id]);
                if (error && Object.keys(error).length > 0) {
                    errors[question.id] = error;
                }
            } else {
                errors[question.id] = "Invalid question type.";
            }
        }
    });
    if (Object.keys(errors).length > 0) return errors;
}

/**
 * @param {{questions: Question[], name: string, disabled?: boolean}} props 
 */
export function Survey({ questions, name, disabled }) {   
    const { values, errors } = useFormikContext();
    const sortedQuestions = useMemo(() => questions.sort((a, b) => a.order - b.order), [questions]); 
    return <>
        {sortedQuestions.map(question => {
            return <SurveyQuestion question={question} disabled={disabled} name={`${name}.${question.id}`} key={question.id} value={values[name][question.id]} error={errors && errors[name] && errors[name][question.id]} />;
        })}
    </>;
}
