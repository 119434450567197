import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import { useEffect, useMemo, useState } from 'react';
import { useEvent } from '../../../../components/EventLayout.tsx';
import { Button } from "../../../../components/ui/Button";
import { Input } from '../../../../components/ui/Input';
import { PopoverPanel } from '../../../../components/ui/Popover';
import { useRequest } from '../../../../lib/API.js';
import { createProjectSearcher } from '../../../../lib/search';
import { hueForID } from '../../../../lib/utils.js';

export function AddButtonPopoverContent({ scheduleModel, setScheduleModel, judgeID, timeslotID, colorType }) {
    const [searchTerm, setSearchTerm] = useState("");

    const searcher = useMemo(() => {
        return createProjectSearcher(scheduleModel.getProjects());
    }, [scheduleModel]);
    const projects = useMemo(() => {
        if (searchTerm) return searcher.search(searchTerm).map(({ item }) => item);

        const projects = scheduleModel.getProjects();
        return [...projects].sort((a, b) => {
            // Sort by category name, then by project name, using locale compare
            const category = (a.category?.name ?? "").localeCompare(b.category?.name ?? "");
            if (category !== 0) return category;
            return a.name.localeCompare(b.name);
        });
    }, [scheduleModel, searchTerm, searcher]);
    const userProjects = useMemo(() => {
        return new Set(Object.entries(scheduleModel.getProjectIDsForUser(judgeID)).flatMap(([_, projects]) => [...(projects ?? [])]).filter(project => project));
    }, [scheduleModel, judgeID]);

    return <>
        <h3 className="font-bold">Select project to add</h3>
        <label htmlFor="projects-filter" className="block mt-2 mb-1"><FontAwesomeIcon icon={faSearch} className="text-blue-500 dark:text-blue-400 w-4" /> Search by name or category:</label>
        <Input className="border dark:border-gray-600" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} id="projects-filter" />
        <div className="grid mt-3 lg:grid-cols-2">
            {projects.map(project => {
                const hueID = (!colorType && project.category && project.category.id) ? project.category?.id : project.id;
                return (
                    <Button disabled={userProjects.has(project.id)} key={project.id} onClick={() => {
                        setScheduleModel(scheduleModel.assigningProject(judgeID, timeslotID, project.id));
                    }} style={{ "--settings-accent-hue": hueForID(hueID) }}>
                        <div className="w-full h-full flex items-center justify-start">
                            <span className="block w-4 h-4 flex-shrink-0 rounded-full bg-settings-accent mr-2" />
                            <div className="flex-grow flex-shrink overflow-hidden">
                                <h5 className="font-medium text-left overflow-hidden overflow-ellipsis">{project.name}</h5>
                                {project.category && <div className="text-sm muted text-left overflow-hidden overflow-ellipsis">{project.category.name}</div>}
                            </div>
                        </div>
                    </Button>
                );
            })}
        </div>
    </>;
}

/**
 * @param {{scheduleModel: import('../../../lib/ScheduleModel').ScheduleModel, setScheduleModel: (ScheduleModel) => void, judgeID: string, timeslotID: string, cache: import('../../../lib/export').SharedExportCache}} props
 */
export function AddButton({ scheduleModel, setScheduleModel, judgeID, timeslotID, cache, categories, colorType }) {
    // button for blank cells
    const { id: eventID, settings: { auto_schedule: { timeslots_question: timeslot_q } } } = useEvent();
    const [judgeableSlot, setJudgeableSlot] = useState(true);

    const { data } = useRequest(async () => {
        try {
            return await cache.getURL(`/events/${eventID}/registrations/${judgeID}`);
        } catch (e) {
            if (e.status === 404) return null;
            throw e;
        }
    }, [eventID, judgeID]);

    useEffect(() => {
        if (!timeslot_q) {
            setJudgeableSlot(true);
            return;
        }

        if (!data || typeof data[timeslot_q] !== "object") {
            setJudgeableSlot(false);
            return;
        }

        if (!(data[timeslot_q] instanceof Array)) {
            setJudgeableSlot(false);
            return;
        }

        setJudgeableSlot(data[timeslot_q].includes(timeslotID));
    }, [data, timeslot_q, timeslotID]);

    return (
        <div className="w-full h-full">
            <Popover className="h-full">
                {({ open }) => (
                    <>
                        <Popover.Button as={Button} className={`w-full h-full ${open ? 'bg-blue-500/30' : ''} ${judgeableSlot ? "opacity-100" : "opacity-30"}`} size="unsized-unrounded">
                            <FontAwesomeIcon className="p-2" icon={faPlus} />
                        </Popover.Button>

                        <PopoverPanel className="w-screen max-w-sm lg:max-w-md max-h-lg overflow-auto">
                            <AddButtonPopoverContent scheduleModel={scheduleModel} setScheduleModel={setScheduleModel} judgeID={judgeID} timeslotID={timeslotID} colorType={colorType} />
                        </PopoverPanel>
                    </>
                )}
            </Popover>
        </div>
    );
}
