import { Dialog, Switch } from "@headlessui/react";
import { useState, useEffect } from "react";
import { useEvent } from "../../../components/EventLayout";
import { OneColumnLayout } from "../../../components/OneColumnLayout";
import { ErrorText } from "../../../components/ui/Input";
import { LoadingScreen, LoadingText } from "../../../components/ui/Loading";
import { Main } from "../../../components/ui/Main";
import { useRequest, useAPI } from "../../../lib/API";
import { ScheduleModel } from "../../../lib/ScheduleModel";
import { ManualScheduleEditor } from "./manual/ManualScheduleEditor";
import { Onboarding } from "./onboarding";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleNotch, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../components/ui/Button";
import { StyledRouterLink } from "../../../components/ui/Link";
import { StyledDialog } from "../../../components/ui/Dialog";
import { BigIcon } from "../../../components/ui/BigIcon";
import { StyledSwitch } from "../../../components/ui/Switch";
import Helmet from "react-helmet";
import { CategoriesService, RolesService } from "../../../client";

export function Scheduling() {
    const { id, name: eventName } = useEvent();
    const api = useAPI();
    const titleTop = "Schedule for " + eventName;
    const { data, error, loading: scheduleLoading, reload: reloadSchedule } = useRequest(api => ScheduleModel.fetch(api, id), [id]);
    const { data: statusServerData, error: statusError, reload: reloadStatus } = useRequest(api => api.get(`/events/${id}/schedule/status`));
    // useRequest(() => ScheduleService.getEventScheduleStatusEventsEventIdScheduleStatusGet(id));
    
    const { data: rolesData } = useRequest(() => RolesService.listEventRolesEventsEventIdRolesGet(id));
    const { data: categories } = useRequest(() => /** @type {Promise<Category[]>} */ CategoriesService.listEventCategoriesEventsEventIdCategoriesGet(id));
    const [isLoadingStatus, setLoadingStatus] = useState(false);

    const [displayAs, setDisplayAs] = useState("title");
    const [showRole, setShowRole] = useState("");
    // const [swap, setSwap] = useState(true);
    const [colorCode, setColorCode] = useState(true);
    const [colorType, setColorType] = useState(true);
    const [timeslotName, setTimeslotName] = useState(false);
    const [displayTable, setDisplayTable] = useState(true);

    // Possible states: loading, load_error, onboarding, automatic_running, automatic_error, automatic_success, manual
    const [mode, setMode] = useState("loading");
    const [autoError, setAutoError] = useState(null);
    const [dismissError, setDismissError] = useState(false);

    // Possible states: closed, open, resetting, error
    const [resetState, setResetState] = useState("closed");

    const [model, setModel] = useState(data);
    useEffect(() => {
        if (data) {
            setModel(data);
        }
    }, [data]);

    const [statusData, setStatusData] = useState(null);
    useEffect(() => {
        if (statusServerData) {
            setStatusData(statusServerData);
        }
    }, [statusServerData]);

    useEffect(() => {
        console.log(`GUSP: Mode ${mode}`);

        // If we have an error, set mode to load_error.
        if (error || statusError) {
            setMode("load_error");
            console.error(error);
            return;
        }

        // If we're still loading, return.
        if (!model || !statusData) {
            return;
        }

        if (mode === "manual" || mode === "onboarding") return;

        if (statusData.status === "running") {
            setMode("automatic_running");
            setLoadingStatus(true);
            return;
        }
        
        if (statusData.status === "complete") {
            if (mode === "automatic_running") {
                console.log("GUSP: Reloading schedule...");
                reloadSchedule();
            }
            setMode("automatic_success");
            return;
        }
        
        if (statusData.status === "error") {
            setMode("automatic_error");
            setAutoError(<>The scheduler was unable to generate a schedule.</>);
            return;
        }

        if (mode === "automatic_running") {
            setLoadingStatus(true);
            return;
        }
        
        if (mode === "automatic_success" || mode === "automatic_error") return;
        
        if (model.isEmpty()) {
            setMode("onboarding");
        } else {
            setMode("manual");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, statusData, error, statusError, mode]);

    useEffect(() => {
        if (isLoadingStatus) {
            console.log("GUSP: Loading status in 1 second...");
            setLoadingStatus(true);
            const timeout = setTimeout(() => {
                console.log("GUSP: Loading status...");
                reloadStatus();
                setLoadingStatus(false);
            }, 1000);
            return () => {
                console.log("GUSP: Clearing timeout...");
                clearTimeout(timeout);
                setLoadingStatus(false);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingStatus]);

    let title = "Scheduling";
    if (mode === "manual") {
        title = "Schedule";
    } else if (mode === "automatic_running") {
        title = "⏳ Scheduling";
    } else if (mode === "automatic_success") {
        title = "✅ Scheduling";
    } else if (mode === "automatic_error") {
        title = "⚠️ Scheduling";
    }

    return <OneColumnLayout className="flex flex-col h-screen lg:border-l border-gray-300 dark:border-gray-700" title={titleTop} alwaysSmall>
        <Helmet defer={false}>
            <title>{title}</title>
        </Helmet>
        <div className="bg-gray-100 dark:bg-gray-800 px-4 pb-3 flex border-b border-gray-300 dark:border-gray-700">
            <div className={`pr-3 border-r border-gray-500 ${mode === "manual" ? "" : "opacity-50 cursor-not-allowed"} space-x-2`}>
                <span>Schedule Information:</span>
                <span className="px-3">Projects: </span> {/* {model.getProjects().length} */}
                <span className="px-3">Judges: </span>  {/*  {model.getUsers().length} */}
            </div>
            {categories && categories.map(category => (
                <span className="px-3">{category.name}: projects per judges </span>
            ))}
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 px-4 py-3 flex items-center justify-center border-b border-gray-300 dark:border-gray-700">
            <div className={`pr-3 border-r border-gray-500 ${mode === "manual" ? "" : "opacity-50 cursor-not-allowed"} space-x-2`}>
                <span>Display as:</span>
                <select disabled={mode !== "manual"} className="text-sm rounded bg-white dark:bg-gray-700 px-3 py-1" value={displayAs} onChange={(e) => { setDisplayAs(e.target.value); }}>
                    <option value="title">Title</option>
                    <option value="author">Author</option>
                    <option value="category">Category</option>
                </select>
            </div>
            <div className={`px-3 border-r border-gray-500 ${mode === "manual" ? "" : "opacity-50 cursor-not-allowed"} space-x-2`}>
                <span>Show:</span>
                <select disabled={mode !== "manual"} className="text-sm rounded bg-white dark:bg-gray-700 pl-3 py-1 pr-9" value={showRole} onChange={(e) => { setShowRole(e.target.value); }}>
                    <option value="">All roles</option>
                    {rolesData && rolesData.map(role => <option key={role.id} value={role.id}>{role.name}</option>)}
                </select>
            </div>
            <Switch.Group as="div" className="pl-3 space-x-2">
                <StyledSwitch checked={displayTable} onChange={value => setDisplayTable(value)} />
                <Switch.Label>Display as {displayTable ? "table" : "cards"}</Switch.Label>
            </Switch.Group>  
            <Switch.Group as="div" className="pl-3 space-x-2">
                <StyledSwitch checked={timeslotName} onChange={value => setTimeslotName(value)} />
                <Switch.Label>Display timeslot names</Switch.Label>
            </Switch.Group>            
            <Switch.Group as="div" className="pl-3 space-x-2">
                <StyledSwitch checked={colorCode} onChange={value => setColorCode(value)} />
                <Switch.Label>Color-code projects</Switch.Label>
            </Switch.Group>
            {categories && colorCode && <Switch.Group as="div" className="pl-3 space-x-2">
                <StyledSwitch checked={colorType} onChange={value => setColorType(value)} />
                <Switch.Label>Color-code by {colorType ? "project" : "category"} </Switch.Label>
            </Switch.Group>}
            <span className="flex-grow mr-2" />
            <Button size="sm" disabled={mode !== "manual" || !model} kind="destructive" onClick={() => {
                if (model.isEmpty()) {
                    setMode("onboarding");
                } else {
                    setResetState("open");
                }
            }}>
                Reset Schedule
            </Button>
        </div>

        <div className="relative flex-grow overflow-auto">
            {(model && (!model.isEmpty() || mode === "manual") && !scheduleLoading) && /*TODO: add sortAs to this  */
                <ManualScheduleEditor scheduleModel={model} setScheduleModel={setModel} displayAs={displayAs} showRole={showRole} displayTable={displayTable} colorCode={colorCode} colorType={colorType} timeslotName={timeslotName} categories={categories} />
            }
            {error && <Main>
                <p>Something went wrong...</p>
            </Main>}
            {mode !== "manual" && <div className="z-20 absolute inset-0 bg-white/70 dark:bg-gray-900/70 backdrop-blur py-4 overflow-auto">
                {mode === "loading" && <LoadingScreen />}
                {mode === "load_error" && <div className="w-full h-full flex flex-col items-center justify-center text-center">
                    <ErrorText>Couldn't load schedule. Try reloading the page.</ErrorText>
                </div>}
                {mode === "automatic_running" && <div className="w-full h-full flex flex-col items-center justify-center p-8 text-center">
                    <FontAwesomeIcon className="mr-1 fa-spin text-9xl opacity-50 mb-6" icon={faCircleNotch} />
                    <h2 className="text-2xl font-medium opacity-50">We're preparing your schedule.</h2>
                    {statusData?.details?.solver?.total_projects ? <p className="opacity-40 text-lg"><strong>{statusData.details.solver.assigned_projects}</strong>/{statusData.details.solver.total_projects} projects assigned</p> : <></>}
                    <p className="opacity-50 mt-2">This will take a few minutes.</p>
                    <p className="opacity-50 mb-6">Feel free to close this page and come back to it later.</p>
                    {process.env.NODE_ENV === "development" && <Button kind="warning" onClick={() => {
                        // ScheduleService.startSchedulerForEventEventsEventIdScheduleStartGet(id);
                        api.get(`/events/${id}/schedule/start`);
                    }}>Force Restart</Button>}
                </div>}
                {mode === "automatic_success" && <div className="w-full h-full flex flex-col items-center justify-center text-center">
                    <FontAwesomeIcon className="mr-1 text-9xl text-green-500 mb-4" icon={faCircleCheck} />
                    <h2 className="text-lg">Schedule generated!</h2>
                    {model && <Button onClick={() => {
                        setMode("manual");
                        // ScheduleService.updateEventScheduleStatusEventsEventIdScheduleStatusPut(id, {status: "not_running"});
                        api.put(`/events/${id}/schedule/status`, {status: "not_running"});
                    }} kind="primary" className="mt-4">View Schedule</Button>}
                    {scheduleLoading && <LoadingText messages={["Fetching schedule..."]} />}
                </div>}
                {mode === "automatic_error" && <div className="w-full h-full flex flex-col items-center justify-center text-center">
                    <FontAwesomeIcon className="mr-1 text-9xl text-red-500 mb-6" icon={faExclamationTriangle} />
                    <h2 className="text-2xl font-medium mb-2">Failed to schedule event.</h2>
                    <p className="mb-4">{autoError}</p>
                    {statusData.status === "error" && <div className="mb-4">
                        <input type="checkbox" className="rounded align-middle mr-2" checked={dismissError} onChange={(e) => { setDismissError(e.target.checked); }} id="auto-error-checkbox" />
                        <label className="align-middle" htmlFor="auto-error-checkbox">Don't show this again</label>
                    </div>}
                    <Button kind="secondary" onClick={() => {
                        setMode(model.isEmpty() ? "onboarding" : "manual");
                        if (dismissError && statusData.status === "error") {
                            // ScheduleService.updateEventScheduleStatusEventsEventIdScheduleStatusPut(id, {status: "not_running"});
                            api.put(`/events/${id}/schedule/status`, {status: "not_running"});
                        }
                    }}>Dismiss</Button>
                </div>}
                {mode === "onboarding" && <Main>
                    <Onboarding onAutomatic={() => {
                        setStatusData({ status: "not_running" });
                        setMode("automatic_running");
                        (async () => {
                            try {
                                // await ScheduleService.updateEventScheduleStatusEventsEventIdScheduleStatusPut(id, {status: "not_running"});
                                // await ScheduleService.startSchedulerForEventEventsEventIdScheduleStartGet(id);
                                await api.put(`/events/${id}/schedule/status`, {status: "not_running"});
                                await api.get(`/events/${id}/schedule/start`);
                            } catch (e) {
                                if (e.status === 409) {
                                    setMode("automatic_error");
                                    setAutoError(<>Your event is misconfigured. Check that <StyledRouterLink to="../settings/scheduling">your scheduling settings</StyledRouterLink> are complete.</>);
                                } else {
                                    setMode("automatic_error");
                                    if (e.status) {
                                        setAutoError(<>The server returned an unexpected status code: <code>{e.status}</code></>);
                                    } else if (e.message) {
                                        setAutoError(<>An error occurred: <code>{e.message}</code></>);
                                    } else {
                                        setAutoError("An unknown error occurred.");
                                    }
                                }
                            }
                        })();
                    }} onManual={() => setMode("manual")} scheduleModel={model} />
                </Main>}
            </div>}
        </div>
        <StyledDialog open={resetState !== "closed"} onClose={() => {
            if (resetState !== "resetting") setResetState("closed");
        }} borderClassName="border-red-500 dark:border-red-400">
            <BigIcon className="bg-red-500 dark:bg-red-400 mx-auto mb-4 text-white" icon={faExclamationTriangle}></BigIcon>
            <Dialog.Title className="heading mb-1 text-center">Reset schedule?</Dialog.Title>
            <Dialog.Description className="mb-6 w-full text-center">
                {resetState === "resetting" ? <LoadingText messages={[
                    "Resetting schedule...",
                ]} /> : "You can't undo this action."}
            </Dialog.Description>
            {resetState === "error" && <ErrorText className="w-full text-center">Couldn't reset schedule. Try again?</ErrorText>}
            <div className="flex mt-4 w-full">
                <Button className="grow" kind="secondary" onClick={() => setResetState("closed")} disabled={resetState === "resetting"}>Cancel</Button>
                <div className="w-4"></div>
                <Button className="grow" kind="destructive" onClick={async () => {
                    setResetState("resetting");
                    try {
                        // await ScheduleService.resetEventScheduleEventsEventIdScheduleDelete(id);
                        await api.delete(`/events/${id}/schedule`);
                        setResetState("closed");
                        setModel(model.emptyCopy());
                        setMode("onboarding");
                    } catch (e) {
                        setResetState("error");
                        console.error(e);
                    }
                }} disabled={resetState === "resetting"}>Reset</Button>
            </div>
        </StyledDialog>
    </OneColumnLayout>;
}
