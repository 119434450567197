import { faArrowRight, faCalendarAlt, faClipboard, faClock, faObjectGroup, faPlusSquare, faSquarePollHorizontal, faTh, faUserCircle, faUserPlus, faUsers, faUsersCog, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from 'classnames';
import { useEvent, usePermissionChecker } from '../../components/EventLayout.tsx';
import { OneColumnLayout } from '../../components/OneColumnLayout';
import { maxHue, minHue } from '../../components/SettingsPanel';
import { RouterLinkButton } from '../../components/ui/Button';
import { Main } from '../../components/ui/Main';
import { useUser } from '../../lib/auth.js';
import permissions from '../../permissions.json';

function Section({title, description, children}) {
    return <section className="mt-10">
        <h2 className="font-semibold uppercase tracking-wide text-lg font-display">{title}</h2>
        <p className="mb-2 muted">{description}</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-4">
            {children}
        </div>
    </section>;
}

function Action({ to, icon, iconClass = "", title, hueFraction = 0 }) {
    const hue = hueFraction * (maxHue - minHue) + minHue;
    return <RouterLinkButton to={to} size="unsized" className="bg-white dark:bg-gray-800 dark:active:bg-gray-700 transition-all px-2 py-6 sm:py-2 flex flex-col items-center justify-center shadow-lg hover:shadow-xl text-center group sm:h-40" style={{ "--settings-accent-hue": `${hue}deg` }}>
        {icon && <FontAwesomeIcon icon={icon} className={classNames("text-5xl mb-4 text-settings-accent", iconClass)} />}
        <div className="font-medium">{title} <FontAwesomeIcon icon={faArrowRight} className="ml-1 relative left-0 group-hover:left-1 transition-all" /></div>
    </RouterLinkButton>;
}

export function EventHome() {
    const check = usePermissionChecker();
    const { settings } = useEvent();
    const proj_lock = settings.survey_locks.project;

    let timeOfDay = "morning";
    const hours = new Date().getHours();
    if (hours >= 17) {
        timeOfDay = "evening";
    } else if (hours >= 12) {
        timeOfDay = "afternoon";
    }

    const { name: userName, id: userID } = useUser();
    const { name: eventName } = useEvent();

    let hueFraction = 0;
    const nextHue = () => {
        const current = hueFraction;
        hueFraction += 0.1;
        return current;
    };

    return <OneColumnLayout title={eventName}>
        <Main className="pt-4 pb-6">
            <p className="mb-4 lead">Good {timeOfDay}, {userName}! What would you like to do today?</p>

            <Section title="Users &amp; People" description={check() ? "Add or change the people in your event, and control what they can do." : "Manage your profile here."}>
                {check(permissions.manageInvites) && <Action icon={faUserPlus} to="users/new" title="Invite users" hueFraction={nextHue()} />}
                {check(permissions.viewUserAll) && <Action icon={check() ? faUsersCog : faUsers} to="users" title={check() ? "View, edit, and remove users" : "View users"} hueFraction={nextHue()} />}
                <Action icon={faUserCircle} to={`users/${userID}`} title="Edit your profile" hueFraction={nextHue()} />
                {check() && <Action icon={faUserShield} to="settings" title="Manage roles and permissions" hueFraction={nextHue()} />}
            </Section>

            <Section title="Projects" description={check() ? "Manage and view all projects." : "Create and review projects."}>
                <Action to="projects" icon={faTh} title={check(permissions.createAllFeedback) || check(permissions.createAssignedFeedback) ? "See and review projects" : "See projects"} hueFraction={nextHue()} />
                {(check(permissions.createProject) && !proj_lock) && <Action to="projects/new" icon={faPlusSquare} title="Create a project" hueFraction={nextHue()} />}
                {check(permissions.viewAllSchedule) && <Action to="scheduling" icon={faClock} title="Schedule projects and reviewers" hueFraction={nextHue()} />}
            </Section>

            <Section title="Settings" description="Tweak your event to your liking. Manage event details, categories, questionnaires, and more.">
                {check() && <Action to="settings/general" icon={faCalendarAlt} title="Edit event details" hueFraction={nextHue()} />}
                {check(permissions.manageCategories) && <Action to="settings/category" icon={faObjectGroup} title="Add or remove categories" hueFraction={nextHue()} />}
                {check(permissions.editFeedbackForm) && <Action to="settings/review" icon={faClipboard} title="Manage questions for judges" hueFraction={nextHue()} />}
                {check(permissions.editRegistrationForm) && <Action to="settings/questionnaire" icon={faSquarePollHorizontal} title="Make a registration form" hueFraction={nextHue()} />}
            </Section>
        </Main>
    </OneColumnLayout>;
}
