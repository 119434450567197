import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Button } from "./ui/Button";

export const minHue = 159;
export const maxHue = 300;

export function SettingsPanel({icon, title, children, hueFraction = 0, className, defaultOpen = false}) {
    const hue = hueFraction * (maxHue - minHue) + minHue;
    return <div className={classNames(className, "overflow-hidden")} style={{"--settings-accent-hue": `${hue}deg`}}>
        <Disclosure defaultOpen={defaultOpen}>{({open}) =>
            <>
                <Disclosure.Button
                    as={Button}
                    size="unsized"
                    mouseEffect
                    mouseEffectColor={`hsl(${hue}, 100%, 50%)`}
                    mouseEffectClassName="mix-blend-normal opacity-0"
                    mouseEffectHoverClassName="mix-blend-normal opacity-20"
                    mouseEffectActiveClassName="mix-blend-normal opacity-30"
                    className={`px-2 py-1 w-full border-2 ${open ? "rounded-b-none border-settings-accent bg-settings-accent/10" : "border-gray-200 dark:border-gray-700 hover:border-settings-accent/60 hover:bg-settings-accent/5"} rounded-lg transition-all`}
                >
                    <h3 className="subheading w-full flex items-center">
                        <FontAwesomeIcon className="w-6 mr-1 text-settings-accent" icon={icon} />
                        {title}
                        <span className="flex-grow" />
                        <FontAwesomeIcon icon={faAngleDown} className={`text-settings-accent mr-1 transition-transform ${open ? "-rotate-180" : ""}`} />
                    </h3>
                </Disclosure.Button>
                <Transition
                    enter="transition duration-100 ease-out overflow-hidden"
                    enterFrom="transform scale-y-0 -translate-y-1/2 opacity-0 border-gray-200 dark:border-gray-700"
                    enterTo="transform scale-y-100 opacity-100"
                    leave="transition duration-75 ease-out overflow-hidden"
                    leaveFrom="transform scale-y-100 opacity-100"
                    leaveTo="transform scale-y-0 -translate-y-1/2 opacity-0 border-gray-200 dark:border-gray-700"
                >
                    <Disclosure.Panel className={`rounded-b-lg border-2 border-t-0 border-settings-accent`}>
                        {children}
                    </Disclosure.Panel>
                </Transition>
            </>
        }</Disclosure>
    </div>;
}
