import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { SaveChanges } from '../../../../components/SaveChanges';
import { useAPI } from '../../../../lib/API.js';
import { ScheduleTable } from './ScheduleTable';
import { ScheduleCards } from './ScheduleCards';

/**
 * @param {{scheduleModel: ScheduleModel, displayAs: string, showRole: string}} props 
 */
export function ManualScheduleEditor({ scheduleModel: model, setScheduleModel: setModel, displayAs, showRole, displayTable, colorCode, colorType, timeslotName, categories }) {
    const [isSaved, setSaved] = useState(false);
    const [serverError, setServerError] = useState(null);
    const api = useAPI();
    console.log(model);
    const formik = useFormik({
        initialValues: {
            scheduleModel: model,
        },
        onSubmit: async ({ scheduleModel }) => {
            try {
                await scheduleModel.uploadSchedule(api, model);
                setModel(scheduleModel);
                setSaved(true);
            } catch (e) {
                setServerError(e);
                console.error(e);
            }
        },
        enableReinitialize: true,
    });
    const { values, setFieldValue } = formik;

    return <div className="pb-16">
        {displayTable && <ScheduleTable scheduleModel={values.scheduleModel} setScheduleModel={model => {
            setFieldValue("scheduleModel", model);
        }} displayAs={displayAs} showRole={showRole} colorCode={colorCode} colorType={colorType} timeslotName={timeslotName} categories={categories} />}
        {!displayTable && <ScheduleCards scheduleModel={values.scheduleModel} setScheduleModel={model => {
            setFieldValue("scheduleModel", model);
        }} displayAs={displayAs} showRole={showRole} colorCode={colorCode} colorType={colorType} categories={categories} />}
         

        <FormikProvider value={formik}>
            <SaveChanges isSaved={isSaved} serverError={serverError} isChanged={values.scheduleModel !== model} fixed />
        </FormikProvider>
    </div>;
}
