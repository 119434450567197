import { faTh } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import { SurveyEditorForm } from '../../../components/surveys/SurveyEditorForm';
import permissions from "../../../permissions.json";

export function ProjectDetails() {
    return <>
        <Helmet>
            <title>Project Details</title>
        </Helmet>
        <SurveyEditorForm title="Project Details" description="Customize the details that need to be filled out for each project." surveyType="project" />
    </>;
}

export const ProjectDetailsPageDefinition = {
    title: "Project Details",
    content: <ProjectDetails />,
    icon: faTh,
    route: "projectdetails",
    permission: permissions.editProjectForm,
};
