import { Button, ExternalLinkButton as LinkButton } from '../../components/ui/Button';
import { StyledExternalLink } from '../../components/ui/Link';
import { Main } from '../../components/ui/Main';
import { Input, Select, Textarea } from '../../components/ui/Input';
import { Dialog, Popover } from '@headlessui/react';
import { PopoverPanel } from '../../components/ui/Popover';
import { useState } from 'react';
import { StyledDialog } from '../../components/ui/Dialog';
import { useRequest } from '../../lib/API.js';
import { TwoColumnLayout, TwoColumnLayoutContext } from '../../components/TwoColumnLayout';
import { BackButton } from '../../components/BackButton';
import { LoadingText } from '../../components/ui/Loading';

function App() {
    const [isOpen, setOpen] = useState(false);

    return (
        <Main>
            <p className="subtitle">Expodite</p>
            <h2 className="heading">Heading</h2>
            <h3 className="subheading">Subheading</h3>
            <h4 className="subsubheading">Subsubheading</h4>
            <p>Normal text</p>
            <p className="caption">Caption</p>
            <p className="over">Overline</p>
            <LoadingText />
            <div className="pt-6 pb-1">
                <LinkButton size="sm" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" kind="primary" className="mr-1">Hello, world!</LinkButton>
                <Button size="sm" kind="secondary" className="mr-1">Hello, world!</Button>
                <Button size="sm" kind="success" className="mr-1">Hello, world!</Button>
                <Button size="sm" kind="destructive" className="mr-1">Hello, world!</Button>
                <Button size="sm" className="bg-purple-500 active:bg-purple-600 text-white">Hello, world!</Button>
            </div>
            <div className="pb-1">
                <LinkButton href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" kind="primary" className="mr-1">Hello, world!</LinkButton>
                <Button kind="secondary" className="mr-1">Hello, world!</Button>
                <Button kind="success" className="mr-1">Hello, world!</Button>
                <Button kind="destructive" className="mr-1">Hello, world!</Button>
                <Button className="bg-purple-500 active:bg-purple-600 text-white">Hello, world!</Button>
            </div>
            <div className="pb-1">
                <LinkButton size="lg" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" kind="primary" className="mr-1">Hello, world!</LinkButton>
                <Button size="lg" kind="secondary" className="mr-1">Hello, world!</Button>
                <Button size="lg" kind="success" className="mr-1">Hello, world!</Button>
                <Button size="lg" kind="destructive" className="mr-1">Hello, world!</Button>
                <Button size="lg" className="bg-purple-500 active:bg-purple-600 text-white">Hello, world!</Button>
            </div>
            <div className="pb-1">
                <LinkButton href="#" size="icon" kind="primary-text">?</LinkButton>
                <Button size="icon" kind="success-text">+</Button>
                <Button size="icon" kind="destructive-text">&times;</Button>
            </div>
            <div className="pb-1">
                <p>These are <StyledExternalLink kind="primary" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">not</StyledExternalLink> <StyledExternalLink kind="success" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">suspicious</StyledExternalLink> <StyledExternalLink kind="destructive" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">links</StyledExternalLink>.</p>
            </div>
            <div>
                <Input type="text" placeholder="Text!" defaultValue="A textbox" className="mr-1" />
                <Input type="text" defaultValue="A disabled textbox" className="mr-1" disabled />
                <Select>
                    <option>Thing 1</option>
                    <option>Thing 2</option>
                </Select>
            </div>
            <div>
                <Textarea defaultValue="A textarea" />
            </div>
            <div className="pb-1">
                <Popover className="relative mb-1">
                    <Popover.Button as={Button} kind="destructive">Delete everything</Popover.Button>
                    <PopoverPanel className="mt-1">
                        <p>Are you sure?</p>
                        <Button kind="destructive" size="lg">Yesn't</Button>
                    </PopoverPanel>
                </Popover>
                <Button kind="success" onClick={() => setOpen(true)}>Obnoxious dialog box</Button>
                <StyledDialog open={isOpen} onClose={() => setOpen(false)}>
                    <Dialog.Title className="heading mb-3">Sign up for Dialogs as a Service today</Dialog.Title>
                    <Dialog.Description className="mb-4">Now at a price of only <strong>$999,999,999/week!</strong> <em>Wow!</em></Dialog.Description>
                    <div className="flex">
                        <Button kind="secondary" className="grow basis-1/2" onClick={() => setOpen(false)}>I want to be clickshamed</Button>
                        <div className="w-4"></div>
                        <Button kind="primary" className="grow basis-1/2" onClick={() => setOpen(false)}>Yes, take my wallet!</Button>
                    </div>
                </StyledDialog>
            </div>
        </Main>
    );
}

export function Test() {
    const { loading, data, error, reload } = useRequest(api => api.get("/"));

    return <TwoColumnLayout>
        <TwoColumnLayout.Primary>
            <div className="p-4">
                {loading && <p>Loading from server...</p>}
                <p>
                    {data && <>{data} <Button kind="secondary" onClick={() => reload()} disabled={loading}>Reload</Button></>}
                    {error && <strong>Error: {error.message}</strong>}
                </p>
                <TwoColumnLayoutContext.Consumer>
                    {({ setCurrent }) => <Button kind="primary" className="md:hidden" onClick={() => setCurrent("secondary")}>Show secondary</Button>}
                </TwoColumnLayoutContext.Consumer>
            </div>
        </TwoColumnLayout.Primary>
        <TwoColumnLayout.Secondary>
            <div className="pt-4 px-4 md:hidden">
                <BackButton />
            </div>
            <App />
        </TwoColumnLayout.Secondary>
    </TwoColumnLayout>;
}
