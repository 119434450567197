import { Transition } from "@headlessui/react";
import { Popover } from "@headlessui/react";
import classNames from 'classnames';

export function PopoverPanel({className, children, usePadding = true, ...props}) {
    return <Transition
        as={Popover.Panel}
        className={classNames("absolute z-30 rounded bg-white/70 dark:bg-gray-800/70 backdrop-blur shadow-lg border dark:border-gray-600 transform", usePadding && "p-4", className)}
        enter="transition duration-200 ease-out"
        enterFrom="-translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition duration-100 ease-in"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="-translate-y-2 opacity-0"
        {...props}
    >
        {children}
    </Transition>;
}
