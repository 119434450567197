import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../components/ui/Button";
import { ErrorText, Input, Select } from "../../../components/ui/Input";
import { Main } from "../../../components/ui/Main";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import { useAPI, useRequest } from "../../../lib/API";
import { submitSurvey } from "../../../lib/surveys";
import { LoadingText } from "../../../components/ui/Loading";
import { OneColumnLayout } from '../../../components/OneColumnLayout';
import { useEvent } from '../../../components/EventLayout.tsx';
import { useContext } from "react";
import { ProjectsContext } from "./list";
import { Survey, useEmptyResponse, validateSurveyResponse } from "../../../components/surveys/Survey";
import Helmet from "react-helmet";
import { CategoriesService, SurveysService, ProjectsService } from '../../../client';

function CatSelect({categories, cat, onCatChange, id, ...props}) {
    useEffect(() => {
        if (!cat) {
            onCatChange(categories[0]);
        }
    }, [categories, cat, onCatChange]);

    return <Select id={id} {...props} value={cat?.id} onChange={event => onCatChange(categories.find(cat => cat.id === event.target.value))}>
        {categories.map(cat => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
    </Select>;
}


export function NewProjectForm() {
    const api = useAPI();
    const { id: eventID } = useEvent();
    const cats = useRequest(() => CategoriesService.listEventCategoriesEventsEventIdCategoriesGet(eventID));
    const [serverError, setServerError] = useState(null);
    const [ProjectCat, setProjectCat] = useState(null);
    const projectsContext = useContext(ProjectsContext);
    const {upsert} = projectsContext ?? {upsert: () => {}};

    const {data: questions, error: questionsError} = useRequest(() => SurveysService.getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(eventID, "project"));
    const emptyResponse = useEmptyResponse(questions);

    const formik = useFormik({
        initialValues: {
            name: "",
            category_id: null,
            details: emptyResponse,
        },
        validate: ({ name, details }) => {
            const errors = {};
            if (!name) {
                errors.name = "Required";
            }
            if (questions) {
                const surveyErrors = validateSurveyResponse(questions, details);
                if (surveyErrors) {
                    errors.details = surveyErrors;
                }
            }
            return errors;
        },
        onSubmit: async ({ name, category_id, details }) => {
            setServerError(null);
            category_id = ProjectCat?.id;
            try {
                const project = await ProjectsService.createProjectEventsEventIdProjectsPost(eventID, {
                    name,
                    category_id,
                });
                // TODO
                await submitSurvey(api, "post", `/project/${project.id}/details`, questions, details);

                upsert(project);
                navigate(`../${project.id}`);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
    });
    const { handleSubmit, getFieldProps, touched, errors, isSubmitting, isValid } = formik;
    const navigate = useNavigate();

    return <OneColumnLayout title="Create Project">
        <Helmet>
            <title>Create project</title>
        </Helmet>
        <Main className="flex py-8">
            <form onSubmit={handleSubmit} className="flex-grow">
                <label className="block mb-2 subheading" htmlFor="name">What's the name?</label>
                <Input className="w-full max-w-lg" id="name" placeholder="My New Project" {...getFieldProps("name")} error={touched.name && errors.name} />
                <ErrorText className="mt-1">{touched.name && errors.name}</ErrorText> 

                {cats.data && cats.data.length > 0 &&
                    <div>
                        <label className="block mt-4 mb-2 subheading" htmlFor="category">Which category?</label>
                        {cats.loading && <LoadingText messages={["Loading categories..."]} />}
                        {cats.error && <ErrorText>Couldn't load categories. Try reloading the page.</ErrorText>}
                        {cats.data && <CatSelect id="category" categories={cats.data} cat={ProjectCat} onCatChange={setProjectCat}/>}
                    </div>
                }

                <FormikProvider value={formik}>
                    <div className="mt-4">
                        {questions && <Survey questions={questions} name="details" />}
                        {(!questions && !questionsError) && <LoadingText messages={["Loading questions..."]} />}
                        {questionsError && <ErrorText>Couldn't load questions. Try reloading the page.</ErrorText>}
                    </div>
                </FormikProvider>
                
                <div className="mt-8 flex justify-start items-center">
                    <Button size="md" type="submit" kind="primary" id="newproject-button" disabled={!isValid || isSubmitting || !questions}>Create project <FontAwesomeIcon className="ml-3" icon={faArrowRight} /></Button>
                    {serverError && <ErrorText className="ml-4">Couldn't create the project. Try again?</ErrorText>}
                    {isSubmitting && <LoadingText className="ml-4" messages={[
                        "Creating project...",
                        "Assembling project...",
                        "Baking project...",
                        "Constructing project...",
                        "Performing machine learning on project...",
                        "Doing project for you",
                    ]}></LoadingText>}
                    
                </div>
            </form>
        </Main>
    </OneColumnLayout>;
}
