import { faSquarePollHorizontal } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import { SurveyEditorForm } from '../../../components/surveys/SurveyEditorForm';
import permissions from "../../../permissions.json";

export function Questionnaire() {
    return <>
        <Helmet>
            <title>Registration Questions</title>
        </Helmet>
        <SurveyEditorForm title="Registration Questions" description="Ask new users a set of onboarding questions." surveyType="registration" supportsCategories={false} />
    </>;
}

export const QuestionnairePageDefinition = {
    title: "Registration Questions",
    content: <Questionnaire />,
    icon: faSquarePollHorizontal,
    route: "questionnaire",
    permission: permissions.editRegistrationForm,
};
