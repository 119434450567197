import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { createContext, useContext } from "react";
import classNames from "classnames";
import { Button } from "./ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const BackContext = createContext({icon: faArrowLeft, label: "Back", className: "hidden", action: () => {}});

/**
 * @param {{size?: "sm" | "lg", className?: string}} props 
 * @returns a back button
 */
export function BackButton({ size, className }) {
    const { icon, label, action, className: contextClass } = useContext(BackContext);
    return <Button size={size === "sm" ? "icon" : "icon-lg"} className={classNames(contextClass, className)} onClick={() => {
        action();
    }}><FontAwesomeIcon icon={icon} /><span className="sr-only">{label}</span></Button>;
}
