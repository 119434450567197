import { useContext, useState, Fragment, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { OneColumnLayout } from "../../../components/OneColumnLayout";
import { Button } from "../../../components/ui/Button";
import { LoadingScreen } from "../../../components/ui/Loading";
import { StyledDialog } from "../../../components/ui/Dialog";
import { Main } from "../../../components/ui/Main";
import { useAPI, useRequest } from "../../../lib/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { BigIcon } from "../../../components/ui/BigIcon";
import { Dialog } from "@headlessui/react";
import { LoadingText } from "../../../components/ui/Loading";
import { ErrorText } from "../../../components/ui/Input";
import { useEvent, usePermissionChecker } from "../../../components/EventLayout";
import { useUser } from "../../../lib/auth";
import { UsersContext } from "./list";
import { useNavigate } from "react-router-dom";
import { StyledExternalLink, StyledRouterLink } from "../../../components/ui/Link";
import { Field, FormikProvider, useFormik } from "formik";
import { formatPlural } from "../../../lib/utils";
import { SurveyForm } from "../../../components/surveys/SurveyForm";
import { SurveyViewer } from "../../../components/surveys/SurveyViewer";
import { submitSurvey } from "../../../lib/surveys";
import Helmet from "react-helmet";
import permissions from "../../../permissions.json";
import { UsersService, RolesService, SurveysService } from "../../../client";
import { useQueryClient } from "@tanstack/react-query";
import { getGetEventEventsEventIdGetQueryKey, getListEventsEventsGetQueryKey } from "src/codegen";

/**
 * @param {{open: boolean, onClose: () => void, user: User, setUser: (user: User, roles: Role[]) => void}} props 
 */
export function UserRolesEditor({open, onClose, user, setUser}) {
    const { id: eventID, admin } = useEvent();
    const [serverError, setServerError] = useState(null);

    /** @type {import("../../../lib/API").RequestResult<Role[]>} */
    const { data: roles, loading, error } = useRequest(() => RolesService.listEventRolesEventsEventIdRolesGet(eventID));

    const formik = useFormik({
        initialValues: {
            roles: user.roles.map(r => r.id),
        },
        onSubmit: async (values) => {
            setServerError(null);

            // If the role IDs are unchanged, skip submission.
            if (values.roles.length === user.roles.length) {
                const newSet = new Set(values.roles);
                if (user.roles.every(role => newSet.has(role.id))) {
                    onClose();
                    return;
                }
            }

            try {
                const newUser = await UsersService.updateUserRolesEventsEventIdUsersUserIdRolesPut(eventID, user.id, values.roles);
                const roleSet = new Set(newUser.roles.map(r => r.id));
                const newRoles = roles.filter(role => roleSet.has(role.id));
                setUser(newUser, newRoles);
                onClose();
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
        enableReinitialize: true,
    });
    const { handleSubmit, isSubmitting, resetForm, values } = formik;

    const currentUser = useUser();
    const isMe = currentUser.id === user.id;
    const adminRoles = useMemo(() => (roles && isMe && admin) && new Set([...roles].filter(r => r.admin).map(r => r.id)), [roles, isMe, admin]);
    const isRemovingAdminFromSelf = adminRoles && !values.roles.some(r => adminRoles.has(r));

    return <StyledDialog open={open} onClose={() => {
        if (!isSubmitting) {
            resetForm();
            onClose();
        }
    }}>
        <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
                <Dialog.Title className="heading mb-2">Edit roles for {user.name}</Dialog.Title>
                <Dialog.Description>Select as many roles as you'd like. Manage roles in <StyledRouterLink to="../../settings">Event Settings</StyledRouterLink>.</Dialog.Description>
                <div className="my-4">
                    {roles && roles.sort((a, b) => a.order - b.order).map(role => {
                        const checkboxID = `role-${role.id}`;

                        return <div key={role.id} className="flex items-center">
                            <Field id={checkboxID} className="rounded cursor-pointer" name="roles" type="checkbox" value={role.id} />
                            <label htmlFor={checkboxID} className={`ml-2 cursor-pointer ${role.admin ? "text-yellow-600 dark:text-yellow-400 italic" : ""}`}>{role.name}</label>
                        </div>;
                    })}
                    {loading && <LoadingText messages={["Loading roles..."]} />}
                    {error && <ErrorText>Couldn't load roles. Try reloading the page.</ErrorText>}
                </div>
                {serverError && <ErrorText>Couldn't save your edits. Try again?</ErrorText>}
                <div className="flex mt-4 w-full items-center">
                    {isRemovingAdminFromSelf ? <div className="flex items-center text-red-600 dark:text-red-400 italic">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        <div>Saving will remove your administrative privileges.</div>
                    </div> : <div>
                        <strong>{values.roles.length}</strong> {formatPlural(values.roles.length, "role", "roles")} selected
                    </div>}
                    <div className="w-4 flex-grow" />
                    <Button type="button" kind="secondary" onClick={() => {
                        resetForm();
                        onClose();
                    }} disabled={isSubmitting}>Cancel</Button>
                    <div className="w-4" />
                    <Button kind={isRemovingAdminFromSelf ? "destructive" : "primary"} type="submit" disabled={isSubmitting || !roles}>Save</Button>
                </div>
            </form>
        </FormikProvider>
    </StyledDialog>;
}

function RegistrationResponses({userID, eventID}) {
    const check = usePermissionChecker();

    const { data, error } = useRequest(() => Promise.all([
        SurveysService.getQuestionsEventsEventIdSurveysSurveyTypeQuestionsGet(eventID, "registration", check()), //{list_all: check()}
        (async () => {
            try {
                return await SurveysService.getRegistrationByUserEventsEventIdRegistrationsUserIdGet(eventID, userID);
            } catch (e) {
                if (e.status === 404) return null;
                throw e;
            }
        })(),
    ]), [eventID, userID]);

    if (data) {
        const [questions, responses] = data;
        if (questions.length === 0) return null;
        // somehow need to sort out the qs by categories "if agreed to judge cat then ..."
        if (!responses) {
            return <div>This user hasn't filled out their registration form yet.</div>;
        }
        return <SurveyViewer questions={questions} values={responses} />;
    } else if (error) {
        return <ErrorText>Error loading registration form responses.</ErrorText>;
    } else {
        return <LoadingText messages={["Loading registration form.."]} />;
    }
}

export function User() {
    const { id } = useParams();
    const event = useEvent();
    const check = usePermissionChecker();
    const currentUser = useUser();
    const api = useAPI();
    const navigate = useNavigate();
    const usersContext = useContext(UsersContext);
    const queryClient = useQueryClient();

    const { name: eventName, id: eventID } = event;

    const isMe = id === currentUser.id;
    const {data, error} = useRequest(() => UsersService.getEventUserEventsEventIdUsersUserIdGet(eventID, id), [eventID, id]);
    const [user, setUser] = useState(data);
    useEffect(() => {
        setUser(data);
    }, [data]);

    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeleteError, setDeleteError] = useState(false);

    const [isEditingRoles, setEditingRoles] = useState(false);

    const close = () => {
        setDeleteOpen(false);
        setDeleteError(false);
    };

    const [isSurveySubmitted, setSurveySubmitted] = useState(false);
    const canEdit = check(permissions.editAllRegistration || (permissions.editOwnRegistration && isMe));

    return <OneColumnLayout title={user?.name ?? "User"}>
        {!user && !error && <LoadingScreen messages={["Loading user..."]} />}
        {user && <Main>
            <Helmet>
                <title>{user.name}</title>
            </Helmet>
            {(check() || check(permissions.viewUserEmail)) && <div><strong>Email:</strong> <StyledExternalLink href={`mailto:${user.email}`}>{user.email}</StyledExternalLink></div>}
            <div><strong>Roles:</strong> {user.roles.length > 0 ? user.roles.sort((a, b) => a.order - b.order).map((({id, name}, index) => {
                let terminator = ", ";
                if (index === user.roles.length - 2) {
                    terminator = user.roles.length === 2 ? " and " : ", and ";
                } else if (index === user.roles.length - 1) {
                    terminator = "";
                }
                return <Fragment key={id}>{name}{terminator}</Fragment>;
            })) : <span className="muted">None</span>}{check(permissions.manageRoles) && <Button className="ml-1" size="sm" kind="secondary" onClick={() => setEditingRoles(true)}>
                <FontAwesomeIcon className="mr-1" icon={faPencil} /> Edit
            </Button>}</div>

            {isMe ? <>
                <hr className="mt-2 mb-4 border-gray-200 dark:border-gray-700" />
                <h3 className="heading mb-2">Registration Form</h3>
                <SurveyForm editable={canEdit} surveyType="registration" existingResponseURL={`/events/${eventID}/registrations`} onSubmit={async (survey, _, questions) => {
                    //TODO
                    await submitSurvey(api, "post", `/events/${eventID}/registrations`, questions, survey);
                    setSurveySubmitted(true);
                    // navigate(`/event/${eventID}/users/${id}`);
                }} onDelete={async () => {
                    await SurveysService.deleteRegistrationEventsEventIdRegistrationsDelete(eventID);
                    setSurveySubmitted(false);
                }} disabled={existing => {
                    if (existing) return true;
                    return isSurveySubmitted;
                }} hint={existing => {
                    if (isSurveySubmitted) return "Submitted!";
                    if (existing) return "You've already submitted this form.";
                    return "Fill out this form to finish registering.";
                }} emptyText="There aren't any questions to answer." />
            </> : ((check() || check(permissions.viewAllRegistration)) && <RegistrationResponses eventID={eventID} userID={id} />)}

            {(check() || check(permissions.deleteUserAll) || isMe) && <>
                <hr className="mt-2 mb-4 border-gray-200 dark:border-gray-700" />
                <div className="flex flex-row">
                    <Button kind="destructive" onClick={() => setDeleteOpen(true)}>{isMe ? "Leave Event" : "Remove from Event"}</Button>
                </div>
            </>}

            <UserRolesEditor open={isEditingRoles} user={user} onClose={() => setEditingRoles(false)} setUser={(user, roles) => {
                usersContext.upsert(user);
                setUser(user);

                if (isMe) {
                    // Update the event as well.
                    const permissions = new Set();
                    roles.forEach(role => {
                        role.permissions.forEach(permission => {
                            permissions.add(permission);
                        });
                    });

                    queryClient.invalidateQueries(getGetEventEventsEventIdGetQueryKey(eventID));
                }
            }} />
        </Main>}
        {error && <div className="w-full h-full min-h-screen flex justify-center items-center text-center">
            <ErrorText>Couldn't load this user. Try reloading the page.</ErrorText>
        </div>}
        <StyledDialog open={isDeleteOpen} onClose={() => {
            if (!isDeleting) close();
        }} borderClassName="border-red-500 dark:border-red-400">
            <BigIcon className="bg-red-500 dark:bg-red-400 mx-auto mb-4 text-white" icon={faExclamationTriangle}></BigIcon>
            <Dialog.Title className="heading mb-1 text-center">{isMe ? `Leave "${eventName}"?` : `Remove ${user?.name || "this user"} from "${eventName}"?`}</Dialog.Title>
            <Dialog.Description as="div" className="mb-6 w-full text-center">
                {isDeleting ? <LoadingText messages={[
                    "Removing user...",
                ]} /> : (isMe ? "You will need to be re-invited. You can't undo this action." : "This user will need to be re-invited. You can't undo this action.")}
            </Dialog.Description>
            {isDeleteError && <ErrorText className="w-full text-center">{isMe ? "Couldn't leave this event. Try again?" : "Couldn't remove this user. Try again?"}</ErrorText>}
            <div className="flex mt-4 w-full">
                <Button className="grow" kind="secondary" onClick={() => close()} disabled={isDeleting}>Cancel</Button>
                <div className="w-4"></div>
                <Button className="grow" kind="destructive" onClick={async () => {
                    setDeleting(true);
                    setDeleteError(false);
                    try {
                        await UsersService.kickUserFromEventEventsEventIdUsersUserIdDelete(eventID, id);
                        usersContext.remove(id);
                        close();
                        if (isMe) {
                            queryClient.invalidateQueries(getListEventsEventsGetQueryKey());
                            navigate("/events");
                        } else {
                            navigate("..");
                        }
                    } catch (e) {
                        setDeleting(false);
                        setDeleteError(true);
                        console.error(e);
                    }
                }} disabled={isDeleting}>{isMe ? "Leave" : "Remove"}</Button>
            </div>
        </StyledDialog>
    </OneColumnLayout>;
}
