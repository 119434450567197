import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProvider, useFormik } from "formik";
import { useState } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { AccountService, AuthService, OpenAPI } from '../client';
import { ColorBackgroundLayout } from '../components/ColorBackgroundLayout.tsx';
import { Button, ExternalLinkButton } from '../components/ui/Button';
import { ErrorText } from "../components/ui/Input";
import { Input } from '../components/ui/Input';
import { StyledExternalLink, StyledRouterLink } from '../components/ui/Link';
import { LoadingText } from '../components/ui/Loading';
import logo from '../logo.svg';

function OrDivider() {
    return <div className="w-full flex items-center space-x-2">
        <hr className="flex-grow border-gray-500" />
        <div className="text-center my-4 text-gray-600 dark:text-gray-400">or</div>
        <hr className="flex-grow border-gray-500" />
    </div>;
}

export function Login() {
    const { state } = /** @type {{state: {from: Location}}} */ (useLocation());

    let redirect = `${OpenAPI.BASE}/auth/google`;
    if (state?.from) {
        redirect += `?redirect=${encodeURIComponent(state.from.pathname + state.from.search + state.from.hash)}`;
    }

    const [serverError, setServerError] = useState(null);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validate: ({ email, password }) => {
            const errors = {};
            if (!email) {
                errors.email = "Required";
            }
            if (!password) {
                errors.password = "Required";
            }
            return errors;
        },
        onSubmit: async ({ email, password }) => {
            setServerError(null);
            setResendState(null);
            try {
                await AuthService.passwordLoginAuthPasswordLoginPost({
                    email,
                    password,
                });
                window.location = "/events";
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
    });
    const { handleSubmit, getFieldProps, touched, errors, values: { email } } = formik;

    const [resendState, setResendState] = useState("none");
    const resend = async () => {
        setServerError(null);
        setResendState("sending");
        try {
            await AccountService.requestEmailVerifyAccountVerifyRequestPost(email);
            setResendState("sent");
        } catch (e) {
            console.error(e);
            setResendState("none");
            setServerError(e);
        }
    };

    return (
        <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
                <Helmet>
                    <title>Log in</title>
                </Helmet>
                
                <ColorBackgroundLayout>
                    <img className="mx-auto h-20 block mb-4" src={logo} alt="Expodite logo" />
                    <h1 className="sr-only">Expodite</h1>
                    <div className="muted text-lg mt-2 font-display">A <StyledExternalLink kind="muted" href="https://github.com/stoneroof" target="_blank" rel="noopener noreferrer">Stoneroof</StyledExternalLink> project</div>
                    <div className="mt-3 mb-5">To get started, log in.</div>
                    {serverError && <div className="border border-red-500 bg-red-500/30 text-center p-2 rounded">
                        {serverError.body?.detail === "Email not verified" ? <>
                            Please verify your email first. Didn't get the email? <StyledExternalLink href="#" onClick={e => {
                                e.preventDefault();
                                resend();
                            }}>Resend it.</StyledExternalLink>
                        </> : serverError.body?.detail?.toString() ?? (serverError.status ? `The server returned an unexpected error code: ${serverError.status}` : "An unknown error occurred. Check your internet connection?")}
                    </div>}
                    {resendState === "sending" && <div className="border border-blue-500 bg-blue-500/30 text-center p-2 rounded">
                        <LoadingText messages={[
                            "Resending...",
                            "Delivering fresh pizza to your inbox...",
                        ]} />
                    </div>}
                    {resendState === "sent" && <div className="border border-blue-500 bg-blue-500/30 text-center p-2 rounded">
                        Verification email resent. Check your inbox and/or spam.
                    </div>}
                    
                    <div className="flex-col w-full max-w-lg mt-5">
                        <Input className="w-full block" id="email" {...getFieldProps("email")} type="email" placeholder="email@example.com" error={touched.email && errors.email} />
                        <ErrorText className="inline">{errors.email}</ErrorText>
                        <Input className="w-full mt-2 block" id="password" {...getFieldProps("password")} type="password" placeholder="Password" error={touched.password && errors.password} />
                        <ErrorText className="inline">{errors.password}</ErrorText>
                    </div>
                    
                    <Button className="mt-2" size="md" type="submit" kind="primary">Log in</Button>
                    <OrDivider />
                    <ExternalLinkButton href={redirect} className="bg-white text-blue-600 hover:bg-blue-50 active:bg-blue-100 border border-gray-300"><FontAwesomeIcon className="mr-3" icon={faGoogle} /> <span className="whitespace-nowrap">Sign in with Google</span></ExternalLinkButton>
                    <OrDivider />

                    <span>
                        <StyledRouterLink kind="muted" to="/register">Create account!</StyledRouterLink>
                        <StyledRouterLink className="ml-3" kind="muted" to="/forgot">Forgot password?</StyledRouterLink>
                    </span>
                </ColorBackgroundLayout>
            </form>
        </FormikProvider>
    );
}
