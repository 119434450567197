import { faBug } from "@fortawesome/free-solid-svg-icons";
import { Component, useState } from "react";
import { BackButton } from "./BackButton";
import { BigIcon } from './ui/BigIcon';
import { Button } from "./ui/Button";

// Some error messages courtesy of Les Pédants
const devErrorMessages = [
    "You broke it. How dare you.",
    "Oh look at that, you messed up again.",
    <>Wow, you made that typo <em>again</em>? What a new typo.</>,
    "I haven't made that mistake in years!",
    "Congratulations, you've caused an error!",
];

/**
 * @param {{showBack?: boolean, fillScreen?: boolean, error: Error, onAttemptReload: () => void}} props 
 */
export function ErrorPage({ showBack, fillScreen, error, onAttemptReload }) {
    const [devMessage] = useState(() => (!!localStorage.getItem("passiveAggressiveMode") || process.env.NODE_ENV !== "production") ? devErrorMessages[Math.floor(Math.random() * devErrorMessages.length)] : null);
    const [showDebugInfo, setShowDebugInfo] = useState(!!devMessage);

    return <div className={`relative p-4 h-full flex flex-col ${fillScreen ? "min-h-screen" : ""} items-center justify-center text-center`}>
        {showBack && <div className="absolute top-4 left-4"><BackButton size="lg" /></div>}
        <BigIcon icon={faBug} className="bg-red-500 mb-4 text-white" />
        {devMessage ? <>
            <h2 className="heading">{devMessage}</h2>
            <p className="mb-4">An error happened while rendering. Check the console for more details.</p>
        </> : <>
            <h2 className="heading">Oops!</h2>
            <p className="mb-4">It looks like Expodite has encountered an unexpected error. Let us know and we'll fix it.</p>
            <Button kind="secondary" className="mb-4" onClick={() => setShowDebugInfo(!showDebugInfo)}>{showDebugInfo ? "Hide Debug Information" : "Show Debug Information"}</Button>
        </>}
        {showDebugInfo && <pre className="border border-red-500 bg-red-500/20 p-4 rounded whitespace-pre-wrap">{error.message || "No error message"}</pre>}
        {devMessage && <Button kind="primary" className="mt-4" onClick={onAttemptReload}>Attempt Reload</Button>}
    </div>;
}

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {error: null};
    }

    static getDerivedStateFromError(error) {
        return {error};
    }

    componentDidUpdate(prevProps) {
        if (this.props.pathname !== prevProps.pathname) {
            this.setState({error: null});
        }
    }

    render() {
        if (this.state.error) {
            return <ErrorPage {...this.props} error={this.state.error} onAttemptReload={() => this.setState({error: null})} />;
        }
        return this.props.children;
    }
}
