import { createContext, useContext, useEffect, useState } from 'react';

/**
 * @typedef {Error & {status: number, detail?: any}} APIError
 */

export class APIContext {
    /**
     * @param {string} url Base URL of the API, such as https://api.expodite.app
     */
    constructor(url) {
        /**
         * @type {string}
         * @readonly
         */
        this.url = url;
    }

    /**
     * @param {RequestInfo} url 
     * @param {RequestInit} [options] 
     * @returns {Promise<any>}
     */
    async fetch(url, options) {
        const response = await fetch(url, {
            credentials: "include",
            ...options,
            headers: {
                ...options?.headers,
                "Accept": "application/json",
            },
        });

        if (response.status >= 200 && response.status < 300) {
            return await response.json();
        } else {
            /** @type {Partial<APIError>} */
            const error = new Error(response.statusText);
            error.status = response.status;

            try {
                const data = await response.json();
                if (data.detail) error.detail = data.detail;
            } catch (e) {
                console.error(`Error parsing error detail: ${e}`);
            }

            throw error;
        }
    }

    /**
     * @param {string} path
     * @param {Record<string, string>} [query]
     * @returns {Promise<any>}
     */
    async get(path, query) {
        return await this.fetch(this.url + path + (query ? '?' + new URLSearchParams(query).toString() : ''));
    }

    /**
     * @param {string} path
     * @param {Record<string, string> | unknown} param2
     * @param {unknown} [param3]
     * @returns {Promise<any>}
     */
    async post(path, param2, param3) {
        /** @type {Record<string, string> | undefined} */
        const query = param3 === undefined ? undefined : (/** @type {Record<string, string>} */ (param2));

        /** @type {unknown} */
        const body = param3 === undefined ? param2 : param3;
        
        return await this.fetch(this.url + path + (query ? '?' + new URLSearchParams(query).toString() : ''), {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    }

    /**
     * @param {string} path
     * @param {Record<string, string> | unknown} param2
     * @param {unknown} [param3]
     * @returns {Promise<any>}
     */
    async put(path, param2, param3) {
        /** @type {Record<string, string> | undefined} */
        const query = param3 === undefined ? undefined : (/** @type {Record<string, string>} */ (param2));

        /** @type {unknown} */
        const body = param3 === undefined ? param2 : param3;

        return await this.fetch(this.url + path + (query ? '?' + new URLSearchParams(query).toString() : ''), {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    }

    /**
     * @param {string} path
     * @param {Record<string, string> | unknown} param2
     * @param {unknown} [param3]
     * @returns {Promise<any>}
     */
    async patch(path, param2, param3) {
        /** @type {Record<string, string> | undefined} */
        const query = param3 === undefined ? undefined : (/** @type {Record<string, string>} */ (param2));

        /** @type {unknown} */
        const body = param3 === undefined ? param2 : param3;

        return await this.fetch(this.url + path + (query ? '?' + new URLSearchParams(query).toString() : ''), {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    }

    /**
     * @param {string} path
     * @param {Record<string, string> | unknown} param2
     * @param {unknown} [param3]
     * @returns {Promise<any>}
     */
    async delete(path, param2, param3) {
        /** @type {Record<string, string> | undefined} */
        const query = param3 === undefined ? undefined : (/** @type {Record<string, string>} */ (param2));

        /** @type {unknown} */
        const body = param3 === undefined ? param2 : param3;

        return await this.fetch(this.url + path + (query ? '?' + new URLSearchParams(query).toString() : ''), {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    }
}

const context = createContext(new APIContext(process.env.REACT_APP_API_URL || "https://api.expodite.app"));

export function useAPI() {
    return useContext(context);
}

/**
 * @template T
 * @typedef RequestResult
 * @property {boolean} loading
 * @property {T} [data]
 * @property {Error | APIError} [error]
 * @property {number} reloads
 * @property {() => void} reload
 */

/**
 * @template T
 * @param {((api: APIContext) => Promise<T>) | ((api: APIContext) => T)} request
 * @param {unknown[]} [deps]
 * @returns {RequestResult<T>}
 */
export function useRequest(request, deps = []) {
    const api = useAPI();
    const [result, setResult] = useState({});
    const [loading, setLoading] = useState(true);
    const [reloads, setReloads] = useState(0);
    
    useEffect(() => {
        setLoading(true);
        setResult({});

        let cancelled = false;
        (async () => {
            try {
                const data = await request(api);
                if (!cancelled) setResult({ data });
            } catch (error) {
                if (!cancelled) setResult({ error });
            } finally {
                if (!cancelled) setLoading(false);
            }
        })();

        return () => {
            cancelled = true;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, reloads, ...deps]);

    return {
        ...result,
        loading,
        reloads,
        reload() {
            setReloads(reloads + 1);
        },
    };
}
