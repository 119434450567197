/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListUserForEventAdmin } from '../models/ListUserForEventAdmin';
import type { UserReadForEventAdmin } from '../models/UserReadForEventAdmin';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * List Event Users
     * @param eventId
     * @returns ListUserForEventAdmin Successful Response
     * @throws ApiError
     */
    public static listEventUsersEventsEventIdUsersGet(
        eventId: string,
    ): CancelablePromise<Array<ListUserForEventAdmin>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/users',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event User
     * @param eventId
     * @param userId
     * @returns UserReadForEventAdmin Successful Response
     * @throws ApiError
     */
    public static getEventUserEventsEventIdUsersUserIdGet(
        eventId: string,
        userId: string,
    ): CancelablePromise<UserReadForEventAdmin> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/users/{user_id}',
            path: {
                'event_id': eventId,
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Kick User From Event
     * @param eventId
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static kickUserFromEventEventsEventIdUsersUserIdDelete(
        eventId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/events/{event_id}/users/{user_id}',
            path: {
                'event_id': eventId,
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User Roles
     * @param eventId
     * @param userId
     * @param requestBody
     * @returns UserReadForEventAdmin Successful Response
     * @throws ApiError
     */
    public static updateUserRolesEventsEventIdUsersUserIdRolesPut(
        eventId: string,
        userId: string,
        requestBody: Array<string>,
    ): CancelablePromise<UserReadForEventAdmin> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/events/{event_id}/users/{user_id}/roles',
            path: {
                'event_id': eventId,
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
