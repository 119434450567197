import { Helmet } from 'react-helmet';
import { Main } from '../components/ui/Main';

function Item({ title, children }) {
    return <div className="mt-6">
        <h2 className="text-green-600 dark:text-green-400 font-medium text-2xl font-serif">{title}</h2>
        <div className="text-sm opacity-80 italic">
            {children}
        </div>
    </div>;
}

export function MenuDuJour() {
    return <Main className="flex flex-col items-center text-center py-16">
        <Helmet>
            <title>Menu du jour</title>
            <meta property="og:title" content="Menu du jour" />
            <meta property="og:description" content="Menu du jour" />
            <meta property="og:url" content="https://expodite.ewf.me/menu" />
        </Helmet>
        <h1 className="text-4xl font-display font-bold text-blue-600 dark:text-blue-400 pb-6 border-b w-full border-blue-600/50 dark:border-blue-400/50">Menu du Jour</h1>
        <Item title="Honey-Miso Eggplant">
            <p>Oven roasted, sweet and salty, sprinkled with sesame</p>
        </Item>
        <Item title="French Onion Soup">
            <p>Classic recipe topped with brioche and melted Gruyére cheese</p>
            <p>"For all those French people"</p>
        </Item>
        <Item title="Dijon-Brown Sugar Glazed Salmon">
            <p>Garnished with roasted potato rounds and rosemary</p>
            <p>The new "sweet and spicy"</p>
        </Item>
        <Item title="Tenderloin Beef Steak">
            <p>Served beside kale sauteed with créme fraîche</p>
        </Item>
        <Item title="Raspberry Chocolate Soufflé">
            <p>Finished with a surprise of raspberry coulis and fresh mint</p>
            <p>Direct any compliments to Gordon Ramsay - it's his recipe</p>
        </Item>
        <Item title="Anthony's 5% Cash Back Grocery Run">
            <p>These, I admit, were not homemade</p>
        </Item>
    </Main>;
}
