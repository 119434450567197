import classNames from "classnames";
import React, { forwardRef, ReactNode, HTMLProps } from 'react';
import { Link } from "react-router-dom";

const kinds = {
    primary: "text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 decoration-blue-500/50 dark:decoration-blue-400/50 dark:hover:decoration-blue-400",
    success: "text-green-600 dark:text-green-500 hover:text-green-600 dark:hover:text-green-400 decoration-green-600/50 hover:decoration-green-600/80 dark:decoration-green-500/50 dark:hover:decoration-green-500",
    destructive: "text-red-500 hover:text-red-600 dark:hover:text-red-400 decoration-red-500/50 hover:decoration-red-500/80",
    muted: "text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 decoration-gray-500/80 dark:decoration-gray-400/80 hover:decoration-gray-600/80 dark:hover:decoration-gray-300/80",
};

function getUnderlineClass(underlineStyle) {
    if (underlineStyle === "none") {
        return;
    } else if (underlineStyle === "hover") {
        return "hover:underline focus:underline";
    } else {
        return "underline";
    }
}

/**
 * @typedef StyledLinkProps
 * @property {keyof kinds | "none"} [kind]
 * @property {"none" | "always" | "hover"} [underline]
 */

interface StyledLinkProps extends HTMLProps<HTMLAnchorElement> {
    kind?: string;
    className?: string;
    underline?: boolean;
    children: ReactNode;
}

export const StyledExternalLink = forwardRef<HTMLAnchorElement, StyledLinkProps>(({
    children,
    kind: kindIdentifier,
    className,
    underline,
    ...props
}, ref) => {
    const kind = kinds[kindIdentifier || "primary"];
    return <a {...props} className={classNames(
        getUnderlineClass(underline),
        "transition-colors",
        kind,
        className,
    )}>{children}</a>;
});

// export const StyledExternalLink = forwardRef(/** @type {React.ForwardRefRenderFunction<HTMLAnchorElement, StyledLinkProps & React.HTMLProps<HTMLAnchorElement>>} */ (({children, kind: kindIdentifier, className, underline, ...props}, ref) => {
//     const kind = kinds[kindIdentifier || "primary"];
//     return <a {...props} className={classNames(
//         getUnderlineClass(underline),
//         "transition-colors",
//         kind,
//         className,
//     )}>{children}</a>;
// }));

{/* @ts-ignore */}
export const StyledRouterLink = forwardRef<Link, StyledLinkProps>(({
    children,
    kind: kindIdentifier,
    className,
    underline,
    ...props
}, ref) => {
    const kind = kinds[kindIdentifier || "primary"];
    {/* @ts-ignore */}
    return (<Link
        {...props}
        className={classNames(
            getUnderlineClass(underline),
            "transition-colors",
            kind,
            className,
        )}
        ref={ref}
    >
        {children}
    </Link>
    );
});

// export const StyledRouterLink = forwardRef(/** @type {React.ForwardRefRenderFunction<import('react-router-dom').Link, StyledLinkProps & import('react-router-dom').LinkProps>} */(({ children, kind: kindIdentifier, className, underline, ...props }, ref) => {
//     const kind = kinds[kindIdentifier || "primary"];
//     return <Link {...props} className={classNames(
//         getUnderlineClass(underline),
//         "transition-colors",
//         kind,
//         className,
//     )}>{children}</Link>;
// }));
