import { useState, useEffect } from 'react';

const defaultMessages = [
    "Loading...",
    "Getting ready...",
    "Herding cats...",
    "Extracting bytes...",
    "Researching microscopes...",
    "Calculating the Answer to the Ultimate Question of Life, the Universe, and Everything...",
    "Creating a hyperspace bypass...",
    "Constructing hourglasses...",
];

/**
 * @typedef LoadingAnimationState
 * @property {number} opacity
 * @property {number} [startTime]
 * @property {-1 | 1} direction
 * @property {string} textIndex
 */

const animationDuration = 1500;

function getLoadingTextIndex(current, messageCount) {
    if (messageCount === 1) return 0;
    if (current < 0) return Math.floor(Math.random() * messageCount);

    const random = Math.floor(Math.random() * (messageCount - 1));
    if (random >= current) {
        return random + 1;
    } else {
        return random;
    }
}

/**
 * @param {{messages?: string[], className?: string}} props 
 */
export function LoadingText({ messages: customMessages, className }) {
    const messages = customMessages || defaultMessages;
    const [animationState, setAnimationState] = useState(/** @type {LoadingAnimationState} */({ opacity: 0, direction: 1, textIndex: getLoadingTextIndex(-1, messages.length) }));

    useEffect(() => {
        const request = requestAnimationFrame(time => {
            {/* @ts-ignore */}
            const { opacity, startTime, direction, textIndex } = animationState;
            if (startTime === undefined) {
                {/* @ts-ignore */}
                setAnimationState({ opacity, startTime: time, direction, textIndex });
                return;
            }

            const timePassed = (time - startTime) / animationDuration;
            if (timePassed >= 1) {
                setAnimationState({
                    opacity: direction === 1 ? 1 : 0,
                    direction: -animationState.direction,
                    textIndex: direction === -1 ? getLoadingTextIndex(textIndex, messages.length) : textIndex,
                });
            } else {
                const original = direction === 1 ? 0 : 1;
                {/* @ts-ignore */}
                setAnimationState({ opacity: original + direction * timePassed, startTime, direction, textIndex });
            }
        });

        return () => cancelAnimationFrame(request);
    }, [animationState, messages]);

    return <div className={className} style={{ opacity: `${animationState.opacity * 100}%` }}>{messages[animationState.textIndex]}</div>;
}

/**
 * @param {{messages?: string[]}} props 
 */
export function LoadingScreen({messages}) {
    return <div className="flex flex-col justify-center items-center text-center min-h-screen p-4">
        <LoadingText messages={messages} className="text-lg" />
    </div>;
}
