/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoryCreate } from '../models/CategoryCreate';
import type { CategoryRead } from '../models/CategoryRead';
import type { CategoryUpdate } from '../models/CategoryUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoriesService {

    /**
     * List Event Categories
     * @param eventId
     * @returns CategoryRead Successful Response
     * @throws ApiError
     */
    public static listEventCategoriesEventsEventIdCategoriesGet(
        eventId: string,
    ): CancelablePromise<Array<CategoryRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/events/{event_id}/categories',
            path: {
                'event_id': eventId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event Category
     * @param eventId
     * @param requestBody
     * @returns CategoryRead Successful Response
     * @throws ApiError
     */
    public static createEventCategoryEventsEventIdCategoriesPost(
        eventId: string,
        requestBody: CategoryCreate,
    ): CancelablePromise<CategoryRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/events/{event_id}/categories',
            path: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Category
     * @param categoryId
     * @returns CategoryRead Successful Response
     * @throws ApiError
     */
    public static getCategoryCategoriesCategoryIdGet(
        categoryId: string,
    ): CancelablePromise<CategoryRead> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/categories/{category_id}',
            path: {
                'category_id': categoryId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Category
     * @param categoryId
     * @param requestBody
     * @returns CategoryRead Successful Response
     * @throws ApiError
     */
    public static updateCategoryCategoriesCategoryIdPut(
        categoryId: string,
        requestBody: CategoryUpdate,
    ): CancelablePromise<CategoryRead> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/categories/{category_id}',
            path: {
                'category_id': categoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Category
     * @param categoryId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteCategoryCategoriesCategoryIdDelete(
        categoryId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/categories/{category_id}',
            path: {
                'category_id': categoryId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
