import { useFileCache } from "../lib/file";
import { getFileIcon } from "../lib/utils";
import { ErrorText } from "./ui/Input";
import { LoadingText } from "./ui/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fileSize from "filesize";
import { ExternalLinkButton } from "./ui/Button";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function FileLinkContent({ file }) {
    const icon = getFileIcon(file.mime);
    return <ExternalLinkButton href={file.url} size="unsized" className="border rounded border-gray-300 dark:border-gray-600 p-3">
        <div className="flex items-center">
            <FontAwesomeIcon icon={icon} className="mr-2 text-xl dark:text-blue-400 text-blue-500" />
            <h5 className="font-medium">{file.name ?? "File"}</h5>
            <div className="ml-3 muted text-xs"><FontAwesomeIcon icon={faDownload} /> {fileSize(file.size)}</div>
        </div>
    </ExternalLinkButton>;
}

export function FileLink({ fileID }) {
    const fileCache = useFileCache();
    const fileResult = fileCache.getFile(fileID, true);

    if (!fileResult) {
        return <LoadingText messages={["Loading file..."]} />;
    } else if (fileResult.error) {
        return <ErrorText>Couldn't load file {fileID}. Try reloading the page.</ErrorText>;
    } else {
        const { file } = fileResult;
        return <FileLinkContent file={file} />;
    }
}
