/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Delete = {
    type: Delete.type;
    id: string;
};

export namespace Delete {

    export enum type {
        DELETE = 'delete',
    }


}

