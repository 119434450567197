import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import { SurveyEditorForm } from '../../../components/surveys/SurveyEditorForm';
import permissions from "../../../permissions.json";

export function Review() {
    return <>
        <Helmet>
            <title>Reviewing</title>
        </Helmet>
        <SurveyEditorForm title="Reviewing" description="Manage the questions judges will see when reviewing projects." surveyType="review" />
    </>;
}

export const ReviewCriteriaPageDefinition = {
    title: "Reviewing",
    content: <Review />,
    icon: faClipboard,
    route: "review",
    permission: permissions.editFeedbackForm,
};
