import { useMemo } from "react";
import { ErrorText } from "../ui/Input";
import { questionTypes } from "./questionTypes";

/**
 * @param {{questions: Question[], values: Record<string, any>}} props
 */
export function SurveyViewer({ questions, values }) {
    const sortedQuestions = useMemo(() => questions.sort((a, b) => a.order - b.order), [questions]);

    return <>
        {sortedQuestions.map(question => {
            const value = values[question.id];
            if (value === "" || value === undefined || value === null) return undefined;

            const type = questionTypes.get(question.question_type) ?? {Viewer: () => <ErrorText>Unknown question type.</ErrorText>};
            return <div className="border-l-2 border-l-blue-500 dark:border-l-blue-400 pl-3 mb-4 py-1" key={question.id}>
                <h3 className="subheading">{question.name}</h3>
                <div><type.Viewer question={question} value={value} /></div>
            </div>;
        })}
    </>;
}
