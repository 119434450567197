import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

/**
 * An icon in a big circle
 * @param {{className?: string, icon: import('@fortawesome/fontawesome-svg-core').IconDefinition, label?: string}} param0 
 */
export function BigIcon({className, icon, label}) {
    return <div className={classNames("rounded-full h-20 w-20 flex justify-center items-center text-center", className)}>
        <FontAwesomeIcon icon={icon} size="2x" />
        <span className="sr-only">{label}</span>
    </div>;
}

export function BigGreenCheckmark({className}) {
    return <BigIcon icon={faCheck} className={classNames("text-white bg-green-500", className)} label="" />;
}

export function BigRedX({className}) {
    return <BigIcon icon={faTimes} className={classNames("text-white bg-red-500", className)} label="" />;
}
