/**
 * A class that represents a schedule for an event.
 * 
 * In ScheduleModel parlance, judges are users, timeslots are timeslots, and projects are, well, projects.
 * Because who could've guessed?
 * 
 * also copilot wrote a lot of this class for me lol
 */
export class ScheduleModel {
    /**
     * A mapping of IDs to users.
     * @type {Record<string, User>}
     */
    #usersByID;

    /**
     * A list of user IDs, in the order they should be displayed.
     * @type {string[]}
     */
    #userOrder;

    /**
     * A mapping of IDs to timeslots.
     * @type {Record<string, Timeslot>}
     */
    #timeslotsByID;

    /**
     * A list of timeslot IDs, in the order they should be displayed.
     * @type {string[]}
     */
    #timeslotOrder;

    /**
     * A mapping of IDs to projects.
     * @type {Record<string, Project>}
     */
    #projectsByID;

    /**
     * The schedule, as a mapping of user IDs to timeslot IDs to project IDs.
     * @type {Record<string, Record<string, Set<string> | null>>}
     */
    #schedule;

    /**
     * Creates an empty ScheduleModel with the given users and timeslots. The order
     * they are given in will determine the order they are displayed.
     * @param {User[]} users 
     * @param {Timeslot[]} timeslots 
     */
    constructor(users, timeslots) {
        this.#usersByID = Object.create(null);
        this.#userOrder = [];
        for (const user of users) {
            if (user.id === "__proto__") throw new Error("This isn't a CTF problem");
            this.#usersByID[user.id] = user;
            this.#userOrder.push(user.id);
        }

        this.#timeslotsByID = Object.create(null);
        this.#timeslotOrder = [];
        for (const timeslot of timeslots) {
            if (timeslot.id === "__proto__") throw new Error("This isn't a CTF problem");
            this.#timeslotsByID[timeslot.id] = timeslot;
            this.#timeslotOrder.push(timeslot.id);
        }
        
        this.#projectsByID = Object.create(null);
        this.#schedule = Object.create(null);
        for (const user of users) {
            const userSchedule = Object.create(null);
            for (const timeslot of timeslots) {
                userSchedule[timeslot.id] = null;
            }
            this.#schedule[user.id] = userSchedule;
        }
    }

    /**
     * Returns a list of user IDs in the order they should be displayed.
     * @returns {string[]}
     */
    getUserIDs() {
        return this.#userOrder;
    }

    /**
     * Returns a list of users in the order they should be displayed.
     * @returns {User[]}
     */
    getUsers() {
        return this.#userOrder.map(id => this.#usersByID[id]);
    }

    /**
     * Returns a user with the given ID.
     * @param {string} id
     * @returns {User | undefined}
     */
    getUser(id) {
        return this.#usersByID[id];
    }

    /**
     * Returns a list of timeslot IDs in the order they should be displayed.
     * @returns {string[]}
     */
    getTimeslotIDs() {
        return this.#timeslotOrder;
    }

    /**
     * Returns a list of timeslots in the order they should be displayed.
     * @returns {Timeslot[]}
     */
    getTimeslots() {
        return this.#timeslotOrder.map(id => this.#timeslotsByID[id]);
    }

    /**
     * Returns a timeslot with the given ID.
     * @param {string} id
     * @returns {Timeslot | undefined}
     */
    getTimeslot(id) {
        return this.#timeslotsByID[id];
    }

    /**
     * Register a project in the ScheduleModel.
     * @param {Project} project
     */
    registerProject(project) {
        if (project.id === "__proto__") throw new Error("This isn't a CTF problem");
        this.#projectsByID[project.id] = project;
    }

    /**
     * Returns a new ScheduleModel with the project registered.
     * (Shallow copy disclaimer applies.)
     * @param {Project} project
     * @returns {ScheduleModel}
     */
    registeringProject(project) {
        if (project.id === "__proto__") throw new Error("This isn't a CTF problem");
        const newModel = new ScheduleModel([], []);
        newModel.#usersByID = this.#usersByID;
        newModel.#userOrder = this.#userOrder;
        newModel.#timeslotsByID = this.#timeslotsByID;
        newModel.#timeslotOrder = this.#timeslotOrder;
        newModel.#projectsByID = { ...this.#projectsByID };
        newModel.#schedule = this.#schedule;
        newModel.#projectsByID[project.id] = project;
        return newModel;
    }

    /**
     * Returns a project with the given ID.
     * @param {string} id
     * @returns {Project | null}
     */
    getProject(id) {
        return this.#projectsByID[id];
    }

    /**
     * Returns a list of project IDs stored in the ScheduleModel.
     * @returns {string[]}
     */
    getProjectIDs() {
        return Object.keys(this.#projectsByID);
    }
    
    /**
     * Returns a list of projects stored in the ScheduleModel.
     * @returns {Project[]}
     */
    getProjects() {
        return Object.values(this.#projectsByID);
    }

    /**
     * Assigns a project to a user at a given timeslot, and returns the previous
     * project ID at that timeslot if there was one. Throws if any of the IDs
     * do not exist.
     * 
     * @param {string} userID
     * @param {string} timeslotID
     * @param {string | null} projectID
     * @returns {string | null}
     * @throws {Error}
     */
    assignProject(userID, timeslotID, projectID) {
        if (!this.#usersByID[userID]) throw new Error(`User ${userID} does not exist`);
        if (!this.#timeslotsByID[timeslotID]) throw new Error(`Timeslot ${timeslotID} does not exist`);
        if (projectID !== null && !this.#projectsByID[projectID]) throw new Error(`Project ${projectID} does not exist`);
        const previousProjectID = this.#schedule[userID][timeslotID];
        if (this.#schedule[userID][timeslotID]) {
            this.#schedule[userID][timeslotID].add(projectID);
        } else {
            this.#schedule[userID][timeslotID] = new Set([projectID]);
        }
        return previousProjectID;
    }

    /**
     * Returns a new ScheduleModel with a project assigned to a user at a given timeslot.
     * Does not mutate the existing ScheduleModel (however, note that the new ScheduleModel is largely a shallow copy.)
     * Throws if any of the IDs do not exist.
     * 
     * @param {string} userID
     * @param {string} timeslotID
     * @param {string} projectID
     * @returns {ScheduleModel}
     * @throws {Error}
     */
    assigningProject(userID, timeslotID, projectID) {
        if (!this.#usersByID[userID]) throw new Error(`User ${userID} does not exist`);
        if (!this.#timeslotsByID[timeslotID]) throw new Error(`Timeslot ${timeslotID} does not exist`);
        if (projectID !== null && !this.#projectsByID[projectID]) throw new Error(`Project ${projectID} does not exist`);

        const newModel = new ScheduleModel([], []);
        newModel.#usersByID = this.#usersByID;
        newModel.#userOrder = this.#userOrder;
        newModel.#timeslotsByID = this.#timeslotsByID;
        newModel.#timeslotOrder = this.#timeslotOrder;
        newModel.#projectsByID = this.#projectsByID;
        newModel.#schedule = { ...this.#schedule };
        newModel.#schedule[userID] = { ...this.#schedule[userID] };
        newModel.#schedule[userID][timeslotID] = newModel.#schedule[userID][timeslotID] ? new Set([
            ...newModel.#schedule[userID][timeslotID],
            projectID, 
        ]) : new Set([projectID]);
        return newModel;
    }

    /**
     * Returns a new ScheduleModel with a project assigned to a user at a given timeslot.
     * Does not mutate the existing ScheduleModel (however, note that the new ScheduleModel is largely a shallow copy.)
     * Throws if any of the IDs do not exist.
     * 
     * @param {string} userID
     * @param {string} timeslotID
     * @param {string} projectID
     * @returns {ScheduleModel}
     * @throws {Error}
     */
    unassigningProject(userID, timeslotID, projectID) {
        if (!this.#usersByID[userID]) throw new Error(`User ${userID} does not exist`);
        if (!this.#timeslotsByID[timeslotID]) throw new Error(`Timeslot ${timeslotID} does not exist`);
        if (projectID !== null && !this.#projectsByID[projectID]) throw new Error(`Project ${projectID} does not exist`);

        if (!this.#schedule[userID][timeslotID] || !this.#schedule[userID][timeslotID].has(projectID)) return;

        const newModel = new ScheduleModel([], []);
        newModel.#usersByID = this.#usersByID;
        newModel.#userOrder = this.#userOrder;
        newModel.#timeslotsByID = this.#timeslotsByID;
        newModel.#timeslotOrder = this.#timeslotOrder;
        newModel.#projectsByID = this.#projectsByID;
        newModel.#schedule = { ...this.#schedule };
        newModel.#schedule[userID] = { ...this.#schedule[userID] };
        if (this.#schedule[userID][timeslotID].size === 1) {
            newModel.#schedule[userID][timeslotID] = null;
        } else {
            newModel.#schedule[userID][timeslotID] = new Set([
                ...newModel.#schedule[userID][timeslotID],
            ]);
            newModel.#schedule[userID][timeslotID].delete(projectID);
        }
        
        return newModel;
    }

    /**
     * Returns a new ScheduleModel with all projects removed from a user.
     * 
     * @param {string} userID
     * @returns {ScheduleModel}
     * @throws {Error}
     */
    removingAllProjectsFromUser(userID) {
        if (!this.#usersByID[userID]) throw new Error(`User ${userID} does not exist`);

        const newModel = new ScheduleModel([], []);
        newModel.#usersByID = this.#usersByID;
        newModel.#userOrder = this.#userOrder;
        newModel.#timeslotsByID = this.#timeslotsByID;
        newModel.#timeslotOrder = this.#timeslotOrder;
        newModel.#projectsByID = this.#projectsByID;
        newModel.#schedule = { ...this.#schedule };
        newModel.#schedule[userID] = {};
        return newModel;
    }

    /**
     * Returns the projects at each timeslot for a given user.
     * @param {string} userID
     * @returns {Record<string, Project[] | null> | undefined}
     */
    getProjectsForUser(userID) {
        if (!this.#usersByID[userID]) return;
        const result = Object.create(null);
        for (const timeslotID of this.#timeslotOrder) {
            const projects = this.#schedule[userID][timeslotID];
            if (projects === null) { //why did I want to do this, who knows?  || projects === undefined || projects.size === 0
                result[timeslotID] = null;
            } else {
                const projectArray = [];
                projects.forEach(projectID => projectArray.push(this.#projectsByID[projectID]));
                result[timeslotID] = projectArray;
            }
        }
        return result;
    }

    /**
     * Returns the project IDs at each timeslot for a given user.
     * @param {string} userID 
     * @param {string} timeslotID 
     * @returns {Record<string, Set<string> | null>}
     */
    getProjectIDsForUser(userID) {
        if (!this.#usersByID[userID]) return;
        return this.#schedule[userID];
    }    

    /**
     * Returns the project ID at the given timeslot for the given user.
     * @param {string} userID
     * @param {string} timeslotID
     * @returns {Set<string> | null}
     */
    getProjectIDsAt(userID, timeslotID) {
        return this.#schedule[userID][timeslotID];
    }

    /**
     * Returns the project at the given timeslot for the given user.
     * @param {string} userID
     * @param {string} timeslotID
     * @returns {Project[] | null}
     */
    getProjectsAt(userID, timeslotID) {
        const projectIDs = this.#schedule[userID][timeslotID];
        if (projectIDs === null) return null;

        const result = [];
        projectIDs.forEach(projectID => result.push(this.#projectsByID[projectID]));
        return result;
    }

    /**
     * Creates an empty copy of the ScheduleModel. Note that this method makes a shallow copy
     * of users and timeslots.
     * @returns {ScheduleModel}
     */
    emptyCopy() {
        const copy = new ScheduleModel([], []);
        copy.#usersByID = this.#usersByID;
        copy.#userOrder = this.#userOrder;
        copy.#timeslotsByID = this.#timeslotsByID;
        copy.#timeslotOrder = this.#timeslotOrder;
        for (const projectID in this.#projectsByID) {
            copy.#projectsByID[projectID] = this.#projectsByID[projectID];
        }
        for (const userID in this.#schedule) {
            copy.#schedule[userID] = Object.create(null);
        }
        return copy;
    }

    /**
     * Copies the ScheduleModel. Note that this method makes a shallow copy
     * of users and timeslots.
     * @returns {ScheduleModel}
     */
    copy() {
        const copy = this.emptyCopy();
        for (const userID in this.#schedule) {
            for (const timeslotID in this.#schedule[userID]) {
                copy.#schedule[userID][timeslotID] = this.#schedule[userID][timeslotID];
            }
        }
        return copy;
    }

    /**
     * Calculates which projects have changed compared to the given ScheduleModel, and
     * returns a list of the changed project IDs. The two schedules are assumed to have
     * the same users and timeslots. Returns a mapping of project IDs to a list of
     * judge IDs to remove.
     * @param {ScheduleModel} other
     * @returns {Map<string, Set<string>>}
     */
    computeChangedProjectIDs(other) {
        const changedProjectIDs = new Map();
        for (const userID in this.#schedule) {
            for (const timeslotID in this.#schedule[userID]) {
                const projectIDs = this.#schedule[userID][timeslotID] ?? new Set();
                const otherProjectIDs = other.#schedule[userID][timeslotID] ?? new Set();

                projectIDs.forEach(projectID => {
                    if (!otherProjectIDs.has(projectID)) {
                        if (!changedProjectIDs.has(projectID)) {
                            changedProjectIDs.set(projectID, new Set());
                        }
                    }
                });

                otherProjectIDs.forEach(projectID => {
                    if (!projectIDs.has(projectID)) {
                        if (!changedProjectIDs.has(projectID)) {
                            changedProjectIDs.set(projectID, new Set());
                        }
                        changedProjectIDs.get(projectID).add(userID);
                    }
                });
            }
        }
        return changedProjectIDs;
    }

    /**
     * Dumps the schedules for the given project IDs to JSON format.
     * @param {Set<string>} projectIDs
     * @returns {Record<string, {judge_id: string, timeslot_id: string}>[]}
     */
    dumpProjectSchedulesToJSON(projectIDs) {
        const result = Object.create(null);
        for (const projectID of projectIDs) {
            result[projectID] = [];
        }
        for (const userID in this.#schedule) {
            for (const timeslotID in this.#schedule[userID]) {
                (this.#schedule[userID][timeslotID] ?? new Set()).forEach(projectID => {
                    if (projectIDs.has(projectID)) {
                        result[projectID].push({
                            judge_id: userID,
                            timeslot_id: timeslotID,
                        });
                    }
                });
            }
        }
        return result;
    }

    /**
     * Returns true if the schedule has no assignments.
     * @returns {boolean}
     */
    isEmpty() {
        for (const userID in this.#schedule) {
            for (const timeslotID in this.#schedule[userID]) {
                if (this.#schedule[userID][timeslotID] !== null) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Uploads the schedule to the server, diffing against the previously uploaded
     * schedule if it is given.
     * 
     * @param {APIContext} api
     * @param {ScheduleModel | undefined} previousSchedule
     */
    async uploadSchedule(api, lastRevision) {
        /** @type {Set<string>} */
        let projectIDs;
        let deletions;
        if (lastRevision) {
            deletions = this.computeChangedProjectIDs(lastRevision);
            projectIDs = new Set(deletions.keys());
        } else {
            projectIDs = new Set(Object.keys(this.#projectsByID));
        }

        console.log(`Uploading schedule for ${projectIDs.size} project(s)...`);
        const dump = this.dumpProjectSchedulesToJSON(projectIDs);
        for (const projectID of projectIDs) {
            // TODO: Don't delete projects if they've simply been moved to another spot on the judge's schedule
            if (deletions && deletions.get(projectID).size > 0) {
                console.log(`Deleting ${deletions.get(projectID).size} judge(s) from project ${projectID}...`);
                await api.delete(`/projects/${projectID}/users/judges/delete`, [...deletions.get(projectID)]);
            }
            console.log(`Uploading schedule for project ${projectID} - ${this.#projectsByID[projectID].name} (${dump[projectID].length} judge(s))...`);
            await api.put(`/projects/${projectID}/users/judges`, dump[projectID]);
        }
        console.log("Upload complete.");
    }

    /**
     * Fetches all the data needed and returns a ScheduleModel.
     * @param {APIContext} api
     * @param {string} eventID
     * @returns {Promise<ScheduleModel>}
     */
    static async fetch(api, eventID) {
        const [users, timeslots, projects, scheduleEntries] = await Promise.all([
            api.get(`/events/${eventID}/users`),
            (async () => {
                const timeslots = await api.get(`/events/${eventID}/schedule/timeslots`);
                timeslots.sort((a, b) => {
                    const aDate = new Date(a.start);
                    const bDate = new Date(b.start);
                    return aDate.getTime() - bDate.getTime();
                });
                return timeslots;
            })(),
            api.get(`/events/${eventID}/projects`),
            api.get(`/events/${eventID}/schedule`, {all: true}),
        ]);

        const schedule = new ScheduleModel(users, timeslots);
        for (const project of projects) {
            schedule.registerProject(project);
        }
        for (const entry of scheduleEntries) {
            try {
                schedule.assignProject(entry.user_id, entry.timeslot_id, entry.project_id);
            } catch (e) {
                console.error(e);
            }
        }
        return schedule;
    }
}
