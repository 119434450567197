import { Dialog } from "@headlessui/react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAPI } from "../lib/API";
import { useAuth } from "../lib/auth";
import { Button } from "./ui/Button";
import { StyledDialog } from "./ui/Dialog";
import { useEffect, useState } from "react";
import { LoadingScreen } from "./ui/Loading";

function useBlamingOfAnli(error) {
    const { url } = useAPI();
    return process.env.NODE_ENV !== "production" && url === "https://api.expodite.app" && error && !error.status;
}

/**
 * @param {{error?: (Error | import('../lib/API.js').APIError) & {retry?: () => void}}} props
 */
export function AuthErrorDialog({error}) {
    const [cachedError, setCachedError] = useState(null);

    useEffect(() => {
        if (error) setCachedError(error);
    }, [error]);

    const isAnlisFault = useBlamingOfAnli(cachedError);

    return <StyledDialog static open={!!error} onClose={() => {}} borderClassName={isAnlisFault ? "border-orange-500 dark:border-orange-400" : "border-red-500 dark:border-red-400"}>
        <Dialog.Title className="heading mb-3">
            {isAnlisFault ? "...Did you forget to start the backend?" : "Uh oh!"}
        </Dialog.Title>
        <Dialog.Description>
            <span className="block">{isAnlisFault ?
                "Starting it might be a good idea, y'know." :
                (cachedError?.status ? "Looks like we're having some issues at the moment. Wait a few moments, then try again." : "We're having trouble connecting to Expodite. Check your Internet connection, or try again.")}</span>
            {cachedError?.status && <span className="block italic text-sm mt-2">The server provided the following error code: {cachedError.status}</span>}
        </Dialog.Description>
        {cachedError?.retry && <div className="flex mt-4">
            <Button kind="primary" disabled={!error?.retry} className="grow" onClick={() => {
                error.retry();
            }}>
                {isAnlisFault ? "I started the backend" : "Try again"}
            </Button>
        </div>}
    </StyledDialog>;
}

export function RequiresAuth({children}) {
    const auth = useAuth();
    const location = useLocation();

    if (auth.state === "unauthenticated") {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return <>
            {auth.state === "authenticated" ? (children || <Outlet />) : <LoadingScreen />}
            <AuthErrorDialog error={auth.state === "error" ? {...auth.error, retry: auth.retry} : undefined} />
        </>;
    }
}
