/// <reference path="../typings.d.ts" />

import * as ResearchExpo from './ResearchExpo';
import * as PeerReview from './PeerReview';
import * as Blank from './Blank';

export const templates = [
    ResearchExpo,
    PeerReview,
    Blank,
];

export class TemplateContext {
    /**
     * @param {import('../API').APIContext} api 
     * @param {ExpoditeEvent} event 
     */
    constructor(api, event) {
        /**
         * @type {import('../API').APIContext}
         */
        this.api = api;

        /**
         * @type {ExpoditeEvent}
         */
        this.event = event;
    }

    /**
     * @param {any} role
     * @returns {Promise<Role>}
     */
    async createRole(role) {
        return await this.api.post(`/events/${this.event.id}/roles`, role);
    }

    /**
     * @param {"project" | "registration" | "review"} type
     * @param {any[]} questions
     * @returns {Promise<any[]>}
     */
    async createSurvey(type, questions) {
        return await this.api.put(`/events/${this.event.id}/surveys/${type}/questions`, questions.map((question, index) => ({
            type: "create",
            question: {
                ...question,
                order: index,
            },
        })));
    }

    async updateSettings(settings) {
        await this.api.put(`/events/${this.event.id}`, {
            settings: {
                ...this.event.settings,
                ...settings,
            },
        });
    }
}
