import { Field, useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faClone, faGripVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Input, Textarea, Select, ErrorText } from "../ui/Input";
import { Button } from "../ui/Button";
import { Popover, Switch } from "@headlessui/react";
import { StyledSwitch } from "../ui/Switch";
import { questionTypes } from "./questionTypes";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { StyledExternalLink } from "../ui/Link";
import { PopoverPanel } from "../ui/Popover";
import { LoadingText } from "../ui/Loading";

export function SurveyQuestionDraggableEditor({id, ...props}) {
    const sortable = useSortable({id});
    return <SurveyQuestionEditor id={id} {...props} sortable={sortable} />;
}

export function SurveyQuestionEditor({ name, supportsCategories, id, onDuplicate, onRemove, sortable, isOverlay, isDragging, roles, rolesError, cats, catsError, everyoneText = "Everyone", filterRoles = () => true, filterCats = () => true }) {
    const { values, setValues, errors } = useFormikContext();
    const question = values[name][id];
    const prefix = `${name}.${id}`;
    const type = questionTypes.get(question.question_type) ?? {
        editor: () => <ErrorText>Unknown question type.</ErrorText>,
    };

    const questionErrors = errors[name] && errors[name][id];

    const setQuestion = question => {
        const newValues = {...values};
        newValues[name] = {...values[name]};
        newValues[name][id] = question;
        setValues(newValues);
    };

    const { attributes, listeners, setNodeRef, transform, transition } = sortable ?? {};

    const Editor = type.Editor;

    let rolesText;
    if (question.all_roles) {
        rolesText = everyoneText;
    } else {
        if (question.roles.length === 1) {
            if (roles) {
                rolesText = roles.find(({id}) => id === question.roles[0])?.name ?? "Unknown Role";
            } else {
                rolesText = "1 role";
            }
        } else {
            rolesText = `${question.roles.length} roles`;
        }
    }

    let catsText;
    if (question.all_categories) {
        catsText = "All categories";
    } else {
        if (question.categories.length === 1) {
            if (cats) {
                catsText = cats.find(({id}) => id === question.categories[0])?.name ?? "Unknown Category";
            } else {
                catsText = "1 category";
            }
        } else {
            catsText = `${question.categories.length} categories`;
        }
    }

    return <div
        ref={setNodeRef}
        className={`mb-4 w-full rounded-lg border-gray-300 dark:border-gray-600 border-2 flex items-start dark:bg-gray-900 bg-white ${isDragging ? "opacity-30" : ""}`}
        style={sortable ? {transform: CSS.Transform.toString(transform), transition} : {}}
    >
        <div className="w-12 sm:w-16 flex-shrink-0 text-lg flex flex-col items-center justify-start h-full">
            <div className="px-4 pt-3 sm:pt-4 pb-3 cursor-grab active:cursor-grabbing group" {...attributes} {...listeners}>
                <div className={`w-8 flex py-1 flex-col items-center ${isOverlay ? "bg-gray-200 dark:bg-gray-700" : "group-hover:bg-gray-100 dark:group-hover:bg-gray-800 group-active:bg-gray-200 dark:group-active:bg-gray-700 transition-colors"} rounded`}>
                    <div className="text-gray-600 dark:text-gray-400">
                        <FontAwesomeIcon icon={faGripVertical} />
                    </div>
                </div>
            </div>
            <Button className="mx-4" size="icon" kind="primary-text" onClick={onDuplicate} disabled={!onDuplicate}>
                <FontAwesomeIcon icon={faClone} />
                <span className="sr-only">Duplicate</span>
            </Button>
            <Button className="mx-4" size="icon" kind="destructive-text" onClick={onRemove} disabled={!onRemove}>
                <FontAwesomeIcon icon={faTrash} />
                <span className="sr-only">Delete</span>
            </Button>
        </div>
        <div className="flex-grow py-3 sm:py-4 pr-4">
            <div className="mb-2"><span className="muted block sm:inline">Answerable by:</span> <Popover className="inline-block relative">
                <Popover.Button as={StyledExternalLink} className="cursor-pointer">
                    {rolesText} <FontAwesomeIcon icon={faChevronDown} />
                </Popover.Button>
                <PopoverPanel>
                    <div className="w-52 sm:w-64">
                        <div className="flex items-start">
                            <input className="mt-1" type="radio" id={`${id}-all-roles`} checked={question.all_roles} onChange={event => setQuestion({ ...question, all_roles: event.target.checked })} /> <label htmlFor={`${id}-all-roles`} className="ml-2 flex-grow">Anyone can answer</label>
                        </div>
                        <div className="flex items-start">
                            <input className="mt-1" type="radio" id={`${id}-restrict-roles`} checked={!question.all_roles} onChange={event => setQuestion({ ...question, all_roles: !event.target.checked })} /> <label htmlFor={`${id}-restrict-roles`} className="ml-2 flex-grow">Only these roles can answer:</label>
                        </div>
                        <div className={`ml-6 ${question.all_roles ? "opacity-30" : ""}`}>
                            {rolesError && <ErrorText>Couldn't load roles.</ErrorText>}
                            {!roles && !rolesError && <LoadingText messages={["Loading roles..."]} />}
                            {roles?.filter(filterRoles).map(role => <div key={role.id} className={"flex items-center"}>
                                <Field className="rounded" type="checkbox" id={`${id}-role-${role.id}`} name={`${prefix}.roles`} value={role.id} disabled={question.all_roles} />
                                <label htmlFor={`${id}-role-${role.id}`} className="ml-2 flex-grow">{role.name}</label>
                            </div>)}
                        </div>
                    </div>
                </PopoverPanel>
            </Popover>
            {supportsCategories && <Popover className="inline-block ml-2 relative">
                <Popover.Button as={StyledExternalLink} className="cursor-pointer">
                    {catsText} <FontAwesomeIcon icon={faChevronDown} />
                </Popover.Button>
                <PopoverPanel>
                    <div className="w-52 sm:w-64">
                        <div className="flex items-start">
                            <input className="mt-1" type="radio" id={`${id}-all-cats`} checked={question.all_categories} onChange={event => setQuestion({ ...question, all_categories: event.target.checked })} /> <label htmlFor={`${id}-all-cats`} className="ml-2 flex-grow">Anyone can answer</label>
                        </div>
                        <div className="flex items-start">
                            <input className="mt-1" type="radio" id={`${id}-restrict-cats`} checked={!question.all_categories} onChange={event => setQuestion({ ...question, all_categories: !event.target.checked })} /> <label htmlFor={`${id}-restrict-cats`} className="ml-2 flex-grow">Only these categories can answer:</label>
                        </div>
                        <div className={`ml-6 ${question.all_categories ? "opacity-30" : ""}`}>
                            {catsError && <ErrorText>Couldn't load categories.</ErrorText>}
                            {!cats && !catsError && <LoadingText messages={["Loading categories..."]} />}
                            {cats?.filter(filterCats).map(cat => <div key={cat.id} className={"flex items-center"}>
                                <Field className="rounded" type="checkbox" id={`${id}-cat-${cat.id}`} name={`${prefix}.categories`} value={cat.id} disabled={question.all_categories} />
                                <label htmlFor={`${id}-cat-${cat.id}`} className="ml-2 flex-grow">{cat.name}</label>
                            </div>)}
                        </div>
                    </div>
                </PopoverPanel>
            </Popover>}</div> 
            <Field as={Input} className="w-full font-bold mb-1" type="text" name={`${prefix}.name`} placeholder="Question Name" />
            <Field as={Textarea} className="w-full italic" name={`${prefix}.description`} placeholder="Enter description..." />
            <Field as={Select}
                name={`${prefix}.question_type`}
                className="mt-6 w-56 py-2 block rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200"
                onChange={e => {
                    const value = e.target.value;
                    setQuestion({...question, question_type: value, details: questionTypes.get(value).defaultSettings(question)});
                }}>
                {[...questionTypes.entries()].map(([type, {name}]) => <option key={type} value={type}>{name}</option>)}
            </Field>
            <Editor prefix={`${prefix}.details`} details={question.details} id={id} errors={questionErrors} setDetails={details => {
                setQuestion({...question, details});
            }} />
            <Switch.Group>
                <div className="mt-4 flex items-center">
                    <StyledSwitch checked={question.required} onChange={enabled => setQuestion({ ...question, required: enabled })} />
                    <Switch.Label><div className="ml-2">Make this a required question.</div></Switch.Label>
                </div>
            </Switch.Group>
        </div>
    </div>;
}
