import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { Button, RouterLinkButton } from "../../../../components/ui/Button";
import { PopoverPanel } from '../../../../components/ui/Popover';
import { hueForID } from '../../../../lib/utils.js';

/**
 * @param {{judgeID: string, scheduleModel: import('../../../lib/ScheduleModel').ScheduleModel, setScheduleModel: (ScheduleModel) => void}} props
 */
export function JudgeCell({ judgeID, scheduleModel, setScheduleModel, colorCode, colorType }) {
    const judge = scheduleModel.getUser(judgeID);
    const url = `../users/${judge.id}`;

    return (
        <div className="w-full px-2">
            <Popover>
                {({ open }) => (
                    <>
                        <Popover.Button className={`${open ? 'muted' : ''} w-full h-full sticky z-10 left-0`}>
                            {judge.name ? judge.name : "Judge name"}
                        </Popover.Button>

                        <PopoverPanel className="w-screen max-w-sm lg:max-w-md">
                            <div className="flex">
                                <FontAwesomeIcon icon={faUser} className="mt-1 text-lg mr-3" />
                                <div className="text-left flex-grow">
                                    <h4 className="text-lg font-bold">{judge.name || "Judge Name"}</h4>
                                    <div className="muted">{judge.roles.map(role => role.name).join(", ")}</div>
                                    <div className="mt-2 space-y-2 w-full">
                                        {scheduleModel.getTimeslots().map(timeslot => {
                                            const projects = scheduleModel.getProjectsAt(judgeID, timeslot.id);
                                            return <Fragment key={timeslot.id}>{projects?.map(project => {
                                                const hueID = (!colorType && project.category && project.category.id) ? project.category?.id : project.id;
                                                return (
                                                    <div key={project.id} className={`${colorCode ? "bg-settings-accent text-white dark:text-black" : "border dark:border-gray-600"} rounded w-full px-3 py-2`} style={{ "--settings-accent-hue": hueForID(hueID) }}>
                                                        <h6 className="font-bold">{project.name}</h6>
                                                        <div className="opacity-70">{project.category?.name ?? "No Category"}</div>
                                                        <div className="opacity-50">{DateTime.fromISO(timeslot.start).toLocaleString(DateTime.DATETIME_MED)}</div>
                                                    </div>
                                                );
                                            })}</Fragment>;
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="flow-root mt-4">
                                <span className="flex items-center">
                                    <RouterLinkButton to={url} kind="primary">View Judge</RouterLinkButton>
                                    <Button className="ml-5" kind="destructive" onClick={() => {
                                        setScheduleModel(scheduleModel.removingAllProjectsFromUser(judgeID));
                                    }}>Clear Projects</Button>
                                </span>
                            </div>
                        </PopoverPanel>
                    </>
                )}
            </Popover>
        </div>
    );
}
