import classNames from "classnames";
import React, { forwardRef, HTMLProps, FC, Ref, ForwardRefRenderFunction } from "react";

interface InputProps extends HTMLProps<HTMLInputElement> {
  error?: boolean;
}

// does this work???? https://js2ts.com/ 
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { error, className, ...rest } = props;
    return <input ref={ref} className={classNames(
        "rounded px-4 py-2 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-900 dark:hover:bg-gray-700",
        "disabled:cursor-not-allowed disabled:hover:bg-gray-100 dark:disabled:hover:bg-gray-800 disabled:opacity-50",
        error && "border-red-500 dark:border-red-400 border",
        className,
    )} {...rest}></input>;
});

// export const Input = forwardRef(/** @type {React.ForwardRefRenderFunction<HTMLInputElement, React.HTMLProps<HTMLInputElement> & {error?: boolean}>} */ (({ error, className, ...props }, ref) => {
//     return <input ref={ref} className={classNames(
//         "rounded px-4 py-2 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-900 dark:hover:bg-gray-700",
//         "disabled:cursor-not-allowed disabled:hover:bg-gray-100 dark:disabled:hover:bg-gray-800 disabled:opacity-50",
//         error && "border-red-500 dark:border-red-400 border",
//         className,
//     )} {...props}></input>;
// }));

interface SelectProps extends HTMLProps<HTMLSelectElement> {
    error?: boolean;
}

export const Select: FC<SelectProps> = forwardRef(({
    error,
    className,
    children,
    ...props
}, ref: Ref<HTMLSelectElement>) => {
    return (
        <select
            ref={ref}
            className={classNames(
                "rounded pl-4 pr-10 py-2 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-900 dark:hover:bg-gray-700",
                "disabled:cursor-not-allowed disabled:hover:bg-gray-100 dark:disabled:hover:bg-gray-800",
                error && "border-red-500 dark:border-red-400 border",
                className,
            )}
            {...props}
        >
            {children}
        </select>
    );
});

// export const Select = forwardRef(/** @type {React.ForwardRefRenderFunction<HTMLSelectElement, React.HTMLProps<HTMLSelectElement> & {error?: boolean}>} */ (({ error, className, children, ...props }, ref) => {
//     return <select ref={ref} className={classNames(
//         "rounded pl-4 pr-10 py-2 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-900 dark:hover:bg-gray-700",
//         "disabled:cursor-not-allowed disabled:hover:bg-gray-100 dark:disabled:hover:bg-gray-800",
//         error && "border-red-500 dark:border-red-400 border",
//         className,
//     )} {...props}>{children}</select>;
// }));

interface TextareaProps extends HTMLProps<HTMLTextAreaElement> {
  error?: boolean;
}

const renderTextarea: ForwardRefRenderFunction<HTMLTextAreaElement, TextareaProps> = ({ error, className, children, ...props }, ref) => {
    return <textarea ref={ref} className={classNames(
        "rounded px-4 py-2 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-900 dark:hover:bg-gray-700",
        "disabled:cursor-not-allowed disabled:hover:bg-gray-100 dark:disabled:hover:bg-gray-800",
        "resize-none",
        error && "border-red-500 dark:border-red-400 border",
        className,
    )} {...props}>{children}</textarea>;
};

export const Textarea = forwardRef(renderTextarea);


// export const Textarea = forwardRef(/** @type {React.ForwardRefRenderFunction<HTMLTextAreaElement, React.HTMLProps<HTMLTextAreaElement> & {error?: boolean}>} */ (({ error, className, children, ...props }, ref) => {
//     return <textarea ref={ref} className={classNames(
//         "rounded px-4 py-2 bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 transition-colors hover:bg-gray-200 dark:bg-gray-800 dark:focus:bg-gray-900 dark:hover:bg-gray-700",
//         "disabled:cursor-not-allowed disabled:hover:bg-gray-100 dark:disabled:hover:bg-gray-800",
//         "resize-none",
//         error && "border-red-500 dark:border-red-400 border",
//         className,
//     )} {...props}>{children}</textarea>;
// }));

/**
 * @param {React.HTMLProps<HTMLDivElement>} props
 */
export function ErrorText({ className, children, ...props }) {
    if (!children) return <></>;

    return <div className={classNames(
        "text-red-500 dark:text-red-400 text-sm font-medium",
        className,
    )} {...props}>{children}</div>;
}
