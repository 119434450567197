import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { useAPI } from '../../../../lib/API.js';
import { SharedExportCache } from '../../../../lib/export';
import { JudgeCell } from './JudgeCell';
import { AddButton } from './AddButton';
import { ScheduleCell } from './ScheduleCell';

function DetermineHeader (timeslot, timeslotName) {
    if (timeslotName && !timeslot?.name) {
        return timeslot.name;
    }
    return DateTime.fromISO(timeslot.start).toLocaleString(DateTime.TIME_SIMPLE);
}

/**
 * @param {{scheduleModel: ScheduleModel, setScheduleModel: (ScheduleModel) => void, displayAs: string, showRole: string, colorCode: boolean}} props
 */
export function ScheduleTable({ scheduleModel, setScheduleModel, displayAs, showRole, colorCode, colorType, timeslotName, categories }) {
    /* const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    ); */

    const api = useAPI();
    const [cache] = useState(new SharedExportCache(api));

    const tableOptions = useMemo(() => ({
        columns: [
            {
                Header: "Judge",
                id: "user.id",
                accessor: column => {
                    return <JudgeCell judgeID={column.user.id} scheduleModel={scheduleModel} setScheduleModel={setScheduleModel} colorCode={colorCode} colorType={colorType} />;
                },
            },
            ...scheduleModel.getTimeslots().map(timeslot => ({
                id: timeslot.id,
                Header: DetermineHeader(timeslot, timeslotName),
                accessor: row => {
                    const project = row[timeslot.id];
                    if (!project) return <AddButton scheduleModel={scheduleModel} setScheduleModel={setScheduleModel} judgeID={row.user.id} timeslotID={timeslot.id} cache={cache} colorType={colorType} />;
                    return <ScheduleCell scheduleModel={scheduleModel} setScheduleModel={setScheduleModel} judgeID={row.user.id} timeslotID={timeslot.id} displayAs={displayAs} colorCode={colorCode} colorType={colorType} categories={categories} />;
                    /* <SortableItem key={timeslot.id} id={timeslot.id} >
                        [schedule thing here]
                    </SortableItem>; */
                },
            })),
        ],
        data: scheduleModel.getUsers().filter(({ roles }) => {
            if (showRole === "") return true;
            return roles.some(role => role.id === showRole);
        }).map(user => ({
            user,
            ...scheduleModel.getProjectsForUser(user.id),
        })),
    }), [scheduleModel, displayAs, colorCode, colorType, timeslotName, setScheduleModel, showRole, cache]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(tableOptions);

    return <table className="min-w-full" {...getTableProps()}>
        <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th className="first-of-type:pl-4 sticky top-0 first-of-type:left-0 bg-gray-200 dark:bg-gray-700 last-of-type:pr-4 px-3 py-2 text-center border border-gray-500 border-t-0 first-of-type:border-l-0 last-of-type:border-r-0 first-of-type:z-12" {...column.getHeaderProps()}>
                            {column.render("Header")}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
        {/* <DndContext sensors={sensors} collisionDetection={closestCenter} >
            <SortableContext items={scheduleModel.getTimeslots()} strategy={rectSwappingStrategy}> */}
        <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                return <tr className="h-1" {...row.getRowProps()}>
                    {row.cells.map(cell => (
                        <td className="h-[inherit] p-0 first-of-type:bg-gray-200 first-of-type:dark:bg-gray-700 border first-of-type:border-l-0 last-of-type:border-r-0 border-gray-500 text-center" {...cell.getCellProps()}>
                            {cell.render("Cell")}
                        </td>
                    ))}
                </tr>;
            })}
        </tbody>
        {/* </SortableContext>
        </DndContext> */}
    </table>;
}
