import { faBolt, faClipboard, faCode, faClock, faTag, faTh, faTimes, faExclamationTriangle, faCogs, faUserCheck, faUserLock, faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OneColumnLayout } from "../../../components/OneColumnLayout";
import { SettingsPanel } from "../../../components/SettingsPanel";
import { ErrorText } from "../../../components/ui/Input";
import { LoadingScreen, LoadingText } from "../../../components/ui/Loading";
import { Main } from "../../../components/ui/Main";
import { FieldArray, FormikProvider, useFormik, useFormikContext } from "formik";
import { Switch, Dialog } from "@headlessui/react";
import { StyledSwitch, SwitchWithLabel } from "../../../components/ui/Switch";
import { Input } from "../../../components/ui/Input";
import { Button } from "../../../components/ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SaveChanges } from "../../../components/SaveChanges";
import { useRequest } from "../../../lib/API";
import { StyledExternalLink } from "../../../components/ui/Link";
import { StyledDialog } from "../../../components/ui/Dialog";
import { BigIcon } from "../../../components/ui/BigIcon";
import { SettingsRolesContext } from "./index";
import Helmet from "react-helmet";
import permissions from '../../../permissions.json';
import { RolesService } from "../../../client";

function AdminIgnored() {
    const { values: { admin } } = useFormikContext();
    return admin && <div className="pt-4 px-4"><strong>These settings are ignored because the role has administrative privileges.</strong></div>;
}

function RoleForm({ role, setRole }) {
    const [serverError, setServerError] = useState(false);
    const [isSaved, setSaved] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: role.name,
            admin: role.admin,
            order: role.order ?? 0,
            permissions: role.permissions,
        },
        validate: ({ name, permissions, order }) => {
            const errors = {};
            if (!name) {
                errors.name = "Required";
            }

            // // Make sure all permissions are filled out.
            if (permissions.some(p => !p)) {
                errors.permissions = "Permissions cannot be empty.";
            } else {
                // Check for duplicate permissions and display them.
                const permissionNames = new Set();
                const duplicatePermissions = permissions.filter(name => {
                    if (permissionNames.has(name)) {
                        return true;
                    }
                    permissionNames.add(name);
                    return false;
                });
                if (duplicatePermissions.length > 0) {
                    errors.permissions = `Cannot have duplicate permissions: ${[...new Set(duplicatePermissions)].join(", ")}.`;
                }
            }

            if (typeof order !== "number") {
                errors.order = "Must be a number.";
            }

            return errors;
        },
        onSubmit: async (values) => {
            setServerError(null);
            try {
                const newRole = await RolesService.updateEventRoleRolesRoleIdPut(role.id, {...role, ...values});
                setRole(newRole);
                setSaved(true);
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
        onReset: () => {
            setServerError(null);
        },
        enableReinitialize: true,
    });
    const { handleSubmit, getFieldProps, touched, errors, values, setValues } = formik;

    useEffect(() => {
        setSaved(false);
    }, [role.id]);

    const permissionsSet = new Set(values.permissions);
    const getPermissionSwitchProps = permission => {
        return {
            checked: permissionsSet.has(permission),
            onChange: checked => {
                if (checked) {
                    if (!permissionsSet.has(permission)) {
                        setValues({...values, permissions: [...values.permissions, permission]});
                    }
                } else {
                    setValues({...values, permissions: values.permissions.filter(p => p !== permission)});
                }
            },
        };
    };

    return <FormikProvider value={formik}>
        <Helmet>
            <title>{role.name}</title>
        </Helmet>
        <form onSubmit={handleSubmit}>
            <SettingsPanel className="mb-3" icon={faTag} title="Role Name &amp; Order" hueFraction={0 / 4}>
                <div className="p-4">
                    <label className="block" htmlFor="name">Role Name</label>
                    <Input className="block" id="name" {...getFieldProps("name")} error={touched.name && errors.name} />
                    <ErrorText>{errors.name}</ErrorText>
                    <label className="block mt-4" htmlFor="name">Order</label>
                    <Input type="number" className="block" id="order" {...getFieldProps("order")} error={touched.order && errors.order} />
                    <ErrorText>{errors.order}</ErrorText>
                </div>
            </SettingsPanel>
            <SettingsPanel className="mb-3" icon={faTh} title="Project Permissions" hueFraction={1 / 7}>
                <AdminIgnored />
                <div className="p-4">
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.createProject)} action="create" item="projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editAllProject)} action="edit" item="projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editOwnProject)} action="edit" item="own projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editUsersAllProject)} action="edit" item="project collaborators" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editUsersOwnProject)} action="edit" item="own project collaborators" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.deleteAllProject)} action="delete" item="projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.deleteOwnProject)} action="delete" item="own projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editProjectForm)} action="edit" item="project form" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewAllProject)} action="view" item="all projects" /> 
                </div>
            </SettingsPanel>
            <SettingsPanel className="mb-3" icon={faClipboard} title="Feedback Permissions" hueFraction={2 / 7}>
                <AdminIgnored />
                <div className="p-4">
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.createAssignedFeedback)} action="leave" item="feedback on assigned projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.createAllFeedback)} action="leave" item="feedback on all projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editOwnFeedback)} action="edit" item="their feedback" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editAllFeedback)} action="edit" item="all feedback" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewProjectFeedback)} action="view" item="feedback on their projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewAssignedFeedback)} action="view" item="feedback on assigned projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewAllFeedback)} action="view" item="feedback on all projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editFeedbackForm)} action="edit" item="feedback form" />
                </div>
            </SettingsPanel>
            <SettingsPanel className="mb-3" icon={faUserCheck} title="Approval Permissions" hueFraction={3 / 7}>
                <AdminIgnored />
                <div className="p-4">
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewAllApproval)} action="view" item="all project approvals" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewAssignedApproval)} action="view" item="assigned project approvals" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewProjectApproval)} action="view" item="own project approval status" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.createAllApproval)} action="leave" item="approvals on all projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.createAssignedApproval)} action="leave" item="approvals on assigned projects" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editApprovalForm)} action="edit" item="approval form" />
                </div>
            </SettingsPanel>
            <SettingsPanel className="mb-3" icon={faUserLock} title="Privacy Permissions" hueFraction={4 / 7}>
                <AdminIgnored />
                <div className="p-4">
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewUserAll)} action="view" item="all users" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewUserEmail)} action="view" item="user emails" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.deleteUserAll)} action="delete" item="all users" />
                </div> 
            </SettingsPanel>
            <SettingsPanel className="mb-3" icon={faClipboardCheck} title="Registration Permissions" hueFraction={5 / 7}>
                <AdminIgnored />
                <div className="p-4">
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewAllRegistration)} action="view" item="all registrations" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editRegistrationForm)} action="edit" item="registration form" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editOwnRegistration)} action="edit" item="own registration" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editAllRegistration)} action="edit" item="all registrations" />
                </div> 
            </SettingsPanel>
            <SettingsPanel className="mb-3" icon={faClock} title="Scheduling Permissions" hueFraction={6 / 7}>
                <AdminIgnored />
                <div className="p-4">
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewAllSchedule)} action="view" item="everyone's schedules" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.viewProjectSchedule)} action="view" item="own project's schedule" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.editAllSchedule)} action="edit" item="all schedules" />
                </div> 
            </SettingsPanel>
            <SettingsPanel className="mb-3" icon={faCogs} title="Miscelaneous Permissions" hueFraction={7 / 7}>
                <AdminIgnored />
                <div className="p-4">
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.manageRoles)} action="manage" item="roles"/>
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.manageInvites)} action="manage" item="invites"/>
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.manageTimeslots)} action="manage" item="timeslots" />
                    <SwitchWithLabel {...getPermissionSwitchProps(permissions.manageCategories)} action="manage" item="categories" />
                </div> 
            </SettingsPanel>
            <SettingsPanel className="mb-3" icon={faBolt} title="Administrative Permissions" hueFraction={1.4}>
                <div className="p-4">
                    <Switch.Group>
                        <div className="flex items-start">
                            <StyledSwitch enabledClassName="bg-red-500" checked={values.admin} onChange={admin => setValues({ ...values, admin })} />
                            <Switch.Label className="ml-2">
                                <div>Allow this user to administer this event.</div>
                                {values.admin && <div className="italic muted">Users with this role will have full control over this event, including removing other administrators.</div>}
                            </Switch.Label>
                        </div>
                    </Switch.Group>
                </div>
            </SettingsPanel>
            {process.env.NODE_ENV === "development" && <SettingsPanel className="mb-3" icon={faCode} title="Interim Permission Editor™" hueFraction={1.8}>
                <AdminIgnored />
                <div className="p-4">
                    <h4 className="heading">Welcome to the Interim Permission Editor™</h4>
                    <p className="muted italic mb-4">This editor is designed for development use only. It will be replaced with a more user-friendly version in the future.</p>
                    <FieldArray name="permissions" render={({push, remove}) => {
                        return <>
                            {values.permissions.map((_, index) => {
                                return <div className="flex items-center mb-2" key={index}>
                                    <Input className="font-mono" placeholder="Permission" {...getFieldProps(`permissions.${index}`)} />
                                    <Button type="button" className="ml-2" size="icon" kind="destructive-text" onClick={e => {
                                        remove(index);
                                    }}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </div>;
                            })}
                            <Button type="button" kind="secondary" size="sm" onClick={e => {
                                push("");
                            }}>Add Permission</Button>
                        </>;
                    }} />
                    <ErrorText className="mt-4">{errors.permissions}</ErrorText>
                </div>
            </SettingsPanel>}
            <SaveChanges serverError={serverError} isSaved={isSaved} />
        </form>
    </FormikProvider>;
}

export function Role() {
    const { roleID } = useParams();
    const { data, error } = useRequest(() => RolesService.getRoleRolesRoleIdGet(roleID), [roleID]);
    const rolesContext = useContext(SettingsRolesContext);
    const navigate = useNavigate();

    const [role, setRole] = useState(null);

    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeleteError, setDeleteError] = useState(false);

    const close = () => {
        setDeleteOpen(false);
        setDeleteError(false);
    };

    useEffect(() => {
        setRole(data);
    }, [data]);

    useEffect(() => {
        if (role) rolesContext?.upsert(role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    return <OneColumnLayout title={role?.name ?? ""}>
        {role && <Main>
            <div className="mb-4">
                <StyledExternalLink role="button" href="#" onClick={e => {
                    e.preventDefault();
                    setDeleteOpen(true);
                }} kind="destructive">Delete Role</StyledExternalLink>
            </div>
            <RoleForm role={role} setRole={setRole} />
        </Main>}
        {!role && !error && <LoadingScreen messages={["Loading role..."]} />}
        {!role && error && <div className="p-4 flex items-center justify-center min-h-screen">
            <ErrorText>Couldn't load role. Try reloading the page.</ErrorText>
        </div>}
        <StyledDialog open={isDeleteOpen} onClose={() => {
            if (!isDeleting) close();
        }} borderClassName="border-red-500 dark:border-red-400">
            <BigIcon className="bg-red-500 dark:bg-red-400 mx-auto mb-4 text-white" icon={faExclamationTriangle}></BigIcon>
            <Dialog.Title className="heading mb-1 text-center">Delete {role ? `the role "${role.name}"` : "this role"}?</Dialog.Title>
            <Dialog.Description as="div" className="mb-6 w-full text-center">
                {isDeleting ? <LoadingText messages={[
                    "Deleting role...",
                ]} /> : "This role will be deleted, but users with this role will not be removed."}
            </Dialog.Description>
            {isDeleteError && <ErrorText className="w-full text-center">Couldn't delete this role. Try again?</ErrorText>}
            <div className="flex mt-4 w-full">
                <Button className="grow" kind="secondary" onClick={() => close()} disabled={isDeleting}>Cancel</Button>
                <div className="w-4"></div>
                <Button className="grow" kind="destructive" onClick={async () => {
                    setDeleting(true);
                    setDeleteError(false);
                    try {
                        await RolesService.deleteEventRoleRolesRoleIdDelete(roleID);
                        close();
                        rolesContext.remove(roleID);
                        navigate("..");
                    } catch (e) {
                        setDeleting(false);
                        setDeleteError(true);
                        console.error(e);
                    }
                }} disabled={isDeleting}>Delete</Button>
            </div>
        </StyledDialog>
    </OneColumnLayout>;
}
