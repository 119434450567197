import { Main } from '../../components/ui/Main';
import { NavLink } from 'react-router-dom';
import { BackButton } from '../../components/BackButton';
import { MasterDetailLayout } from '../../components/MasterDetailLayout';
import { LoadingText } from "../../components/ui/Loading";
import { useRequest } from "../../lib/API";
import { useEvent } from "../../components/EventLayout";
import { ErrorText } from "../../components/ui/Input";

export function Scoring() {
    const { id } = useEvent();

    /** @type {import("../../lib/API.js").RequestResult<Categories[]>} */
    const {data: categories, loading: catsLoading, error: catsError} = useRequest(api => api.get(`/events/${id}/categories`));

    return <MasterDetailLayout>
        <div className="mx-4 my-4">
            <div className="flex items-center mt-3 mb-6">
                <BackButton />
                <h1 className="title font-bold flex-grow">Scoring Categories</h1>
            </div>

            {catsLoading && <LoadingText className="text-center py-8 px-4" messages={["Loading categories..."]} />}
            {catsError && <ErrorText className="text-center py-8 px-4">Couldn't load categories. Try reloading the page.</ErrorText>}
            {categories && <ul className="list-none space-y-1">
                <li className="p-0">
                    <NavLink to="1" className={({ isActive }) => `p-1 rounded ${isActive ? "bg-blue-500 text-white" : "hover:bg-blue-100 active:bg-blue-200 dark:hover:bg-blue-900 dark:active:bg-blue-800"} block transition-colors`}>
                        <h2 className="subsubheading font-bold">Special Awards</h2>
                    </NavLink>
                </li>
                {categories.map(cat => <li className="p-0" key={cat.id}>
                    <NavLink to={cat.id} className={({ isActive }) => `p-1 rounded ${isActive ? "bg-blue-500 text-white" : "hover:bg-blue-100 active:bg-blue-200 dark:hover:bg-blue-900 dark:active:bg-blue-800"} block transition-colors`}>
                        <h2 className="subsubheading font-bold">{cat.name}</h2>
                    </NavLink>
                </li>)}
            </ul>}
        </div>
    </MasterDetailLayout>;
}

export function SelectCategory() {
    return <Main className="flex justify-center items-center h-full">
        <p>Select a category to see award details.</p>
    </Main>;
}
