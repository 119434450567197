/// <reference path="../../../lib/typings.d.ts" />

import { Main } from '../../../components/ui/Main';
import { Button } from '../../../components/ui/Button';
import { ErrorText, Input, Select } from '../../../components/ui/Input';
import { OneColumnLayout } from '../../../components/OneColumnLayout';
import { useEffect, useMemo, useState } from 'react';
import { useRequest } from '../../../lib/API.js';
import { useEvent } from '../../../components/EventLayout.tsx';
import { LoadingText } from '../../../components/ui/Loading';
import { BigGreenCheckmark, BigIcon } from '../../../components/ui/BigIcon';
import { getIndefiniteArticle } from '../../../lib/utils.js';
import { CopyButton } from '../../../components/CopyButton';
import { StyledExternalLink, StyledRouterLink } from '../../../components/ui/Link';
import { useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faExclamationCircle, faExclamationTriangle, faRedo, faTrash } from '@fortawesome/free-solid-svg-icons';
import { StyledDialog } from '../../../components/ui/Dialog';
import { Dialog } from '@headlessui/react';
import { useFormik } from "formik";
import Helmet from 'react-helmet';
import { InvitesService, RolesService } from "../../../client";

function RoleSelect({roles, role, onRoleChange, ...props}) {
    useEffect(() => {
        if (!role) {
            onRoleChange(roles[0]);
        }
    }, [roles, role, onRoleChange]);

    return <Select {...props} value={role?.id} onChange={event => onRoleChange(roles.find(role => role.id === event.target.value))}>
        {roles.map(role => <option key={role.id} value={role.id}>{role.name}</option>)}
    </Select>;
}

function AdminWarning({ open, onClose, onProceed }) {
    return <StyledDialog open={open} onClose={() => onClose()} borderClassName="border-yellow-500 dark:border-yellow-400">
        <BigIcon className="bg-yellow-500 dark:bg-yellow-400 mx-auto mb-4 text-black" icon={faExclamation}></BigIcon>
        <Dialog.Title className="heading mb-1 text-center">Generate an admin invite?</Dialog.Title>
        <Dialog.Description className="mb-6 w-full text-center">Anyone using this invite will have <strong>full control</strong> over this event, including removing other admins. Please be sure you want to do this.</Dialog.Description>
        <div className="flex mt-4 w-full">
            <Button className="grow" kind="secondary" onClick={() => onClose()}>Cancel</Button>
            <div className="w-4"></div>
            <Button className="grow" kind="warning" onClick={async () => {
                onProceed();
                onClose();
            }}>Proceed</Button>
        </div>
    </StyledDialog>;
}

function GenerateLinkPanel({roles, onGenerate}) {
    const [role, setRole] = useState(null);
    const [generationStatus, setGenerationStatus] = useState(null);
    const [isAdminWarningOpen, setAdminWarningOpen] = useState(false);
    const { name: eventName, id: eventID } = useEvent();

    useEffect(() => {
        if (generationStatus?.status === "loading") {
            let cancelled = false;
            (async () => {
                try {
                    const { id: inviteID } = await InvitesService.createEventInviteEventsEventIdInvitesPost(eventID, { type: "link" }, 
                        role.id);
                    if (!cancelled) {
                        setGenerationStatus({ status: "success", link: `${window.origin}/invite/${inviteID}` });
                        if (onGenerate) onGenerate();
                    }
                } catch (e) {
                    setGenerationStatus({status: "error"});
                }
            })();

            return () => {
                cancelled = true;
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generationStatus, eventID, role]);

    let content;
    if (generationStatus?.status === "loading") {
        content = <LoadingText className="py-8 text-center" messages={[
            "Preparing your invite...",
            "Performing arts and crafts...",
            "Applying sprinkles to your invite...",
        ]} />;
    } else if (generationStatus?.status === "success") {
        content = <div className="py-8 flex flex-col justify-center items-center text-center">
            <BigGreenCheckmark className="mb-4" />
            <h3 className="lead">Link generated!</h3>
            <p className="muted mb-4">Use this link to invite {getIndefiniteArticle(role.name)} {role.name} to {eventName}.</p>
            <div className="w-full max-w-lg mb-4 border border-gray-200 dark:border-gray-600 rounded flex items-center">
                <div className="relative flex-grow">
                    <input className="pl-4 py-2 w-full bg-transparent opacity-100" disabled defaultValue={generationStatus.link} />
                    <div className="bg-gradient-to-r from-white/0 to-white dark:from-gray-900/0 dark:to-gray-900 w-20 absolute top-0 bottom-0 right-0 pointer-events-none" />
                </div>
                <CopyButton className="ml-2 mr-[0.35rem]" text={generationStatus.link} />
            </div>
            <StyledExternalLink href="#" onClick={event => {
                event.preventDefault();
                setGenerationStatus(null);
            }}>Generate another link</StyledExternalLink>
        </div>;
    } else {
        content = <div>
            {generationStatus?.status === "error" && <ErrorText className="mb-2">Couldn't generate a link. Try again?</ErrorText>}
            {roles.loading && <LoadingText messages={["Loading roles..."]} />}
            {roles.error && <ErrorText>Couldn't load roles. Try reloading the page.</ErrorText>}
            {roles.data && <div className="flex items-middle">
                <RoleSelect className="flex-grow mr-1" roles={roles.data} role={role} onRoleChange={setRole} />
                <Button kind="primary" onClick={() => {
                    if (role?.admin) {
                        setAdminWarningOpen(true);
                    } else {
                        setGenerationStatus({ status: "loading" });
                    }
                }}>Generate {role?.name} Link</Button>
            </div>}
        </div>;
    }

    return <div className="shadow-lg rounded-lg p-4 w-full dark:shadow-none dark:border border-gray-700">
        <h2 className="subheading font-bold">Generate a link</h2>
        <p className="mb-2">Invite users by sending them a link.</p>
        {content}
        <AdminWarning open={isAdminWarningOpen} onClose={() => setAdminWarningOpen(false)} onProceed={() => setGenerationStatus({ status: "loading" })} />
    </div>;
}

function InviteActions({id, type, reload}) {
    const [isOpen, setOpen] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isError, setError] = useState(false);

    const close = () => {
        setOpen(false);
        setError(false);
    };

    return <>
        {type === "email" && <Button size="icon" kind="primary-text" onClick={async () => {
            try {
                await InvitesService.resendEventInviteInvitesInviteIdResendPut(id);
                close();
                reload();
            } catch (e) {
                setError(true);
                console.error(e);
            }
        }}>
            <FontAwesomeIcon icon={faRedo} />
            <span className="sr-only">Resend</span>
        </Button>}
        <Button size="icon" kind="destructive-text" onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faTrash} />
        </Button>
        <StyledDialog open={isOpen} onClose={() => {
            if (!isDeleting) close();
        }} borderClassName="border-red-500 dark:border-red-400">
            <BigIcon className="bg-red-500 dark:bg-red-400 mx-auto mb-4 text-white" icon={faExclamationTriangle}></BigIcon>
            <Dialog.Title className="heading mb-1 text-center">Delete this invite?</Dialog.Title>
            <Dialog.Description className="mb-6 w-full text-center">
                {isDeleting ? <LoadingText messages={[
                    "Deleting invite...",
                    "Nuking invite...",
                    "Disinviting guests...",
                ]} /> : "This invite will stop working immediately. You can't undo this action."}
            </Dialog.Description>
            {isError && <ErrorText className="w-full text-center">Couldn't delete the invite. Try again?</ErrorText>}
            <div className="flex mt-4 w-full">
                <Button className="grow" kind="secondary" onClick={() => close()} disabled={isDeleting}>Cancel</Button>
                <div className="w-4"></div>
                <Button className="grow" kind="destructive" onClick={async () => {
                    setDeleting(true);
                    try {
                        await InvitesService.deleteInviteInvitesInviteIdDelete(id);
                        close();
                        reload();
                    } catch (e) {
                        setError(true);
                        console.error(e);
                    } finally {
                        setDeleting(false);
                    }
                }} disabled={isDeleting}>Delete</Button>
            </div>
        </StyledDialog>
    </>;
}

function ManageInvites({data, loading, error, reload}) {    
    const tableOptions = useMemo(() => ({
        columns: [
            {
                Header: "Invite",
                accessor: ({id}) => {
                    return <StyledRouterLink to={`/invite/${id}`}>
                        <span className="font-mono">{id.slice(0, 6)}</span>...
                    </StyledRouterLink>;
                },
            },
            {
                Header: "Inviter",
                accessor: "inviter.name",
            },
            {
                Header: "Role",
                accessor: ({role}) => {
                    if (role) {
                        if (role.admin) {
                            return <><FontAwesomeIcon className="text-yellow-500 dark:text-yellow-400" icon={faExclamationCircle} /> {role.name}</>;
                        } else {
                            return role.name;
                        }
                    } else {
                        return "";
                    }
                },
            },
            {
                Header: "Type",
                accessor: ({type}) => {
                    if (type === "link") {
                        return "Link";
                    } else if (type === "email") {
                        return "Email";
                    } else {
                        return "This is a broken type Edward please fix thissfljashfijqhweroiqweoiruyqweoiruyqweoiruqwoeiryqwoieruyosdifjghkdffghksdjfhlkajdshflkjasdhflajsdhflkjasdhflkajsdhflajsdhfo9iuawyeroiqwyeroiqweyrtoiwyertowuyefotiuqyeortiuywqeoiruyqwoeiruy";
                    }
                },
            },
            {
                Header: "Uses",
                accessor: ({num_users}) => {
                    return <span className={num_users ? "" : "opacity-30"}>{num_users.toLocaleString()}</span>;
                },
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Actions",
                accessor: ({id, type}) => {
                    return <InviteActions id={id} type={type} reload={reload} />;
                },
            },
        ],
        data: data || [],
    }), [data, reload]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(tableOptions);

    return <div className="shadow-lg rounded-lg w-full dark:shadow-none dark:border border-gray-700">
        <div className="px-4 pt-4">
            <h2 className="subheading font-bold">Manage invites</h2>
            <p className="mb-4">See and delete the invites you've created.</p>
            {loading && <LoadingText messages={[
                "Loading invites...",
                "Simulating birthday party...",
            ]} />}
            {error && <ErrorText>Couldn't load invites. Try reloading the page.</ErrorText>}
        </div>
        {data && <div className="w-full overflow-x-auto">
            <table className="min-w-full" {...getTableProps()}>
                <thead className="sticky top-0 bg-gray-200 dark:bg-gray-700">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th className="first-of-type:pl-4 last-of-type:pr-4 px-3 py-2 text-center" {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return <tr className="even:bg-gray-100 dark:even:bg-gray-800" {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td className="px-3 py-1 text-center" {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                </td>
                            ))}
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>}
        {data && data.length === 0 && <div className="w-full flex justify-center items-center px-4 py-8">
            <div>You don't have any invites yet. Create some to see them here.</div>
        </div>}
    </div>;
}

export function NewUserForm() {
    const { id } = useEvent();
    //const navigate = useNavigate();
    const [serverError, setServerError] = useState(null);
    
    const invitesRequest = useRequest(() => InvitesService.listEventInvitesEventsEventIdInvitesGet(id));
    const roles = useRequest(() => RolesService.listEventRolesEventsEventIdRolesGet(id));
    const [emailUserRole, setEmailUserRole] = useState(null);

    const { handleSubmit, getFieldProps, resetForm, isSubmitting, isValid } = useFormik({
        initialValues: {
            email: "",
        },
        validate: ({ email }) => {
            const errors = {};
            if (!email) {
                errors.email = "Required";
            }
            return errors;
        },
        onSubmit: async ({email}) => {
            setServerError(null);
            try {
                await InvitesService.createEventInviteEventsEventIdInvitesPost(id, {
                    type: "email", 
                    email: email,
                }, emailUserRole.id);
                resetForm();
                invitesRequest.reload();
            } catch (e) {
                console.error(e);
                setServerError(e);
            }
        },
    });
    const [isEmailAdminWarningOpen, setEmailAdminWarningOpen] = useState(false);

    return <OneColumnLayout title="Invite Users">
        <Helmet><title>Invite users</title></Helmet>
        <Main>
            <GenerateLinkPanel roles={roles} onGenerate={() => invitesRequest.reload()} />

            <form onSubmit={handleSubmit} className="shadow-lg my-4 rounded-lg dark:shadow-none dark:border border-gray-700 p-4 w-full">
                <h2 className="subheading font-bold">Invite by email</h2>
                <p>Enter an email to invite a student, judge, or admin:</p>
                {roles.loading && <LoadingText messages={["Loading roles..."]} />}
                {roles.error && <ErrorText>Couldn't load roles. Try reloading the page.</ErrorText>}
                {roles.data && <div className="flex mt-3 mb-2 flex-col sm:flex-row">
                    <Input {...getFieldProps("email")} required className="flex-grow sm:mr-2 sm:mb-0 mb-2" type="email" placeholder="me@example.com" />
                    <RoleSelect roles={roles.data.sort((a, b) => a.order - b.order)} role={emailUserRole} onRoleChange={setEmailUserRole} />
                </div>}
                <Button disabled={isSubmitting || !isValid} size="md" kind="primary" id="invite-button" type="submit" onClick={event => {
                    if (emailUserRole?.admin) {
                        event.preventDefault();
                        setEmailAdminWarningOpen(true);
                    }
                }} className="w-full">
                    Invite {emailUserRole?.name ?? "User"}
                </Button>
                <AdminWarning open={isEmailAdminWarningOpen} onClose={() => setEmailAdminWarningOpen(false)} onProceed={() => handleSubmit()} />
            </form>
            <ManageInvites {...invitesRequest} />
        </Main>
    </OneColumnLayout>;
}
